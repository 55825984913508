<div
  *ngIf="bulkCreate?.percentage || bulkCreate?.isError"
  class="alert alert-secondary"
  [ngClass]="{'alert-dismissible fade show': isCompleted || bulkCreate?.isError, 'alert-success ': isCompleted, 'alert-danger': bulkCreate?.isError && isCompleted}">
  <button *ngIf="isCompleted || bulkCreate?.isError" type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="onCloseClicked()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="progress-text">
    <p>{{text}}</p>
    <p *ngIf="bulkCreate?.errorMessage?.length" class="error-text mb-1" [innerHTML]="bulkCreate?.errorMessage"></p>
  </div>
  <div class="warning-text mb-2" *ngIf="bulkCreate?.areDuplicatesAvoided">
    <p>Some of the records were updated to avoid duplicates.</p>
  </div>
  <div class="progress" *ngIf="!isCompleted">
    <div class="progress_container">
      <div [style.transform]="'translateX(-' + (100 - (bulkCreate?.percentage || 100)) + '%)'" class="progress_bar" [ngClass]="{'completed': isCompleted}"></div>
    </div>
  </div>
  <div class="progress-percentage mt-1">
    <p>{{bulkCreate?.percentage}}% completed</p>
  </div>

</div>
