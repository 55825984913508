import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { first } from 'rxjs/operators';

import { User } from '../shared/models/user.model';
import { EguideService } from '../eguide/eguide.service';
import { NotificationService } from '../shared/notifications.service';
import { SessionStateService } from '../shared/session-state.service';
import { Notification } from '../shared/models/notification.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  delay = 250;
  isSimulated = false;
  adminGuides: any[] = [];

  constructor(
    private sessionStateService: SessionStateService,
    private notificationService: NotificationService,
    private eguideService: EguideService,
    private router: Router) { }

  ngOnInit(): void {
    const user = this.sessionStateService.get()?.user as User;

    if (!user) {
      return;
    }

    setTimeout(() => {
      this.notificationService.list(((user.roles.indexOf('account-admin') > -1) || (user.roles.indexOf('user-guide-manager') > -1)) ? undefined : user.username, true).subscribe(adminGuides => {
        this.adminGuides = adminGuides;

        this.adminGuides.forEach(notification => {
          this.eguideService.lookupByUuid(notification.guideId).subscribe(guide => {
            notification.guideName = guide.name;
            notification.owner = guide.owner;
          });
        });
      });
    },
    2000);

    this.isSimulated = !!this.sessionStateService.get().session.isSimulated;
  }

  goToGuide(notification: any): void {
    const user = this.sessionStateService.get()?.user as User;
    const { guideName, owner, ...originalNotification } = notification;

    if (guideName) {
      this.router.navigate(['guides', guideName], { queryParams: { user: owner } });
    }

    if (user.roles.indexOf('account-admin') === -1) {
      originalNotification.read = true;
      this.notificationService.update(originalNotification).pipe(first()).subscribe(() => {});
    }
  }
}
