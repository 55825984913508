import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { first, map, switchMap, take } from 'rxjs/operators';

import { Candidate } from '../candidate.model';
import { CandidatesActions } from '../candidates.actions';
import { CandidatesState } from '../candidates.state';

import { EmailType } from '../../../shared/models/email.model';
import { PhoneType } from '../../../shared/models/phone.model';
import { Note } from '../../../shared/widgets/notes/note.model';

import { ContentService } from '../../../shared/content.service';
import { SessionStateService } from '../../../shared/session-state.service';

import { CommonDialogComponent } from '../../../shared/common-dialog/common-dialog.component';
import {LocationAddress} from '../../../shared/models/location-address.model';
import { CandidatesService } from '../candidates.service';

@Component({
  selector: 'app-candidate-detail',
  templateUrl: './candidate-detail.component.html',
  styleUrls: ['./candidate-detail.component.scss']
})
export class CandidateDetailComponent implements OnInit, OnDestroy {

  // @ts-ignore
  @Select(CandidatesState.candidates) candidates$: Observable<Candidate[]>;
  // @ts-ignore
  @Select(CandidatesState.currentCandidates) currentCandidates$: Observable<{[key: string]: Candidate}>;

  isUnique$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  current$: Observable<Candidate | null> =
    of({ firstName: '', lastName: '', title: '', active: false, private: false, emails: [], phoneNumbers: [], addresses: [] });

  uuid = '';

  form: FormGroup;
  formSubscription: Subscription | null = null;
  initSubscription: Subscription | null = null;

  uploadedItem: File | null;

  EmailType = EmailType;
  PhoneType = PhoneType;

  initialNotes: Note[] = [];

  isSimulated = false;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private store: Store,
    private sessionStateService: SessionStateService,
    private contentService: ContentService,
    private candidatesService: CandidatesService,
  ) {
    this.uploadedItem = null;

    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      title: ['', [Validators.required]],
      employer: ['', [Validators.required]],
      location: [''],
      address2: [''],
      state: [''],
      city: [''],
      zip: [''],
      country: [''],
      skills: [''],
      memo: ['']
    });
  }

  ngOnInit(): void {
    const session = this.sessionStateService.get();
    this.isSimulated = !!session.session.simulatedSession;

    this.current$ = this.currentCandidates$.pipe(switchMap(currentCandidates => {
      if (this.uuid) {
        const preloadedCandidate = currentCandidates[this.uuid];
        if (preloadedCandidate) {
          return of(preloadedCandidate);
        }
        return this.candidatesService.lookup(this.uuid).pipe(take(1));

      } else {
        return  of({ firstName: '', lastName: '', title: '', active: false, private: false, emails: [], phoneNumbers: [], addresses: [] });
      }
    }));

    this.candidates$.pipe(first()).subscribe(candidates => {
      const unique = candidates.filter(candidateCheck => (candidateCheck.uuid !== this.uuid) &&
        (candidateCheck.firstName === this.f.firstName.value && candidateCheck.lastName === this.f.lastName.value)).length === 0;
      this.isUnique$.next(unique);
    });

    // @ts-ignore
    this.initSubscription = this.current$.subscribe((candidate: Candidate | null) => {
      if (candidate) {
        this.form.patchValue({
          firstName: candidate.firstName,
          lastName: candidate.lastName,
          title: candidate.title,
          employer: candidate.employer,
          location: candidate.location,
          address2: candidate.address2,
          state: candidate.state,
          city: candidate.city,
          zip: candidate.zip,
          country: candidate.country,
          skills: candidate.skills,
          memo: candidate.memo
        });

        if (this.initSubscription) {
          this.initSubscription.unsubscribe();
          this.initSubscription = null;
        }
      }

      setTimeout(() => {
        Object.values(this.form.controls).forEach(control => {
          control.markAsDirty();
        });

        this.formSubscription = this.form.valueChanges.subscribe(() => {
          this.updateMethodBackend();

          this.candidates$.pipe(first()).subscribe(candidates => {
            const unique = candidates.filter(candidateCheck => (candidateCheck.uuid !== this.uuid) &&
              (candidateCheck.firstName === this.f.firstName.value && candidateCheck.lastName === this.f.lastName.value)).length === 0;
            this.isUnique$.next(unique);
          });
        });
      }, 200);
    });
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }

    if (this.initSubscription) {
      this.initSubscription.unsubscribe();
      this.initSubscription = null;
    }
  }

  updateMethodBackend(): void {
    if (this.isSimulated) {
      return;
    }

    const candidate: any = {
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      title: this.f.title.value,
      location: this.f.location.value,
      address2: this.f.address2.value,
      state: this.f.state.value,
      city: this.f.city.value,
      zip: this.f.zip.value,
      country: this.f.country.value,
      employer: this.f.employer.value,
      skills: this.f.skills.value,
      memo: this.f.memo.value
    };

    this.store.dispatch(new CandidatesActions.UpdateCurrent({ uuid: this.uuid, candidate }));
  }

  save(another?: boolean): void {
    if (this.isSimulated) {
      return;
    }

    this.updateMethodBackend();

    this.activeModal.close({ initialNotes: this.initialNotes, uploadedItem: this.uploadedItem, another });
  }

  close(del?: boolean): void {
    if (del) {
      const modalRef = this.modalService.open(CommonDialogComponent);

      modalRef.componentInstance.closeButtonText = 'Cancel';
      modalRef.componentInstance.actionButtonText = 'Delete';
      modalRef.componentInstance.headerText = 'Delete Candidate';
      modalRef.componentInstance.bodyText = 'Are you sure you want to delete this candidate?';

      modalRef.result.then((result) => {
        this.activeModal.dismiss({ delete: true });
      }).catch((error) => { });
    } else {
      this.activeModal.dismiss();
    }
  }

  addEmail(): void {
    if (this.isSimulated) {
      return;
    }

    this.store.dispatch(new CandidatesActions.AddEmail({ uuid: this.uuid }));
  }

  setEmailType(index: number, select: EventTarget | null): void {
    if (select) {
      const value = ((select as HTMLSelectElement).value as unknown);
      const type: EmailType = (value as EmailType);

      this.store.dispatch(new CandidatesActions.SetEmailType({ uuid: this.uuid, index, type }));
    }
  }

  setEmailAddress(index: number, input: EventTarget | null): void {
    const address = (input as HTMLInputElement).value;

    this.store.dispatch(new CandidatesActions.SetEmailAddress({ uuid: this.uuid, index, address }));
  }

  removeEmail(index: number): void {
    if (this.isSimulated) {
      return;
    }

    this.store.dispatch(new CandidatesActions.RemoveEmail({ uuid: this.uuid, index }));
  }

  addPhone(): void {
    if (this.isSimulated) {
      return;
    }

    this.store.dispatch(new CandidatesActions.AddPhone({ uuid: this.uuid }));
  }

  setPhoneType(index: number, select: EventTarget | null): void {
    if (select) {
      const value = ((select as HTMLSelectElement).value as unknown);
      const type: PhoneType = (value as PhoneType);

      this.store.dispatch(new CandidatesActions.SetPhoneType({ uuid: this.uuid, index, type }));
    }
  }

  setPhoneNumber(index: number, input: EventTarget | null): void {
    const phone = (input as HTMLInputElement).value;

    this.store.dispatch(new CandidatesActions.SetPhoneNumber({ uuid: this.uuid, index, phoneNumber: phone }));
  }

  removePhone(index: number): void {
    if (this.isSimulated) {
      return;
    }

    this.store.dispatch(new CandidatesActions.RemovePhone({ uuid: this.uuid, index }));
  }

  onFileChange(event: Event): void {
    if (event && event.target) {
      const files = (event.target as HTMLInputElement).files;

      if (files && files.length) {
        this.uploadedItem = files[0];
      }
    }
  }

  resumeDownload(candidate: Candidate): void {
    const csrfToken = this.sessionStateService.getSessionToken();

    const link = document.createElement('a');
    link.setAttribute('href',
      this.contentService.getDownloadPath('users/' + candidate.uuid + '/resumes/' + candidate.resume + '?csrf=' + csrfToken));
    document.body.appendChild(link); // Required for FF
    link.click();
  }

  addToPlan(): void {
    if (this.isSimulated) {
      return;
    }

    this.store.dispatch(new CandidatesActions.AddToPlan({ uuid: this.uuid }));
  }

  notesChanged(notes: Note[]): void {
    this.initialNotes = notes;
  }

  // convenience getter for easy access to form fields
  get f(): {[key: string]: AbstractControl} { return this.form.controls; }

  onAddressFormValueChange(v: LocationAddress): void {
    this.form.patchValue({...this.form.value, ...v});
  }
}
