import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

import { AlertService } from '../shared/alert.service';
import { ServerService } from '../shared/server.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(
    private http: HttpClient,
    private serverService: ServerService,
    private alertService: AlertService) { }

  merge(template: string, uuid: string): Observable<any> {
    return this.http.get(this.serverService.api('/documents/merge/' + template + '/' + uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to merge document ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  /**
   *
   * @param document = { name: 'valid document name such as Innerview, SearchAssignment, ReferenceCheck',
   *                     uuid: the uuid of the document,
   *                     recipients: [array] of user email addresses
   *                     }
   */
  share(document: any): Observable<any> {
    return this.http.post(this.serverService.api('/documents/share'), { document }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to share document ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  download(name: string, fields: any): Observable<any> {
    return this.http.post(this.serverService.api('/documents/download'), { name, fields }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to download document ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }
}
