import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  private apiHttpConfig = {
    withCredentials: true
  };

  // private appConfig = {
  //   reCaptchaPublic: '<%= reCaptchaPublic %>',
  //   apiDomain: 'https://api.recruitersplaybook.com', // https://rpb2.herokuapp.com', https://my.doma.in
  //   apiPort: '8080', // '<%= apiPort %>',
  //   rootPath: '/api' // '<%= rootPath %>',
  // };

  constructor() { }

  apiConfig(): any {
    return _.clone(this.apiHttpConfig);
  }

  api(endPoint: string, pathParam: string = '', pathParam2: string = '', pathParam3: string = '', pathParam4: string = ''): string {
    let url = '';

    // url = (!!environment.apiDomain ? environment.apiDomain :
    //   (location.protocol + '//' + location.hostname + ':' + environment.apiPort)) + '/api' + endPoint;

    url = environment.apiDomain + '/api' + endPoint;

    if (!!pathParam) {
      url += '/' + pathParam;
    }

    if (!!pathParam2) {
      url += '/' + pathParam2;
    }

    if (!!pathParam3) {
      url += '/' + pathParam3;
    }

    if (!!pathParam4) {
      url += '/' + pathParam4;
    }

    return url;
  }

  path(path: string): string {
    let url = '';

    // if (location.hostname === 'localhost') {
    //   url = location.protocol + '//' + location.hostname + ':' + environment.apiPort + '/' + path;
    // } else {
    //   url = (!!environment.apiDomain ? environment.apiDomain : (location.protocol + '//' + location.hostname)) + '/' + path;
    // }
    url = environment.apiDomain + '/' + path;

    return url;
  }
}
