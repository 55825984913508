import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Observable, Subscription, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';

import { SearchString } from '../strings.model';
import { StringsActions } from '../strings.actions';
import { StringsState } from '../strings.state';

@Component({
  selector: 'app-string-detail',
  templateUrl: './string-detail.component.html',
  styleUrls: ['./string-detail.component.scss']
})
export class StringDetailComponent implements OnInit, OnDestroy {

  // @ts-ignore
  @Select(StringsState.currentStrings) currentStrings$: Observable<{[key: string]: SearchString}>;

  current$: Observable<SearchString | null> =
    of({ name: '', displayName: '', string: '', type: 'google' });

  uuid = '';

  stringForm: FormGroup;
  formSubscription: Subscription | null = null;
  stringSubscription: Subscription | null = null;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private store: Store
  ) {
    this.stringForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      displayName: ['', [Validators.required]],
      string: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.current$ = this.currentStrings$.pipe(map(currentStrings => {
      return currentStrings[this.uuid] ||
        { name: '', displayName: '', string: '', type: 'google' };
    }));

    // @ts-ignore
    this.stringSubscription = this.current$.subscribe((searchString: SearchString | null) => {
      if (searchString) {
        this.stringForm.patchValue({
          name: searchString.name,
          displayName: searchString.displayName,
          string: searchString.string
        });

        if (this.stringSubscription) {
          this.stringSubscription.unsubscribe();
          this.stringSubscription = null;
        }
      }
    });

    this.formSubscription = this.stringForm.valueChanges.subscribe(() => {
      this.updateMethodBackend();
    });
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }

    if (this.stringSubscription) {
      this.stringSubscription.unsubscribe();
    }
  }

  updateMethodBackend(): void {
    const searchString = {
      name: this.f.name.value,
      displayName: this.f.displayName.value,
      string: this.f.string.value,
      type: 'google'
    };

    this.store.dispatch(new StringsActions.UpdateCurrent({ uuid: this.uuid, searchString }));
  }

  save(): void {
    this.updateMethodBackend();

    this.activeModal.close();
  }

  close(): void {
    this.activeModal.dismiss();
  }

  // convenience getter for easy access to form fields
  get f(): {[key: string]: AbstractControl} { return this.stringForm.controls; }
}
