<div *ngIf="step" class="step" [ngClass]="{'step__active': step.isActive, 'step__last': isLast}">
  <div class="step_header"  (click)="stepIconClicked.emit()">
    <div class="step_icon" >
      {{step.index}}
    </div>
    <h5 class="step_title">{{step.title}}</h5>
  </div>
  <div class="step_body">
    <div class="step_content">
      <ng-content select="[content]"></ng-content>
    </div>
    <div class="step_footer">
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</div>
