<div class="rpb-widget">
  <div class="rpb-widget_header">
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="m-0">Data Transport Options</h5>
    </div>
  </div>
  <div class="rpb-widget_body">
    <ng-container *ngIf="items?.length; else noData">
      <div class="card-content" #cardContent>
        <table class="export-search-table">
          <thead>
          <tr>
            <th class="num-cell"></th>
            <th class="title-cell">Title</th>
            <th class="link-cell">Link</th>
            <th class="actions-cell">Add to planner</th>
            <th class="actions-cell">Remove</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of items; let i = index" [ngClass]="{'added-to-planner': item.isAddedToPlanner}">
            <td class="num-cell">{{i + 1}}</td>
            <td class="title-cell title-value">{{item.searchTitle}}</td>
            <td class="link-cell link-value">{{item.link}}</td>
            <td class="actions-cell">
              <i class="fas fa-plus" role="button" ngbTooltip="Add to planner"  data-toggle="tooltip" data-placement="top" (click)="onAddToPlanerClick(item)"></i>
            </td>
            <td class="actions-cell">
              <i class="fa fa-times" role="button" ngbTooltip="Delete Row" data-toggle="tooltip" data-placement="top" (click)="onRemoveClick(i)"></i>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-end mt-4">
        <button type="button" class="btn btn-primary" (click)="onExportClick()">Export in csv</button>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #noData>
  <app-no-data [text]="'Please, select items'"></app-no-data>
</ng-template>
