<ngb-accordion #acc="ngbAccordion" [activeIds]="activeId">
  <ngb-panel id="ngb-source-card">

    <ng-template ngbPanelHeader let-opened="opened">
      <ng-container *ngIf="withHeader">
        <div class="row m-0">
          <div class="col-12 widget-header">Source Card
            <button ngbPanelToggle id="source-card-collapser" class="btn btn-outline-secondary float-right">
              <i [hidden]="opened" class="fas fa-plus"></i>
              <i [hidden]="!opened" class="fas fa-minus"></i>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-template>

    <ng-template ngbPanelContent>
      <div class="container-fluid">
        <div class="widget-body pl-0 pr-0">

          <form>
            <div class="form-group">
              <div class="col-12 p-0">
                <input type="text" class="form-control" [(ngModel)]="firstName" name="firstName" placeholder="First Name" />
              </div>
            </div>

            <div class="form-group">
              <div class="col-12 p-0">
                <input type="text" class="form-control" [(ngModel)]="lastName" name="lastName" placeholder="Last Name" />
              </div>
            </div>

            <div class="form-group">
              <div class="col-12 p-0">
                <input type="text" class="form-control" [(ngModel)]="company" name="company" placeholder="Company" />
              </div>
            </div>

            <div class="form-group">
              <div class="col-12 p-0">
                <input type="text" class="form-control" [(ngModel)]="title" name="title" placeholder="Title" />
              </div>
            </div>

            <div class="form-group">
              <div class="col-12 p-0">
                <input type="text" class="form-control" [(ngModel)]="phone" name="phone" mask="(000) 000-0000" [showMaskTyped]="true" placeholder="Phone" />
              </div>
            </div>

            <div class="form-group">
              <div class="col-12 p-0">
                <input type="email" class="form-control" [(ngModel)]="email" name="email" placeholder="Email" />
              </div>
            </div>

            <div class="form-group">
              <div class="col-12 p-0">
                <textarea rows="3" class="form-control" [(ngModel)]="notes" name="notes" placeholder="Notes"></textarea>
              </div>
            </div>

            <div class="form-group mb-1 small-text">
              <p class="mb-1"><strong>Planner Options</strong></p>
              <div class="row m-0">
                <div class="col-6 p-0">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="addToRec" name="addToRec" [(ngModel)]="addToRec" />
                    <label class="form-check-label" for="addToRec">
                    Add to Rec. Calls / DB
                    </label>
                  </div>
                </div>
                <div class="col-6 p-0">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="addToMkt" name="addToMkt" [(ngModel)]="addToMkt" />
                    <label class="form-check-label" for="addToMkt">
                      Add to Mkt. Calls / DB
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <button type="button" #sourceCardSaveBtn id="sourceCardSaveBtn" class="btn btn-danger w-100 mt-1 mb-0" (click)="save()" [disabled]="isDisabled()">Save</button>
            <button type="submit" disabled style="display: none" aria-hidden="true"></button>
          </form>

        </div>
      </div>
    </ng-template>

  </ngb-panel>
</ngb-accordion>
