import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export interface Step {
  isActive: boolean;
  title: string;
  index: number;
  isValid: boolean;
}
@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {
  @Input() step?: Step;
  @Input() isLast = false;

  @Output() stepIconClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }


}
