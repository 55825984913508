<div class="widget">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 widget-header">Client</div>
        </div>
        <div class="widget-body">
            <div class="row">
                <div class="col-12 p-0">
                    <select class="col-12 pl-0" [(ngModel)]="clientUuid" (change)="clientChanged()">
                        <option *ngFor="let client of clients; trackBy: trackByUuid" [value]="client.uuid">{{ client.company + ', ' + client.firstName + ' ' + client.lastName }}</option>
                    </select>
                    <div *ngIf="!!client" class="col-12" id="clientName">{{ client.firstName }} {{ client.lastName }}<br />{{client.title}}</div>
                </div>
            </div>
            <div class="row d-flex justify-content-around">
                <button type="button" class="btn btn-danger btn-sm" (click)="viewJO()" [hidden]="!clientHasJO()">View JO</button>
                <button type="button" class="btn btn-danger btn-sm" (click)="viewClient()">View Details</button>
            </div>
        </div>
    </div>
</div>
