import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss']
})
export class AddressDialogComponent implements OnInit {

  address: any = null;
  form: FormGroup;

  constructor(private formBuilder: FormBuilder, private activeModal: NgbActiveModal) {
    this.form = this.formBuilder.group({
      address1: ['', [Validators.required]],
      address2: [''],
      city: [''],
      state: [''],
      zip: [''],
      country: ['']
    });
  }

  ngOnInit(): void {
    if (this.address) {
      this.form.patchValue({
        address1: this.address.address1,
        address2: this.address.address2,
        city: this.address.city,
        state: this.address.state,
        zip: this.address.zip,
        country: this.address.country
      });
    }
  }

  save(another?: boolean): void {
    const address = {
      address1: this.f.address1.value,
      address2: this.f.address2.value,
      city: this.f.city.value,
      state: this.f.state.value,
      zip: this.f.zip.value,
      country: this.f.country.value
    };

    this.activeModal.close({ address });
  }

  close(): void {
    this.activeModal.dismiss();
  }

  // convenience getter for easy access to form fields
  get f(): {[key: string]: AbstractControl} { return this.form.controls; }
}
