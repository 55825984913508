<div class="container-fluid">
    <!-- Page Content -->
    <div class="container-inner">
        <div class="img-container">
            <nav class="navbar navbar-inverse navbar-expand-md w-100" role="navigation">
                <!-- Brand and toggle get grouped for better mobile display -->
                <div class="navbar-header">
                    <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#bs-navbar-collapse-1">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                </div>
                <!-- Collect the nav links, forms, and other content for toggling -->
                <div class="collapse navbar-collapse justify-content-start" id="bs-navbar-collapse-1">
                    <ul class="nav navbar-nav ml-auto">
                        <li [hidden]="loggedIn" class="pl-3 pr-3 login-link">
                            <a (click)="login()">Login</a>
                        </li>
                        <li [hidden]="!loggedIn" class="pl-3 pr-3 login-link">
                            <a href="/dashboard">Dashboard</a>
                        </li>
                    </ul>
                </div>
                <!-- /.navbar-collapse -->
            </nav>

            <img class="main-background" src="assets/images/background.jpg" />
            <img class="logo logo-below" src="assets/images/MorganRecruitersPlaybookLogo.png" />
            <span id="tagline">Everything you need.<br />Nothing more.</span>
        </div>
        <img class="logo" src="assets/images/MorganMentor2.png" />
    </div>
    <div id="bottom">&copy; 2022 Morgan Mentor<a class="ml-4" href="/terms">Terms and conditions</a></div>
</div> 