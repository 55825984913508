import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { User } from '../../models/user.model';
import { UsersService } from '../../users.service';

import { Objection, Rebuttal } from './objection.model';
import { ObjectionsService } from './objections.service';
import { ObjectionComponent } from './objection/objection.component';
import { SessionStateService } from '../../session-state.service';

@Component({
  selector: 'app-objections',
  templateUrl: './objections.component.html',
  styleUrls: ['./objections.component.scss']
})
export class ObjectionsComponent implements OnInit {

  @Input() ownerUUID = '';
  @Input() withHeader = false;

  private internalGuideName = '';

  @Input() set guideName(value: string) {
    this.internalGuideName = value;
    this.refresh();
  }
  get guideName(): string {
    return this.internalGuideName;
  }

  isAdmin = false;
  objections: Objection[] = [];

  modalRef: any = null;

  constructor(
    private modalService: NgbModal,
    private usersService: UsersService,
    private objectionsService: ObjectionsService,
    private sessionStateService: SessionStateService) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(): void {
    this.usersService.loginStatus().subscribe((sessionData) => {
      const roles = sessionData.status.roles;

      this.isAdmin = roles.indexOf('admin') > -1;

      if (!this.isAdmin) {
        this.objectionsService.listByUser(this.internalGuideName, this.ownerUUID).subscribe((objections) => {
          this.objections = objections;
        });
      } else {
        this.objectionsService.listByMorgan(this.internalGuideName).subscribe((objections) => {
          this.objections = objections;
        });
      }
    });
  }

  add(): void {
    if (this.modalRef) {
      return;
    }

    const ngbModalOptions: NgbModalOptions = {
      size: 'lg',
      windowClass: 'free-floating-modal',
      backdrop: false
    };

    const modalRef = this.modalRef = this.modalService.open(ObjectionComponent, ngbModalOptions);
    modalRef.componentInstance.isNew = true;

    modalRef.result.then(({ objection, rebuttals }) => {
      const session = this.sessionStateService.get();
      const user = (session.user as User);

      this.modalRef = null;
      objection.createdBy = user.username;
      objection.guide = this.internalGuideName;

      if (!this.isAdmin) {
        objection.user_uuid = user.uuid || '';
        objection.account_uuid = user.account_uuid;

        this.objectionsService.insertByUser(objection, rebuttals)
          .subscribe(() => { this.refresh(); });
      } else {
        this.objectionsService.insertByMorgan(objection, rebuttals)
          .subscribe(() => { this.refresh(); });
      }
    }).catch((error) => { this.modalRef = null; });
  }

  edit(index: number): void {
    if (this.modalRef) {
      this.modalRef.componentInstance.objection = this.objections[index];
      this.modalRef.componentInstance.reload();
      return;
    }

    const ngbModalOptions: NgbModalOptions = {
      size: 'lg',
      windowClass: 'free-floating-modal',
      backdrop: false
    };

    const modalRef = this.modalRef = this.modalService.open(ObjectionComponent, ngbModalOptions);
    modalRef.componentInstance.objection = this.objections[index];

    modalRef.result.then(objection => {
      this.modalRef = null;

      if ((objection as string) === 'remove') {
        if (!this.isAdmin) {
          this.objectionsService.removeByUser(this.objections[index].uuid as string)
            .subscribe(() => { this.refresh(); });
        } else {
          this.objectionsService.removeByMorgan(this.objections[index].uuid as string)
            .subscribe(() => { this.refresh(); });
        }
      } else {
        objection = objection.objection;  // nested object;

        if (!this.isAdmin) {
          const session = this.sessionStateService.get();
          const user = (session.user as User);

          (objection as Objection).user_uuid = user.uuid || '';
          (objection as Objection).account_uuid = user.account_uuid;

          this.objectionsService.updateByUser(objection as Objection)
            .subscribe(() => { this.refresh(); });
        }
        else {
          this.objectionsService.updateByMorgan(objection as Objection)
            .subscribe(() => { this.refresh(); });
        }
      }
    }).catch((error) => { this.modalRef = null; });
  }
}
