import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TableModule } from 'ngx-easy-table';
import { AngularSplitModule } from 'angular-split';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgToggleModule } from 'ng-toggle-button';
import { NgxCurrencyModule } from 'ngx-currency';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccountsComponent } from './admin/accounts/accounts.component';
import { CandidatesComponent } from './admin/candidates/candidates.component';
import { ClientsComponent } from './admin/clients/clients.component';
import { SessionsComponent } from './admin/sessions/sessions.component';
import { StringsComponent } from './admin/strings/strings.component';
import { UsersComponent } from './admin/users/users.component';
import { CheatsheetComponent } from './cheatsheet/cheatsheet.component';
import { DailyplannerComponent } from './dailyplanner/dailyplanner.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InnerviewComponent } from './documents/innerview/innerview.component';
import { ReferencecheckComponent } from './documents/referencecheck/referencecheck.component';
import { SearchassignmentComponent } from './documents/searchassignment/searchassignment.component';
import { EguideComponent } from './eguide/eguide.component';
import { EsourcingComponent } from './esourcing/esourcing.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { MorganrescueComponent } from './morganrescue/morganrescue.component';
import { MorginarsComponent } from './morginars/morginars.component';
import { NotesComponent } from './shared/widgets/notes/notes.component';
import { TermsComponent } from './terms/terms.component';
import { SettingsComponent } from './admin/settings/settings.component';
import { CandidatesWidgetComponent } from './shared/widgets/candidates/candidates.component';
import { ClientsWidgetComponent } from './shared/widgets/clients/clients.component';
import { ObjectionsComponent } from './shared/widgets/objections/objections.component';

import { LoginDialogComponent } from './login/dialogs/login/login.component';
import { CommonDialogComponent } from './shared/common-dialog/common-dialog.component';
import { StringDetailComponent } from './admin/strings/string-detail/string-detail.component';
import { ClientDetailComponent } from './admin/clients/client-detail/client-detail.component';
import { CandidateDetailComponent } from './admin/candidates/candidate-detail/candidate-detail.component';
import { AccountDetailComponent } from './admin/accounts/account-detail/account-detail.component';

import { ToolbarComponent } from './shared/toolbar/toolbar.component';
import { AlertComponent } from './shared/alert/alert.component';

import { AlertService } from './shared/alert.service';
import { UsersService } from './shared/users.service';
import { SessionStateService } from './shared/session-state.service';
import { MenuService } from './header/menu.service';

import { TokenInterceptor } from './shared/token.interceptor';

import { SessionState } from './admin/sessions/sessions.state';
import { StringsState } from './admin/strings/strings.state';
import { ClientsState } from './admin/clients/clients.state';
import { CandidatesState } from './admin/candidates/candidates.state';
import { AccountsState } from './admin/accounts/accounts.state';
import { UsersState } from './admin/users/users.state';

import { FocusNewInputDirective } from './shared/directives/focus-new-input.directive';
import { AutofocusDirective } from './shared/directives/autofocus.directive';
import { AutoopenDirective } from './header/autoopen.directive';
import { NoteComponent } from './shared/widgets/notes/note/note.component';
import { AddressDialogComponent } from './shared/address-dialog/address-dialog.component';
import { UserDetailComponent } from './admin/users/user-detail/user-detail.component';
import { FullHeightDirective } from './shared/directives/full-height.directive';
import { ObjectionComponent } from './shared/widgets/objections/objection/objection.component';
import { SourceCardComponent } from './shared/widgets/source-card/source-card.component';
import { UserPasswordComponent } from './admin/users/user-password/user-password.component';
import { CurrentUserPasswordComponent } from './admin/users/current-user-password/current-user-password.component';
import { DraggableDirective } from './shared/directives/draggable.directive';
import { SearchNameComponent } from './esourcing/search-name/search-name.component';
import { TrueFalseValueDirective } from './shared/directives/true-false-value.directive';
import { PositionComponent } from './documents/searchassignment/position/position.component';
import { TimePickerComponent } from './dailyplanner/time-picker/time-picker.component';
import { SameHeightDirective } from './shared/directives/same-height.directive';
import { SanitizeHtmlPipe } from './shared/sanitize-html.pipe';
import { FollowScrollDirective } from './esourcing/follow-scroll.directive';
import { AdminNotesComponent } from './admin/users/admin-notes/admin-notes.component';
import { ReorderDirective } from './admin/strings/reorder.directive';
import { NoDataComponent } from './shared/no-data/no-data.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { EsourcingProcessSelectedComponent } from './esourcing/esourcing-process-selected/esourcing-process-selected.component';
import { EsourcingAddToPlannerComponent } from './esourcing/esourcing-add-to-planner/esourcing-add-to-planner.component';
import { ExportDataDialogComponent } from './shared/export-data-dialog/export-data-dialog.component';
import { NestedChecklistComponent } from './shared/nested-checklist/nested-checklist.component';
import { PageSpinnerComponent } from './shared/page-spinner/page-spinner.component';
import { AddressFormComponent } from './shared/address-form/address-form.component';
import { StateSelectorComponent } from './shared/state-selector/state-selector.component';
import { ImportDataDialogComponent } from './shared/import-data-dialog/import-data-dialog.component';
import { FileInputComponent } from './shared/file-input/file-input.component';
import { MapColumnsComponent } from './shared/map-columns/map-columns.component';
import { LoadingNotificationComponent } from './shared/loading-notification/loading-notification.component';
import {StepComponent} from './shared/step/step.component';
import { UserActivityComponent } from './admin/user-activity/user-activity.component';
import { TabsComponent } from './shared/tabs/tabs.component';
import { ActivityComponent } from './admin/user-activity/components/activity/activity.component';
import { SummaryActivityComponent } from './admin/user-activity/components/summary-activity/summary-activity.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DateDifferencePipe } from './shared/pipes/date-difference.pipe';
import { ActivityReportComponent } from './admin/activity-report/activity-report.component';
import { CompanyActivityReportComponent } from './admin/activity-report/components/company-activity-report/company-activity-report.component';
import { UserActivityReportComponent } from './admin/activity-report/components/user-activity-report/user-activity-report.component';
import { GlobalActivityReportComponent } from './admin/activity-report/components/global-activity-report/global-activity-report.component';
import { PageTitleComponent } from './shared/page-title/page-title.component';
import { TimestampToDurationPipe } from './shared/pipes/timestamp-to-duration.pipe';
import { GoBackBtnComponent } from './shared/go-back-btn/go-back-btn.component';
import { DownloadReportPdfComponent } from './admin/activity-report/components/download-report-pdf/download-report-pdf.component';

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};

@NgModule({
  declarations: [
    AppComponent,
    AccountsComponent,
    CandidatesComponent,
    ClientsComponent,
    SessionsComponent,
    StringsComponent,
    UsersComponent,
    CheatsheetComponent,
    DailyplannerComponent,
    DashboardComponent,
    InnerviewComponent,
    ReferencecheckComponent,
    SearchassignmentComponent,
    EguideComponent,
    EsourcingComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    MorganrescueComponent,
    MorginarsComponent,
    NotesComponent,
    TermsComponent,
    ToolbarComponent,
    SettingsComponent,
    LoginDialogComponent,
    AlertComponent,
    CommonDialogComponent,
    StringDetailComponent,
    ClientDetailComponent,
    CandidateDetailComponent,
    AccountDetailComponent,
    FocusNewInputDirective,
    AutofocusDirective,
    AutoopenDirective,
    NoteComponent,
    AddressDialogComponent,
    UserDetailComponent,
    FullHeightDirective,
    CandidatesWidgetComponent,
    ClientsWidgetComponent,
    ObjectionsComponent,
    ObjectionComponent,
    SourceCardComponent,
    UserPasswordComponent,
    CurrentUserPasswordComponent,
    DraggableDirective,
    SearchNameComponent,
    TrueFalseValueDirective,
    PositionComponent,
    TimePickerComponent,
    SameHeightDirective,
    SanitizeHtmlPipe,
    FollowScrollDirective,
    AdminNotesComponent,
    ReorderDirective,
    NoDataComponent,
    SpinnerComponent,
    EsourcingProcessSelectedComponent,
    EsourcingAddToPlannerComponent,
    ExportDataDialogComponent,
    NestedChecklistComponent,
    PageSpinnerComponent,
    AddressFormComponent,
    StateSelectorComponent,
    ImportDataDialogComponent,
    FileInputComponent,
    StepComponent,
    MapColumnsComponent,
    LoadingNotificationComponent,
    UserActivityComponent,
    TabsComponent,
    ActivityComponent,
    SummaryActivityComponent,
    DateDifferencePipe,
    ActivityReportComponent,
    CompanyActivityReportComponent,
    UserActivityReportComponent,
    GlobalActivityReportComponent,
    PageTitleComponent,
    TimestampToDurationPipe,
    GoBackBtnComponent,
    DownloadReportPdfComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    TableModule,
    EditorModule,
    AngularSplitModule,
    NgToggleModule,
    NgxCurrencyModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    NgxsModule.forRoot([
      SessionState,
      StringsState,
      ClientsState,
      CandidatesState,
      AccountsState,
      UsersState
    ], {
      developmentMode: false,
      selectorOptions: {
        injectContainerState: false
      }
    }),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  providers: [
    AlertService,
    SessionStateService,
    UsersService,
    MenuService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
  entryComponents: [
    LoginDialogComponent,
    StringDetailComponent,
    ClientDetailComponent,
    CandidateDetailComponent,
    AccountDetailComponent,
    UserDetailComponent,
    AddressDialogComponent,
    NoteComponent,
    ObjectionComponent,
    UserPasswordComponent,
    CurrentUserPasswordComponent,
    SearchNameComponent,
    PositionComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
