<div class="tabs-container">
  <ul class="tabs">
    <li
      class="tab"
      *ngFor="let tab of tabs"
      [ngClass]="{'tab__active': tab.isActive}"
      (click)="onTabClick(tab)">
      {{tab.name}}
    </li>
  </ul>
</div>
