<div class="modal-header" appDraggable=".modal-dialog">
    <h4 class="modal-title">{{ rebuttal ? 'Rebuttal' : 'Objection' }}</h4>
    <button type="button" class="close" (click)="close()" aria-hidden="true">&times;</button>
</div>
<div class="modal-body">
    <div class="container-fluid pb-3" *ngIf="!rebuttal">
        <div class="position-relative">
            <h2 class="m-0 mt-3 covered-field">{{objection.script || ' '}}</h2>
            <input class="cover-field cover-field-h2" id="objectionName" placeholder="Objection name" [(ngModel)]="objection.script" [hidden]="(!isOwner && isNotAdmin && isNotAccountAdmin)" />
        </div>
        <hr />
        <div class="row">
            <div class="col-12 mb-3">
                <button class="btn btn-primary" (click)="update()" [hidden]="(!isNew && objection.preloaded) || (!isOwner && isNotAdmin && isNotAccountAdmin)">Save &amp; Exit</button>&nbsp;
                <button class="btn btn-danger" (click)="remove()" [hidden]="(objection.preloaded && !isSuperUser) || isNew || (!isOwner && isNotAdmin && isNotAccountAdmin)">Delete</button>&nbsp;
                <button class="btn btn-primary float-right" (click)="addRebuttal()"><i class="fas fa-plus"></i> New Rebuttal</button>
            </div>
            <div class="col-12" [hidden]="!isNotAdmin">
                <span class="valign-input"><input size="35" id="shareObjection" [(ngModel)]="objectionIsPrivate" type="checkbox" /> <label for="shareObjection">&nbsp;Share with others</label></span><span class="valign-input" [hidden]="isNotAdmin"><br /></span>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <ul class="list-group">
                    <li class="list-group-item rebuttal-item" *ngFor="let rebuttal of (delayedRebuttals.length ? delayedRebuttals : rebuttals); let i = index">
                        <div class="rebuttal-header">
                            Rebuttal #{{i + 1}}
                            <div class="small-tools float-right" [hidden]="(rebuttal.preloaded && !isSuperUser) || (!(userUuid === rebuttal.user_uuid) && isNotAdmin && isNotAccountAdmin)">
                                <button class="btn btn-primary btn-sm" (click)="editRebuttal(i)">Edit</button>&nbsp;
                                <button class="btn btn-danger btn-sm" (click)="deleteRebuttal(i)" [hidden]="isNew || (!(userUuid === rebuttal.user_uuid) && isNotAdmin && isNotAccountAdmin)">Delete</button>
                            </div>
                        </div>
                        <div class="rebuttal-content" [innerHTML]="rebuttal.script | sanitizeHtml"></div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="container-fluid pt-3 pb-3" *ngIf="!!rebuttal">
        <div class="row mb-3">
            <div class="col-12">
                <editor [(ngModel)]="rebuttal.script" [init]="tinymceOptions"></editor>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <button class="btn btn-primary" (click)="accept()">Accept</button>
                &nbsp;&nbsp;<span class="valign-input" [hidden]="!isNotAdmin"><input size="35" id="shareRebuttal" [(ngModel)]="rebuttalIsPrivate" type="checkbox" /> <label for="shareRebuttal">&nbsp;Share with others</label></span>

                <button class="btn btn-default float-right" (click)="cancel()">Cancel</button>
            </div>
        </div>
    </div>

</div>