import { EmailType } from 'src/app/shared/models/email.model';
import { PhoneType } from 'src/app/shared/models/phone.model';
import { Candidate } from './candidate.model';
import { Note } from 'src/app/shared/widgets/notes/note.model';

export namespace CandidatesActions {
  export class Load {
    static readonly type = 'CandidatesActions.Load';
  }

  export class SetOrder {
    static readonly type = 'CandidatesActions.SetOrder';
    constructor(public payload: {
      sort: string,
      order: string
    } ) {}
  }

  export class SetPagination {
    static readonly type = 'CandidatesActions.SetPagination';
    constructor(public payload: {
      page: number,
      limit: number
    } ) {}
  }

  export class NeedsReload {
    static readonly type = 'CandidatesActions.NeedsReload';
  }

  export class Search {
    static readonly type = 'CandidatesActions.Search';
    constructor(public payload: {
      candidate: any
    } ) {}
  }

  export class Show {
    static readonly type = 'CandidatesActions.Show';
    constructor(public payload: {
      uuid: string
    } ) {}
  }

  export class UpdateCurrent {
    static readonly type = 'CandidatesActions.UpdateCurrent';
    constructor(public payload: {
      uuid: string,
      candidate: any
    } ) {}
  }

  export class Update {
    static readonly type = 'CandidatesActions.Update';
    constructor(public payload: {
      uuid: string,
      initialNotes?: Note[]
    } ) {}
  }

  export class UploadResume {
    static readonly type = 'CandidatesActions.UploadResume';
    constructor(public payload: {
      uuid: string,
      file: any,
      initialNotes?: Note[]
    } ) {}
  }

  export class Delete {
    static readonly type = 'CandidatesActions.Delete';
    constructor(public payload: {
      uuid: string | null
    } ) {}
  }

  export class Select {
    static readonly type = 'CandidatesActions.Select';
    constructor(public payload: {
      uuid: string,
      select: boolean
    } ) {}
  }

  export class SelectAll {
    static readonly type = 'CandidatesActions.SelectAll';
    constructor(public payload: {
      select: boolean
    } ) {}
  }


  export class UnselectAll {
    static readonly type = 'CandidatesActions.UnselectAll';
  }

  export class Finished {
    static readonly type = 'CandidatesActions.Finished';
    constructor(public payload: {
      uuid: string
    } ) {}
  }

  export class SetCurrentPartial {
    static readonly type = 'CandidatesActions.SetCurrentPartial';
    constructor(public payload: {
      uuid: string,
      candidate: Partial<Candidate>
    } ) {}
  }

  export class AddEmail {
    static readonly type = 'CandidatesActions.AddEmail';
    constructor(public payload: {
      uuid: string
    } ) {}
  }

  export class SetEmailType {
    static readonly type = 'CandidatesActions.SetEmailType';
    constructor(public payload: {
      uuid: string,
      index: number,
      type: EmailType
    } ) {}
  }

  export class SetEmailAddress {
    static readonly type = 'CandidatesActions.SetEmailAddress';
    constructor(public payload: {
      uuid: string,
      index: number,
      address: string
    } ) {}
  }

  export class RemoveEmail {
    static readonly type = 'CandidatesActions.RemoveEmail';
    constructor(public payload: {
      uuid: string,
      index: number
    } ) {}
  }

  export class AddPhone {
    static readonly type = 'CandidatesActions.AddPhone';
    constructor(public payload: {
      uuid: string
    } ) {}
  }

  export class SetPhoneType {
    static readonly type = 'CandidatesActions.SetPhoneType';
    constructor(public payload: {
      uuid: string,
      index: number,
      type: PhoneType
    } ) {}
  }

  export class SetPhoneNumber {
    static readonly type = 'CandidatesActions.SetPhoneNumber';
    constructor(public payload: {
      uuid: string,
      index: number,
      phoneNumber: string
    } ) {}
  }

  export class RemovePhone {
    static readonly type = 'CandidatesActions.RemovePhone';
    constructor(public payload: {
      uuid: string,
      index: number
    } ) {}
  }

  export class AddToPlan {
    static readonly type = 'CandidatesActions.AddToPlan';
    constructor(public payload: {
      uuid: string
    } ) {}
  }

  export class BulkCreate {
    static readonly type = 'CandidatesActions.BulkCreate';
    constructor(public payload: {
      candidates: Candidate[]
    } ) {}
  }
}
