import {ChecklistCategory} from '../../shared/nested-checklist/nested-checklist.component';

export enum ClientChecklistProp {
  FirstName = 'firstName',
  LastName = 'lastName',
  Title = 'title',
  Company = 'company',
  PhoneNumber = 'phoneNumber', // custom
  Email = 'email',  // custom
  Location = 'location',
  Address2 = 'address2',
  State = 'state', // custom
  City = 'city',
  Zip = 'zip',
  Country = 'country',
  Notes = 'notes',  // custom
  Memo = 'memo',
  Position = 'positions',
  ContactSource = 'source'}
export const ExportClientsChecklistCategories: ChecklistCategory[] = [
  {
    name: 'Contact Details',
    checked: false,
    items: [
      {name: 'First Name', checked: false, propKey: ClientChecklistProp.FirstName},
      {name: 'Last Name', checked: false, propKey: ClientChecklistProp.LastName},
      {name: 'Job title', checked: false, propKey: ClientChecklistProp.Title},
      {name: 'Company', checked: false, propKey: ClientChecklistProp.Company},
      {name: 'Phone number', checked: false, propKey: ClientChecklistProp.PhoneNumber},
      {name: 'Email address', checked: false, propKey: ClientChecklistProp.Email},
    ]
  },
  {
    name: 'Location Details',
    checked: false,
    items: [
      {name: 'Address Line 1', checked: false, propKey: ClientChecklistProp.Location},
      {name: 'Address Line 2', checked: false, propKey: ClientChecklistProp.Address2},
      {name: 'City', checked: false, propKey: ClientChecklistProp.City},
      {name: 'State/Province', checked: false, propKey: ClientChecklistProp.State},
      {name: 'Zip/Postal code', checked: false, propKey: ClientChecklistProp.Zip},
      {name: 'Country', checked: false, propKey: ClientChecklistProp.Country},
    ]
  },
  {
    name: 'Custom Fields',
    checked: false,
    items: [
      {name: 'Notes', checked: false, propKey: ClientChecklistProp.Notes},
      {name: 'Memo', checked: false, propKey: ClientChecklistProp.Memo},
      {name: 'Position', checked: false, propKey: ClientChecklistProp.Position},
      {name: 'Contact Source', checked: false, propKey: ClientChecklistProp.ContactSource},
    ]
  }
];
