import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.scss']
})
export class PositionComponent implements OnInit {

  form: FormGroup;
  signedInOthers = false;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      position: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
  }

  save(): void {
    this.activeModal.close(this.form.get('position')?.value);
  }

  close(): void {
    this.activeModal.dismiss();
  }
}
