import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { Client } from '../../../admin/clients/clients.model';
import { ClientsService } from '../../../admin/clients/clients.service';
import { ClientDetailComponent } from '../../../admin/clients/client-detail/client-detail.component';
import { ClientsActions } from '../../../admin/clients/clients.actions';

import { SessionStateService } from '../../session-state.service';
import { EventBusService } from '../../event-bus.service';
import { ContentService } from '../../content.service';

import { User } from '../../models/user.model';

@Component({
  selector: 'app-widget-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsWidgetComponent implements OnInit {

  clients: Client[] = [];
  clientUuid: string | undefined = '';
  client: Client | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private clientsService: ClientsService,
    private sessionStateService: SessionStateService,
    private eventBusService: EventBusService,
    private modalService: NgbModal
    ) { }

  ngOnInit(): void {
    this.store.dispatch(new ClientsActions.Load());

    this.refreshClients();

    this.eventBusService.on('clientChanged', (client: any) => {
      this.clientUuid = client?.uuid;
      this.clientChanged(true);
    });
  }

  refreshClients(): void {
    const session = this.sessionStateService.get();
    const uuid = (session.user as User).uuid;

    if (!uuid) {
      return;
    }

    const setClient: Client = this.eventBusService.getClient();

    this.clientsService.list(uuid).subscribe(clients => {
      this.clients = clients.sort((a, b) => b.company?.toLowerCase() > a.company?.toLowerCase() ? -1 : 1);

      this.clients.map(client => {
        if (client.uuid === setClient?.uuid) {
          this.clientUuid = client.uuid;
          this.client = client;
          this.clientChanged();
        }

        return client;
      });

      if (!this.client) {
        if (setClient) {
          this.clientUuid = setClient.uuid;
          this.clientChanged();
        } else if (this.clients.length) {
          this.clientUuid = this.clients[0].uuid;
          this.clientChanged();
        }
      }
    });
  }

  viewJO(): void {
    this.eventBusService.emit({ event: 'clientChanged', value: this.client });

    const newRelativeUrl = this.router.createUrlTree(['searchassignment']);
    const baseUrl = window.location.href.replace(this.router.url, '');

    window.open(baseUrl + newRelativeUrl + '?clientUuid=' + this.client?.uuid, '_blank');
  }

  viewClient(): void {
    if (!this.client || !this.client.uuid) {
      return;
    }

    const uuid = this.client.uuid;
    this.store.dispatch(new ClientsActions.Show({ uuid }));

    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      size: 'lg'
    };

    const urlSegments = this.route.snapshot.url;

    if (urlSegments && urlSegments[0] && (urlSegments[0].path === 'guides')) {
      ngbModalOptions.backdrop = false;
      ngbModalOptions.windowClass = 'free-floating-modal';
    }

    const modalRef = this.modalService.open(ClientDetailComponent, ngbModalOptions);
    modalRef.componentInstance.uuid = uuid;

    modalRef.result.then((result) => {
      this.store.dispatch(new ClientsActions.Update({ uuid }));

      setTimeout(() => {
        this.refreshClients();
      }, 1000);
    }).catch((error) => {
      if (error && error.delete) {
        this.store.dispatch(new ClientsActions.Delete({ uuid }));

        setTimeout(() => {
          this.refreshClients();
        }, 1000);
      }
    });
  }

  clientChanged(doNotEmit: boolean = false): void {
    if (!this.clientUuid) {
      this.client = null;
      return;
    }

    this.clientsService.lookup(this.clientUuid).subscribe(fullclient => {
      this.client = fullclient;

      if (!doNotEmit) {
        this.eventBusService.emit({ event: 'clientChanged', value: this.client });
      }
    });
  }

  clientHasJO(): boolean {
    if (!this.clientUuid) {
      return false;
    }

    const client = this.clients.find(clnt => clnt.uuid === this.clientUuid);

    if (client) {
      return !!client.searchassignments && !!client.searchassignments.length;
    }

    return false;
  }

  trackByUuid(index: number, client: Client): string {
    return client.uuid || '';
  }
}
