import { Directive, ElementRef, Input, AfterViewChecked } from '@angular/core';

import { debounceTime } from 'rxjs/operators';

import { StringsComponent } from './strings.component';

import { User } from '../../shared/models/user.model';
import { UsersService } from '../../shared/users.service';

declare var jQuery: any;
declare var $: any;

@Directive({
  selector: '[appReorder]'
})
export class ReorderDirective implements AfterViewChecked {

  @Input()
  appReorder = false;

  private allowed = false;
  private row: any;
  private initialDragIndex = 0;
  private finalDragIndex = 0;

  constructor(
    private el: ElementRef,
    private usersService: UsersService,
    private host: StringsComponent) {
    this.usersService.currentUser().subscribe((user: User) => {
      this.allowed = (user.roles.indexOf('super-user') > -1);
    });
  }

  ngAfterViewChecked(): void {
    if (!this.allowed) {
      return;
    }

    const table = $(this.el.nativeElement).find('table');

    if (table.length) {
      table.find('tbody').find('tr').get().forEach((tr: any) => {
        if (!tr.hasAttribute('draggable')) {
          tr.setAttribute('draggable', 'true');

          tr.ondragstart = this.dragStart.bind(this);
          tr.ondragover = this.dragOver.bind(this);
          tr.ondragend = this.dragEnd.bind(this);
        }
      });
    }
  }

  dragStart(event: Event): void {
    this.row = event.target;

    if (this.row) {
      this.initialDragIndex = this.row.rowIndex - 1;
    }
  }

  dragOver(event: Event): void {
    event.preventDefault();

    const draggingOver = $(event.target).closest('tr').get(0);
    const children = Array.from($(event.target).closest('tbody').find('tr').get());

    if (draggingOver && this.row) {
      if (children.indexOf(draggingOver) > children.indexOf(this.row)) {
        draggingOver.after(this.row);
      }
      else {
        draggingOver.before(this.row);
      }
    }

    this.finalDragIndex = this.row.rowIndex - 1;
  }

  dragEnd(event: Event): void {
    event.preventDefault();

    this.host.reorder(this.initialDragIndex, this.finalDragIndex);

    this.initialDragIndex = this.finalDragIndex = 0;
  }
}
