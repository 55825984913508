export enum EmailType {
    'work' = 'work',
    'home' = 'home',
    'other' = 'other'
}

export interface Email {
    type: EmailType;
    address: string;
    id?: string;
}
