import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

import {Select, Store} from '@ngxs/store';
import {forkJoin, Observable} from 'rxjs';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DefaultConfig} from 'ngx-easy-table';

import {Candidate} from './candidate.model';
import {CandidatesState} from './candidates.state';
import {CandidatesActions} from './candidates.actions';

import {CommonDialogComponent} from '../../shared/common-dialog/common-dialog.component';
import {CandidateDetailComponent} from './candidate-detail/candidate-detail.component';

import {User} from '../../shared/models/user.model';
import {UsersService} from '../../shared/users.service';
import {SessionStateService} from '../../shared/session-state.service';
import {EventBusService} from '../../shared/event-bus.service';
import {ContentService} from '../../shared/content.service';

import {Pagination} from 'src/app/shared/models/pagination.model';
import {ExportDataDialogComponent} from '../../shared/export-data-dialog/export-data-dialog.component';
import {
  CandidateChecklistProp,
  ExportCandidatesChecklistCategories
} from './export-candidates-checklist-categories.const';
import {take} from 'rxjs/operators';
import {ChecklistItem} from '../../shared/nested-checklist/nested-checklist.component';
import {AlertService} from '../../shared/alert.service';
import {PageSpinnerService} from '../../shared/services/page-spinner.service';
import {NotesService} from '../../shared/widgets/notes/notes.service';
import {Note} from '../../shared/widgets/notes/note.model';
import {deepCopy} from '../../shared/utils/deep-copy.util';
import {ExportFileService} from '../../shared/services/export-file.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ImportDataDialogComponent} from '../../shared/import-data-dialog/import-data-dialog.component';
import {
  BULLHORN_CANDIDATES_FIELDS_MAP,
  CANDIDATES_DESTINATION_FIELDS, CRELATE_CANDIDATES_FIELDS_MAP, JAZZHR_CANDIDATES_FIELDS_MAP,
  LOXO_CANDIDATES_FIELDS_MAP, PC_RECRUITER_CANDIDATES_FIELDS_MAP, WORKABLE_CANDIDATES_FIELDS_MAP
} from './candidates.data';
import {ImportPlatform} from '../../shared/enums/import-platform.enum';
import {BulkCreateStatusModel} from '../../shared/models/bulk-create.model';
import {MapField} from '../../shared/map-columns/map-columns.consts';


@UntilDestroy()
@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss']
})
export class CandidatesComponent implements OnInit {

  // @ts-ignore
  @Select(CandidatesState.candidates) candidates$: Observable<Candidate[]>;
  // @ts-ignore
  @Select(CandidatesState.pagination) pagination$: Observable<Pagination>;
  // @ts-ignore
  @Select(CandidatesState.selected) selected$: Observable<string[]>;

  @Select(CandidatesState.selectedFullObjects) selectedFullObjects$?: Observable<Candidate[]>;

  @Select(CandidatesState.bulkCreate) bulkCreate$?: Observable<BulkCreateStatusModel>;

  // @ts-ignore
  @ViewChild('innerviewTpl', { static: true }) innerviewTpl: TemplateRef<any>;
  // @ts-ignore
  @ViewChild('lastNameTpl', { static: true }) lastNameTpl: TemplateRef<any>;
  // @ts-ignore
  @ViewChild('firstNameTpl', { static: true }) firstNameTpl: TemplateRef<any>;
  // @ts-ignore
  @ViewChild('ownerTpl', { static: true }) ownerTpl: TemplateRef<any>;

  // @ts-ignore
  @ViewChild('columnActionTemplate', { static: true }) columnActionTemplate: TemplateRef<any>;

  public configuration = {
    ...DefaultConfig,
    rows: 100,
    serverPagination: true,
    threeWaySort: true,
    checkboxes: true
  };
  importDestinationFields: MapField[] = CANDIDATES_DESTINATION_FIELDS;
  platforms: ImportPlatform[] = [
    ImportPlatform.Bullhorn,
    ImportPlatform.Loxo,
    ImportPlatform.PCRecruiter,
    ImportPlatform.Crelate,
    ImportPlatform.Other,
  ];
  platformFieldsMaps: Record<ImportPlatform, Record<string, string>> = {
    [ImportPlatform.Avionte]: {},
    [ImportPlatform.JobAdder]: {},
    [ImportPlatform.Bullhorn]: BULLHORN_CANDIDATES_FIELDS_MAP,
    [ImportPlatform.Crelate]: CRELATE_CANDIDATES_FIELDS_MAP,
    [ImportPlatform.Loxo]: LOXO_CANDIDATES_FIELDS_MAP,
    [ImportPlatform.BigBiller]: {},
    [ImportPlatform.PCRecruiter]: PC_RECRUITER_CANDIDATES_FIELDS_MAP,
    [ImportPlatform.Other]: {},
  };
  public columns = [];

  isSimulated = false;

  constructor(
    private store: Store,
    private router: Router,
    private modalService: NgbModal,
    private usersService: UsersService,
    private contentService: ContentService,
    private eventBusService: EventBusService,
    private sessionStateService: SessionStateService,
    private alertService: AlertService,
    private pageSpinnerService: PageSpinnerService,
    private notesService: NotesService,
    private exportFileService: ExportFileService,
  ) { }

  ngOnInit(): void {
    const session = this.sessionStateService.get();
    this.isSimulated = !!session.session.simulatedSession;

    this.usersService.currentUser().subscribe((user: User) => {
      if (user.roles.indexOf('account-admin') > -1) {
        this.columns = [
          // @ts-ignore
          { key: '', title: 'IV', width: '60px', cellTemplate: this.innerviewTpl,
          // @ts-ignore
            headerActionTemplate: this.columnActionTemplate, cssClass: { includeHeader: true, name: 'd-flex'  } },
          // @ts-ignore
          { key: 'lastName', title: 'Last Name', width: '20%',
          // @ts-ignore
            cellTemplate: this.lastNameTpl, headerActionTemplate: this.columnActionTemplate },
          // @ts-ignore
          { key: 'firstName', title: 'First Name', width: '20%',
          // @ts-ignore
            cellTemplate: this.firstNameTpl, headerActionTemplate: this.columnActionTemplate },
          // @ts-ignore
          { key: 'title', title: 'Title', width: '20%', headerActionTemplate: this.columnActionTemplate },
          // @ts-ignore
          { key: 'employer', title: 'Employer', width: '20%', headerActionTemplate: this.columnActionTemplate},
          // @ts-ignore
          { key: 'ownerUser.username', title: 'Owner', width: '15%',
          // @ts-ignore
            cellTemplate: this.ownerTpl, headerActionTemplate: this.columnActionTemplate }
        ];
      } else {
        this.columns = [
          // @ts-ignore
          { key: '', title: 'IV', width: '60px', cellTemplate: this.innerviewTpl,
          // @ts-ignore
            headerActionTemplate: this.columnActionTemplate, cssClass: { includeHeader: true, name: 'd-flex'  } },
          // @ts-ignore
          { key: 'lastName', title: 'Last Name', width: '25%',
          // @ts-ignore
            cellTemplate: this.lastNameTpl, headerActionTemplate: this.columnActionTemplate },
          // @ts-ignore
          { key: 'firstName', title: 'First Name', width: '20%',
          // @ts-ignore
            cellTemplate: this.firstNameTpl, headerActionTemplate: this.columnActionTemplate },
          // @ts-ignore
          { key: 'title', title: 'Title', width: '25%', headerActionTemplate: this.columnActionTemplate },
          // @ts-ignore
          { key: 'employer', title: 'Employer', width: '25%', headerActionTemplate: this.columnActionTemplate }
        ];
      }

      this.store.dispatch(new CandidatesActions.Load());
    });

    this.candidates$.pipe(untilDestroyed(this)).subscribe(() => {
      this.resetCheckboxes();
    });
  }

  resetCheckboxes(): void {
    const selectAllCheckboxEl = document.querySelector('#selectAllCheckboxes:checked');
    if (!selectAllCheckboxEl) {
      return;
    }
    const clickEvent = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    });
    selectAllCheckboxEl.dispatchEvent(clickEvent);
  }

  add(): void {
    if (this.isSimulated) {
      return;
    }

    const uuid = this.contentService.uuid();
    this.store.dispatch(new CandidatesActions.Show({ uuid }));

    const ngbModalOptions: NgbModalOptions = {
      windowClass: 'free-floating-modal',
      backdrop: false,
      size: 'lg'
    };

    const modalRef = this.modalService.open(CandidateDetailComponent, ngbModalOptions);
    modalRef.componentInstance.uuid = uuid;

    modalRef.result.then((result) => {
      if (result.uploadedItem) {
        this.store.dispatch(new CandidatesActions.UploadResume({ uuid, file: result.uploadedItem, initialNotes: result.initialNotes }));
      } else {
        this.store.dispatch(new CandidatesActions.Update({ uuid, initialNotes: result.initialNotes }));

        this.store.dispatch(new CandidatesActions.Finished({ uuid }));
      }

      if (result.another) {
        this.add();
      }
    }).catch((error) => {
      this.store.dispatch(new CandidatesActions.Finished({ uuid }));

      this.store.dispatch(new CandidatesActions.Load());
    });
  }

  edit(uuid: string): void {
    const ngbModalOptions: NgbModalOptions = {
      windowClass: 'free-floating-modal',
      backdrop: false,
      size: 'lg'
    };

    this.store.dispatch(new CandidatesActions.Show({ uuid }));

    const modalRef = this.modalService.open(CandidateDetailComponent, ngbModalOptions);
    modalRef.componentInstance.uuid = uuid;

    modalRef.result.then((result) => {
      if (result.uploadedItem) {
        this.store.dispatch(new CandidatesActions.UploadResume({ uuid, file: result.uploadedItem }));
      } else {
        this.store.dispatch(new CandidatesActions.Update({ uuid }));

        this.store.dispatch(new CandidatesActions.Finished({ uuid }));
      }
    }).catch((error) => {
      if (error && error.delete) {
        this.store.dispatch(new CandidatesActions.Delete({ uuid }));
      } else {
        this.store.dispatch(new CandidatesActions.Load());
      }

      this.store.dispatch(new CandidatesActions.Finished({ uuid }));
    });
  }

  eventEmitted($event: { event: string; value: any }): void {
    switch ($event.event)
    {
      case 'onClick':
        if ($event.value.row && $event.value.row.uuid) {
          if (!$event.value.key) {
            return;
          }

          this.edit($event.value.row.uuid);
        }
        break;

      case 'onOrder':
        this.store.dispatch(new CandidatesActions.SetOrder({sort: $event.value.key, order: $event.value.order}));
        break;

      case 'onPagination':
        this.configuration.rows = $event.value.limit;
        this.store.dispatch(new CandidatesActions.SetPagination({limit: $event.value.limit, page: $event.value.page}));
        break;
      case 'onCheckboxSelect':
        this.store.dispatch(new CandidatesActions.Select({uuid: $event.value.row.uuid, select: $event.value.event.target.checked}));
        break;
      case 'onSelectAll':
        this.store.dispatch(new CandidatesActions.SelectAll({select: $event.value}));
        break;
    }
  }

  delete(): void {
    if (this.isSimulated) {
      return;
    }

    const modalRef = this.modalService.open(CommonDialogComponent);

    modalRef.componentInstance.closeButtonText = 'Cancel';
    modalRef.componentInstance.actionButtonText = 'Delete';
    modalRef.componentInstance.headerText = 'Delete Candidate';
    modalRef.componentInstance.bodyText = 'Are you sure you want to delete the selected candidates?';

    modalRef.result.then((result) => {
      this.store.dispatch(new CandidatesActions.Delete({ uuid: null }));
    }).catch((error) => { });
  }

  search(element: EventTarget | null): void {
    if (element) {
      const name = (element as HTMLInputElement).value;

      this.store.dispatch(new CandidatesActions.Search({ candidate: name }));
    } else {
      this.store.dispatch(new CandidatesActions.Search({ candidate: null }));
    }
  }

  onChange(row: any, checkbox: EventTarget | null): void {
    if (checkbox) {
      this.store.dispatch(new CandidatesActions.Select({uuid: row.uuid, select: (checkbox as HTMLInputElement).checked}));
    }
  }

  gotoInnerview(candidate: Candidate): void {
    this.eventBusService.emit({ event: 'candidateChanged', value: candidate });

    setTimeout(() => {
      this.router.navigate(['/innerview']);
    },
    250);
  }

  hideColumn(event: any): void {
    let target = event.target as HTMLElement;

    while (target.parentElement && target.tagName.toLowerCase() !== 'th') {
      target = target.parentElement;
    }

    let index = 0;

    while (target.previousSibling) {
      target = target.previousSibling as HTMLElement;

      if (target.tagName && target.tagName.toLowerCase() === 'th') {
        index++;
      }
    }

    if (index === 2) {
      this.columns.splice(1, 2);
    } else {
      this.columns.splice(index, 1);
    }
  }

  exportClicked(): void {
    if (!this.selectedFullObjects$) {
      return;
    }
    this.selectedFullObjects$.pipe(take(1)).subscribe((candidates: Candidate[]) => {
      const modalRef = this.modalService.open(ExportDataDialogComponent);
      modalRef.componentInstance.categories = deepCopy(ExportCandidatesChecklistCategories);
      modalRef.componentInstance.exportClicked.pipe(untilDestroyed(this)).subscribe((selectedChecklistItems: ChecklistItem[]) => {
        this.prepareDataToExport(candidates, selectedChecklistItems, modalRef);
      });
    });
  }

  importClicked(): void {
    const modalRef = this.modalService.open(ImportDataDialogComponent, {size: 'xl', backdrop: 'static'});
    modalRef.componentInstance.title = 'Import Candidates';
    modalRef.componentInstance.platforms = this.platforms;
    modalRef.componentInstance.destinationFields = this.importDestinationFields;
    modalRef.componentInstance.platformFieldsMaps = this.platformFieldsMaps;
    modalRef.componentInstance.dataToUploadIsReady.pipe(untilDestroyed(this)).subscribe((data: any[]) => {
      this.store.dispatch(new CandidatesActions.BulkCreate({ candidates: data }));
      modalRef.close();
    });
  }

  notesShouldBeExported(selectedChecklistItems: ChecklistItem[]): boolean {
    return !!selectedChecklistItems.find(i => i.propKey === CandidateChecklistProp.Notes);
  }

  prepareDataToExport(candidates: Candidate[], selectedChecklistItems: ChecklistItem[], modalRef: NgbModalRef): void {
    this.pageSpinnerService.startLoading();
    if (this.notesShouldBeExported(selectedChecklistItems)) {
      const noteRequests$: Observable<any>[] = candidates.map(c => {
        return this.notesService.list(c.uuid || '');
      });

      forkJoin(noteRequests$).subscribe((noteRes: Array<Note[]>) => {
        noteRes.forEach((notes: Note[], i: number) => {
          (candidates as any)[i].notes = notes;
        });
        this.exportData(candidates, selectedChecklistItems, modalRef);
      });
    } else {
      this.exportData(candidates, selectedChecklistItems, modalRef);
    }
  }

  exportData(candidates: Candidate[], selectedChecklistItems: ChecklistItem[], modalRef: NgbModalRef): void {
    const res = candidates.map((c: any) => {
      delete c.addresses;
      const resObject: any = {};
      selectedChecklistItems.forEach(i => {
        switch (i.propKey) {
          case CandidateChecklistProp.PhoneNumber: {
            this.exportFileService.processPhoneNumber(resObject, c);
            break;
          }
          case CandidateChecklistProp.Email: {
            this.exportFileService.processEmail(resObject, c);
            break;
          }
          default: {
            resObject[i.name] = this.getCandidateField(c, i.propKey);
            break;
          }
        }
      });
      return resObject;
    });

    const blob = this.exportFileService.prepareBlobData(res);
    if (blob) {
      this.exportFileService.saveFileByFilePicker(blob, 'candidates.csv').then(() => {
        modalRef?.close();
        this.pageSpinnerService.stopLoading();
      });
    } else {
      this.pageSpinnerService.stopLoading();
      this.alertService.error('The File is invalid');
    }
  }
  getCandidateField(c: Candidate, propKey: string): string {
    switch (propKey) {
      case CandidateChecklistProp.Notes: {
        return this.exportFileService.getNotesString(c);
      }
      default: return (c as any)[propKey] || '';
    }
  }
}
