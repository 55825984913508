<div class="modal-header" appDraggable=".modal-dialog" *ngIf="{ user: current$ | async } as data">
    <h4 class="modal-title">{{ data.user && data.user.uuid ? 'Edit' : 'New' }} User</h4>
    <button type="button" class="close" aria-hidden="true" (click)="close()">&times;</button>
</div>
<div class="modal-body" *ngIf="{ user: current$ | async, isUnique: isUnique$ | async } as data">
    <form [formGroup]="form">

        <div class="form-group row">
            <label class="col-3 control-label text-right">User Name:</label>
            <div class="col-6">
                <input type="text" class="form-control" formControlName="userName" appAutofocus />
                <small class="form-text text-muted">Enter a unique user name</small>
                <small [hidden]="data.isUnique" class="form-text text-danger">Account with this user name already exists; please change it.</small>
            </div>
            <div class="col-3 text-right">
                <button type="button" class="btn btn-default" [hidden]="!data.user || !data.user.uuid" (click)="changePassword()">Change Password</button>
            </div>
        </div>

        <div class="form-group row" *ngIf="!data.user || !data.user.uuid">
            <label class="col-3 control-label text-right">Password:</label>
            <div class="col-6">
                <input type="password" class="form-control" formControlName="password" />
                <small class="form-text text-muted">Password must be a minimum of 6 characters</small>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 control-label text-right">First Name:</label>
            <div class="col-6">
                <input type="text" class="form-control" formControlName="firstName" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 control-label text-right">Last Name:</label>
            <div class="col-6">
                <input type="text" class="form-control" formControlName="lastName" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 control-label text-right">Email Address:</label>
            <div class="col-6">
                <input type="text" class="form-control" formControlName="email" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 control-label text-right">Division:</label>
            <div class="col-6">
                <input type="text" class="form-control" formControlName="division" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 control-label text-right">Hire Date:</label>
            <div class="col-6">
                <div class="input-group">
                    <input type="text" class="form-control" formControlName="hireDate" name="hireDate" ngbDatepicker container="body" #d="ngbDatepicker" />
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                            <i class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>

            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-9 offset-sm-3">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" formControlName="active" /> Active
                    </label>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-9 offset-sm-3">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" (click)="switchUserAccountAdmin($event.target)" formControlName="isAccountAdmin" /> Account Admin
                    </label>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-9 offset-sm-3">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" (click)="switchUserGuideManager($event.target)" formControlName="isUserGuideManager" /> May Approve / Reject User Guides
                    </label>
                </div>
            </div>
        </div>

        <div class="form-group row" *ngIf="isEditUserSuperAdmin">
          <div class="col-sm-9 offset-sm-3">
            <div class="checkbox">
              <label>
                <input type="checkbox" [checked]="true" [disabled]="true" /> Super Admin
              </label>
            </div>
          </div>
        </div>
        <br />

        <div class="form-group row">
            <label class="col-3 control-label text-right">User Type:</label>
            <div class="col-9">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="userTypeRecruiter" name="userType" formControlName="userType" value="recruiter">
                    <label class="form-check-label" for="userTypeRecruiter">Recruiter</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="userTypeBizDev" name="userType" formControlName="userType" value="bizDev">
                    <label class="form-check-label" for="userTypeBizDev">Biz Dev</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="userTypeAcctMgr" name="userType" formControlName="userType" value="acctManager">
                    <label class="form-check-label" for="userTypeAcctMgr">Acct Mgr.</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="userTypeOwnerMgr" name="userType" formControlName="userType" value="ownerManager">
                    <label class="form-check-label" for="userTypeAcctMgr"> Owner/Mgr.</label>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 control-label text-right">Experience:</label>
            <div class="col-9">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="experienceLevelRookie" name="experienceLevel" formControlName="experienceLevel" value="rookie">
                    <label class="form-check-label" for="experienceLevelRookie">Rookie</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="experienceLevelStaff" name="experienceLevel" formControlName="experienceLevel" value="staff">
                    <label class="form-check-label" for="experienceLevelStaff">Staff</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="experienceLevelSenior" name="experienceLevel" formControlName="experienceLevel" value="senior">
                    <label class="form-check-label" for="experienceLevelSenior">Senior</label>
                </div>
            </div>
        </div>

    </form>

    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-user-phones,ngb-user-addresses" *ngIf="current$ | async as user">
        <ngb-panel id="ngb-user-phones">
            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="m-0">Phone Numbers</h5>
                    <div>
                        <button ngbPanelToggle class="btn btn-outline-primary">
                            <i [hidden]="opened" class="fas fa-plus"></i>
                            <i [hidden]="!opened" class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <ul class="list-group p-0 col-12">
                    <li class="list-group-item" *ngFor="let phone of user.phoneNumbers; index as i">
                        <div class="row">
                            <div class="col-sm-4">
                                <select class="form-control" [value]="phone.type" (change)="setPhoneType(i, $event.target)">
                                    <option value="work">Work</option>
                                    <option value="home">Home</option>
                                    <option value="mobile">Mobile</option>
                                    <option value="fax">Fax</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            <div class="col-sm-6 p-0">
                                <input class="form-control" type="text" [(ngModel)]="phone.number" mask="(000) 000-0000" [showMaskTyped]="true" (input)="setPhoneNumber(i, $event.target)" required />
                            </div>
                            <div class="col-sm-2">
                                <div class="delete-icon float-right" (click)="removePhone(i)"><i class="fas fa-times-circle"></i></div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item"><input class="btn btn-primary" type="button" value="New" (click)="addPhone()" appFocusNewInput /></li>
                </ul>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="ngb-user-addresses">
            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="m-0">Addresses</h5>
                    <div>
                        <button ngbPanelToggle class="btn btn-outline-primary">
                            <i [hidden]="opened" class="fas fa-plus"></i>
                            <i [hidden]="!opened" class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <ul class="list-group p-0 col-12">
                    <li class="list-group-item" *ngFor="let address of user.addresses; index as i">
                        <div class="row">
                            <div class="col-sm-11">
                                <span class="link" (click)="editAddress(i)">{{ address.address1 }}</span>
                            </div>
                            <div class="col-sm-1">
                                <div class="delete-icon float-right" (click)="removeAddress(i)"><i class="fas fa-times-circle"></i></div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item"><input class="btn btn-primary" type="button" value="New" (click)="addAddress()" /></li>
                </ul>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="ngb-user-memo">
            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="m-0">Memo</h5>
                    <div>
                        <button ngbPanelToggle class="btn btn-outline-primary">
                            <i [hidden]="opened" class="fas fa-plus"></i>
                            <i [hidden]="!opened" class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <form [formGroup]="form">
                    <p *ngIf="!!data.user?.adminMemo">{{ data.user?.adminMemo || '' }}</p>
                    <textarea style="height: 200px" class="col-sm-12 form-control" formControlName="memo"></textarea>
                </form>
            </ng-template>
        </ngb-panel>

    </ngb-accordion>
</div>

<div class="modal-footer" *ngIf="{ user: current$ | async, isUnique: isUnique$ | async } as data">
    <button
        type="submit"
        class="btn btn-secondary"
        (click)="save(true)"
        [hidden]="data.user && data.user.uuid"
        [disabled]="!data.isUnique || !form.valid">Save and Create Another</button>
    <button
        type="submit"
        class="btn btn-secondary"
        (click)="save()"
        [disabled]="((!data.user || !data.user.uuid) && !data.isUnique) || !form.valid">Save</button>
    <button
        type="button"
        class="btn btn-danger"
        [hidden]="!data.user || !data.user.uuid"
        (click)="close(true)">Delete</button>
    <button
        type="button"
        class="btn btn-default"
        (click)="close()">Cancel</button>
</div>
