import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SelectedSearchResult} from '../esourcing-process-selected/esourcing-process-selected.component';
import {ClipboardService} from 'ngx-clipboard';
import {AlertService} from '../../shared/alert.service';
import {User} from '../../shared/models/user.model';

@Component({
  selector: 'app-esourcing-add-to-planner',
  templateUrl: './esourcing-add-to-planner.component.html',
  styleUrls: ['./esourcing-add-to-planner.component.scss']
})
export class EsourcingAddToPlannerComponent implements OnInit {
  searchItem?: SelectedSearchResult;
  user: User | null = null;

  constructor(
    private activeModal: NgbActiveModal,
    private clipboardService: ClipboardService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {}


  close(): void {
    this.activeModal.dismiss();
  }

  copyToClipboard(contentToCopy: string): void {
    this.clipboardService.copyFromContent(contentToCopy);
    this.alertService.success('Content is copied!');
  }

  onSucceedSaving(): void {
    this.activeModal.close({isAddedToPlanner: true});
  }

}
