<app-header></app-header>
<app-toolbar></app-toolbar>

<nav class="navbar navbar-default navbar-fixed-top navbar-global navbar-expand-md w-100 bg-white" role="navigation">
  <div class="container">
    <a class="navbar-brand text-dark" href="javascript:void"><i class="fas fa-users"></i>&nbsp;&nbsp;<b>User Sessions</b></a>

    <div class="ml-auto" *ngIf="selected$ | async as selected">
      <button type="button" class="btn btn-secondary mr-3" (click)="refresh()" placement="bottom" ngbTooltip="Refresh Sessions"><i class="fas fa-sync-alt"></i></button>
      <button type="button" class="btn btn-danger-inversed" (click)="delete()" [disabled]="!selected.length" placement="bottom" ngbTooltip="Delete Session(s)"><i class="fas fa-trash"></i></button>
    </div>
  </div>
</nav>

<br />

<div class="container-fluid main-container" *ngIf="sessions$ | async as data">
  <ngx-table [data]="data" [configuration]="configuration" [columns]="columns"></ngx-table>
</div>

<app-footer></app-footer>

<ng-template #loggedInAt let-row>
  <span>{{row.loggedInAt | date: 'medium'}}</span>
</ng-template>

<ng-template #columnActionTemplate>
  <button class="text-nowrap" (click)="hideColumn($event)">Hide Column</button>
</ng-template>

<ng-template #checkboxTpl let-row let-column="column">
  <div class="form-group">
    <label class="form-checkbox">
      <input type="checkbox" (change)="onChange(row, $event.target)" />
    </label>
  </div>
</ng-template>