<app-header></app-header>

<app-toolbar>
  <div class="float-right d-flex flex-row">
    <div ngbDropdown class="d-inline-block">
      <button class="btn btn-light dropdown-toggle p-0" type="button" *ngIf="!!adminGuides.length" ngbDropdownToggle id="dropdownNotifications" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="fa-stack fa-2x" [attr.data-count]="adminGuides.length">
          <i class="fa fa-bell"></i>
        </span>
      </button>
      <div ngbDropdownMenu class="dropdown-menu pb-0" id="dropdownNotificationsDropdown" aria-labelledby="dropdownNotifications">
        <label class="dropdown-item" *ngFor="let notification of adminGuides">
          <a href="javascript:void(0)" (click)="goToGuide(notification)">{{ notification.message }}</a>
        </label>
      </div>
    </div>
  </div>
</app-toolbar>

<div class="container-fluid main-container">
  <div class="panel dashboard-panel">
      <div class="panel-header">
          <span class="header-title">On the Phone</span>
      </div>
      <div class="panel-content">
          <div class="section-wrapper">
            <div class="section-header">Candidate</div>

            <div class="panel-content single-panel p-0">
              <div class="dashboard-item" id="telesourcing" [hidden]="isSimulated"><a routerLink="/guides/telesourcing"></a></div>
              <div class="dashboard-item" id="recruiting-call" [hidden]="isSimulated"><a routerLink="/guides/recruiting-call"></a></div>
              <div class="dashboard-item" id="innerview"><a routerLink="/innerview"></a></div>
              <div class="dashboard-item dashboard-halves" [hidden]="isSimulated">
                <div class="dashboard-half" id="candidate-prep-innerview" placement="top" [openDelay]="delay" container="body" ngbTooltip="First InnerView Prep"><a routerLink="/guides/candidate-prep-innerview"></a></div>
                <div class="dashboard-half" id="candidate-prep-final" placement="bottom" [openDelay]="delay" container="body" ngbTooltip="Final Prep"><a routerLink="/guides/candidate-prep-final"></a></div>
              </div>
              <div class="dashboard-item dashboard-halves" [hidden]="isSimulated">
                <div class="dashboard-half" id="candidate-debrief" placement="top" [openDelay]="delay" container="body" ngbTooltip="Candidate Debrief"><a routerLink="/guides/candidate-debrief"></a></div>
                <div class="dashboard-half" id="candidate-debrief-trial-closing" placement="bottom" [openDelay]="delay" container="body" ngbTooltip="Trial Closing After Final Interview"><a routerLink="/guides/candidate-debrief-trial-closing"></a></div>
              </div>
              <div class="dashboard-item" id="closing-candidate" [hidden]="isSimulated"><a routerLink="/guides/closing-candidate"></a></div>
              <div class="dashboard-item" id="resignation" [hidden]="isSimulated"><a routerLink="/guides/resignation"></a></div>
            </div>
          </div>
          <div class="section-wrapper">
            <div class="section-header section-client">Client</div>

            <div class="panel-content single-panel p-0">
              <div class="dashboard-item" id="biz-dev" [hidden]="isSimulated"><a routerLink="/guides/bizdev"></a></div>
              <div class="dashboard-item" id="pop-marketing" [hidden]="isSimulated"><a routerLink="/guides/pop-marketing"></a></div>
              <div class="dashboard-item" id="taking-search"><a routerLink="/searchassignment"></a></div>
              <div class="dashboard-item dashboard-halves" [hidden]="isSimulated">
                <div class="dashboard-half" id="client-prep-first" placement="top" [openDelay]="delay" container="body" ngbTooltip="Client First Interview Prep"><a routerLink="/guides/client-prep-first"></a></div>
                <div class="dashboard-half" id="client-prep-final" placement="bottom" [openDelay]="delay" container="body" ngbTooltip="Client Final Prep"><a routerLink="/guides/client-prep-final"></a></div>
              </div>
              <div class="dashboard-item dashboard-halves" [hidden]="isSimulated">
                <div class="dashboard-half" id="client-debrief" placement="top" [openDelay]="delay" container="body" ngbTooltip="Client Debrief"><a routerLink="/guides/client-debrief"></a></div>
                <div class="dashboard-half" id="client-debrief-trial-closing" placement="bottom" [openDelay]="delay" container="body" ngbTooltip="Client Debrief After Final Interview"><a routerLink="/guides/client-debrief-trial-closing"></a></div>
              </div>
              <div class="dashboard-item" id="closing-client" [hidden]="isSimulated"><a routerLink="/guides/closing-client"></a></div>
              <div class="dashboard-item" id="reference-checking"><a routerLink="/referencecheck"></a></div>
            </div>
          </div>
      </div>
  </div>

  <div class="panel dashboard-panel" id="off-phone">
      <div class="panel-header">
          <span class="header-title">Off the Phone</span>
      </div>
      <div class="panel-content single-panel">
        <div class="section-header">&nbsp;</div>

        <a routerLink="/dailyplanner"><div class="dashboard-item" id="daily-plan"></div></a>
        <a routerLink="/esourcing" [hidden]="isSimulated"><div class="dashboard-item" id="e-sourcing"></div></a>
        <a routerLink="/candidates"><div class="dashboard-item" id="candidates"><div class="dashboard-item-inner"></div></div></a>
        <a routerLink="/clients"><div class="dashboard-item" id="clients"><div class="dashboard-item-inner"></div></div></a>
        <a href="https://www.youtube.com/@morganconsultinggroupinc9743/videos" target="_blank" [hidden]="isSimulated"><div class="dashboard-item" id="tuneups"><div class="dashboard-item-inner"></div></div></a>
        <a href="https://www.morgancg.com/recruiter-toolbox/" [hidden]="isSimulated" target="_blank"><div class="dashboard-item" id="tall-pony-ranch"><div class="dashboard-item-inner"></div></div></a>
      </div>
  </div>
</div>

<app-footer></app-footer>
