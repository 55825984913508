export class UserActivity {
  username: string;
  email: string;
  account: string;
  loggedInAt: string;
  loggedOutAt: string;

  constructor(username: string, email: string, account: string, loggedInAt: string, loggedOutAt: string) {
    this.username = username;
    this.email = email;
    this.account = account;
    this.loggedInAt = loggedInAt;
    this.loggedOutAt = loggedOutAt;
  }
}
