import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss']
})
export class CommonDialogComponent implements OnInit {

  headerText = '';
  bodyText = '';
  actionButtonText = '';
  closeButtonText = '';
  list = null;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  save(): void {
    this.activeModal.close();
  }

  close(): void {
    this.activeModal.dismiss();
  }
}
