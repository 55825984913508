export const isOpera = navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR') !== -1;

export const isEdge = navigator.userAgent.indexOf('Edg') !== -1;

export const isChrome = navigator.userAgent.indexOf('Chrome') !== -1;

export const isSafari = navigator.userAgent.indexOf('Safari') !== -1;

export const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;

