<div class="widget">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 widget-header">Candidate</div>
        </div>
        <div class="widget-body">
            <div class="row">
                <div class="col-12 p-0">
                    <select class="col-12 pl-0" [(ngModel)]="candidateUuid" (change)="candidateChanged()">
                        <option *ngFor="let candidate of candidates; trackBy: trackByUuid" [value]="candidate.uuid">{{ candidate.firstName + ' ' + candidate.lastName }}</option>
                    </select>
                    <div *ngIf="!!candidate" class="col-12" id="candidateName">{{ candidate.firstName + ' ' + candidate.lastName }}<br />{{candidate.title}}</div>
                </div>
            </div>
            <div class="row d-flex justify-content-between">
                <button type="button" class="btn btn-danger btn-sm" (click)="viewCandidate()">Details</button>
                <button type="button" class="btn btn-danger btn-sm" (click)="goToInnerView()" [hidden]="!candidateHasInnerview()">Innerview</button>
                <a [ngClass]="{ disabled: !resumeLink }" type="button" class="btn btn-danger btn-sm" href="javascript:void(0)" (click)="download(resumeLink)">Resume</a>
            </div>
        </div>
    </div>
</div>
