import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';

import { Note } from './note.model';
import { AlertService } from '../../alert.service';
import { ServerService } from '../../server.service';
import { UsersService } from '../../users.service';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor(
    private http: HttpClient,
    private serverService: ServerService,
    private usersService: UsersService,
    private alertService: AlertService) { }

  lookup(uuid: string): Observable<Note> {
    return this.http.get(this.serverService.api('/notes', 'lookup', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to fetch a note ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  list(owner: string): Observable<Note[]> {
    if (!owner) {
      return of([]);
    }

    return this.http.get(this.serverService.api('/notes', 'list', owner), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to list notes ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  insert(note: Note, owneruuid: string): Observable<any> {
    if (!note.owner_uuid) {
      note.owner_uuid = owneruuid;
    }

    return this.usersService.currentUser().pipe(switchMap(user => {
      if (!note.user_uuid) {
        note.user_uuid = user.uuid;
      }

      if (!note.account_uuid) {
        note.account_uuid = user.account_uuid;
      }

      return this.http.post(this.serverService.api('/notes'), { note }, this.serverService.apiConfig())
        .pipe(first(), catchError((error) => {
          this.alertService.error('Failed to insert a note ' + JSON.stringify(error.data));
          return throwError(error);
        }), map((response: any) => {
          return response;
        }));
    }));
  }

  update(note: Note): Observable<any> {
    return this.http.put(this.serverService.api('/notes'), { note }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to update a note ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  delete(uuid: string): Observable<any> {
    return this.http.delete(this.serverService.api('/notes', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to delete a note ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }
}
