export enum ImportPlatform {
  Avionte  = 'Avionte',
  JobAdder = 'Job Adder',
  Bullhorn = 'Bullhorn',
  Crelate = 'Crelate',
  Loxo = 'Loxo',
  BigBiller = 'Big Biller',
  PCRecruiter = 'PCRecruiter',
  Other = 'Other',
}
