import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { TermsComponent } from './terms/terms.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EguideComponent } from './eguide/eguide.component';
import { EsourcingComponent } from './esourcing/esourcing.component';
import { AccountsComponent } from './admin/accounts/accounts.component';
import { UsersComponent } from './admin/users/users.component';
import { ObjectionsComponent } from './admin/objections/objections.component';
import { StringsComponent } from './admin/strings/strings.component';
import { SessionsComponent } from './admin/sessions/sessions.component';
import { CandidatesComponent } from './admin/candidates/candidates.component';
import { ClientsComponent } from './admin/clients/clients.component';
import { SettingsComponent } from './admin/settings/settings.component';
import { DailyplannerComponent } from './dailyplanner/dailyplanner.component';
import { MorganrescueComponent } from './morganrescue/morganrescue.component';
import { ReferencecheckComponent } from './documents/referencecheck/referencecheck.component';
import { InnerviewComponent } from './documents/innerview/innerview.component';
import { SearchassignmentComponent } from './documents/searchassignment/searchassignment.component';
import { MorginarsComponent } from './morginars/morginars.component';
import { CheatsheetComponent } from './cheatsheet/cheatsheet.component';

import { NoOpenModalsGuard } from './no-open-modals.guard';
import { UserActivityComponent } from './admin/user-activity/user-activity.component';
import { SummaryActivityComponent } from './admin/user-activity/components/summary-activity/summary-activity.component';
import {ActivityComponent} from './admin/user-activity/components/activity/activity.component';
import {
  GlobalActivityReportComponent
} from './admin/activity-report/components/global-activity-report/global-activity-report.component';
import {
  CompanyActivityReportComponent
} from './admin/activity-report/components/company-activity-report/company-activity-report.component';
import {
  UserActivityReportComponent
} from './admin/activity-report/components/user-activity-report/user-activity-report.component';
import { ActivityReportComponent } from './admin/activity-report/activity-report.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    data: {
      title: 'Morgan Mentor'
    },
    canActivate: [NoOpenModalsGuard]
  },
  {
    path: 'terms',
    component: TermsComponent,
    data: {
      title: 'Terms & Conditions'
    },
    canActivate: [NoOpenModalsGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: {
      title: 'Dashboard'
    },
    canActivate: [NoOpenModalsGuard]
  },
  {
    path: 'guides/:name',
    component: EguideComponent,
    data: {
      title: 'E-Guide'
    },
    canActivate: [NoOpenModalsGuard]
  },
  {
    path: 'esourcing',
    component: EsourcingComponent,
    data: {
      title: 'E-Sourcing'
    },
    canActivate: [NoOpenModalsGuard]
  },
  {
    path: 'admin/accounts',
    component: AccountsComponent,
    data: {
      title: 'Accounts'
    },
    canActivate: [NoOpenModalsGuard]
  },
  {
    path: 'admin/users/:id',
    component: UsersComponent,
    data: {
      title: 'Users'
    },
    canActivate: [NoOpenModalsGuard]
  },
  {
    path: 'admin/objections',
    component: ObjectionsComponent,
    data: {
      title: 'Objections'
    },
    canActivate: [NoOpenModalsGuard]
  },
  {
    path: 'admin/search',
    component: StringsComponent,
    data: {
      title: 'Strings'
    },
    canActivate: [NoOpenModalsGuard]
  },
  {
    path: 'sessions',
    component: SessionsComponent,
    data: {
      title: 'Sessions'
    },
    canActivate: [NoOpenModalsGuard]
  },
  {
    path: 'activity-report',
    component: ActivityReportComponent,
    data: {
      title: 'Activity Report'
    },
    canActivate: [NoOpenModalsGuard],
    children: [
      {
        path: 'general',
        component: GlobalActivityReportComponent,
        canActivate: [NoOpenModalsGuard],
      },
      {
        path: 'company/:id',
        component: CompanyActivityReportComponent,
        canActivate: [NoOpenModalsGuard],
      },
      {
        path: 'user/:id',
        component: UserActivityReportComponent,
        canActivate: [NoOpenModalsGuard],
      },
    ],
  },
  {
    path: 'candidates',
    component: CandidatesComponent,
    data: {
      title: 'Candidates'
    },
    canActivate: [NoOpenModalsGuard]
  },
  {
    path: 'clients',
    component: ClientsComponent,
    data: {
      title: 'Clients'
    },
    canActivate: [NoOpenModalsGuard]
  },
  {
    path: 'admin/settings',
    component: SettingsComponent,
    data: {
      title: 'Settings'
    },
    canActivate: [NoOpenModalsGuard]
  },
  {
    path: 'dailyplanner',
    component: DailyplannerComponent,
    data: {
      title: 'Daily Planner'
    },
    canActivate: [NoOpenModalsGuard]
  },
  {
    path: 'morganrescue',
    component: MorganrescueComponent,
    data: {
      title: 'Morgan Rescue'
    },
    canActivate: [NoOpenModalsGuard]
  },
  {
    path: 'referencecheck',
    component: ReferencecheckComponent,
    data: {
      title: 'Reference Check'
    },
    canActivate: [NoOpenModalsGuard]
  },
  {
    path: 'innerview',
    component: InnerviewComponent,
    data: {
      title: 'InnerView'
    },
    canActivate: [NoOpenModalsGuard]
  },
  {
    path: 'searchassignment',
    component: SearchassignmentComponent,
    data: {
      title: 'Search Assignment'
    },
    canActivate: [NoOpenModalsGuard]
  },
  {
    path: 'morginars',
    component: MorginarsComponent,
    data: {
      title: 'Morginars'
    },
    canActivate: [NoOpenModalsGuard]
  },
  {
    path: 'cheatsheet',
    component: CheatsheetComponent,
    data: {
      title: 'Cheatsheet'
    },
    canActivate: [NoOpenModalsGuard]
  },

  // Otherwise redirect to login:
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
