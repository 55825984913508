import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  Store,
  State,
  StateContext,
} from '@ngxs/store';

import {forkJoin, of} from 'rxjs';

import * as _ from 'lodash';

import { ClientsActions } from './clients.actions';
import { Client } from './clients.model';
import { ClientsService } from './clients.service';
import { UsersService } from '../../shared/users.service';
import { EmailType } from 'src/app/shared/models/email.model';
import { PhoneType } from 'src/app/shared/models/phone.model';
import { Pagination } from 'src/app/shared/models/pagination.model';

import { PlannerService } from '../../dailyplanner/planner.service';
import { AlertService } from 'src/app/shared/alert.service';
import {BulkCreateStatusModel} from '../../shared/models/bulk-create.model';
import {finalize, mergeMap} from 'rxjs/operators';
import {DEFAULT_CHUNK_SIZE} from '../../shared/consts/default-chunk-size.const';

export interface ClientStateModel {
  clients: Client[];
  currentClients: {[key: string]: Client};
  selected: string[];
  pagination: Pagination;
  bulkCreate: BulkCreateStatusModel;
}

const defaultState: ClientStateModel = {
  clients: [],
  currentClients: {},
  selected: [],
  pagination: {
    search: '',
    limit: 100,
    offset: 0,
    count: 0,
    sort: '',
    order: '',
  },
  bulkCreate: {
    percentage: 0,
    isError: false,
  },
};

@State<ClientStateModel>({
  name: 'clients',
  defaults: { ...defaultState }
})

@Injectable()
export class ClientsState {

  @Selector()
  static clients(state: ClientStateModel): Client[] {
    return state.clients;
  }

  @Selector()
  static pagination(state: ClientStateModel): Pagination {
    return state.pagination;
  }

  @Selector()
  static currentClients(state: ClientStateModel): {[key: string]: Client} {
    return state.currentClients;
  }

  @Selector()
  static selected(state: ClientStateModel): string[] {
    return state.selected;
  }

  @Selector()
  static selectedFullObjects(state: ClientStateModel): Client[] {
    const res = state.clients.filter(c => {
      return state.selected.find(uuid => c.uuid === uuid);
    });
    return res || [];
  }

  @Selector()
  static bulkCreate(state: ClientStateModel): BulkCreateStatusModel {
    return state.bulkCreate;
  }

  constructor(
    private store: Store,
    private clientsService: ClientsService,
    private usersService: UsersService,
    private plannerService: PlannerService,
    private alertService: AlertService
  ) {
  }

  @Action(ClientsActions.Load)
  load(context: StateContext<ClientStateModel>): void {
    const state = context.getState();
    const pagination = state.pagination;

    this.usersService.currentUser().subscribe(user => {
      if (user.roles.indexOf('account-admin') > -1) {
        this.clientsService.listByAccount(
            user.account_uuid, pagination.search, pagination.offset, pagination.limit,
            pagination.sort, pagination.order).subscribe(clients => {
          context.patchState({
            clients,
            pagination: {
              ...pagination,
              count: clients.length
            },
            selected: [],
          });

          this.store.dispatch(new ClientsActions.NeedsReload());
        });
      } else {
        this.clientsService.list(
          user.uuid, pagination.search, pagination.offset, pagination.limit, pagination.sort, pagination.order).subscribe(clients => {
          context.patchState({
            clients,
            pagination: {
              ...pagination,
              count: clients.length
            },
            selected: [],
          });

          this.store.dispatch(new ClientsActions.NeedsReload());
        });
      }
    });
  }

  @Action(ClientsActions.SetOrder)
  setOrder(context: StateContext<ClientStateModel>,
           { payload: { sort, order } }: ClientsActions.SetOrder): void {
    const state = context.getState();

    const pagination = {
      ...state.pagination,
      sort,
      order
    };

    context.patchState({
      pagination
    });

    context.dispatch(new ClientsActions.Load());
  }

  @Action(ClientsActions.SetPagination)
  setPagination(context: StateContext<ClientStateModel>,
                { payload: { limit, page } }: ClientsActions.SetPagination): void {
    const state = context.getState();

    const pagination = {
      ...state.pagination,
      limit,
      page
    };

    context.patchState({
      pagination
    });

    context.dispatch(new ClientsActions.Load());
  }

  @Action(ClientsActions.Search)
  search(context: StateContext<ClientStateModel>,
         { payload: { client } }: ClientsActions.Search): void {

    const state = context.getState();

    const pagination = {
      ...state.pagination,
      search: client || ''
    };

    context.patchState({
      pagination,
      selected: [],
    });

    context.dispatch(new ClientsActions.Load());
  }

  @Action(ClientsActions.UpdateCurrent)
  updateCurrent(context: StateContext<ClientStateModel>,
                { payload: { uuid, client } }: ClientsActions.UpdateCurrent): void {
    const state = context.getState();
    const currentClients = {
      ...state.currentClients
    };

    currentClients[uuid] = {
      ...currentClients[uuid],
      ...client
    };

    context.patchState({
      currentClients
    });
  }

  @Action(ClientsActions.AddEmail)
  addEmail(context: StateContext<ClientStateModel>,
           { payload: { uuid } }: ClientsActions.AddEmail): void {
    const state = context.getState();
    const currentClients = {
      ...state.currentClients
    };
    const client = currentClients[uuid];

    if (!client) {
      return;
    }

    const emails = (client.emails || []).slice();
    emails.push({ type: EmailType.work, address: '' });

    client.emails = emails;
    currentClients[uuid] = client;

    context.patchState({
      currentClients
    });
  }

  @Action(ClientsActions.SetEmailType)
  setEmailType(context: StateContext<ClientStateModel>,
               { payload: { uuid, index, type } }: ClientsActions.SetEmailType): void {
    const state = context.getState();
    const currentClients = {
      ...state.currentClients
    };
    const client = currentClients[uuid];

    if (!client) {
      return;
    }

    const emails = (client.emails || []).slice();
    emails[index].type = type;

    client.emails = emails;
    currentClients[uuid] = client;

    context.patchState({
      currentClients
    });
  }

  @Action(ClientsActions.SetEmailAddress)
  setEmailAddress(context: StateContext<ClientStateModel>,
                  { payload: { uuid, index, address } }: ClientsActions.SetEmailAddress): void {
    const state = context.getState();
    const currentClients = {
      ...state.currentClients
    };
    const client = currentClients[uuid];

    if (!client) {
      return;
    }

    const emails = (client.emails || []).slice();
    emails[index].address = address;

    client.emails = emails;
    currentClients[uuid] = client;

    context.patchState({
      currentClients
    });
  }

  @Action(ClientsActions.RemoveEmail)
  removeEmail(context: StateContext<ClientStateModel>,
              { payload: { uuid, index } }: ClientsActions.RemoveEmail): void {
    const state = context.getState();
    const currentClients = {
      ...state.currentClients
    };
    const client = currentClients[uuid];

    if (!client) {
      return;
    }

    const emails = (client.emails || []).slice();
    emails.splice(index, 1);

    client.emails = emails;
    currentClients[uuid] = client;

    context.patchState({
      currentClients
    });
  }

  @Action(ClientsActions.AddPhone)
  addPhone(context: StateContext<ClientStateModel>,
           { payload: { uuid } }: ClientsActions.AddPhone): void {
    const state = context.getState();
    const currentClients = {
      ...state.currentClients
    };
    const client = currentClients[uuid];

    if (!client) {
      return;
    }

    const phoneNumbers = (client.phoneNumbers || []).slice();
    phoneNumbers.push({ type: PhoneType.work, number: '' });

    client.phoneNumbers = phoneNumbers;
    currentClients[uuid] = client;

    context.patchState({
      currentClients
    });
  }

  @Action(ClientsActions.SetPhoneType)
  setPhoneType(context: StateContext<ClientStateModel>,
               { payload: { uuid, index, type } }: ClientsActions.SetPhoneType): void {
    const state = context.getState();
    const currentClients = {
      ...state.currentClients
    };
    const client = currentClients[uuid];

    if (!client) {
      return;
    }

    const phoneNumbers = (client.phoneNumbers || []).slice();
    phoneNumbers[index].type = type;

    client.phoneNumbers = phoneNumbers;
    currentClients[uuid] = client;

    context.patchState({
      currentClients
    });
  }

  @Action(ClientsActions.SetPhoneNumber)
  setPhoneNumber(context: StateContext<ClientStateModel>,
                 { payload: { uuid, index, phoneNumber } }: ClientsActions.SetPhoneNumber): void {
    const state = context.getState();
    const currentClients = {
      ...state.currentClients
    };
    const client = currentClients[uuid];

    if (!client) {
      return;
    }

    const phoneNumbers = (client.phoneNumbers || []).slice();
    phoneNumbers[index].number = phoneNumber;

    client.phoneNumbers = phoneNumbers;
    currentClients[uuid] = client;

    context.patchState({
      currentClients
    });
  }

  @Action(ClientsActions.RemovePhone)
  removePhone(context: StateContext<ClientStateModel>,
              { payload: { uuid, index } }: ClientsActions.RemovePhone): void {
    const state = context.getState();
    const currentClients = {
      ...state.currentClients
    };
    const client = currentClients[uuid];

    if (!client) {
      return;
    }

    const phoneNumbers = (client.phoneNumbers || []).slice();
    phoneNumbers.splice(index, 1);

    client.phoneNumbers = phoneNumbers;
    currentClients[uuid] = client;

    context.patchState({
      currentClients
    });
  }

  @Action(ClientsActions.Update)
  update(context: StateContext<ClientStateModel>,
         { payload: { uuid } }: ClientsActions.Update): void {
    const state = context.getState();
    const currentClients = {
      ...state.currentClients
    };
    const client = {
      ...state.clients.find(cli => cli.uuid === uuid),
      ...currentClients[uuid]
    };

    if (!client) {
      return;
    }

    this.usersService.currentUser().subscribe(user => {
      if (!client.owner) {
        client.owner = user.uuid;
      }

      if (!client.account) {
        client.account = user.account_uuid;
      }

      if (!!client.uuid) {
        this.clientsService.update(client).subscribe(() => {
          this.load(context);
        });
      } else {
        this.clientsService.insert(client).subscribe(() => {
          this.load(context);
        });
      }
    });
  }

  @Action(ClientsActions.Delete)
  delete(context: StateContext<ClientStateModel>,
         { payload: { uuid } }: ClientsActions.Delete): void {
    if (uuid) {
      this.clientsService.delete(uuid).subscribe(() => {
        this.load(context);

        this.select(context, { payload: { uuid, select: false } });
      });
    } else {
      const selectedUuids = context.getState().selected;

      forkJoin(selectedUuids.map(this.clientsService.delete.bind(this.clientsService))).subscribe(() => {
        this.load(context);

        selectedUuids.forEach(selectedUuid => this.select(context, { payload: { uuid: selectedUuid, select: false } }) );
      });
    }
  }

  @Action(ClientsActions.Select)
  select(context: StateContext<ClientStateModel>, { payload: { uuid, select } }: ClientsActions.Select): void {
    const state = context.getState();
    const selected = state.selected.slice();
    const index = selected.indexOf(uuid);

    if (select) {
      if (index === -1) {
        context.patchState({
          selected: selected.concat([uuid])
        });
      }
    } else {
      if (index > -1) {
        selected.splice(index, 1);

        context.patchState({
          selected
        });
      }
    }
  }

  @Action(ClientsActions.SelectAll)
  selectAll(context: StateContext<ClientStateModel>, { payload: { select } }: ClientsActions.SelectAll): void {
    const state = context.getState();
    if (select) {
      const uuids = state.clients.filter(c => c.uuid).map(c => c.uuid);
      context.patchState({
        selected: (uuids || []) as any
      });
    } else {
      context.patchState({
        selected: []
      });
    }
  }

  @Action(ClientsActions.UnselectAll)
  unselectAll(context: StateContext<ClientStateModel>): void {
    context.patchState({
      selected: []
    });
  }

  @Action(ClientsActions.SetCurrentPartial)
  setCurrentPartial(context: StateContext<ClientStateModel>, { payload: { uuid, client } }: ClientsActions.SetCurrentPartial): void {
    this.usersService.currentUser().subscribe(user => {
      const state = context.getState();
      const currentClients = {
        ...state.currentClients
      };

      const currentClient = {
        ...client,
        ...currentClients[uuid],
        firstName: '',
        lastName: '',
        title: '',
        location: '',
        company: '',
        source: 'Cold Call',
        active: true,
        private: false,
        positions: '',
        memo: '',
        emails: [{ type: EmailType.work, address: '' }],
        phoneNumbers: [{ type: PhoneType.work, number: '' }],
        addresses: []
      };

      currentClients[uuid] = currentClient;

      context.patchState({
        currentClients
      });
    });
  }

  @Action(ClientsActions.AddToPlan)
  addToPlan(context: StateContext<ClientStateModel>, { payload: { uuid } }: ClientsActions.AddToPlan): void {
    const state = context.getState();
    const currentClients = {
      ...state.currentClients
    };
    const client = currentClients[uuid];

    if (client) {
      this.plannerService.get().subscribe((planner) => {
        planner.generatedLeads.push({
          status: 'none',
          company: client.company,
          phone: ((client.phoneNumbers && client.phoneNumbers.length) ? client.phoneNumbers[0].number : ''),
          position: client.title,
          hiringManager: client.firstName + ' ' + client.lastName,
          source: '',
          notes: ''
        });

        this.plannerService.update( _.pick(planner, ['uuid', 'generatedLeads'])).subscribe(() => {
          this.alertService.success('Client record was added to Daily Planner');
        });
      });
    }
  }

  @Action(ClientsActions.Show)
  show(context: StateContext<ClientStateModel>,
       { payload: { uuid } }: ClientsActions.Show): void {
    const state = context.getState();
    const currentClients = {
      ...state.currentClients
    };

    const cli = state.clients.find(client => client.uuid === uuid);

    if (cli) {
      currentClients[uuid] = cli;
    }

    context.patchState({
      currentClients
    });
  }

  @Action(ClientsActions.Finished)
  finished(context: StateContext<ClientStateModel>,
           { payload: { uuid } }: ClientsActions.Finished): void {
    const state = context.getState();
    const currentClients = {
      ...state.currentClients
    };

    delete currentClients[uuid];

    context.patchState({
      currentClients
    });
  }

  // Import files could contain hundreds or thousands of records. We use bulk creation to provide better performance.
  @Action(ClientsActions.BulkCreate)
  bulkCreate(context: StateContext<ClientStateModel>,
             { payload: { clients } }: ClientsActions.BulkCreate): void {
    if (!clients?.length) {
      return;
    }
    const state = context.getState();

    this.usersService.currentUser().subscribe(user => {
      const clientsToSave = clients.map(client => {
        if (!client.owner) {
          client.owner = user.uuid;
        }

        if (!client.account) {
          client.account = user.account_uuid;
        }

        if (!client.firstName) {
          client.firstName = client.company || 'Imported Client';
        }

        return client;
      });

      context.patchState({
        bulkCreate: {
          percentage: 2,
          isError: false,
          errorMessage: undefined
        }
      });

      const urls = [];
      const chunkSize = DEFAULT_CHUNK_SIZE;
      for (let i = 0; i < clientsToSave.length; i += chunkSize) {
        const chunk = clientsToSave.slice(i, i + chunkSize);
        urls.push( this.clientsService.bulkInsert(chunk));
      }

      const numberOfChunks = Math.ceil(clientsToSave.length / chunkSize) || 1;
      let numberOfCompletedChunks = 0;

      for (let i = 0; i < urls.length; i++) {
        urls[i].pipe(
          finalize(() => {
            numberOfCompletedChunks++;
            const bulkCreatePercentage = Math.ceil(numberOfCompletedChunks * 100 / numberOfChunks);
            const bulkCreateState = context.getState().bulkCreate;
            context.patchState({
              bulkCreate: {
                ...bulkCreateState,
                percentage: bulkCreatePercentage,
              }
            });
            if (numberOfCompletedChunks === numberOfChunks) {
              this.load(context);
              setTimeout(() => {
                context.patchState({
                  bulkCreate: undefined
                });
              }, 3000);
            }
          })
        ).subscribe(
          (res: {client: {clients: Client[], areDuplicatesAvoided: boolean}}) => {
            context.patchState({
              bulkCreate: {
                ...state.bulkCreate,
                areDuplicatesAvoided: res?.client?.areDuplicatesAvoided || false
              }
            });
          },
          (error: any) => {
            const existingErrorMessage = context.getState().bulkCreate?.errorMessage || '';
            const newErrorMessage =  error.error.message?.length ? error.error.message : '';
            const errorMessageToSet = existingErrorMessage  + newErrorMessage + '<br />';
            context.patchState({
              bulkCreate: {
                ...state.bulkCreate,
                isError: true,
                errorMessage: errorMessageToSet
              }
            });
          }
        );
      }
    });
  }
}
