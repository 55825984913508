import { Injectable } from '@angular/core';
import {isChrome, isFirefox, isOpera} from '../utils/get-browser.util';
import {FileSaverService} from 'ngx-filesaver';
import {Candidate} from '../../admin/candidates/candidate.model';
import {ExportDataSeparateSymbol} from '../consts/export-data-separate-symbol.const';
import {Note} from '../widgets/notes/note.model';
import {AlertService} from '../alert.service';
import {capitalizeFirstLetter} from '../utils/capitalize-first-letter.util';

@Injectable({
  providedIn: 'root'
})
export class ExportFileService {

  constructor( private fileSaverService: FileSaverService, private alertService: AlertService, ) { }


  async saveFileByFilePicker(blob: Blob, suggestedFileName: string): Promise<void> {
    if (isChrome || isFirefox || isOpera) {
      try {
        const handle = await (window as any).showSaveFilePicker({ suggestedName: suggestedFileName });
        const writable = await handle.createWritable();
        await writable.write(blob);
        await writable.close();
        this.alertService.success('The File is successfully exported');
      } catch (error) {
        console.error('Error saving file:', error);
      }
    } else {
      this.saveFileToDefaultDirectory(blob, suggestedFileName);
      return Promise.resolve();
    }
  }

  saveFileToDefaultDirectory(blob: Blob, fileName: string): void {
    this.fileSaverService.save(blob, fileName);
    this.alertService.success('The File is successfully exported');
  }

  prepareBlobData = (items: any[]): Blob | undefined => {
    if (!items?.length) {
      return;
    }

    // Extract all unique keys (column names) from objects
    const csvColumnsSet: string[] = [];
    items.forEach(obj => {
      Object.keys(obj).forEach(key => {
        if (!csvColumnsSet.find(c => c === key)) {
          const keyParts = key.split(' ');
          const lastKeyPart = keyParts[keyParts.length - 1];
          // if last word is a number
          if (!isNaN(+lastKeyPart)) {
            keyParts.pop();
            const nameWithoutNumber = keyParts.join(' ').trim();
            const index = csvColumnsSet.findIndex(c => c.includes(nameWithoutNumber));
            if (index !== -1) {
              csvColumnsSet.splice(index + 1, 0, key);
            } else {
              csvColumnsSet.push(key);
            }
          } else {
            csvColumnsSet.push(key);
          }
        }
      });
    });

    // Convert the set of columns back to an array
    const csvColumns = Array.from(csvColumnsSet);
    const csvColumnsWords = csvColumns.map(c => this.camelCaseToWords(c));
    const csvData = [
      csvColumnsWords.join(','),
      ...items.map((obj: any) => csvColumns.map(field => {
        // Check if the field contains a comma, if so, enclose it in double quotes
        let fieldValue = obj[field];
        if (fieldValue?.includes(',')) {
          fieldValue = `"${fieldValue}"`;
        }
        return fieldValue;
      }).join(','))
    ].join('\n');

    const blob = new Blob([csvData], { type: 'text/csv' });
    return blob;
  }

  camelCaseToWords(input: string): string {
    return input.replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
      .replace(/^./, (str) => str.toUpperCase());
  }

  processPhoneNumber(resObject: any, c: Candidate): void {
    const phoneTypeCounts: { [key: string]: number } = {};

    c.phoneNumbers.forEach(phoneNumber => {
      const phoneType = phoneNumber.type;
      const phoneNumberValue = phoneNumber.number;

      phoneTypeCounts[phoneType] = (phoneTypeCounts[phoneType] || 0) + 1;

      let propertyName = `${capitalizeFirstLetter(phoneType)} Phone Number`;
      if (phoneTypeCounts[phoneType] > 1) {
        propertyName += ` ${phoneTypeCounts[phoneType]}`;
      }
      resObject[propertyName] = phoneNumberValue;
    });
  }

  processEmail(resObject: any, c: Candidate): void {
    const phoneTypeCounts: { [key: string]: number } = {};

    c.emails.forEach(email => {
      const emailType = email.type;
      const emailValue = email.address;

      phoneTypeCounts[emailType] = (phoneTypeCounts[emailType] || 0) + 1;

      let propertyName = `${capitalizeFirstLetter(emailType)} Email`;
      if (phoneTypeCounts[emailType] > 1) {
        propertyName += ` ${phoneTypeCounts[emailType]}`;
      }
      resObject[propertyName] = emailValue;
    });
  }

  getAddressString = (c: Candidate, addressFieldName: string): string => {
    return c.addresses?.map((a: any) => a?.[addressFieldName] ? a[addressFieldName] : '' ).join(ExportDataSeparateSymbol) || '';
  }

  getPhoneNumberString(c: Candidate): string {
    return c.phoneNumbers.map(p => p.type + ': ' + p.number).join(ExportDataSeparateSymbol);
  }

  getEmailString(c: Candidate): string {
    return c.emails.map(e => e.type + ': ' + e.address).join(ExportDataSeparateSymbol);
  }

  getAddressLineString(c: Candidate): string {
    return c.addresses.map(a => {
      if (a.address1 && a.address2) {
        return 'Address 1: ' + a.address1 + ' Address 2: ' + a.address2;
      }
      if (a.address1) {
        return 'Address 1: ' + a.address1;
      }
      if (a.address2) {
        return 'Address 2: ' + a.address2;
      }
      return '';
    }).join(' | ');
  }

  getNotesString(c: Candidate & {notes?: Note[]}): string {
    return c.notes?.map(n => n.text).join(ExportDataSeparateSymbol) || '';
  }
}
