<div class="modal-header" *ngIf="{ account: current$ | async } as data" appDraggable=".modal-dialog">
    <h4 class="modal-title">{{ data.account && data.account.uuid ? 'Edit' : 'New' }} Account</h4>
    <button type="button" class="close" aria-hidden="true" (click)="close()">&times;</button>
</div>
<div class="modal-body" *ngIf="{ isUnique: isUnique$ | async } as data">
    <form [formGroup]="form">

        <div class="form-group row">
            <label class="col-3 control-label text-right">Account Name:</label>
            <div class="col-9">
                <input type="text" class="form-control" formControlName="name" appAutofocus />
                <small class="form-text text-muted">Enter a unique account name</small>
                <small [hidden]="data.isUnique" class="form-text text-danger">Account with this name already exists; please change the name.</small>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 control-label text-right">Display Name:</label>
            <div class="col-9">
                <input type="text" class="form-control" formControlName="displayName" />
                <small class="form-text text-muted">Enter a full account name to be displayed</small>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 control-label text-right">Description:</label>
            <div class="col-9">
                <input type="text" class="form-control" formControlName="description" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 control-label text-right">Primary Contact:</label>
            <div class="col-9">
                <input type="text" class="form-control" formControlName="primaryContact" />
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-9 offset-sm-3">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" formControlName="active" /> Active
                    </label>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-9 offset-sm-3 row">
                <div class="col-6 pl-0">
                    Created: <input type="text" class="form-control d-inline w-auto ml-0" formControlName="created" readonly />
                </div>
                <div class="col-6 pl-0">
                    Modified: <input type="text" class="form-control d-inline w-auto ml-0" formControlName="modified" readonly />
                </div>
            </div>
        </div>
    </form>

    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-account-subscription,ngb-account-emails,ngb-account-phones,ngb-account-addresses" *ngIf="current$ | async as account">
        <ngb-panel id="ngb-account-subscription">
            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="m-0">Subscription</h5>
                    <div>
                        <button ngbPanelToggle class="btn btn-outline-primary">
                            <i [hidden]="opened" class="fas fa-plus"></i>
                            <i [hidden]="!opened" class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <form [formGroup]="form">

                <div class="row">
                    <div class="col-6">
                        <div class="form-group row">
                            <label class="col-3 p-0 control-label text-right">Total Seats:</label>
                            <div class="col-9">
                                <input type="number" class="form-control" formControlName="totalSeats" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-3 p-0 control-label text-right">Start Date:</label>
                            <div class="col-9">
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="startDate" name="startDate" ngbDatepicker container="body" #d="ngbDatepicker" />
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group row">
                            <label class="col-3 p-0 control-label text-right">Seats Used:</label>
                            <div class="col-9">
                                <input type="number" readonly class="form-control" formControlName="seatsUsed" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-3 p-0 control-label text-right">End Date:</label>
                            <div class="col-9">
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="endDate" name="endDate" ngbDatepicker container="body" #dd="ngbDatepicker" />
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" (click)="dd.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
                </form>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="ngb-account-emails">
            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="m-0">Emails</h5>
                    <div>
                        <button ngbPanelToggle class="btn btn-outline-primary">
                            <i [hidden]="opened" class="fas fa-plus"></i>
                            <i [hidden]="!opened" class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <ul class="list-group p-0 col-12">
                    <li class="list-group-item" *ngFor="let email of account.emails; index as i">
                        <div class="row">
                            <div class="col-sm-4">
                                <select class="form-control" [value]="email.type" (change)="setEmailType(i, $event.target)">
                                    <option value="work">Work</option>
                                    <option value="home">Home</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            <div class="col-sm-6 p-0">
                                <input class="form-control" type="email" [value]="email.address" (input)="setEmailAddress(i, $event.target)" required />
                            </div>
                            <div class="col-sm-2">
                                <div class="delete-icon float-right" (click)="removeEmail(i)"><i class="fas fa-times-circle"></i></div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item"><input class="btn btn-primary" type="button" value="New" (click)="addEmail()" appFocusNewInput /></li>
                </ul>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="ngb-account-phones">
            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="m-0">Phone Numbers</h5>
                    <div>
                        <button ngbPanelToggle class="btn btn-outline-primary">
                            <i [hidden]="opened" class="fas fa-plus"></i>
                            <i [hidden]="!opened" class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <ul class="list-group p-0 col-12">
                    <li class="list-group-item" *ngFor="let phone of account.phoneNumbers; index as i">
                        <div class="row">
                            <div class="col-sm-4">
                                <select class="form-control" [value]="phone.type" (change)="setPhoneType(i, $event.target)">
                                    <option value="work">Work</option>
                                    <option value="home">Home</option>
                                    <option value="mobile">Mobile</option>
                                    <option value="fax">Fax</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            <div class="col-sm-6 p-0">
                                <input class="form-control" type="text" [(ngModel)]="phone.number" mask="(000) 000-0000" [showMaskTyped]="true" (input)="setPhoneNumber(i, $event.target)" required />
                            </div>
                            <div class="col-sm-2">
                                <div class="delete-icon float-right" (click)="removePhone(i)"><i class="fas fa-times-circle"></i></div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item"><input class="btn btn-primary" type="button" value="New" (click)="addPhone()" appFocusNewInput /></li>
                </ul>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="ngb-account-addresses">
            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="m-0">Addresses</h5>
                    <div>
                        <button ngbPanelToggle class="btn btn-outline-primary">
                            <i [hidden]="opened" class="fas fa-plus"></i>
                            <i [hidden]="!opened" class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <ul class="list-group p-0 col-12">
                    <li class="list-group-item" *ngFor="let address of account.addresses; index as i">
                        <div class="row">
                            <div class="col-sm-11">
                                <span class="link" (click)="editAddress(i)">{{ address.address1 }}</span>
                            </div>
                            <div class="col-sm-1">
                                <div class="delete-icon float-right" (click)="removeAddress(i)"><i class="fas fa-times-circle"></i></div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item"><input class="btn btn-primary" type="button" value="New" (click)="addAddress()" /></li>
                </ul>
            </ng-template>
        </ngb-panel>

    </ngb-accordion>
</div>

<div class="modal-footer" *ngIf="{ account: current$ | async, isUnique: isUnique$ | async } as data">
    <button
        type="submit"
        class="btn btn-secondary"
        (click)="save()"
        [disabled]="!data.isUnique || !form.valid">Save</button>
    <button
        type="button"
        class="btn btn-danger"
        [hidden]="!data.account || !data.account.uuid"
        (click)="close(true)">Delete</button>
    <button
        type="button"
        class="btn btn-default"
        (click)="close()">Cancel</button>
</div>