import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ImportPlatform} from '../enums/import-platform.enum';
import {UploadedFile} from '../file-input/file-input.component';
import {ImportDataService} from '../services/import-data.service';
import {Step} from '../step/step.component';
import {GetAllColumnsFromCsvData} from '../utils/get-all-columns-from-data.util';
import {Columns} from 'ngx-easy-table/lib';
import {CamelCaseToWords} from '../utils/camel-case-to-words.util';
import {CommonDialogComponent} from '../common-dialog/common-dialog.component';
import {MapField} from '../map-columns/map-columns.consts';

@Component({
  selector: 'app-import-data-dialog',
  templateUrl: './import-data-dialog.component.html',
  styleUrls: ['./import-data-dialog.component.scss']
})
export class ImportDataDialogComponent implements OnInit {
  @Input() title = 'Import';
  @Input() destinationFields: MapField[] = [];
  @Input() platformFieldsMaps: Record<ImportPlatform, Record<string, string>> | undefined;
  @Input() platforms: ImportPlatform[] = [ImportPlatform.Other];

  @Output() dataToUploadIsReady = new EventEmitter<any[]>();

  steps: Step[] = [
    {
      isActive: true,
      title: 'Upload file',
      index: 1,
      isValid: false,
    },
    {
      isActive: false,
      title: 'Confirm data',
      index: 2,
      isValid: false,
    },
    {
      isActive: false,
      title: 'Map columns',
      index: 3,
      isValid: false,
    },
    {
      isActive: false,
      title: 'Upload data',
      index: 4,
      isValid: false,
    },
  ];

  // Upload file step
  isFileParsingError = false;
  platform: ImportPlatform = ImportPlatform.Other;

  // Confirm data step
  columns: Columns[] = [];
  jsonData: any[] | undefined;

  // Map columns step
  mappedFields: Record<string, MapField> = {};
  columnNames: string[] = [];

  // Upload data
  dataToUpload: any[] = [];
  dataToUploadColumns: Columns[] = [];

  // common
  isLoading = false;
  isStepContentRendered = false;
  platformFieldsMap: Record<string, string> = {}; // platform, RPb
  isSmallSpinnerType = true;


  constructor(
    private activeModal: NgbActiveModal,
    private importDataService: ImportDataService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    if (this.platformFieldsMaps) {
      this.setPlatformFieldsMap();
    }
  }

  clearData(): void {
    this.steps.forEach(s => s.isValid = true);
    this.steps[0].isValid = false;
    this.isFileParsingError = false;
    this.columns = [];
    this.jsonData = [];
    this.mappedFields = {};
    this.columnNames = [];
    this.dataToUpload = [];
    this.dataToUploadColumns = [];
    this.isStepContentRendered = false;
  }

  setPlatformFieldsMap(): void {
    this.platformFieldsMap =
      this.platform && this.platformFieldsMaps && this.platformFieldsMaps[this.platform] ?
        this.platformFieldsMaps[this.platform]  : {};
  }

  platformChanged(): void {
    this.setPlatformFieldsMap();
  }

  onFileUpload(data: UploadedFile | undefined): void {
    if (!data) {
      this.clearData();
      return;
    }
    this.isLoading = false;
    if (!data.csvData) {
      return;
    }

    this.clearData();
    this.isLoading = true;
    this.isSmallSpinnerType = data.file ? (data.file.size / 1024 / 1024 < 1) : true;
    this.importDataService.csvToJson(data.csvData, this.platform)
      .then(jsonData => {
        const formattedJsonData = jsonData.filter(obj => {
          if (Object.keys(obj).length === 1) {
            return obj[0]?.length;
          }
          return true;
        });
        console.log(formattedJsonData);
        this.isFileParsingError = false;
        this.setStepValidation(0, true);
        this.jsonData = formattedJsonData || [];
        this.setTableColumns(this.jsonData);
        this.isLoading = false;
      })
      .catch(() => {
        this.isFileParsingError = true;
        this.setStepValidation(0, false);
        this.isLoading = false;
      });

    this.jsonData = [];
    this.setTableColumns(this.jsonData);
  }

  onFileDeleteClick(): void {
    this.jsonData = [];
    this.columns = [];
    this.isFileParsingError = false;
    this.setStepValidation(0, false);
  }

  setTableColumns(jsonData: any[]): void {
    this.columnNames = GetAllColumnsFromCsvData(jsonData);

    this.columns = this.columnNames.map(c => {
      return {
        key: c,
        title: CamelCaseToWords(c)
      };
    });
  }

  activateStep(index: number): void {
    const step = this.steps[index];
    if (!step) {
      return;
    }
    this.steps.forEach(s => s.isActive = false);
    step.isActive = true;
  }

  setStepValidation(index: number, isValid: boolean): void {
    const step = this.steps[index];
    if (!step) {
      return;
    }
    step.isValid = isValid;
  }

  onFileReadingStarted(): void {
    this.isLoading = true;
  }

  goToStep(stepIndex: number): void {
    this.isLoading = true;
    this.cd.detectChanges();
    setTimeout(() => {
      this.activateStep(stepIndex);
      this.isLoading = false;
      this.cd.detectChanges();
    }, 100);
  }

  onStepRendered(): void {
    this.isStepContentRendered = true;
    this.isLoading = false;
    this.cd.detectChanges();
  }

  goToUploadStep(): void {
    this.prepareDataForImport();
    this.goToStep(3);
  }

  prepareDataForImport(): void {
    if (!this.jsonData || !Object.keys(this.mappedFields)?.length) {
      this.dataToUpload = [];
      this.dataToUploadColumns = [];
      this.goToStep(3);
      return;
    }
    const res = this.jsonData.map(row => {
      const rpbObj: any = {};
      Object.keys(this.mappedFields).forEach(sourceFieldName => {
        const destinationField: MapField = this.mappedFields[sourceFieldName];
        const rpbObjProperty = destinationField.name;
        if (destinationField.customSetter) {
          destinationField.customSetter(rpbObj, row[sourceFieldName]);
        } else {
          rpbObj[rpbObjProperty] = row[sourceFieldName];
        }
      });
      return rpbObj;
    });

    this.dataToUpload = res;
    if (this.dataToUpload?.length) {
      const columnNames = Object.keys(this.dataToUpload[0]);
      this.dataToUploadColumns = columnNames.map(c => {
        return {
          key: c,
          title: CamelCaseToWords(c)
        };
      });
    }
  }

  goToUploadFileStep(): void {
    this.clearData();
    this.goToStep(0);
  }

  goBackToStep(stepIndex: number): void {
    this.activateStep(stepIndex);
  }

  onMappedFieldsChange(mappedFields: Record<string, MapField>): void {
    this.mappedFields = mappedFields;
  }

  onMappedFieldsValidationChange(isValid: boolean): void  {
     this.setStepValidation(2, isValid);
  }

  close(): void {
    const modalRef = this.modalService.open(CommonDialogComponent);
    modalRef.componentInstance.closeButtonText = 'Go back';
    modalRef.componentInstance.actionButtonText = 'Close';
    modalRef.componentInstance.headerText = 'Close Import modal';
    modalRef.componentInstance.bodyText = 'Are you sure you want to close modal? Data will not be saved';

    modalRef.result.then(() => {
      this.activeModal.dismiss();
    });
  }

  onMapStepLoadingChanged(isLoading: boolean): void {
    this.isLoading = isLoading;
    this.cd.detectChanges();
  }

  importClicked(): void {
     this.dataToUploadIsReady.emit(this.dataToUpload);
  }
}
