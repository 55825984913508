import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-current-user-password',
  templateUrl: './current-user-password.component.html',
  styleUrls: ['./current-user-password.component.scss']
})
export class CurrentUserPasswordComponent implements OnInit {

  passwordForm: FormGroup;
  signedInOthers = false;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) {
    this.passwordForm = this.formBuilder.group({
      oldpassword: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmpassword: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
  }

  change(): void {
    const formValue = this.passwordForm.value;
    this.activeModal.close({ oldPassword: formValue.oldpassword, password: formValue.password });
  }

  close(): void {
    this.activeModal.dismiss();
  }

  // convenience getter for easy access to form fields
  get f(): {[key: string]: AbstractControl} { return this.passwordForm.controls; }
}
