<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-hidden="true" (click)="close()">&times;</button>
</div>

<div class="modal-body">
  <app-step [step]="steps[0]">
    <div content *ngIf="steps[0].isActive">
      <p class="mb-1">Select the platform you would like to make an import from</p>
      <select class="form-control mb-3" [(ngModel)]="platform" (ngModelChange)="platformChanged()">
        <option *ngFor="let platform of platforms" [value]="platform">{{platform}}</option>
      </select>

      <app-file-input (fileUploaded)="onFileUpload($event)" (deleteClicked)="onFileDeleteClick()" (loadingStarted)="onFileReadingStarted()"></app-file-input>
      <p *ngIf="isFileParsingError" class="error-message text-danger mb-0 mt-1">File parsing error. Please, try again</p>
    </div>
    <div class="step-confirmation" footer>
      <button type="button" class="btn btn-primary" (click)="goToStep(1)" [disabled]="!steps[0].isValid || isLoading">
        <div *ngIf="isLoading && isSmallSpinnerType" class="spinner-border text-light spinner-border-sm" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        Continue
      </button>
    </div>
  </app-step>
  <app-step [step]="steps[1]">
    <div content *ngIf="steps[1].isActive">
      <div *ngIf="columns?.length && jsonData?.length; else noImportedData" class="table-sticky-header-container">
        <table class="custom-table">
          <thead>
            <tr>
              <th *ngFor="let c of columns">{{c.title}}</th>
            </tr>
          </thead>
          <tr *ngFor="let row of jsonData; let lastRow = last">
            <td *ngFor="let c of columns">
              {{row[c.key] || ''}}
            </td>
            <ng-container *ngIf="lastRow && !isStepContentRendered">
              {{ onStepRendered() }}
            </ng-container>
          </tr>
        </table>
      </div>
    </div>
    <div footer class="step-confirmation preview-confirmation">
      <div class="btns">
        <button type="button" class="btn btn-default" (click)="goToUploadFileStep()">Something is wrong, upload a file again</button>
        <button type="button" class="btn btn-primary" (click)="goToStep(2)" [disabled]="isLoading">
          <div *ngIf="isLoading && isSmallSpinnerType" class="spinner-border text-light spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          Looks good, proceed
        </button>
      </div>
    </div>
  </app-step>
  <app-step [step]="steps[2]" [isLast]="false">
    <div content *ngIf="steps[2].isActive">
      <p class="mb-1">For each field, select corresponding column. Only matched columns will be imported</p>
      <app-map-columns
        [columnNames]="columnNames"
        [jsonData]="jsonData"
        [destinationFields]="destinationFields"
        [platformFieldsMap]="platformFieldsMap"
        (mappedFieldsChanged)="onMappedFieldsChange($event)"
        (validationChanged)="onMappedFieldsValidationChange($event)"
        (loadingChanged)="onMapStepLoadingChanged($event)">
      </app-map-columns>
    </div>
    <div footer class="step-confirmation preview-confirmation">
      <div class="btns">
        <button type="button" class="btn btn-default" (click)="goBackToStep(1)">Go back</button>
        <button type="button" class="btn btn-primary" (click)="goToUploadStep()" [disabled]="!steps[2].isValid">
          <div *ngIf="isLoading && isSmallSpinnerType" class="spinner-border text-light spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          Looks good, proceed
        </button>
      </div>
    </div>
  </app-step>
  <app-step [step]="steps[3]" [isLast]="true">
    <div content *ngIf="steps[3].isActive">
      <p class="mb-1">Those records will be imported</p>
      <div *ngIf="dataToUpload?.length; else noUploadData" class="table-sticky-header-container">
        <table class="custom-table">
          <thead>
          <tr>
            <th *ngFor="let c of dataToUploadColumns">{{c.title}}</th>
          </tr>
          </thead>
          <tr *ngFor="let row of dataToUpload; let lastRow = last">
            <td *ngFor="let c of dataToUploadColumns">
              <ng-container *ngIf="row[c.key] as value">
                <ng-container *ngIf="!!value['map']; else stringValue">
                  <ng-container *ngFor="let arrayValue of value; let arrayValueIndex = index">
                    <ng-container *ngIf="arrayValue.hasOwnProperty('address') || arrayValue.hasOwnProperty('number'); else parsedValue">{{arrayValue['address']}} {{arrayValue['number']}}</ng-container>
                    <ng-template #parsedValue>{{arrayValue | json}}</ng-template>
                    <ng-container *ngIf="value?.length > 1 && arrayValueIndex !== value?.length && arrayValue?.length"> | </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-template #stringValue>{{row[c.key] || ''}}</ng-template>
            </td>
            <ng-container *ngIf="lastRow && !isStepContentRendered">
              {{ onStepRendered() }}
            </ng-container>
          </tr>
        </table>
      </div>
    </div>
    <div footer class="step-confirmation preview-confirmation">
      <div class="btns">
        <button type="button" class="btn btn-default" (click)="goBackToStep(2)">Go back</button>
        <button type="button" class="btn btn-primary" (click)="importClicked()" [disabled]="!dataToUpload?.length">
          <div *ngIf="isLoading && isSmallSpinnerType" class="spinner-border text-light spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          Import
        </button>
      </div>
    </div>
  </app-step>

  <div class="spinner" *ngIf="isLoading && !isSmallSpinnerType">
    <app-spinner></app-spinner>
  </div>
</div>

<ng-template #noImportedData>
  <app-no-data [text]="'The file does not contain valid records. Please upload a new file.'"></app-no-data>
</ng-template>

<ng-template #noUploadData>
  <app-no-data [text]="'There are no items to import. Please move to the previous step and map the needed columns'"></app-no-data>
</ng-template>

