import {Component, ElementRef, Input, OnChanges} from '@angular/core';
import {BulkCreateStatusModel} from '../models/bulk-create.model';

@Component({
  selector: 'app-loading-notification',
  templateUrl: './loading-notification.component.html',
  styleUrls: ['./loading-notification.component.scss']
})
export class LoadingNotificationComponent implements OnChanges {
  @Input() text = '';
  @Input() bulkCreate?: BulkCreateStatusModel;

  isCompleted = false;

  constructor(private host: ElementRef<HTMLElement>) {}

  ngOnChanges(): void {
    if (this.bulkCreate?.percentage === 100) {
      this.isCompleted = true;
      this.text = this.bulkCreate.isError ?
        'Completed with errors in some parts of the file. Not all records are imported. Please, reload the page and check new items' :
        'Completed successfully!';

      if (!this.bulkCreate.isError ) {
        setTimeout(() => {
          this.destroyItself();
        }, 3000);
      }
    }
  }

  onCloseClicked(): void {
    this.destroyItself();
  }

  destroyItself(): void {
    this.host.nativeElement.remove();
  }
}
