<div class="modal-header" appDraggable=".modal-dialog">
    <h4 class="modal-title">Note</h4>
    <button type="button" class="close" (click)="close()" aria-hidden="true">&times;</button>
</div>
<div class="modal-body">
    <form class="form-horizontal">
        <div class="form-group">
            <div class="col-sm-12">
                <textarea class="form-control" name="note" [(ngModel)]="note" rows="5"></textarea>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button class="btn btn-primary" [ngClass]="{'mr-auto': isNew}" type="button" (click)="save()">Save</button>
    <button class="btn btn-danger mr-auto" type="button" (click)="delete()" [hidden]="isNew">Delete</button>
    <button class="btn btn-default" type="button" (click)="close()">Cancel</button>
</div> 