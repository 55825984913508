<div class="user-selector">
  <div class="filter">
    <label>Select User</label>
    <input
      class="form-control"
      [(ngModel)]="search"
      (input)="onSearchChange()"
      autocomplete="off"
      placeholder="Search by user name, email, or account"
    />
  </div>
</div>

<hr>

<div class="summary">
  <div class="summary_item">
    <span class="summary_number">23</span>
    <span>Total number of logins: </span>
  </div>
  <div class="summary_item">
    <span class="summary_number">22</span>
    <span>Total number of logouts: </span>
  </div>
  <div class="summary_item">
    <span class="summary_number">12.03.2024 1:30 PM</span>
    <span>Last login datetime: </span>
  </div>
  <div class="summary_item">
    <span class="summary_number"> --- </span>
    <span>Last logout datetime: </span>
  </div>
</div>
