<div *ngFor="let category of categories" class="nested-checklist">
  <div class="nested-checklist_row">
    <label class="nested-checklist_label" for="checkbox_{{category.name}}">
      <input type="checkbox" id="checkbox_{{category.name}}" [(ngModel)]="category.checked" (ngModelChange)="onCategoryCheckboxChange(category)" />
      <span class="name">{{ category.name }}</span>
    </label>
  </div>
  <ul>
    <li class="nested-checklist_row" *ngFor="let item of category.items">
      <label class="nested-checklist_label" for="checkbox_{{item.name}}">
        <input type="checkbox" id="checkbox_{{item.name}}" [(ngModel)]="item.checked" (ngModelChange)="onItemCheckboxChange(category, item.checked)" />
        <span class="name">{{ item.name }}</span>
      </label>
    </li>
  </ul>
</div>
