<app-header></app-header>
<app-toolbar></app-toolbar>

<nav class="navbar navbar-default navbar-fixed-top navbar-global navbar-expand-md w-100 bg-white" role="navigation">
  <div class="container">
    <a class="navbar-brand text-dark" href="javascript:void"><i class="fas fa-list"></i>&nbsp;&nbsp;<b>Search Strings</b></a>

    <div class="ml-auto" *ngIf="selected$ | async as selected">
      <button type="button" class="btn btn-secondary mr-3" (click)="add()" placement="bottom" ngbTooltip="Create Search String"><i class="fas fa-plus"></i></button>
      <button type="button" class="btn btn-danger-inversed" (click)="delete()" [disabled]="!selected.length" placement="bottom" ngbTooltip="Delete String(s)"><i class="fas fa-trash"></i></button>
    </div>
  </div>
</nav>

<br />

<div class="container-fluid main-container" *ngIf="{ strings: strings$ | async, pagination: pagination$ | async } as data" [appReorder]="true">
  <ngx-table
    [data]="data.strings || []"
    [configuration]="configuration"
    [columns]="columns"
    [pagination]="data.pagination || { limit: 100, offset: 0, count: (data.strings || []).length }"
    (event)="eventEmitted($event)"></ngx-table>
</div>

<app-footer></app-footer>

<ng-template #columnActionTemplate>
  <button class="text-nowrap" (click)="hideColumn($event)">Hide Column</button>
</ng-template>

<ng-template #nameTpl let-row>
  <span>{{row.name}}</span>
</ng-template>

<ng-template #checkboxTpl let-row let-column="column">
  <div class="form-group">
    <label class="form-checkbox">
      <input type="checkbox" (change)="onChange(row, $event.target)" />
    </label>
  </div>
</ng-template>