import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  Store,
  State,
  StateContext,
} from '@ngxs/store';

import { forkJoin } from 'rxjs';

import * as _ from 'lodash';

import { UsersActions } from './users.actions';
import { User, UserType, ExperienceType } from '../../shared/models/user.model';
import { UsersService } from '../../shared/users.service';
import { PhoneType } from 'src/app/shared/models/phone.model';
import { Pagination } from 'src/app/shared/models/pagination.model';

import { SessionStateService } from '../../shared/session-state.service';
import {AlertService} from '../../shared/alert.service';

export interface UsersStateModel {
  accountId: string;
  users: User[];
  currentUsers: {[key: string]: User};
  selected: string[];
  pagination: Pagination;
}

const defaultState: UsersStateModel = {
  accountId: '',
  users: [],
  currentUsers: {},
  selected: [],
  pagination: {
    search: '',
    limit: 100,
    offset: 0,
    count: 0,
    sort: '',
    order: '',
  }
};

@State<UsersStateModel>({
  name: 'users',
  defaults: { ...defaultState }
})

@Injectable()
export class UsersState {

  @Selector()
  static users(state: UsersStateModel): User[] {
    return state.users;
  }

  @Selector()
  static pagination(state: UsersStateModel): Pagination {
    return state.pagination;
  }

  @Selector()
  static currentUsers(state: UsersStateModel): {[key: string]: User} {
    return state.currentUsers;
  }

  @Selector()
  static selected(state: UsersStateModel): string[] {
    return state.selected;
  }

  constructor(
    private store: Store,
    private usersService: UsersService,
    private sessionStateService: SessionStateService,
    private alertService: AlertService,
  ) {
  }

  @Action(UsersActions.Load)
  load(context: StateContext<UsersStateModel>, { payload: { accountId } }: UsersActions.Load): void {
    const state = context.getState();
    const pagination = state.pagination;

    if (!!accountId) {
      this.usersService.listByAccount(
          accountId, pagination.offset, pagination.limit, pagination.sort, pagination.order).subscribe(users => {
        context.patchState({
          accountId,
          users,
          pagination: {
            ...pagination,
            count: users.length
          }
        });
      });
    } else {
      this.usersService.list(pagination.offset, pagination.limit, pagination.sort, pagination.order).subscribe(users => {
        context.patchState({
          accountId,
          users,
          pagination: {
            ...pagination,
            count: users.length
          }
        });
      });
    }
  }

  @Action(UsersActions.SetOrder)
  setOrder(context: StateContext<UsersStateModel>,
           { payload: { sort, order } }: UsersActions.SetOrder): void {
    const state = context.getState();

    const pagination = {
      ...state.pagination,
      sort,
      order
    };

    context.patchState({
      pagination
    });

    context.dispatch(new UsersActions.Load({ accountId: state.accountId }));
  }

  @Action(UsersActions.SetPagination)
  setPagination(context: StateContext<UsersStateModel>,
                { payload: { limit, page } }: UsersActions.SetPagination): void {
    const state = context.getState();

    const pagination = {
      ...state.pagination,
      limit,
      page
    };

    context.patchState({
      pagination
    });

    context.dispatch(new UsersActions.Load({ accountId: state.accountId }));
  }

  @Action(UsersActions.Search)
  search(context: StateContext<UsersStateModel>,
         { payload: { user, accountId } }: UsersActions.Search): void {
    const state = context.getState();

    const pagination = {
      ...state.pagination,
      search: user || ''
    };

    context.patchState({
      pagination
    });

    context.dispatch(new UsersActions.Load({ accountId: accountId || state.accountId }));
  }

  @Action(UsersActions.UpdateCurrent)
  updateCurrent(context: StateContext<UsersStateModel>,
                { payload: { uuid, user } }: UsersActions.UpdateCurrent): void {
    const state = context.getState();
    const currentUsers = {
      ...state.currentUsers
    };

    currentUsers[uuid] = {
      ...currentUsers[uuid],
      ...user
    };

    context.patchState({
      currentUsers
    });
  }

  @Action(UsersActions.SwitchAdminRole)
  switchAdminRole(context: StateContext<UsersStateModel>,
                  { payload: { uuid, turn } }: UsersActions.SwitchAdminRole): void {
    const state = context.getState();
    const currentUsers = {
      ...state.currentUsers
    };
    const user = currentUsers[uuid];

    if (!user) {
      return;
    }

    const roles = user.roles.slice();

    if (!roles) {
      return;
    }

    const index = roles.indexOf('account-admin');

    if (turn) {
      if (index === -1) {
        roles.push('account-admin');
      }
    } else {
      if (index > -1) {
        roles.splice(index, 1);
      }
    }

    currentUsers[uuid] = {
      ...currentUsers[uuid],
      ...user,
      roles
    };

    context.patchState({
      currentUsers
    });
  }

  @Action(UsersActions.SwitchUserGuideManagerRole)
  switchUserGuideManagerRole(context: StateContext<UsersStateModel>,
                             { payload: { uuid, turn } }: UsersActions.SwitchUserGuideManagerRole): void {
    const state = context.getState();
    const currentUsers = {
      ...state.currentUsers
    };
    const user = currentUsers[uuid];

    if (!user) {
      return;
    }

    const roles = user.roles.slice();

    if (!roles) {
      return;
    }

    const index = roles.indexOf('user-guide-manager');

    if (turn) {
      if (index === -1) {
        roles.push('user-guide-manager');
      }
    } else {
      if (index > -1) {
        roles.splice(index, 1);
      }
    }

    currentUsers[uuid] = {
      ...currentUsers[uuid],
      ...user,
      roles
    };

    context.patchState({
      currentUsers
    });
  }

  @Action(UsersActions.AddPhone)
  addPhone(context: StateContext<UsersStateModel>,
           { payload: { uuid } }: UsersActions.AddPhone): void {
    const state = context.getState();
    const currentUsers = {
      ...state.currentUsers
    };
    const user = currentUsers[uuid];

    if (!user) {
      return;
    }

    const phoneNumbers = (user.phoneNumbers || []).slice();
    phoneNumbers.push({ type: PhoneType.work, number: '' });

    user.phoneNumbers = phoneNumbers;
    currentUsers[uuid] = user;

    context.patchState({
      currentUsers
    });
  }

  @Action(UsersActions.SetPhoneType)
  setPhoneType(context: StateContext<UsersStateModel>,
               { payload: { uuid, index, type } }: UsersActions.SetPhoneType): void {
    const state = context.getState();
    const currentUsers = {
      ...state.currentUsers
    };
    const user = currentUsers[uuid];

    if (!user) {
      return;
    }

    const phoneNumbers = (user.phoneNumbers || []).slice();
    phoneNumbers[index].type = type;

    user.phoneNumbers = phoneNumbers;
    currentUsers[uuid] = user;

    context.patchState({
      currentUsers
    });
  }

  @Action(UsersActions.SetPhoneNumber)
  setPhoneNumber(context: StateContext<UsersStateModel>,
                 { payload: { uuid, index, phoneNumber } }: UsersActions.SetPhoneNumber): void {
    const state = context.getState();
    const currentUsers = {
      ...state.currentUsers
    };
    const user = currentUsers[uuid];

    if (!user) {
      return;
    }

    const phoneNumbers = (user.phoneNumbers || []).slice();
    phoneNumbers[index].number = phoneNumber;

    user.phoneNumbers = phoneNumbers;
    currentUsers[uuid] = user;

    context.patchState({
      currentUsers
    });
  }

  @Action(UsersActions.RemovePhone)
  removePhone(context: StateContext<UsersStateModel>,
              { payload: { uuid, index } }: UsersActions.RemovePhone): void {
    const state = context.getState();
    const currentUsers = {
      ...state.currentUsers
    };
    const user = currentUsers[uuid];

    if (!user) {
      return;
    }

    const phoneNumbers = (user.phoneNumbers || []).slice();
    phoneNumbers.splice(index, 1);

    user.phoneNumbers = phoneNumbers;
    currentUsers[uuid] = user;

    context.patchState({
      currentUsers
    });
  }

  @Action(UsersActions.AddAddress)
  addAddress(context: StateContext<UsersStateModel>,
             { payload: { uuid, address } }: UsersActions.AddAddress): void {
    const state = context.getState();
    const currentUsers = {
      ...state.currentUsers
    };
    const user = currentUsers[uuid];

    if (!user) {
      return;
    }

    const addresses = (user.addresses || []).slice();
    addresses.push(address);

    user.addresses = addresses;
    currentUsers[uuid] = user;

    context.patchState({
      currentUsers
    });
  }

  @Action(UsersActions.UpdateAddress)
  updateAddress(context: StateContext<UsersStateModel>,
                { payload: { uuid, address, index } }: UsersActions.UpdateAddress): void {
    const state = context.getState();
    const currentUsers = {
      ...state.currentUsers
    };
    const user = currentUsers[uuid];

    if (!user) {
      return;
    }

    const addresses = (user.addresses || []).slice();
    addresses.splice(index, 1, address);

    user.addresses = addresses;
    currentUsers[uuid] = user;

    context.patchState({
      currentUsers
    });
  }

  @Action(UsersActions.RemoveAddress)
  removeAddress(context: StateContext<UsersStateModel>,
                { payload: { uuid, index } }: UsersActions.RemoveAddress): void {
    const state = context.getState();
    const currentUsers = {
      ...state.currentUsers
    };
    const user = currentUsers[uuid];

    if (!user) {
      return;
    }

    const addresses = (user.addresses || []).slice();
    addresses.splice(index, 1);

    user.addresses = addresses;
    currentUsers[uuid] = user;

    context.patchState({
      currentUsers
    });
  }

  @Action(UsersActions.ChangePassword)
  changePassword(context: StateContext<UsersStateModel>, { payload: { uuid, oldPassword, password } }: UsersActions.ChangePassword): void {
    const state = context.getState();
    const currentUsers = {
      ...state.currentUsers
    };
    const user = currentUsers[uuid];
    if (!user) {
      return;
    }

    if (!!user.uuid) {
      const session = this.sessionStateService.get();
      const userId = (session.user as User).uuid;
      if (user.uuid === userId) {
        if (!!oldPassword) {
          this.usersService.updateCurrentUserPassword(oldPassword, password).subscribe(() => {this.onPasswordChangeSuccess(); });
        }
      } else {
        this.usersService.updatePassword(user.uuid, password).subscribe(() => {this.onPasswordChangeSuccess(); });
      }
    }
  }

  onPasswordChangeSuccess = () => {
    this.alertService.success('Password is successfully changed');
  }

  @Action(UsersActions.Update)
  update(context: StateContext<UsersStateModel>, { payload: { uuid } }: UsersActions.Update): void {
    const state = context.getState();
    const currentUsers = {
      ...state.currentUsers
    };
    const user = currentUsers[uuid];

    if (!user) {
      return;
    }

    if (!user.phoneNumbers) {
      user.phoneNumbers = [];
    }

    if (!user.addresses) {
      user.addresses = [];
    }

    if (!!user.uuid) {
      const updatedUser = _.omit(user, 'password');

      this.usersService.update(updatedUser).subscribe(() => {
        this.store.dispatch(new UsersActions.NeedsReload());
      });
    } else {
      this.usersService.insert(user).subscribe(() => {
        setTimeout(() => {
          this.store.dispatch(new UsersActions.SendWelcomeEmail({user}));
          this.store.dispatch(new UsersActions.NeedsReload());
        }, 2000);

      });
    }
  }

  @Action(UsersActions.Delete)
  delete(context: StateContext<UsersStateModel>, { payload: { uuid } }: UsersActions.Delete): void {
    if (uuid) {
      this.usersService.delete(uuid).subscribe(() => {
        this.select(context, { payload: { uuid, select: false } });

        this.store.dispatch(new UsersActions.NeedsReload());
      });
    } else {
      const selectedUuids = context.getState().selected;

      forkJoin(selectedUuids.map(this.usersService.delete.bind(this.usersService))).subscribe(() => {
        selectedUuids.forEach(selectedUuid => this.select(context, { payload: { uuid: selectedUuid, select: false } }) );

        this.store.dispatch(new UsersActions.NeedsReload());
      });
    }
  }

  @Action(UsersActions.Select)
  select(context: StateContext<UsersStateModel>, { payload: { uuid, select } }: UsersActions.Select): void {
    const state = context.getState();
    const selected = state.selected.slice();
    const index = selected.indexOf(uuid);

    if (select) {
      if (index === -1) {
        context.patchState({
          selected: selected.concat([uuid])
        });
      }
    } else {
      if (index > -1) {
        selected.splice(index, 1);

        context.patchState({
          selected
        });
      }
    }
  }

  @Action(UsersActions.UnselectAll)
  unselectAll(context: StateContext<UsersStateModel>): void {
    context.patchState({
      selected: []
    });
  }

  @Action(UsersActions.ShowWithUser)
  setCurrentWithUser(context: StateContext<UsersStateModel>, { payload: { uuid, user } }: UsersActions.ShowWithUser): void {
    const state = context.getState();
    const currentUsers = {
      ...state.currentUsers
    };

    if (!!user) {
      currentUsers[uuid] = user;

      context.patchState({
        currentUsers
      });
    }
  }

  @Action(UsersActions.Show)
  show(context: StateContext<UsersStateModel>,
       { payload: { uuid } }: UsersActions.Show): void {
    const state = context.getState();
    const currentUsers = {
      ...state.currentUsers
    };

    const usr = state.users.find(user => user.uuid === uuid);

    if (usr) {
      currentUsers[uuid] = usr;
    }

    context.patchState({
      currentUsers
    });
  }

  @Action(UsersActions.Finished)
  finished(context: StateContext<UsersStateModel>,
           { payload: { uuid } }: UsersActions.Finished): void {
    const state = context.getState();
    const currentUsers = {
      ...state.currentUsers
    };

    delete currentUsers[uuid];

    context.patchState({
      currentUsers
    });
  }

  @Action(UsersActions.SendWelcomeEmail)
  sendWelcomeEmail(context: StateContext<UsersStateModel>,
                   { payload: {user} }: UsersActions.SendWelcomeEmail): void {
    this.usersService.sendWelcomeEmail(user.username, user.password).subscribe(() => {
      this.alertService.success('The welcome email is sent to the user');
    });
  }
}
