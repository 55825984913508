import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AlertService } from '../shared/alert.service';
import { UsersService } from '../shared/users.service';
import { SessionStateService } from '../shared/session-state.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private defaultSession = {
    loggedIn: false,
    username: 'guest',
    roles: ['guest']
  };

  constructor(
    private router: Router,
    private usersService: UsersService,
    private alertService: AlertService,
    private sessionStateService: SessionStateService) { }

  loginStatus(): void {
    this.usersService.loginStatus().subscribe((sessionData) => {
      if (sessionData.status.loggedIn) {
        this.usersService.currentUser().subscribe((user) => {
          const session = {
            session: sessionData.status,
            version: sessionData.version,
            user
          };

          this.sessionStateService.set(session);
          this.router.navigate(['/dashboard']);
        });
      } else {
        this.sessionStateService.clear();
        this.router.navigate(['/']);
      }
    }, () => {
      this.sessionStateService.clear();
      this.router.navigate(['/']);
    });
  }

  login(username: string, password: string): Observable<any> {
    return this.usersService.login(username, password).pipe(tap(sessionData => {
      const session = {
        session: sessionData.session,
        version: sessionData.version,
        user: sessionData.user
      };

      this.alertService.clear();
      this.sessionStateService.set(session);

      return of(sessionData);
    }, () => { }), catchError((error: HttpErrorResponse): Observable<any> => {
      if (error.status === 401) {
        const message = error.error.message;
        this.alertService.error(message ? message : 'Invalid username or password');
        return of(false);
      } else {
        return throwError(error.error);
      }
    }));
  }

  logoutOther(username: string, password: string): Observable<any> {
    return this.usersService.logoutOther({username, password});
  }
}
