import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  Store,
  State,
  StateContext,
} from '@ngxs/store';

import {forkJoin, Observable, of, throwError} from 'rxjs';
import {catchError, delay, finalize, first, map, mergeMap} from 'rxjs/operators';

import * as _ from 'lodash';

import { ContentService } from '../../shared/content.service';
import { User } from '../../shared/models/user.model';

import { CandidatesActions } from './candidates.actions';
import { Candidate } from './candidate.model';
import { CandidatesService } from './candidates.service';
import { UsersService } from '../../shared/users.service';
import { EmailType } from 'src/app/shared/models/email.model';
import { PhoneType } from 'src/app/shared/models/phone.model';
import { Pagination } from 'src/app/shared/models/pagination.model';

import { PlannerService } from '../../dailyplanner/planner.service';
import { AlertService } from 'src/app/shared/alert.service';
import { NotesService } from 'src/app/shared/widgets/notes/notes.service';
import { BulkCreateStatusModel } from '../../shared/models/bulk-create.model';
import {DEFAULT_CHUNK_SIZE} from '../../shared/consts/default-chunk-size.const';

export interface CandidateStateModel {
  candidates: Candidate[];
  currentCandidates: {[key: string]: Candidate};
  selected: string[];
  pagination: Pagination;
  bulkCreate: BulkCreateStatusModel;
}
const defaultState: CandidateStateModel = {
  candidates: [],
  currentCandidates: {},
  selected: [],
  pagination: {
    search: '',
    limit: 100,
    offset: 0,
    count: 0,
    sort: '',
    order: '',
  },
  bulkCreate: {
    percentage: 0,
    isError: false,
    errorMessage: undefined,
    areDuplicatesAvoided: false
  },
};

@State<CandidateStateModel>({
  name: 'candidates',
  defaults: { ...defaultState }
})

@Injectable()
export class CandidatesState {

  @Selector()
  static candidates(state: CandidateStateModel): Candidate[] {
    return state.candidates;
  }

  @Selector()
  static pagination(state: CandidateStateModel): Pagination {
    return state.pagination;
  }

  @Selector()
  static currentCandidates(state: CandidateStateModel): {[key: string]: Candidate} {
    return state.currentCandidates;
  }

  @Selector()
  static selected(state: CandidateStateModel): string[] {
    return state.selected;
  }

  @Selector()
  static selectedFullObjects(state: CandidateStateModel): Candidate[] {
    const res = state.candidates.filter(c => {
      return state.selected.find(uuid => c.uuid === uuid);
    });
    return res || [];
  }

  @Selector()
  static bulkCreate(state: CandidateStateModel): BulkCreateStatusModel {
    return state.bulkCreate;
  }

  constructor(
    private store: Store,
    private candidatesService: CandidatesService,
    private usersService: UsersService,
    private contentService: ContentService,
    private plannerService: PlannerService,
    private notesService: NotesService,
    private alertService: AlertService
  ) { }

  @Action(CandidatesActions.Load)
  load(context: StateContext<CandidateStateModel>): void {
    const state = context.getState();
    const pagination = state.pagination;

    this.usersService.currentUser().subscribe(user => {
      if (user.roles.indexOf('account-admin') > -1) {
        this.candidatesService.listByAccount(
            user.account_uuid, pagination.search, pagination.offset, pagination.limit,
            pagination.sort, pagination.order).subscribe(candidates => {
          context.patchState({
            candidates,
            pagination: {
              ...pagination,
              count: candidates.length
            },
            selected: [],
          });

          this.store.dispatch(new CandidatesActions.NeedsReload());
        });
      } else {
        this.candidatesService.list(
            user.uuid, pagination.search, pagination.offset, pagination.limit, pagination.sort, pagination.order).subscribe(candidates => {
          context.patchState({
            candidates,
            pagination: {
              ...pagination,
              count: candidates.length,
            },
            selected: [],
          });

          this.store.dispatch(new CandidatesActions.NeedsReload());
        });
      }
    });
  }

  @Action(CandidatesActions.SetOrder)
  setOrder(context: StateContext<CandidateStateModel>,
           { payload: { sort, order } }: CandidatesActions.SetOrder): void {
    const state = context.getState();

    const pagination = {
      ...state.pagination,
      sort,
      order
    };

    context.patchState({
      pagination
    });

    context.dispatch(new CandidatesActions.Load());
  }

  @Action(CandidatesActions.SetPagination)
  setPagination(context: StateContext<CandidateStateModel>,
                { payload: { limit, page } }: CandidatesActions.SetPagination): void {
    const state = context.getState();

    const pagination = {
      ...state.pagination,
      limit,
      page
    };

    context.patchState({
      pagination
    });

    context.dispatch(new CandidatesActions.Load());
  }

  @Action(CandidatesActions.Search)
  search(context: StateContext<CandidateStateModel>,
         { payload: { candidate } }: CandidatesActions.Search): void {
    const state = context.getState();

    const pagination = {
      ...state.pagination,
      search: candidate || ''
    };

    context.patchState({
      pagination,
      selected: [],
    });

    context.dispatch(new CandidatesActions.Load());
  }

  @Action(CandidatesActions.UpdateCurrent)
  updateCurrent(context: StateContext<CandidateStateModel>,
                { payload: { uuid, candidate } }: CandidatesActions.UpdateCurrent): void {
    const state = context.getState();
    const currentCandidates = {
      ...state.currentCandidates
    };

    currentCandidates[uuid] = {
      ...currentCandidates[uuid],
      ...candidate
    };

    context.patchState({
      currentCandidates
    });
  }

  @Action(CandidatesActions.AddEmail)
  addEmail(context: StateContext<CandidateStateModel>,
           { payload: { uuid } }: CandidatesActions.AddEmail): void {
    const state = context.getState();
    const currentCandidates = {
      ...state.currentCandidates
    };
    const candidate = currentCandidates[uuid];

    if (!candidate) {
      return;
    }

    const emails = (candidate.emails || []).slice();
    emails.push({ type: EmailType.work, address: '' });

    candidate.emails = emails;
    currentCandidates[uuid] = candidate;

    context.patchState({
      currentCandidates
    });
  }

  @Action(CandidatesActions.SetEmailType)
  setEmailType(context: StateContext<CandidateStateModel>,
               { payload: { uuid, index, type } }: CandidatesActions.SetEmailType): void {
    const state = context.getState();
    const currentCandidates = {
      ...state.currentCandidates
    };
    const candidate = currentCandidates[uuid];

    if (!candidate) {
      return;
    }

    const emails = (candidate.emails || []).slice();
    emails[index].type = type;

    candidate.emails = emails;
    currentCandidates[uuid] = candidate;

    context.patchState({
      currentCandidates
    });
  }

  @Action(CandidatesActions.SetEmailAddress)
  setEmailAddress(context: StateContext<CandidateStateModel>,
                  { payload: { uuid, index, address } }: CandidatesActions.SetEmailAddress): void {
    const state = context.getState();
    const currentCandidates = {
      ...state.currentCandidates
    };
    const candidate = currentCandidates[uuid];

    if (!candidate) {
      return;
    }

    const emails = (candidate.emails || []).slice();
    emails[index].address = address;

    candidate.emails = emails;
    currentCandidates[uuid] = candidate;

    context.patchState({
      currentCandidates
    });
  }

  @Action(CandidatesActions.RemoveEmail)
  removeEmail(context: StateContext<CandidateStateModel>,
              { payload: { uuid, index } }: CandidatesActions.RemoveEmail): void {
    const state = context.getState();
    const currentCandidates = {
      ...state.currentCandidates
    };
    const candidate = currentCandidates[uuid];

    if (!candidate) {
      return;
    }

    const emails = (candidate.emails || []).slice();
    emails.splice(index, 1);

    candidate.emails = emails;
    currentCandidates[uuid] = candidate;

    context.patchState({
      currentCandidates
    });
  }

  @Action(CandidatesActions.AddPhone)
  addPhone(context: StateContext<CandidateStateModel>,
           { payload: { uuid } }: CandidatesActions.AddPhone): void {
    const state = context.getState();
    const currentCandidates = {
      ...state.currentCandidates
    };
    const candidate = currentCandidates[uuid];

    if (!candidate) {
      return;
    }

    const phoneNumbers = (candidate.phoneNumbers || []).slice();
    phoneNumbers.push({ type: PhoneType.work, number: '' });

    candidate.phoneNumbers = phoneNumbers;
    currentCandidates[uuid] = candidate;

    context.patchState({
      currentCandidates
    });
  }

  @Action(CandidatesActions.SetPhoneType)
  setPhoneType(context: StateContext<CandidateStateModel>,
               { payload: { uuid, index, type } }: CandidatesActions.SetPhoneType): void {
    const state = context.getState();
    const currentCandidates = {
      ...state.currentCandidates
    };
    const candidate = currentCandidates[uuid];

    if (!candidate) {
      return;
    }

    const phoneNumbers = (candidate.phoneNumbers || []).slice();
    phoneNumbers[index].type = type;

    candidate.phoneNumbers = phoneNumbers;
    currentCandidates[uuid] = candidate;

    context.patchState({
      currentCandidates
    });
  }

  @Action(CandidatesActions.SetPhoneNumber)
  setPhoneNumber(context: StateContext<CandidateStateModel>,
                 { payload: { uuid, index, phoneNumber } }: CandidatesActions.SetPhoneNumber): void {
    const state = context.getState();
    const currentCandidates = {
      ...state.currentCandidates
    };
    const candidate = currentCandidates[uuid];

    if (!candidate) {
      return;
    }

    const phoneNumbers = (candidate.phoneNumbers || []).slice();
    phoneNumbers[index].number = phoneNumber;

    candidate.phoneNumbers = phoneNumbers;
    currentCandidates[uuid] = candidate;

    context.patchState({
      currentCandidates
    });
  }

  @Action(CandidatesActions.RemovePhone)
  removePhone(context: StateContext<CandidateStateModel>,
              { payload: { uuid, index } }: CandidatesActions.RemovePhone): void {
    const state = context.getState();
    const currentCandidates = {
      ...state.currentCandidates
    };
    const candidate = currentCandidates[uuid];

    if (!candidate) {
      return;
    }

    const phoneNumbers = (candidate.phoneNumbers || []).slice();
    phoneNumbers.splice(index, 1);

    candidate.phoneNumbers = phoneNumbers;
    currentCandidates[uuid] = candidate;

    context.patchState({
      currentCandidates
    });
  }

  @Action(CandidatesActions.Update)
  update(context: StateContext<CandidateStateModel>,
         { payload: { uuid, initialNotes } }: CandidatesActions.Update, callback?: (data?: any) => void): void {
    const state = context.getState();
    const currentCandidates = {
      ...state.currentCandidates
    };
    const candidate = {
      ...state.candidates.find(cand => cand.uuid === uuid),
      ...currentCandidates[uuid]
    };

    if (!candidate) {
      return;
    }

    this.usersService.currentUser().subscribe(user => {
      if (!candidate.owner) {
        candidate.owner = user.uuid;
      }

      if (!candidate.account) {
        candidate.account = user.account_uuid;
      }

      if (!candidate.emails) {
        candidate.emails = [];
      }

      if (!candidate.phoneNumbers) {
        candidate.phoneNumbers = [];
      }

      if (!candidate.addresses) {
        candidate.addresses = [];
      }

      if (!!candidate.uuid) {
        this.candidatesService.update(candidate).subscribe((res: any) => {
          this.load(context);

          if (callback) {
            callback(res.candidate);
          }
        });
      } else {
        this.candidatesService.insert(candidate).subscribe((res: any) => {
          this.load(context);

          // Initial notes are only saved for new clients
          if (initialNotes) {
            initialNotes.forEach(initialNote => this.notesService.insert(initialNote, res.candidate.uuid).subscribe(() => {}));
          }

          if (callback) {
            callback(res.candidate);
          }
        });
      }
    });
  }

  private createContentPath(owner: string, path: string, accountuuid: string, uuid: string): Observable<any> {
    return this.contentService.lookup(path).pipe(first(), catchError(res => {
      if (res && (res.status === 404)) {
        return this.contentService.userInsert({
          path: '/' + path,
          owner,
          account_uuid: accountuuid,
          user_uuid: uuid,
          title: '',
          type: '',
          uri: '/' + path
        });
      } else {
        return of(res);
      }
    }));
  }

  @Action(CandidatesActions.UploadResume)
  uploadResume(context: StateContext<CandidateStateModel>,
               { payload: { uuid, file, initialNotes } }: CandidatesActions.UploadResume): void {
    const state = context.getState();
    const currentCandidates = {
      ...state.currentCandidates
    };
    const candidate = currentCandidates[uuid];

    if (!candidate) {
      return;
    }

    if (!candidate.uuid) {
      this.update(context, { payload: { uuid, initialNotes } }, (newcandidate) => {
        currentCandidates[uuid] = newcandidate;

        context.patchState({
          currentCandidates
        });

        this.uploadResume_(context, { payload: { uuid, file } });
      });
    } else {
      this.uploadResume_(context, { payload: { uuid, file } });
    }
  }

  uploadResume_(context: StateContext<CandidateStateModel>, { payload: { uuid, file } }: CandidatesActions.UploadResume): void {
    const state = context.getState();
    const currentCandidates = {
      ...state.currentCandidates
    };
    const candidate = currentCandidates[uuid];

    if (!candidate) {
      return;
    }

    this.usersService.currentUser().pipe(first()).subscribe((user: User) => {
      if (!user.uuid) {
        return;
      }

      const owner = user.username;
      const useruuid = candidate.uuid || user.uuid;
      const accountuuid = candidate.account || user.account_uuid;

      this.createContentPath(useruuid, 'users/' + useruuid + '/', accountuuid, useruuid).subscribe(() => {
        this.createContentPath(useruuid, 'users/' + useruuid + '/resumes/', accountuuid, useruuid).subscribe(() => {
          const path = 'users/' + useruuid + '/resumes/'; // owner is guaranteed to be $rootScope.mm.user.uuid

          // @ts-ignore
          const filteredFileName = file.name.replace(/\s+/g, '_');

          this.contentService.lookup(path + filteredFileName)
            .subscribe(res => {
              this.contentService.userUpdate({
                path: '/' + path + filteredFileName,
                owner,
                title: filteredFileName,
                type: file.type,
                uri: '/' + path + filteredFileName
              }).subscribe(content => {
                const formData = new FormData();
                formData.append('path', '/' + path + filteredFileName);
                formData.append('title', filteredFileName);
                formData.append('type', file.type);
                formData.append('file', file);

                this.candidatesService.upload(this.contentService.getUserUploadPath(), formData).subscribe(() => {
                  this.store.dispatch(new CandidatesActions.UpdateCurrent({ uuid, candidate: {
                    resume: content.title
                  }}));

                  this.store.dispatch(new CandidatesActions.Update({ uuid }));
                  this.store.dispatch(new CandidatesActions.Finished({ uuid }));
                });
              });
            }, res => {
              if (res && (res.status === 404)) {
                this.contentService.userInsert({
                  path: '/' + path + filteredFileName,
                  owner,
                  account_uuid: accountuuid,
                  user_uuid: useruuid,
                  title: filteredFileName,
                  type: file.type,
                  uri: '/' + path + filteredFileName
                }).subscribe(content => {
                  const formData = new FormData();
                  formData.append('path', '/' + path + filteredFileName);
                  formData.append('title', filteredFileName);
                  formData.append('type', file.type);
                  formData.append('user_uuid', useruuid);
                  formData.append('file', file);

                  this.candidatesService.upload(this.contentService.getUserUploadPath(), formData).subscribe(() => {
                    this.store.dispatch(new CandidatesActions.UpdateCurrent({ uuid, candidate: {
                      resume: content.title
                    }}));

                    this.store.dispatch(new CandidatesActions.Update({ uuid }));
                    this.store.dispatch(new CandidatesActions.Finished({ uuid }));
                  });
                });
              }
            });
        });
      });
    });
  }

  @Action(CandidatesActions.Delete)
  delete(context: StateContext<CandidateStateModel>,
         { payload: { uuid } }: CandidatesActions.Delete): void {
    if (uuid) {
      this.candidatesService.delete(uuid).subscribe(() => {
        this.load(context);

        this.select(context, { payload: { uuid, select: false } });
      });
    } else {
      const selectedUuids = context.getState().selected;

      forkJoin(context.getState().selected.map(this.candidatesService.delete.bind(this.candidatesService))).subscribe(() => {
        this.load(context);

        selectedUuids.forEach(selectedUuid => this.select(context, { payload: { uuid: selectedUuid, select: false } }) );
      });
    }
  }

  @Action(CandidatesActions.Select)
  select(context: StateContext<CandidateStateModel>, { payload: { uuid, select } }: CandidatesActions.Select): void {
    const state = context.getState();
    const selected = state.selected.slice();
    const index = selected.indexOf(uuid);

    if (select) {
      if (index === -1) {
        context.patchState({
          selected: selected.concat([uuid])
        });
      }
    } else {
      if (index > -1) {
        selected.splice(index, 1);

        context.patchState({
          selected
        });
      }
    }
  }

  @Action(CandidatesActions.SelectAll)
  selectAll(context: StateContext<CandidateStateModel>, { payload: { select } }: CandidatesActions.SelectAll): void {
    const state = context.getState();
    if (select) {
      const uuids = state.candidates.filter(c => c.uuid).map(c => c.uuid);
      context.patchState({
        selected: (uuids || []) as any
      });
    } else {
      context.patchState({
        selected: []
      });
    }
  }


  @Action(CandidatesActions.UnselectAll)
  unselectAll(context: StateContext<CandidateStateModel>): void {
    context.patchState({
      selected: []
    });
  }

  @Action(CandidatesActions.SetCurrentPartial)
  setCurrentPartial(context: StateContext<CandidateStateModel>,
                    { payload: { uuid, candidate } }: CandidatesActions.SetCurrentPartial): void {
    this.usersService.currentUser().subscribe(user => {
      const state = context.getState();
      const currentCandidates = {
        ...state.currentCandidates
      };

      const currentCandidate = {
        ...candidate,
        ...currentCandidates[uuid],
        owner: user.uuid,
        account: user.account_uuid,
        firstName: '',
        lastName: '',
        title: '',
        location: '',
        employer: '',
        active: true,
        private: false,
        skills: '',
        memo: '',
        emails: [{ type: EmailType.work, address: '' }],
        phoneNumbers: [{ type: PhoneType.work, number: '' }],
        addresses: [],
      };

      currentCandidates[uuid] = currentCandidate;

      context.patchState({
        currentCandidates
      });
    });
  }

  @Action(CandidatesActions.AddToPlan)
  addToPlan(context: StateContext<CandidateStateModel>, { payload: { uuid } }: CandidatesActions.AddToPlan): void {
    const state = context.getState();
    const currentCandidates = {
      ...state.currentCandidates
    };
    const candidate = currentCandidates[uuid];

    if (candidate) {
      this.plannerService.get().subscribe((planner) => {
        planner.recruitingCalls.push({
          status: 'none',
          project: '',
          candidate: candidate.firstName + ' ' + candidate.lastName,
          title: candidate.title,
          company: '',
          phone: ((candidate.phoneNumbers && candidate.phoneNumbers.length) ? candidate.phoneNumbers[0].number : ''),
          hiringManager: '',
          source: '',
          result: '',
          notes: ''
        });

        this.plannerService.update( _.pick(planner, ['uuid', 'recruitingCalls'])).subscribe(() => {
          this.alertService.success('Candidate record was added to Daily Planner');
        });
      });
    }
  }

  @Action(CandidatesActions.Show)
  show(context: StateContext<CandidateStateModel>,
       { payload: { uuid } }: CandidatesActions.Show): void {
    const state = context.getState();
    const currentCandidates = {
      ...state.currentCandidates
    };

    const cand = state.candidates.find(candidate => candidate.uuid === uuid);

    if (cand) {
      currentCandidates[uuid] = cand;
    }

    context.patchState({
      currentCandidates
    });
  }

  @Action(CandidatesActions.Finished)
  finished(context: StateContext<CandidateStateModel>,
           { payload: { uuid } }: CandidatesActions.Finished): void {
    const state = context.getState();
    const currentCandidates = {
      ...state.currentCandidates
    };

    delete currentCandidates[uuid];

    context.patchState({
      currentCandidates
    });
  }


  // Import files could contain hundreds or thousands of records. We use bulk creation to provide better performance.
  @Action(CandidatesActions.BulkCreate)
  bulkCreate(context: StateContext<CandidateStateModel>,
             { payload: { candidates } }: CandidatesActions.BulkCreate): void {
    if (!candidates?.length) {
      return;
    }
    const state = context.getState();

    this.usersService.currentUser().subscribe(user => {
      const candidatesToSave: Candidate[] = candidates.map(candidate => {
        if (!candidate.owner) {
          candidate.owner = user.uuid;
        }

        if (!candidate.account) {
          candidate.account = user.account_uuid;
        }

        if (!candidate.emails) {
          candidate.emails = [];
        }

        if (!candidate.phoneNumbers) {
          candidate.phoneNumbers = [];
        }

        if (!candidate.addresses) {
          candidate.addresses = [];
        }

        return candidate;
      });


      context.patchState({
        bulkCreate: {
          percentage: 2,
          isError: false,
          errorMessage: undefined
        }
      });

     // Logic to mock urls for testing
     //  const urls: Observable<any>[] = [
     //    of(1).pipe(delay(1000)),
     //    of(2).pipe(delay(2000)),
     //    throwError({error: {message: 'Test error message'}}).pipe(delay(5000)),
     //    throwError({error: {message: 'Test error message 2'}}).pipe(delay(5000)),
     //    of(4).pipe(delay(7000)),
     //    of(5).pipe(delay(8000)),
     //  ];
      const urls: any[] = [];
      const chunkSize = DEFAULT_CHUNK_SIZE;
      for (let i = 0; i < candidatesToSave.length; i += chunkSize) {
        const chunk = candidatesToSave.slice(i, i + chunkSize);
        urls.push( this.candidatesService.bulkInsert(chunk));
      }

      const numberOfChunks = Math.ceil(candidatesToSave.length / chunkSize) || 1;
      let numberOfCompletedChunks = 0;

      for (let i = 0; i < urls.length; i++) {
        urls[i].pipe(
          finalize(() => {
            numberOfCompletedChunks++;
            const bulkCreatePercentage = Math.ceil(numberOfCompletedChunks * 100 / numberOfChunks);
            const bulkCreateState = context.getState().bulkCreate;
            context.patchState({
              bulkCreate: {
                ...bulkCreateState,
                percentage: bulkCreatePercentage,
              }
            });
            if (numberOfCompletedChunks === numberOfChunks) {
              this.load(context);
              setTimeout(() => {
                context.patchState({
                  bulkCreate: undefined
                });
              }, 3000);
            }
          })
        ).subscribe(
          (res: {candidate: {candidates: Candidate[], areDuplicatesAvoided: boolean}}) => {
            context.patchState({
              bulkCreate: {
                ...state.bulkCreate,
                areDuplicatesAvoided: res?.candidate?.areDuplicatesAvoided || false
              }
            });
          },
          (error: any) => {
            const existingErrorMessage = context.getState().bulkCreate?.errorMessage || '';
            const newErrorMessage =  error.error.message?.length ? error.error.message : '';
            const errorMessageToSet = existingErrorMessage  + newErrorMessage + '<br />';
            context.patchState({
              bulkCreate: {
                ...state.bulkCreate,
                isError: true,
                errorMessage: errorMessageToSet
              }
            });
          }
        );
      }
    });
  }
}
