import { Directive, OnInit, ElementRef, Input } from '@angular/core';

declare var jQuery: any;
declare var $: any;

@Directive({
  selector: '[appSameHeight]'
})
export class SameHeightDirective implements OnInit {

  @Input() appSameHeight: string;

  constructor(private el: ElementRef) { this.appSameHeight = ''; }

  ngOnInit(): void {
    const element = $(this.el.nativeElement);
    const reference = $('#' + this.appSameHeight);

    if (reference && reference.length) {
      // @ts-ignore
      element.css('height', reference.outerHeight() + 'px');
    }
  }
}
