<div class="modal-header" *ngIf="{ client: current$ | async } as data" appDraggable=".modal-dialog">
    <h4 class="modal-title">{{ data.client && data.client.uuid ? 'Edit' : 'New' }} Client</h4>
    <button type="button" class="close" aria-hidden="true" (click)="close()">&times;</button>
</div>
<div class="modal-body" *ngIf="{ isUnique: isUnique$ | async } as data">
    <form [formGroup]="form">

        <div class="form-group row">
            <label class="col-3 control-label text-right">Company:</label>
            <div class="col-9">
                <input type="text" class="form-control" formControlName="company" appAutofocus />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 control-label text-right">First Name:</label>
            <div class="col-9">
                <input type="text" class="form-control" formControlName="firstName" />
                <small [hidden]="data.isUnique" class="form-text text-danger">Client with this first name and/or last name already exists; please change the name.</small>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 control-label text-right">Last Name:</label>
            <div class="col-9">
                <input type="text" class="form-control" formControlName="lastName" />
                <small [hidden]="data.isUnique" class="form-text text-danger">Client with this first name and/or last name already exists; please change the name.</small>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 control-label text-right">Title:</label>
            <div class="col-9">
                <input type="text" class="form-control" formControlName="title" />
            </div>
        </div>

        <app-address-form
          [initialValue]="form.value"
          [isSimulated]="isSimulated"
          (formValueChanged)="onAddressFormValueChange($event)"
          (onAddToPlanClick)="addToPlan()">
        </app-address-form>

        <div class="form-group row">
            <label class="col-3 control-label text-right">Positions:</label>
            <div class="col-9">
                <textarea style="height: 200px" class="col-sm-12 form-control" formControlName="positions"></textarea>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 control-label text-right">Memo:</label>
            <div class="col-9">
                <textarea style="height: 200px" class="col-sm-12 form-control" formControlName="memo"></textarea>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 control-label text-right">Source:</label>
            <div class="col-9">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="clientcoldcall" name="source" formControlName="source" value="Cold Call">
                    <label class="form-check-label" for="clientcoldcall">Cold Call</label>
                </div>
                <div class="form-check form-check-inline ml-3">
                    <input class="form-check-input" type="radio" id="clientexisting" name="source" formControlName="source" value="Existing">
                    <label class="form-check-label" for="clientexisting">Existing</label>
                </div>
                <div class="form-check form-check-inline ml-3">
                    <input class="form-check-input" type="radio" id="clientjobboard" name="source" formControlName="source" value="Job Board">
                    <label class="form-check-label" for="clientjobboard">Job Board</label>
                </div>
                <div class="form-check form-check-inline ml-3">
                    <input class="form-check-input" type="radio" id="clientpost" name="source" formControlName="source" value="Client Post">
                    <label class="form-check-label" for="clientpost">Client Post</label>
                </div>
            </div>
        </div>
    </form>

    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-client-emails,ngb-client-phones" *ngIf="current$ | async as client">
        <ngb-panel id="ngb-client-emails">
            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="m-0">Emails</h5>
                    <div>
                        <button ngbPanelToggle class="btn btn-outline-primary">
                            <i [hidden]="opened" class="fas fa-plus"></i>
                            <i [hidden]="!opened" class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <ul class="list-group p-0 col-12">
                    <li class="list-group-item" *ngFor="let email of client.emails; index as i">
                        <div class="row">
                            <div class="col-sm-4">
                                <select class="form-control" [value]="email.type" (change)="setEmailType(i, $event.target)">
                                    <option value="work">Work</option>
                                    <option value="home">Home</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            <div class="col-sm-6 p-0">
                                <input class="form-control" type="email" [value]="email.address" (input)="setEmailAddress(i, $event.target)" required />
                            </div>
                            <div class="col-sm-2">
                                <div class="delete-icon float-right" [hidden]="isSimulated" (click)="removeEmail(i)"><i class="fas fa-times-circle"></i></div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item"><input class="btn btn-primary" type="button" value="New"  [disabled]="isSimulated" (click)="addEmail()" appFocusNewInput /></li>
                </ul>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="ngb-client-phones">
            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="m-0">Phone Numbers</h5>
                    <div>
                        <button ngbPanelToggle class="btn btn-outline-primary">
                            <i [hidden]="opened" class="fas fa-plus"></i>
                            <i [hidden]="!opened" class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <ul class="list-group p-0 col-12">
                    <li class="list-group-item" *ngFor="let phone of client.phoneNumbers; index as i">
                        <div class="row">
                            <div class="col-sm-4">
                                <select class="form-control" [value]="phone.type" (change)="setPhoneType(i, $event.target)">
                                    <option value="work">Work</option>
                                    <option value="home">Home</option>
                                    <option value="mobile">Mobile</option>
                                    <option value="fax">Fax</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            <div class="col-sm-6 p-0">
                                <input class="form-control" type="text" [(ngModel)]="phone.number" mask="(000) 000-0000" [showMaskTyped]="true" (input)="setPhoneNumber(i, $event.target)" required />
                            </div>
                            <div class="col-sm-2">
                                <div class="delete-icon float-right" [hidden]="isSimulated" (click)="removePhone(i)"><i class="fas fa-times-circle"></i></div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item"><input class="btn btn-primary" type="button" value="New"  [disabled]="isSimulated" (click)="addPhone()" appFocusNewInput /></li>
                </ul>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="ngb-client-notes">
            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="m-0">Notes</h5>
                    <div>
                        <button ngbPanelToggle class="btn btn-outline-primary">
                            <i [hidden]="opened" class="fas fa-plus"></i>
                            <i [hidden]="!opened" class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-notes [ownerUUID]="client.uuid || ''"></app-notes>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>

<div class="modal-footer" *ngIf="{ client: current$ | async, isUnique: isUnique$ | async } as data">
    <button
        type="submit"
        class="btn btn-secondary"
        (click)="save(true)"
        [hidden]="data.client && data.client.uuid"
        [disabled]="isSimulated || !data.isUnique || !form.valid">Save and Create Another</button>
    <button
        type="submit"
        class="btn btn-secondary"
        (click)="save()"
        [disabled]="isSimulated || !data.isUnique || !form.valid">Save</button>
    <button
        type="button"
        class="btn btn-danger"
        [hidden]="isSimulated || !data.client || !data.client.uuid"
        (click)="close(true)">Delete</button>
    <button
        type="button"
        class="btn btn-default"
        (click)="close()">Cancel</button>
</div>
