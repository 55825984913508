<div class="modal-header" appDraggable=".modal-dialog">
    <h4 class="modal-title">Search String</h4>
    <button type="button" class="close" aria-hidden="true" (click)="close()">&times;</button>
  </div>
  <div class="modal-body">
    <form [formGroup]="stringForm">

        <div class="form-group">
            <label class="col-sm-12 control-label">Search String Name:</label>
            <div class="col-sm-12">
                <input type="text" class="form-control" formControlName="name" />
                <span *ngIf="f.name.touched && f.name.invalid" class="error-message text-danger">Please enter the string name.</span>
            </div>
        </div>
        <div class="form-group">
            <label class="col-sm-12 control-label">Display Name:</label>
            <div class="col-sm-12">
                <input type="text" class="form-control" formControlName="displayName" />
            </div>
        </div>
        <div class="form-group">
            <div class="col-sm-12">
                <textarea style="height: 250px" class="col-sm-12 form-control" formControlName="string"></textarea>
            </div>
        </div>

    </form>
  </div>

  <div class="modal-footer">
    <button
      type="submit"
      class="btn btn-secondary"
      (click)="save()"
      [disabled]="!stringForm.valid">Save</button>
    <button
      type="button"
      class="btn btn-default"
      (click)="close()">Cancel</button>
  </div>