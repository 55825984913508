<div class="modal-header" appDraggable=".modal-dialog" *ngIf="{ user: current$ | async } as data">
    <h4 class="modal-title">Admin Notes for the User</h4>
    <button type="button" class="close" aria-hidden="true" (click)="close()">&times;</button>
</div>

<div class="modal-body">
    <form [formGroup]="form">
        <textarea style="height: 200px" class="col-sm-12 form-control" formControlName="adminMemo"></textarea>
    </form>
</div>

<div class="modal-footer">
    <button
        type="submit"
        class="btn btn-secondary"
        (click)="save()">Save</button>
    <button
        type="button"
        class="btn btn-default"
        (click)="close()">Cancel</button>
</div>