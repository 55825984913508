import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { tap } from 'rxjs/operators';

import { SessionStateService } from './session-state.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private sessionStateService: SessionStateService, private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const sessionToken = this.sessionStateService.getSessionToken();

    if (sessionToken) {
      const modifiedRequest = request.clone({
        headers: request.headers.set('x-session-token', sessionToken),
      });

      return next.handle(modifiedRequest).pipe(tap(() => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
             return;
            }

            this.router.navigate(['/']);
          }
        }
      ));
    } else {
      return next.handle(request);
    }
  }
}
