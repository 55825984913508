<div class="modal-header">
  <h4 class="modal-title">Set New Password</h4>
  <button type="button" class="close" aria-hidden="true" (click)="close()">&times;</button>
</div>
<div class="modal-body">
  <form [formGroup]="passwordForm">
    <div class="container">

      <div class="form-group row">
        <label for="username" class="col-sm-3 col-form-label">New Password</label>
        <div class="col-sm-9">
          <input type="password" name="password" id="password" class="form-control" placeholder="Password" formControlName="password" appAutofocus>
        </div>
      </div>

      <div class="form-group row">
        <label for="confirmpassword" class="col-sm-3 col-form-label">Confirm Password</label>
        <div class="col-sm-9">
          <input type="password" name="confirmpassword" id="confirmpassword" class="form-control" placeholder="Confirm Password" formControlName="confirmpassword">
        </div>
      </div>

    </div>
  </form>
</div>

<div class="modal-footer">
  <button
    type="submit"
    class="btn btn-secondary"
    (click)="change()"
    [disabled]="!passwordForm.valid || (f.password.value != f.confirmpassword.value)">Change Password</button>
  <button
    type="button"
    class="btn btn-default"
    (click)="close()">Cancel</button>
</div>