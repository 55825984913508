import {Component, EventEmitter, Input, Output} from '@angular/core';
export interface UploadedFile {
  file?: File;
  csvData?: string;
}
@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent {

  @Input() acceptedText = 'Accepted csv file type';
  @Input() fileName?: string;
  @Input() maximumFileSize = 4; // MB
  @Output() fileUploaded = new EventEmitter<UploadedFile | undefined>();
  @Output() deleteClicked = new EventEmitter();
  @Output() loadingStarted = new EventEmitter();

  file?: File;
  isDragover = false;
  isFileSizeError = false;

  clearData(): void {
    this.file = undefined;
    this.fileName = undefined;
    this.isFileSizeError = false;
    this.isDragover = false;
    this.fileUploaded.emit(undefined);
  }

  onFileDropped(event: DragEvent): void {
    event.preventDefault();
    const file = event.dataTransfer?.files[0];
    if (!file) {
      return;
    }
    this.onFileUpload(file);
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragover = true;
  }

  onDragLeave(): void {
    this.isDragover = false;
  }

  onFileSelected(event: Event): void {
    const file: File = (event.target as HTMLInputElement)!.files![0];
    if (file) {
      this.onFileUpload(file);
    }
  }

  onFileUpload(file: File): void {
    if (!file) {
      return;
    }
    this.clearData();
    const fileSize = file.size / 1024 / 1024; // in MB

    if (fileSize > this.maximumFileSize) {
      this.isFileSizeError = true;
      return;
    }
    this.file = file;
    this.fileName = file.name;
    this.loadingStarted.emit();
    const reader: FileReader = new FileReader();
    reader.onload = () => {
      const csvData = reader.result as string;
      this.fileUploaded.emit({file, csvData });
    };
    reader.readAsText(file);
  }

  deleteFileClick(inputEl: HTMLInputElement): void {
    this.file = undefined;
    this.fileName = undefined;
    this.deleteClicked.emit();
    inputEl.value = '';

  }

}
