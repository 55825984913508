<div class="widget container-fluid">
    <div class="row" *ngIf="withHeader">
        <div class="col-sm-12 widget-header">Notes</div>
    </div>
    <div class="widget-body p-0">
        <div class="row">
            <div class="col-sm-12 p-0">
                <ul class="list-group">
                    <li class="list-group-item one-liner" *ngFor="let note of notes; index as i" (click)="edit(i)">
                        <div>{{ note.text }}</div>
                        <div class="created-on" *ngIf="!!note.createdOn">{{ note.createdOn | date: 'MM/dd/yyyy hh:mm:ss a' }}</div>
                    </li>
                    <li class="list-group-item" id="add-note" (click)="add()" [hidden]="isSimulated"><i class="fas fa-plus mr-2"></i>&nbsp;New Note</li>
                </ul>
            </div>
        </div>
    </div>
</div>
