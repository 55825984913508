import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FileSaverService} from 'ngx-filesaver';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {EsourcingAddToPlannerComponent} from '../esourcing-add-to-planner/esourcing-add-to-planner.component';
import {User} from '../../shared/models/user.model';
import {first} from 'rxjs/operators';
import {UsersService} from '../../shared/users.service';
import {ExportFileService} from '../../shared/services/export-file.service';
import {
  SEARCH_RESULT_IS_REMOVED_FROM_TABLE_EVENT,
  SEARCH_RESULT_SELECTION_CHANGED_EVENT,
  SEARCH_RESULTS_READY_EVENT,
  SELECTED_SEARCH_RESULT_ITEMS_EVENT,
  SOURCING_IFRAME_ID
} from '../../shared/consts/search-result-events.const';
import {CustomDocumentEvent} from '../../shared/models/custom-document-event.model';

export interface SelectedSearchResult {
  link: string;
  searchTitle: string;
  firstName?: string;
  lastName?: string;
  company?: string;
  title?: string;
  isAddedToPlanner?: boolean;
}

@Component({
  selector: 'app-esourcing-process-selected',
  templateUrl: './esourcing-process-selected.component.html',
  styleUrls: ['./esourcing-process-selected.component.scss']
})
export class EsourcingProcessSelectedComponent implements OnInit {
  @ViewChild('cardContent') cardContent?: ElementRef<any>;

  items: SelectedSearchResult[] = [];

  user: User | null = null;

  constructor(
    private fileSaverService: FileSaverService,
    private modalService: NgbModal,
    private usersService: UsersService,
    private exportFileService: ExportFileService
  ) { }

  ngOnInit(): void {
    this.listenSearchResultsReadyEvent();
    this.listenSearchResultCheckboxSelection();
    this.getCurrentUser();
  }

  listenSearchResultsReadyEvent(): void {
    window.document.addEventListener(SEARCH_RESULTS_READY_EVENT, (e: any) => {
      if (!e) {
        return;
      }
      this.passEventToSearchIframe(SELECTED_SEARCH_RESULT_ITEMS_EVENT, this.items);
    });
  }

  listenSearchResultCheckboxSelection(): void {
    window.document.addEventListener(SEARCH_RESULT_SELECTION_CHANGED_EVENT, (e: any) => {
      if (!e) {
        return;
      }
      this.processItemSelection(e);
    });
  }

  processItemSelection(e: any): void {
    const link = e?.detail?.target?.nextSibling.href;
    const title = e?.detail?.target?.nextSibling.innerText;
    const index = this.items.findIndex(i => i.link + '' === link + '');
    if (index === -1) {
      const detailedItem = this.extractItemDetails(title, link);
      this.items.push(detailedItem);
    } else {
      this.items.splice(index, 1);
    }

    // wait until rendered at the UI
    setTimeout(() => {
      this.scrollToBottom();
    });
  }

  extractItemDetails(title: string, link: string): SelectedSearchResult {
    const item: SelectedSearchResult = {
      link,
      searchTitle: title,
      firstName: '',
      lastName: '',
      company: '',
      title: '',
    };
    let parts = title.split('-');
    if (!parts?.length || parts?.length === 1) {
      parts = title.split('|');
      if (!parts?.length || parts?.length === 1) {
        return item;
      }
    }
    if (parts[0]) {
      const firstSpaceIndex = parts[0].indexOf(' ');
      if (firstSpaceIndex !== -1) {
        item.firstName = parts[0].slice(0, firstSpaceIndex)?.trim();
        const secondNamePart = parts[0].slice(firstSpaceIndex);
        const specialCharacters = ['|', ',', '.'];
        specialCharacters.forEach(c => {
          const specialCharacterIndex = secondNamePart.indexOf(c);
          if (specialCharacterIndex !== -1) {
            item.lastName = secondNamePart.slice(0, specialCharacterIndex)?.trim();
          }
        });

        if (!item.lastName) {
          item.lastName = parts[0].slice(firstSpaceIndex)?.trim();
        }
      }
    }

    if (parts[2]) {
      if (parts[1]) {
        item.title = parts[1]?.trim() || '';
      }

      if (parts[2]) {
        item.company = parts[2]?.trim() || '';
      }
    } else {
      item.company = parts[1]?.trim() || '';
    }
    return item;
  }


  scrollToBottom(): void {
    if (!this.cardContent?.nativeElement) {
      return;
    }
    this.cardContent.nativeElement.scroll({
      top: this.cardContent.nativeElement.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  getCurrentUser(): void {
    this.usersService.currentUser().pipe(first()).subscribe(user => {
      this.user = user;
    });
  }

  onExportClick(): void {
    if (!this.items) {
      return;
    }

    const blob: Blob | undefined = this.exportFileService.prepareBlobData(this.items);
    if (!blob) {
      return;
    }
    this.exportFileService.saveFileToDefaultDirectory(blob, 'SearchResults');
  }

  onAddToPlanerClick(item: SelectedSearchResult): void {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      size: 'sm'
    };
    const modalRef = this.modalService.open(EsourcingAddToPlannerComponent, ngbModalOptions);
    modalRef.componentInstance.searchItem = item;
    modalRef.componentInstance.user = this.user;
    modalRef.result.then((result) => {
      if (result?.isAddedToPlanner) {
        item.isAddedToPlanner = true;
      }
    });
  }

  onRemoveClick(index: number): void {
    const item = this.items[index];
    this.passEventToSearchIframe(SEARCH_RESULT_IS_REMOVED_FROM_TABLE_EVENT, item);
    this.items.splice(index, 1);
  }

  passEventToSearchIframe(eventType: string, data?: any): void {
    const iframeWindow: any = document.getElementById(SOURCING_IFRAME_ID);
    if (iframeWindow) {
      const e: CustomDocumentEvent = {
        type: eventType,
        detail: data || undefined
      };
      iframeWindow.contentWindow.postMessage(e);
    }
  }

}
