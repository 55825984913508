<app-header></app-header>
<app-toolbar></app-toolbar>

<nav class="navbar navbar-default navbar-fixed-top navbar-global navbar-expand-md w-100 bg-white" role="navigation">
    <div class="container">
      <a class="navbar-brand text-dark" href="javascript:void"><b>Search Assignment / Job Order</b></a>
  
      <div class="ml-auto">
        <button type="button" class="btn btn-default mr-3" (click)="showExample()">View Example</button>

        <button #searchAssignmentDownload type="button" class="btn btn-default mr-3" [disabled]="!client || !position" (click)="download()">Download</button>

        <button #searchAssignmentSave type="button" class="btn btn-primary mr-3" [disabled]="!client || !position || isSimulated" (click)="accept()">Save</button>

        <div class="btn-group">
          <button type="button" class="btn btn-light" (click)="share()" [disabled]="!client || !position || !shareList.length">Share</button>
          <div class="btn-group" ngbDropdown role="group">
            <button class="btn btn-light dropdown-toggle-split" ngbDropdownToggle></button>
            <div ngbDropdownMenu class="dropdown-menu pb-0 dropdown-right" id="dropdownShareDropdown" aria-labelledby="dropdownShare">
              <label *ngFor="let user of users" class="dropdown-item">
                <input type="checkbox" class="top1px" [value]="user.email" (click)="toggleSelection(user)" /> {{user.firstName}} {{user.lastName}}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
</nav>

  <div class="container-fluid form-container mt-3 position-relative" id="searchassignmentform">

    <div class="internal">Morgan Method Form</div>

    <table class="head-table">
        <tr>
            <td class="head1" width="50%"></td>
            <td class="head2" width="50%">
                <h2>RecruitersPlayBook&trade;</h2>
                <p>Search Assignment / Job Order</p>
            </td>
        </tr>
    </table>

    <table class="main-table full-width">
        <tr>
            <td colspan="3" class="p-0">
                <table class="full-width"><tr>
                    <td width="50%" class="noborder border-right">Client Name: <div class="float-right"><button class="btn btn-default mr-3" [disabled]="isSimulated" (click)="newClient()">Add</button><select [(ngModel)]="client" (ngModelChange)="changedClient()" [compareWith]="compareClients"><option *ngFor="let client of clients; trackBy: trackByFullName" [ngValue]="client">{{ client.company }}</option></select></div>
                    </td>
                    <td width="50%" class="noborder">Position Title: <div class="float-right"><button class="btn btn-default mr-3" [disabled]="isSimulated" (click)="newPosition()">Add</button><select class="mr-3 shorter-select" [(ngModel)]="position" (change)="changedPosition()"><option *ngFor="let position of positions" [ngValue]="position">{{ position.position }}</option></select><button class="btn btn-danger" [disabled]="!position || !fields.uuid || isSimulated" (click)="deletePosition()">Delete</button></div></td>
               </tr></table>
            </td>
        </tr>
        <tr>
            <td colspan="3" class="p-0">
            <table class="full-width"><tr>
                <td width="50%" class="noborder border-right">Date: <input type="date" class="float-right" size="20" [(ngModel)]="fields.date" [disabled]="!client || !position" (change)="save('date')" /></td>
                <td width="50%" class="noborder">Contact Name: <input type="text" class="float-right" size="20" [(ngModel)]="fields.contactFirstName" [disabled]="!client || !position" (change)="save('contactFirstName')" /></td>
            </tr></table>
            </td>
        </tr>
        <tr>
            <td colspan="3">Location of Position: <input type="text" class="float-right" size="148" [(ngModel)]="fields.location" [disabled]="!client || !position" (change)="save('location')" /></td>
        </tr>
        <tr>
            <td width="50%">Associate: <div class="float-right"><input type="text" size="20" [(ngModel)]="fields.recruiter" [disabled]="!client || !position" (change)="save('recruiter')" /></div></td>
            <td width="50%">Source: <div class="float-right"><input type="text" size="20" [(ngModel)]="fields.source" [disabled]="!client || !position" (change)="save('source')" /></div></td>
        </tr>
    </table>

    <table class="main-table">
        <tr>
            <td colspan="2"><ol type="I"><li>URGENCY.<br />
            <div class="red">&ldquo;During the course of any given month, our team is charged with the responsibility of filling anywhere between 3-5 <input type="text" class="mini-input" size="15" [(ngModel)]="fields.urgentPosition" [disabled]="!client || !position" (change)="save('urgentPosition')" /> positions that are almost identical in scope to the position you’ve started to describe. As a result, it becomes <i>critical that we prioritize</i> which one of our clients will get exposure to our top tier of <input type="text" class="mini-input" size="15" [(ngModel)]="fields.topTier" [disabled]="!client || !position" (change)="save('topTier')" />. Some clients are in 911 mode, some are kicking tires. I'd like to take you through a series of questions to determine how we prioritize this search against other companies looking for similar skillsets. This will also help me determine how many staff and what level we assign to this project. May I?&rdquo;</div></li></ol></td>
        </tr>
        <tr>
            <td colspan="2"><ol start="2"><li>&ldquo;What is the downside to the company (possible financial loss) if this position remains unfilled? &rdquo;<br /><textarea rows="1" [(ngModel)]="fields.downsideCompany" [disabled]="!client || !position" (change)="save('downsideCompany')" textarea-grow></textarea></li></ol></td>
        </tr>
        <tr>
            <td colspan="2"><ol start="3"><li>&ldquo;What is the downside to you personally (workload, hours, boss’ judgment) if the position remains unfilled?&rdquo;<br /><textarea rows="1" [(ngModel)]="fields.downsidePersonal" [disabled]="!client || !position" (change)="save('downsidePersonal')" textarea-grow></textarea><br /><br />&ldquo;Since this would be a contingency based fee with us carrying 100% of the risk... What’s the likelihood after my company invests 5-10k in payroll conducting the search on contingency, that the search will be pulled or postponed or that paying a fee has not been approved?&rdquo;<br /><textarea rows="1" [(ngModel)]="fields.searchPulled" [disabled]="!client || !position" (change)="save('searchPulled')" textarea-grow></textarea></li></ol></td>
        </tr>
        <tr>
            <td colspan="2"><ol start="4"><li>CORPORATE DESIRED OUTCOME:<br /><br />
                <ul>
                    <li>
                        What is the ultimate objective this hire will accomplish?<br />
                        <textarea rows="1" [(ngModel)]="fields.objective" [disabled]="!client || !position" (change)="save('objective')" textarea-grow></textarea><br /><br />
                    </li>
                    <li>
                        What problem or issue needs to be fixed or satisfied by this hire?<br />
                        <textarea rows="1" [(ngModel)]="fields.problemToBeSolved" [disabled]="!client || !position" (change)="save('problemToBeSolved')" textarea-grow></textarea><br /><br />
                    </li>
                    <li>
                        What results will be seen to justify the hire?<br />
                        <textarea rows="1" [(ngModel)]="fields.desiredResults" [disabled]="!client || !position" (change)="save('desiredResults')" textarea-grow></textarea><br /><br />
                    </li>
                </ul>
            </li></ol></td>
        </tr>
        <tr>
            <td colspan="2"><ol start="5"><li>&ldquo;Now help me fit this position into your group. It is important for this new hire to visualize where they are.  Verbally, walk me through the org chart.&rdquo;</li></ol><br />

                <div class="duty-box col-4 center-block p-0" id="company-top">
                    <table><tr><td [ngClass]="{'selected': position?.position && position?.position?.length && (position?.position == fields.orgTop)}"><input type="text" [(ngModel)]="fields.orgTop" [disabled]="!client || !position" (change)="save('orgTop')" /></td></tr></table>
                </div>

                <table class="layout layout-2">
                    <tr>
                        <td>
                            <div class="duty-box col-10 center-block p-0" id="org-1">
                                <table><tr><td [ngClass]="{'selected': position?.position && position?.position?.length && (position?.position == fields.orgLevel2[0])}"><input type="text" [(ngModel)]="fields.orgLevel2[0]" [disabled]="!client || !position" (change)="save('orgLevel2')" /></td></tr></table>
                            </div>
                        </td>
                        <td>
                            <div class="duty-box col-10 center-block p-0" id="org-2">
                                <table><tr><td [ngClass]="{'selected': position?.position && position?.position?.length && (position?.position == fields.orgLevel2[1])}"><input type="text" [(ngModel)]="fields.orgLevel2[1]" [disabled]="!client || !position" (change)="save('orgLevel2')" /></td></tr></table>
                            </div>
                        </td>
                     </tr>
                 </table>

                <table class="layout layout-6">
                    <tr>
                        <td>
                            <div class="duty-box col-10 center-block p-0" id="team-1">
                                <table>
                                    <tr><td [ngClass]="{'selected': position?.position && position?.position?.length && (position?.position == fields.orgLevel3[0].position)}">Title:<br /><input type="text" [(ngModel)]="fields.orgLevel3[0].position" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td>Staff:<br /><input type="text" [(ngModel)]="fields.orgLevel3[0].team[0]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td><input type="text" [(ngModel)]="fields.orgLevel3[0].team[1]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td><input type="text" [(ngModel)]="fields.orgLevel3[0].team[2]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td><input type="text" [(ngModel)]="fields.orgLevel3[0].team[3]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                </table>
                            </div>
                        </td>
                        <td>
                            <div class="duty-box col-10 center-block p-0" id="team-2">
                                <table>
                                    <tr><td [ngClass]="{'selected': position?.position && position?.position?.length && (position?.position == fields.orgLevel3[1].position)}">Title:<br /><input type="text" [(ngModel)]="fields.orgLevel3[1].position" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td>Staff:<br /><input type="text" [(ngModel)]="fields.orgLevel3[1].team[0]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td><input type="text" [(ngModel)]="fields.orgLevel3[1].team[1]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td><input type="text" [(ngModel)]="fields.orgLevel3[1].team[2]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td><input type="text" [(ngModel)]="fields.orgLevel3[1].team[3]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                </table>
                            </div>
                        </td>
                        <td>
                            <div class="duty-box col-10 center-block p-0" id="team-3">
                                <table>
                                    <tr><td [ngClass]="{'selected': position?.position && position?.position?.length && (position?.position == fields.orgLevel3[2].position)}">Title:<br /><input type="text" [(ngModel)]="fields.orgLevel3[2].position" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td>Staff:<br /><input type="text" [(ngModel)]="fields.orgLevel3[2].team[0]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td><input type="text" [(ngModel)]="fields.orgLevel3[2].team[1]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td><input type="text" [(ngModel)]="fields.orgLevel3[2].team[2]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td><input type="text" [(ngModel)]="fields.orgLevel3[2].team[3]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                </table>
                            </div>
                        </td>
                        <td>
                            <div class="duty-box col-10 center-block p-0" id="team-4">
                                <table>
                                    <tr><td [ngClass]="{'selected': position?.position && position?.position?.length && (position?.position == fields.orgLevel3[3].position)}">Title:<br /><input type="text" [(ngModel)]="fields.orgLevel3[3].position" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td>Staff:<br /><input type="text" [(ngModel)]="fields.orgLevel3[3].team[0]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td><input type="text" [(ngModel)]="fields.orgLevel3[3].team[1]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td><input type="text" [(ngModel)]="fields.orgLevel3[3].team[2]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td><input type="text" [(ngModel)]="fields.orgLevel3[3].team[3]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                </table>
                            </div>
                        </td>
                        <td>
                            <div class="duty-box col-10 center-block p-0" id="team-5">
                                <table>
                                    <tr><td [ngClass]="{'selected': position?.position && position?.position?.length && (position?.position == fields.orgLevel3[4].position)}">Title:<br /><input type="text" [(ngModel)]="fields.orgLevel3[4].position" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td>Staff:<br /><input type="text" [(ngModel)]="fields.orgLevel3[4].team[0]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td><input type="text" [(ngModel)]="fields.orgLevel3[4].team[1]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td><input type="text" [(ngModel)]="fields.orgLevel3[4].team[2]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td><input type="text" [(ngModel)]="fields.orgLevel3[4].team[3]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                </table>
                            </div>
                        </td>
                        <td>
                            <div class="duty-box col-10 center-block p-0" id="team-6">
                                <table>
                                    <tr><td [ngClass]="{'selected': position?.position && position?.position?.length && (position?.position == fields.orgLevel3[5].position)}">Title:<br /><input type="text" [(ngModel)]="fields.orgLevel3[5].position" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td>Staff:<br /><input type="text" [(ngModel)]="fields.orgLevel3[5].team[0]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td><input type="text" [(ngModel)]="fields.orgLevel3[5].team[1]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td><input type="text" [(ngModel)]="fields.orgLevel3[5].team[2]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                    <tr><td><input type="text" [(ngModel)]="fields.orgLevel3[5].team[3]" [disabled]="!client || !position" (change)="save('orgLevel3')" /></td></tr>
                                </table>
                            </div>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>

        <tr>
            <td colspan="2">
                <ol start="6"><li>&ldquo;Now I’m going to ask you to think about this role in an unusual way... Let’s imagine that the role of this (title) is equal to 100% of the role’s responsibilities and duties... <b>Break that 100% down in the order of greatest importance of time to least time spent as of start date.</b>” (Ex. 40% of the job is...)&rdquo;</li></ol><br />

                <p class="red">Set this up as an org chart of responsibilities with the top box being the title.</p>
                <div class="duty-box col-4 center-block p-0" id="current-company">
                    <table>
                        <tr><td class="text-right" width="25%">Title:</td><td width="75%"><input type="text" [(ngModel)]="fields.title" [disabled]="!client || !position" (change)="save('title')" /></td></tr>
                    </table>
                </div>
                <table class="layout">
                    <tr>
                        <td>
                            <div class="duty-box col-6 center-block p-0" id="current-percent-1">
                                <table>
                                    <tr><td class="text-right percent-box" width="90%"><input type="number" [(ngModel)]="fields.duties[0].percentage" [disabled]="!client || !position" (change)="save('duties')" /></td><td class="percent-label" width="10%">%</td></tr>
                                </table>
                            </div>

                            <div class="duty-box col-9 center-block padded">
                                <p class="text-center">Macro Duty</p>
                                <input type="text" [(ngModel)]="fields.duties[0].macroDuty" [disabled]="!client || !position" (change)="save('duties')" />
                            </div>

                            <div class="duty-box col-9 center-block padded" id="current-micro-1">
                                <p class="text-center">Micro Duties</p>
                                <ol type="1">
                                    <li>
                                        <textarea textarea-grow rows="1" [(ngModel)]="fields.duties[0].microDuties[0]" [disabled]="!client || !position" (change)="save('duties')"></textarea>
                                    </li>
                                    <li>
                                        <textarea textarea-grow rows="1" [(ngModel)]="fields.duties[0].microDuties[1]" [disabled]="!client || !position" (change)="save('duties')"></textarea>
                                    </li>
                                    <li>
                                        <textarea textarea-grow rows="1" [(ngModel)]="fields.duties[0].microDuties[2]" [disabled]="!client || !position" (change)="save('duties')"></textarea>
                                    </li>
                                    <li>
                                        <textarea textarea-grow rows="1" [(ngModel)]="fields.duties[0].microDuties[3]" [disabled]="!client || !position" (change)="save('duties')"></textarea>
                                    </li>
                                </ol>
                            </div>
                        </td>

                        <td>
                            <div class="duty-box col-6 center-block p-0" id="current-percent-2">
                                <table>
                                    <tr><td class="text-right" width="90%"><input type="number" [(ngModel)]="fields.duties[1].percentage" [disabled]="!client || !position" (change)="save('duties')" /></td><td width="10%">%</td></tr>
                                </table>
                            </div>

                            <div class="duty-box col-9 center-block padded">
                                <p class="text-center">Macro Duty</p>
                                <input type="text" [(ngModel)]="fields.duties[1].macroDuty" [disabled]="!client || !position" (change)="save('duties')" />
                            </div>

                            <div class="duty-box col-9 center-block padded" id="current-micro-2">
                                <p class="text-center">Micro Duties</p>
                                <ol type="1">
                                    <li>
                                        <textarea textarea-grow rows="1" [(ngModel)]="fields.duties[1].microDuties[0]" [disabled]="!client || !position" (change)="save('duties')"></textarea>
                                    </li>
                                    <li>
                                        <textarea textarea-grow rows="1" [(ngModel)]="fields.duties[1].microDuties[1]" [disabled]="!client || !position" (change)="save('duties')"></textarea>
                                    </li>
                                    <li>
                                        <textarea textarea-grow rows="1" [(ngModel)]="fields.duties[1].microDuties[2]" [disabled]="!client || !position" (change)="save('duties')"></textarea>
                                    </li>
                                    <li>
                                        <textarea textarea-grow rows="1" [(ngModel)]="fields.duties[1].microDuties[3]" [disabled]="!client || !position" (change)="save('duties')"></textarea>
                                    </li>
                                </ol>
                            </div>
                        </td>

                        <td>
                            <div class="duty-box col-6 center-block p-0" id="current-percent-3">
                                <table>
                                    <tr><td class="text-right" width="90%"><input type="number" [(ngModel)]="fields.duties[2].percentage" [disabled]="!client || !position" (change)="save('duties')" /></td><td width="10%">%</td></tr>
                                </table>
                            </div>

                            <div class="duty-box col-9 center-block padded">
                                <p class="text-center">Macro Duty</p>
                                <input type="text" [(ngModel)]="fields.duties[2].macroDuty" [disabled]="!client || !position" (change)="save('duties')" />
                            </div>

                            <div class="duty-box col-9 center-block padded" id="current-micro-3">
                                <p class="text-center">Micro Duties</p>
                                <ol type="1">
                                    <li>
                                        <textarea textarea-grow rows="1" [(ngModel)]="fields.duties[2].microDuties[0]" [disabled]="!client || !position" (change)="save('duties')"></textarea>
                                    </li>
                                    <li>
                                        <textarea textarea-grow rows="1" [(ngModel)]="fields.duties[2].microDuties[1]" [disabled]="!client || !position" (change)="save('duties')"></textarea>
                                    </li>
                                    <li>
                                        <textarea textarea-grow rows="1" [(ngModel)]="fields.duties[2].microDuties[2]" [disabled]="!client || !position" (change)="save('duties')"></textarea>
                                    </li>
                                    <li>
                                        <textarea textarea-grow rows="1" [(ngModel)]="fields.duties[2].microDuties[3]" [disabled]="!client || !position" (change)="save('duties')"></textarea>
                                    </li>
                                </ol>
                            </div>
                        </td>

                        <td>
                            <div class="duty-box col-6 center-block p-0" id="current-percent-4">
                                <table>
                                    <tr><td class="text-right" width="90%"><input type="number" [(ngModel)]="fields.duties[3].percentage" [disabled]="!client || !position" (change)="save('duties')" /></td><td width="10%">%</td></tr>
                                </table>
                            </div>

                            <div class="duty-box col-9 center-block padded">
                                <p class="text-center">Macro Duty</p>
                                <input type="text" [(ngModel)]="fields.duties[3].macroDuty" [disabled]="!client || !position" (change)="save('duties')" />
                            </div>

                            <div class="duty-box col-9 center-block padded" id="current-micro-4">
                                <p class="text-center">Micro Duties</p>
                                <ol type="1">
                                    <li>
                                        <textarea textarea-grow rows="1" [(ngModel)]="fields.duties[3].microDuties[0]" [disabled]="!client || !position" (change)="save('duties')"></textarea>
                                    </li>
                                    <li>
                                        <textarea textarea-grow rows="1" [(ngModel)]="fields.duties[3].microDuties[1]" [disabled]="!client || !position" (change)="save('duties')"></textarea>
                                    </li>
                                    <li>
                                        <textarea textarea-grow rows="1" [(ngModel)]="fields.duties[3].microDuties[2]" [disabled]="!client || !position" (change)="save('duties')"></textarea>
                                    </li>
                                    <li>
                                        <textarea textarea-grow rows="1" [(ngModel)]="fields.duties[3].microDuties[3]" [disabled]="!client || !position" (change)="save('duties')"></textarea>
                                    </li>
                                </ol>
                            </div>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>

        <tr>
            <td colspan="2"><ol start="7"><li>&ldquo;Now that I understand the job itself, what are the minimum experience levels and skills necessary?&rdquo;

            <br /><br />Answer: (TECHNICAL QUALIFICATIONS)

            <br /><br />Education:<br /><textarea rows="1" [(ngModel)]="fields.education" [disabled]="!client || !position" (change)="save('education')" textarea-grow></textarea>

            <br /><br />Minimum experience:<br /><textarea rows="1" [(ngModel)]="fields.experience" [disabled]="!client || !position" (change)="save('experience')" textarea-grow></textarea>

            <br /><br />Ideal experience:<br /><textarea rows="1" [(ngModel)]="fields.idealExperience" [disabled]="!client || !position" (change)="save('idealExperience')" textarea-grow></textarea>

            <br /><br />Needed Communication Skills:<br /><textarea rows="1" [(ngModel)]="fields.communicationSkills" [disabled]="!client || !position" (change)="save('communicationSkills')" textarea-grow></textarea>

            <br /><br />Technology Exposure:<br /><textarea rows="1" [(ngModel)]="fields.technology" [disabled]="!client || !position" (change)="save('technology')" textarea-grow></textarea>

            <hr />

            <div>COMPATIBILITY TO THE DEPARTMENT: &ldquo;To mesh in with the current existing group, what characteristics or management style must they possess?&rdquo;</div><br /><br />

            <table class="full-width"><tr>
                <td><input type="radio" name="compatibility-energetic" [(ngModel)]="fields.energetic" [disabled]="!client || !position" value="energetic" (change)="save('energetic')" />&nbsp;Fast-Paced/Energetic or<br /><input type="radio" name="compatibility-energetic" [(ngModel)]="fields.energetic" [disabled]="!client || !position" value="laidback" (change)="save('energetic')" />&nbsp;Laid-Back/Methodical?</td>
                <td><input type="radio" name="compatibility-profile" [(ngModel)]="fields.profile" [disabled]="!client || !position" value="high" (change)="save('profile')" />&nbsp;Assertive/Aggressive or<br /><input type="radio" name="compatibility-profile" [(ngModel)]="fields.profile" [disabled]="!client || !position" value="low" (change)="save('profile')" />&nbsp;Compliant/Low Profile?</td>
                <td><input type="radio" name="compatibility-task" [(ngModel)]="fields.taskOriented" [disabled]="!client || !position" value="multi" (change)="save('taskOriented')" />&nbsp;Multi-Project Oriented or<br /><input type="radio" name="compatibility-task" [(ngModel)]="fields.taskOriented" [disabled]="!client || !position" value="single" (change)="save('taskOriented')" />&nbsp;Single Task Oriented?</td>
            </tr></table>

            </li></ol></td>
        </tr>

        <tr>
            <td colspan="2"><ol start="8"><li>&ldquo;What 3 technical questions need to be answered 100% correctly in order to move forward with interviews?&rdquo;

             <br /><br /><div>1.</div><textarea rows="1" [(ngModel)]="fields.technicalQuestions[0]" [disabled]="!client || !position" (change)="save('technicalQuestions')" textarea-grow></textarea>
             <br /><br /><div>2.</div><textarea rows="1" [(ngModel)]="fields.technicalQuestions[1]" [disabled]="!client || !position" (change)="save('technicalQuestions')" textarea-grow></textarea>
             <br /><br /><div>3.</div><textarea rows="1" [(ngModel)]="fields.technicalQuestions[2]" [disabled]="!client || !position" (change)="save('technicalQuestions')" textarea-grow></textarea>

            </li></ol></td>
        </tr>

        <tr>
            <td colspan="2"><ol start="9"><li>&ldquo;Now that I understand the skills needed for this position, what is the dollar value  you attach to it?&rdquo;
                <br /><br /><h4 class="underline text-center">COMPENSATION/BENEFITS</h4><br />

                <table class="full-width"><tr>
                    <td>Salary Range:&nbsp;<input type="text" class="mini-input" [(ngModel)]="fields.salaryRange" [disabled]="!client || !position" (change)="save('salaryRange')" /></td>
                    <td>Do not submit at:&nbsp;<input type="text" class="mini-input" [(ngModel)]="fields.doNotSubmitAt" [disabled]="!client || !position" (change)="save('doNotSubmitAt')" /></td>
                    <td>Hourly Rate:&nbsp;<input type="text" class="mini-input" [(ngModel)]="fields.hourlyRate" [disabled]="!client || !position" (change)="save('hourlyRate')" /></td>
                    <td>OT:&nbsp;<input type="text" class="mini-input" [(ngModel)]="fields.overtime" [disabled]="!client || !position" (change)="save('overtime')" /></td>
                </tr></table><br />

                <table class="full-width"><tr>
                    <td>Date of Salary Review:&nbsp;<input type="date"  class="float-right" [(ngModel)]="fields.salaryReviewDate" [disabled]="!client || !position" (change)="save('salaryReviewDate')" /></td>
                    <td>Date of Performance Review:&nbsp;<input type="date" class="mini-input float-right" [(ngModel)]="fields.performanceReviewDate" [disabled]="!client || !position" (change)="save('performanceReviewDate')" /></td></tr>
                <tr><td>Bonus:&nbsp;<input type="text" class="float-right" [(ngModel)]="fields.bonus" [disabled]="!client || !position" (change)="save('bonus')" /></td>
                    <td>Profit Sharing:&nbsp;<input type="text" class="mini-input float-right" [(ngModel)]="fields.profitSharing" [disabled]="!client || !position" (change)="save('profitSharing')" /></td>
                </tr></table><br />

                <table class="full-width"><tr>
                    <td>MM:&nbsp;<input type="checkbox" [(ngModel)]="fields.mm" [disabled]="!client || !position" appTrueFalseValue [trueValue]="'true'" [falseValue]="'false'" (change)="save('mm')" /></td>
                    <td>Dental:&nbsp;<input type="checkbox" [(ngModel)]="fields.dental" [disabled]="!client || !position" appTrueFalseValue [trueValue]="'true'" [falseValue]="'false'" (change)="save('dental')" /></td>
                    <td>Vision:&nbsp;<input type="checkbox" [(ngModel)]="fields.vision" [disabled]="!client || !position" appTrueFalseValue [trueValue]="'true'" [falseValue]="'false'" (change)="save('vision')" /></td>
                    <td>Rx Card:&nbsp;<input type="checkbox" [(ngModel)]="fields.rxcard" [disabled]="!client || !position" appTrueFalseValue [trueValue]="'true'" [falseValue]="'false'" (change)="save('rxcard')" /></td>
                    <td>Life:&nbsp;<input type="checkbox" [(ngModel)]="fields.life" [disabled]="!client || !position" appTrueFalseValue [trueValue]="'true'" [falseValue]="'false'" (change)="save('life')" /></td>
                    <td>Eligibility Date:&nbsp;<input type="date" [(ngModel)]="fields.eligibilityDate" [disabled]="!client || !position" (change)="save('eligibilityDate')" /></td>
                </tr></table><br />

                <table class="full-width"><tr>
                    <td>Vacation:&nbsp;<input type="checkbox" [(ngModel)]="fields.vacation" [disabled]="!client || !position" appTrueFalseValue [trueValue]="'true'" [falseValue]="'false'" (change)="save('vacation')" /></td>
                    <td>ST Disability:&nbsp;<input type="checkbox" [(ngModel)]="fields.stDisability" [disabled]="!client || !position" appTrueFalseValue [trueValue]="'true'" [falseValue]="'false'" (change)="save('stDisability')" /></td>
                    <td>LT Disability:&nbsp;<input type="checkbox" [(ngModel)]="fields.ltDisability" [disabled]="!client || !position" appTrueFalseValue [trueValue]="'true'" [falseValue]="'false'" (change)="save('ltDisability')" /></td>
                    <td>Tuition:&nbsp;<input type="checkbox" [(ngModel)]="fields.tuition" [disabled]="!client || !position" appTrueFalseValue [trueValue]="'true'" [falseValue]="'false'" (change)="save('tuition')" /></td>
                    <td>Training:&nbsp;<input type="checkbox" [(ngModel)]="fields.training" [disabled]="!client || !position" appTrueFalseValue [trueValue]="'true'" [falseValue]="'false'" (change)="save('training')" /></td>
                    <td>Parking Paid:&nbsp;<input type="checkbox" [(ngModel)]="fields.parkingPaid" [disabled]="!client || !position" appTrueFalseValue [trueValue]="'true'" [falseValue]="'false'" (change)="save('parkingPaid')" /></td>
                </tr></table><br />

                <table class="full-width"><tr>
                    <td>Co-Pay:&nbsp;<input type="checkbox" [(ngModel)]="fields.copay" [disabled]="!client || !position" appTrueFalseValue [trueValue]="'true'" [falseValue]="'false'" (change)="save('copay')" /></td>
                    <td>Paid time off:&nbsp;<input type="checkbox" [(ngModel)]="fields.paidTimeOff" [disabled]="!client || !position" appTrueFalseValue [trueValue]="'true'" [falseValue]="'false'" (change)="save('paidTimeOff')" /></td>
                    <td>Additional Benefits:&nbsp;<input type="checkbox" [(ngModel)]="fields.additionalBenefits" [disabled]="!client || !position" appTrueFalseValue [trueValue]="'true'" [falseValue]="'false'" (change)="save('additionalBenefits')" /></td>
                </tr></table><br />

                <table class="full-width"><tr>
                    <td>Relocation Package:&nbsp;<input type="text" size="80" [(ngModel)]="fields.relocationPackage" [disabled]="!client || !position" (change)="save('relocationPackage')" /></td>
                </tr></table>

            </li></ol></td>
        </tr>

        <tr>
            <td colspan="2"><ol start="10"><li>&ldquo;Now what I'd like to cover is the most important part of us working together; it is understanding your hiring process. Knowing the tempo and time between interviews allows me to pace the candidate.  We have no problem with a short or long process, however, unexpected changes affect candidate predictability. Assuming I had a candidate today, walk me through your process.&rdquo;

            <br />
            <h4 class="text-center underline">HIRING PROCESS</h4>

            <div>1st Interview w/:&nbsp;<input type="text" [(ngModel)]="fields.interviewName1" [disabled]="!client || !position" (change)="save('interviewName1')" /></div><br />

            <div>Interview Title:&nbsp;<input type="text" [(ngModel)]="fields.interviewTitle1" [disabled]="!client || !position" (change)="save('interviewTitle1')" /></div><br />

            <table class="full-width"><tr>
                <td>Persona/Focus:&nbsp;<input type="text" class="mini-input" [(ngModel)]="fields.personalityFocus1" [disabled]="!client || !position" (change)="save('personalityFocus1')" /></td>
                <td>Testing:&nbsp;<input type="text" class="mini-input" [(ngModel)]="fields.testing" [disabled]="!client || !position" (change)="save('testing')" /></td>
                <td>Length of Meeting:&nbsp;<input type="text" class="micro-input" [(ngModel)]="fields.lengthOfMeeting" [disabled]="!client || !position" (change)="save('lengthOfMeeting')" /></td>
            </tr></table><br />

            <div>2nd Interview w/:&nbsp;<input type="text" [(ngModel)]="fields.interviewName2" [disabled]="!client || !position" (change)="save('interviewName2')" /></div><br />

            <div>Interview Title:&nbsp;<input type="text" [(ngModel)]="fields.interviewTitle2" [disabled]="!client || !position" (change)="save('interviewTitle2')" /></div><br />

            <table class="full-width">
                <tr>
                    <td>Persona/Focus:&nbsp;<input type="text" class="mini-input" [(ngModel)]="fields.personalityFocus2" [disabled]="!client || !position" (change)="save('personalityFocus2')" /></td>
                    <td>Time Between 1<sup>st</sup> & 2<sup>nd</sup>:&nbsp;<input type="text" class="micro-input" [(ngModel)]="fields.timeBetween" [disabled]="!client || !position" (change)="save('timeBetween')" /></td>
                </tr>
                <tr>
                    <td>Multi-person Interviews?&nbsp;<input type="text" class="mini-input" [(ngModel)]="fields.multiPersonInterviews" [disabled]="!client || !position" (change)="save('multiPersonInterviews')" /></td>
                    <td>Time Between 1<sup>st</sup> & 2<sup>nd</sup>:&nbsp;<input type="text" class="micro-input" [(ngModel)]="fields.timeBetweenInterviews" [disabled]="!client || !position" (change)="save('timeBetweenInterviews')" /></td>
                </tr>
            </table><br />

            <div>Who will deliver the <i>offer</i> to us?<br /><textarea rows="1" [(ngModel)]="fields.whoMakesOffer" [disabled]="!client || !position" (change)="save('whoMakesOffer')" textarea-grow></textarea></div><br />

            <div>Advise against direct candidate offer.<br /><textarea rows="1" [(ngModel)]="fields.offerOnSpot" [disabled]="!client || !position" (change)="save('offerOnSpot')" textarea-grow></textarea></div><br />

            <div>Who, if anyone, approves your decision?<br /><textarea rows="1" [(ngModel)]="fields.approvesDecision" [disabled]="!client || !position" (change)="save('approvesDecision')" textarea-grow></textarea></div><br />

            <div>Preferred start date:&nbsp;<input type="date" class="mini-input" [(ngModel)]="fields.preferredStartDate" [disabled]="!client || !position" (change)="save('preferredStartDate')" /><br />(Back into realism of interview process with 2 weeks' notice)</div><br />

            <h4 class="text-center underline">URGENCY/COMPETITON/FEE</h4>

            <div>Why is the position open?<br /><textarea rows="1" [(ngModel)]="fields.whyPositionOpen" [disabled]="!client || !position" (change)="save('whyPositionOpen')" textarea-grow></textarea></div><br />

            <div>Incumbent’s name:<br /><textarea rows="1" [(ngModel)]="fields.incumbentsName" [disabled]="!client || !position" (change)="save('incumbentsName')" textarea-grow></textarea></div><br />

            <div><span class="red">What would you add/subtract to incumbent's or existing candidate's skills?</span><br /><textarea rows="1" [(ngModel)]="fields.incumbentSkills" [disabled]="!client || !position" (change)="save('incumbentSkills')" textarea-grow></textarea></div><br />

            <div>Who have you interviewed? Common themes?<br /><textarea rows="1" [(ngModel)]="fields.commonThemes" [disabled]="!client || !position" (change)="save('commonThemes')" textarea-grow></textarea></div><br />

            <div>Who are we competing against?<br /><textarea rows="1" [(ngModel)]="fields.competingAgainst" [disabled]="!client || !position" (change)="save('competingAgainst')" textarea-grow></textarea></div><br />

            <div>Have you run internal or external postings?<br /><textarea rows="1" [(ngModel)]="fields.postings" [disabled]="!client || !position" (change)="save('postings')" textarea-grow></textarea></div><br />

            <div>Do you have internal candidates in process?<br /><textarea rows="1" [(ngModel)]="fields.internalCandidates" [disabled]="!client || !position" (change)="save('internalCandidates')" textarea-grow></textarea></div><br />

            <div>Do you understand the benefits of us working exclusively?<br /><textarea rows="1" [(ngModel)]="fields.exclusiveBenefits" [disabled]="!client || !position" (change)="save('exclusiveBenefits')" textarea-grow></textarea></div><br />

            <div>And again, you do have full approval to pay fee?<br /><textarea rows="1" [(ngModel)]="fields.approval" [disabled]="!client || !position" (change)="save('approval')" textarea-grow></textarea></div>

            </li></ol></td>
        </tr>

        <tr>
            <td colspan="2"><h4 class="text-center">FEE FOR SERVICE</h4><ol start="11"><li>&ldquo;Hopefully by now you've seen a sampling of the depth that we put into getting things right the first time. When it comes to our fees, we believe very strongly in front end agreement vs. tail end negotiation. Finding the right (title) for you will be a (%) investment of the candidate's first year of annual compensation (including sign on incentives). The guarantee on our work extends for (#) months, <b>provided our fees are paid in the agreed upon time frame</b>. We invoice you upon offer/acceptance date and the fee is payable (X days) from the day of offer/acceptance.&rdquo;<br /><br />&ldquo;Our team (I) will begin putting full time effort into the search after our fee agreement has been acknowledged and received by us.&nbsp;Good to go?&rdquo;
                <textarea rows="1" [(ngModel)]="fields.goodToGo" [disabled]="!client || !position" (change)="save('goodToGo')" textarea-grow></textarea>
            </li></ol></td>
        </tr>

        <tr>
            <td colspan="2"><ol start="12"><li>&ldquo;We’d like to know you better; sometimes it helps us to find a common ground with candidates.  Tell me about your background.&rdquo;<br /><br />
                <h4 class="underline text-center">EMPLOYER BIOGRAPHY</h4>
                <textarea rows="1" [(ngModel)]="fields.biography" [disabled]="!client || !position" (change)="save('biography')" textarea-grow></textarea>
            </li></ol></td>
        </tr>

        <tr>
            <td colspan="2"><ol start="13"><li>&ldquo;Give me three times you will make yourself available for candidate meetings.&rdquo;
                <br /><br /><textarea rows="5" [(ngModel)]="fields.meetingNotes" [disabled]="!client || !position" (change)="save('meetingNotes')" textarea-grow></textarea></li></ol>
            </td>
        </tr>

        <tr><td class="ending" colspan="2"></td></tr>
    </table>
</div>

<app-footer></app-footer>
