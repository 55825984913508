<app-header></app-header>
<app-toolbar></app-toolbar>

<nav class="navbar navbar-default navbar-fixed-top navbar-global navbar-expand-md bg-white" role="navigation">
    <div class="container">
      <a class="navbar-brand text-dark" href="javascript:void"><b>Candidate <i>Inner</i>View<sup>TM</sup> Profile</b></a>

      <div class="ml-auto">
        <button type="button" class="btn btn-default mr-3" (click)="showExample()">View Example</button>

        <button #innerviewDownload type="button" class="btn btn-default mr-3" [disabled]="!candidate" (click)="download()">Download</button>

        <button #innerviewSave type="button" class="btn btn-primary mr-3" [disabled]="!candidate || isSimulated" (click)="accept()">Save</button>

        <div class="btn-group">
          <button type="button" class="btn btn-light" (click)="share()" [disabled]="!candidate || !shareList.length">Share</button>
          <div class="btn-group" ngbDropdown role="group">
            <button class="btn btn-light dropdown-toggle-split" ngbDropdownToggle></button>
            <div ngbDropdownMenu class="dropdown-menu pb-0 dropdown-right" id="dropdownShareDropdown" aria-labelledby="dropdownShare">
              <label *ngFor="let user of users" class="dropdown-item">
                <input type="checkbox" class="top1px" [value]="user.email" (click)="toggleSelection(user)" /> {{user.firstName}} {{user.lastName}}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
</nav>

<div class="container-fluid form-container mt-3 position-relative" id="innerviewform">

    <div class="internal">INTERNAL USE ONLY</div>
    <table class="head-table">
      <tr>
          <td class="head1" width="50%"></td>
          <td class="head2" width="50%">Candidate <i>Inner</i>View<sup>TM</sup> PROFILE</td>
      </tr>
  </table>

  <table class="main-table">
      <tr>
          <td colspan="2">
              <div class="float-left col-4 pl-0 pr-2 text-centered">Candidate:&nbsp;<div class="float-right"><button class="btn btn-default mr-3" [disabled]="isSimulated" (click)="newCandidate()">Add</button><select [(ngModel)]="candidate" (ngModelChange)="changedCandidate()" [compareWith]="compareCandidates"><option *ngFor="let candidate of candidates; trackBy: trackByFullName" [ngValue]="candidate">{{ candidate.firstName + ' ' + candidate.lastName }}</option></select></div></div>
              <div class="float-left col-4 pl-0 pr-2 text-centered text-end">Company:&nbsp;<input type="text" class="float-right" size="20" [(ngModel)]="fields.candidateCompany" [disabled]="!candidate" (change)="save('candidateCompany')" /></div>
              <div class="float-left col-4 p-0 text-centered text-end">Title:&nbsp;<input type="text" class="float-right" size="20" [(ngModel)]="fields.candidateTitle" [disabled]="!candidate" (change)="save('candidateTitle')" /></div>
          </td>
      </tr>
      <tr>
          <td width="50%"><div class="text-centered">Recruiter: <input type="text" class="float-right" size="20" [(ngModel)]="fields.recruiter" [disabled]="!candidate" (change)="save('recruiter')" /></div></td>
          <td width="50%"><div class="text-centered">Date: <input type="date" class="float-right" size="20" [(ngModel)]="fields.date" [disabled]="!candidate" (change)="save('date')" /></div></td>
      </tr>
      <tr>
          <td colspan="2"><ol type="I"><li>GREET.<br />
          <div class="text-blue">&ldquo;Thanks for taking the time to chat today. After our last conversation, I was excited to hear more... Ok, lets define the process. There are 3 things I’d like to cover with you today:<br /><br />
              <ol type="1">
                  <li><span class="red">Skills Assessment:</span> I would like to hear how strong you are among others that do what you do. How do you stand out?</li>
                  <br />
                  <li><span class="red">Your Wish List:</span> I gave you 5 subjects to think about in preparation for our meeting today. No promises, but if I don't know what you are looking for, I can't get you closer.</li>
                  <br />
                  <li><span class="red">Etiquette of Search:</span> Different quality search firms have different expectations. I'd like to talk with you about what you can expect from me and vice versa. Ok? Let's begin...&rdquo;</li>
              </ol>
          </div></li></ol></td>
      </tr>

     <tr>
          <td colspan="2">
              <ol type="I" start="2"><li>SKILLS ASSESSMENT<br />
                  <ol type="a" class="text-blue"><li>&ldquo;Take 5 minutes and start with the company you are with now. Go back to your start date with (current company) and walk me through your progression with them leading me up to your <span class="underline">current</span> responsibilities.&rdquo;</li></ol>
                  <br />
                  <p><textarea rows="5" [(ngModel)]="fields.skillsAssessment" [disabled]="!candidate" (change)="save('skillsAssessment')"></textarea></p>
              </li></ol>
              <hr />
              <p class="quote">Now, give me an understanding of where you fit in your department’s organizational chart. Starting at the top, talk me through who reports to who, all the way to your role (and staff reporting to you).</p>
              <br />
              <div class="duty-box col-4 center-block p-0" id="current-department">
                  <table>
                      <tr><td width="100%"><input type="text" [(ngModel)]="fields.departmentHead" [disabled]="!candidate" (change)="save('departmentHead')" /></td></tr>
                  </table>
              </div>
              <table class="layout layout-5">
                  <tr>
                      <td>
                          <div class="duty-box col-7 center-block padded" id="current-department-1">
                              <textarea [(ngModel)]="fields.departmentStaff[0]" rows="10" [disabled]="!candidate" (change)="save('departmentStaff')"></textarea>
                          </div>
                      </td>

                      <td>
                          <div class="duty-box col-7 center-block padded" id="current-department-2">
                            <textarea [(ngModel)]="fields.departmentStaff[1]" rows="10" [disabled]="!candidate" (change)="save('departmentStaff')"></textarea>
                        </div>
                      </td>

                      <td>
                          <div class="duty-box col-7 center-block padded" id="current-department-3">
                            <textarea [(ngModel)]="fields.departmentStaff[2]" rows="10" [disabled]="!candidate" (change)="save('departmentStaff')"></textarea>
                          </div>
                      </td>

                      <td>
                          <div class="duty-box col-7 center-block padded" id="current-department-4">
                            <textarea [(ngModel)]="fields.departmentStaff[3]" rows="10" [disabled]="!candidate" (change)="save('departmentStaff')"></textarea>
                          </div>
                      </td>

                      <td>
                        <div class="duty-box col-7 center-block padded" id="current-department-5">
                            <textarea [(ngModel)]="fields.departmentStaff[4]" rows="10" [disabled]="!candidate" (change)="save('departmentStaff')"></textarea>
                        </div>
                    </td>
                  </tr>
              </table>
          </td>
      </tr>
 
      <tr>
          <td colspan="2">
              <hr />
              <p class="quote">Now, I'm going to ask you to think about your job in an <i>unusual</i> way... Let's imagine that your current job is equal to 100% of its full responsibilities and duties... Break that 100% down into percentages of time spent on specific duties ranging from greatest to least time spent in those areas:</p>
              <br />
              <div class="duty-box col-4 center-block p-0" id="current-company">
                  <table>
                      <tr><td class="text-right" width="25%">Title:</td><td width="75%"><input type="text" [(ngModel)]="fields.title" [disabled]="!candidate" (change)="save('title')" /></td></tr>
                      <tr><td class="text-right" width="25%">Company:</td><td width="75%"><input type="text" [(ngModel)]="fields.company" [disabled]="!candidate" (change)="save('company')" /></td></tr>
                  </table>
              </div>
              <table class="layout">
                  <tr>
                      <td>
                          <div class="duty-box col-6 center-block p-0" id="current-percent-1">
                              <table>
                                  <tr><td class="text-right" width="90%"><input type="number" [(ngModel)]="fields.duties[0].percentage" [disabled]="!candidate" (change)="save('duties')" /></td><td width="10%">%</td></tr>
                              </table>
                          </div>

                          <div class="duty-box col-9 center-block padded">
                              <p class="text-center">Macro Duty</p>
                              <input type="text" [(ngModel)]="fields.duties[0].macroDuty" [disabled]="!candidate" (change)="save('duties')" />
                          </div>

                          <div class="duty-box col-9 center-block padded" id="current-micro-1">
                              <p class="text-center">Micro Duties</p>
                              <ol type="1">
                                  <li>
                                      <input type="text" [(ngModel)]="fields.duties[0].microDuties[0]" [disabled]="!candidate" (change)="save('duties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.duties[0].microDuties[1]" [disabled]="!candidate" (change)="save('duties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.duties[0].microDuties[2]" [disabled]="!candidate" (change)="save('duties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.duties[0].microDuties[3]" [disabled]="!candidate" (change)="save('duties')" />
                                  </li>
                              </ol>
                          </div>
                      </td>

                      <td>
                          <div class="duty-box col-6 center-block p-0" id="current-percent-2">
                              <table>
                                  <tr><td class="text-right" width="90%"><input type="number" [(ngModel)]="fields.duties[1].percentage" [disabled]="!candidate" (change)="save('duties')" /></td><td width="10%">%</td></tr>
                              </table>
                          </div>

                          <div class="duty-box col-9 center-block padded">
                              <p class="text-center">Macro Duty</p>
                              <input type="text" [(ngModel)]="fields.duties[1].macroDuty" [disabled]="!candidate" (change)="save('duties')" />
                          </div>

                          <div class="duty-box col-9 center-block padded" id="current-micro-2">
                              <p class="text-center">Micro Duties</p>
                              <ol type="1">
                                  <li>
                                      <input type="text" [(ngModel)]="fields.duties[1].microDuties[0]" [disabled]="!candidate" (change)="save('duties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.duties[1].microDuties[1]" [disabled]="!candidate" (change)="save('duties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.duties[1].microDuties[2]" [disabled]="!candidate" (change)="save('duties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.duties[1].microDuties[3]" [disabled]="!candidate" (change)="save('duties')" />
                                  </li>
                              </ol>
                          </div>
                      </td>

                      <td>
                          <div class="duty-box col-6 center-block p-0" id="current-percent-3">
                              <table>
                                  <tr><td class="text-right" width="90%"><input type="number" [(ngModel)]="fields.duties[2].percentage" [disabled]="!candidate" (change)="save('duties')" /></td><td width="10%">%</td></tr>
                              </table>
                          </div>

                          <div class="duty-box col-9 center-block padded">
                              <p class="text-center">Macro Duty</p>
                              <input type="text" [(ngModel)]="fields.duties[2].macroDuty" [disabled]="!candidate" (change)="save('duties')" />
                          </div>

                          <div class="duty-box col-9 center-block padded" id="current-micro-3">
                              <p class="text-center">Micro Duties</p>
                              <ol type="1">
                                  <li>
                                      <input type="text" [(ngModel)]="fields.duties[2].microDuties[0]" [disabled]="!candidate" (change)="save('duties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.duties[2].microDuties[1]" [disabled]="!candidate" (change)="save('duties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.duties[2].microDuties[2]" [disabled]="!candidate" (change)="save('duties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.duties[2].microDuties[3]" [disabled]="!candidate" (change)="save('duties')" />
                                  </li>
                              </ol>
                          </div>
                      </td>

                      <td>
                          <div class="duty-box col-6 center-block p-0" id="current-percent-4">
                              <table>
                                  <tr><td class="text-right" width="90%"><input type="number" [(ngModel)]="fields.duties[3].percentage" [disabled]="!candidate" (change)="save('duties')" /></td><td width="10%">%</td></tr>
                              </table>
                          </div>

                          <div class="duty-box col-9 center-block padded">
                              <p class="text-center">Macro Duty</p>
                              <input type="text" [(ngModel)]="fields.duties[3].macroDuty" [disabled]="!candidate" (change)="save('duties')" />
                          </div>

                          <div class="duty-box col-9 center-block padded" id="current-micro-4">
                              <p class="text-center">Micro Duties</p>
                              <ol type="1">
                                  <li>
                                      <input type="text" [(ngModel)]="fields.duties[3].microDuties[0]" [disabled]="!candidate" (change)="save('duties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.duties[3].microDuties[1]" [disabled]="!candidate" (change)="save('duties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.duties[3].microDuties[2]" [disabled]="!candidate" (change)="save('duties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.duties[3].microDuties[3]" [disabled]="!candidate" (change)="save('duties')" />
                                  </li>
                              </ol>
                          </div>
                      </td>
                  </tr>
              </table>
          </td>
      </tr>
      <tr>
          <td width="50%">Income Progression: Base/ Bonus<br />When is the bonus paid out and what is the bonus payout track record?</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.incomeProgression" [disabled]="!candidate" (change)="save('incomeProgression')" /></td>
      </tr>
      <tr>
          <td width="50%">What is your reason for exploring something new?</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.reasonExploring" [disabled]="!candidate" (change)="save('reasonExploring')" /></td>
      </tr>

      <tr>
          <td colspan="2">
              <ol type="a" start="2" class="red"><li>&ldquo;Let’s do the same thing with the job directly prior to (current company). Walk me through your progression, leading me up to your final responsibilities at the time of your departure.&rdquo;</li></ol>
              <hr />
              <p class="quote">Describe the functionality of this job, breaking it down into percentages of time spent on specific duties:</p>
              <br />
              <div class="duty-box col-4 center-block p-0" id="previous-company">
                  <table>
                      <tr><td class="text-right" width="25%">Title:</td><td width="75%"><input type="text" [(ngModel)]="fields.previousTitle" [disabled]="!candidate" (change)="save('previousTitle')" /></td></tr>
                      <tr><td class="text-right" width="25%">Company:</td><td width="75%"><input type="text" [(ngModel)]="fields.previousCompany" [disabled]="!candidate" (change)="save('previousCompany')" /></td></tr>
                  </table>
              </div>
              <table class="layout">
                  <tr>
                      <td>
                          <div class="duty-box col-6 center-block p-0" id="previous-percent-1">
                              <table>
                                  <tr><td class="text-right" width="90%"><input type="text" [(ngModel)]="fields.previousDuties[0].percentage" [disabled]="!candidate" (change)="save('previousDuties')" /></td><td width="10%">%</td></tr>
                              </table>
                          </div>

                          <div class="duty-box col-9 center-block padded">
                              <p class="text-center">Macro Duty</p>
                              <input type="text" [(ngModel)]="fields.previousDuties[0].macroDuty" [disabled]="!candidate" (change)="save('previousDuties')" />
                          </div>

                          <div class="duty-box col-9 center-block padded" id="previous-micro-1">
                              <p class="text-center">Micro Duties</p>
                              <ol type="1">
                                  <li>
                                      <input type="text" [(ngModel)]="fields.previousDuties[0].microDuties[0]" [disabled]="!candidate" (change)="save('previousDuties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.previousDuties[0].microDuties[1]" [disabled]="!candidate" (change)="save('previousDuties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.previousDuties[0].microDuties[2]" [disabled]="!candidate" (change)="save('previousDuties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.previousDuties[0].microDuties[3]" [disabled]="!candidate" (change)="save('previousDuties')" />
                                  </li>
                              </ol>
                          </div>
                      </td>

                      <td>
                          <div class="duty-box col-6 center-block p-0" id="previous-percent-2">
                              <table>
                                  <tr><td class="text-right" width="90%"><input type="text" [(ngModel)]="fields.previousDuties[1].percentage" [disabled]="!candidate" (change)="save('previousDuties')" /></td><td width="10%">%</td></tr>
                              </table>
                          </div>

                          <div class="duty-box col-9 center-block padded">
                              <p class="text-center">Macro Duty</p>
                              <input type="text" [(ngModel)]="fields.previousDuties[1].macroDuty" [disabled]="!candidate" (change)="save('previousDuties')" />
                          </div>

                          <div class="duty-box col-9 center-block padded" id="previous-micro-2">
                              <p class="text-center">Micro Duties</p>
                              <ol type="1">
                                  <li>
                                      <input type="text" [(ngModel)]="fields.previousDuties[1].microDuties[0]" [disabled]="!candidate" (change)="save('previousDuties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.previousDuties[1].microDuties[1]" [disabled]="!candidate" (change)="save('previousDuties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.previousDuties[1].microDuties[2]" [disabled]="!candidate" (change)="save('previousDuties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.previousDuties[1].microDuties[3]" [disabled]="!candidate" (change)="save('previousDuties')" />
                                  </li>
                              </ol>
                          </div>
                      </td>

                      <td>
                          <div class="duty-box col-6 center-block p-0" id="previous-percent-3">
                              <table>
                                  <tr><td class="text-right" width="90%"><input type="text" [(ngModel)]="fields.previousDuties[2].percentage" [disabled]="!candidate" (change)="save('previousDuties')" /></td><td width="10%">%</td></tr>
                              </table>
                          </div>

                          <div class="duty-box col-9 center-block padded">
                              <p class="text-center">Macro Duty</p>
                              <input type="text" [(ngModel)]="fields.previousDuties[2].macroDuty" [disabled]="!candidate" (change)="save('previousDuties')" />
                          </div>

                          <div class="duty-box col-9 center-block padded" id="previous-micro-3">
                              <p class="text-center">Micro Duties</p>
                              <ol type="1">
                                  <li>
                                      <input type="text" [(ngModel)]="fields.previousDuties[2].microDuties[0]" [disabled]="!candidate" (change)="save('previousDuties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.previousDuties[2].microDuties[1]" [disabled]="!candidate" (change)="save('previousDuties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.previousDuties[2].microDuties[2]" [disabled]="!candidate" (change)="save('previousDuties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.previousDuties[2].microDuties[3]" [disabled]="!candidate" (change)="save('previousDuties')" />
                                  </li>
                              </ol>
                          </div>
                      </td>

                      <td>
                          <div class="duty-box col-6 center-block p-0" id="previous-percent-4">
                              <table>
                                  <tr><td class="text-right" width="90%"><input type="text" [(ngModel)]="fields.previousDuties[3].percentage" [disabled]="!candidate" (change)="save('previousDuties')" /></td><td width="10%">%</td></tr>
                              </table>
                          </div>

                          <div class="duty-box col-9 center-block padded">
                              <p class="text-center">Macro Duty</p>
                              <input type="text" [(ngModel)]="fields.previousDuties[3].macroDuty" [disabled]="!candidate" (change)="save('previousDuties')" />
                          </div>

                          <div class="duty-box col-9 center-block padded" id="previous-micro-4">
                              <p class="text-center">Micro Duties</p>
                              <ol type="1">
                                  <li>
                                      <input type="text" [(ngModel)]="fields.previousDuties[3].microDuties[0]" [disabled]="!candidate" (change)="save('previousDuties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.previousDuties[3].microDuties[1]" [disabled]="!candidate" (change)="save('previousDuties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.previousDuties[3].microDuties[2]" [disabled]="!candidate" (change)="save('previousDuties')" />
                                  </li>
                                  <li>
                                      <input type="text" [(ngModel)]="fields.previousDuties[3].microDuties[3]" [disabled]="!candidate" (change)="save('previousDuties')" />
                                  </li>
                              </ol>
                          </div>
                      </td>
                  </tr>
              </table>
          </td>
      </tr>

      <tr>
          <td width="50%">Income Progression: Base/ Bonus<br />When is the bonus paid out and what is the bonus payout track record?</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.previousIncomeProgression" [disabled]="!candidate" (change)="save('previousIncomeProgression')" /></td>
      </tr>
      <tr>
          <td width="50%">What was your reason for exploring something new?</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.previousReasonExploring" [disabled]="!candidate" (change)="save('previousReasonExploring')" /></td>
      </tr>
      <tr>
          <td colspan="2" class="quote">{{candidate?.firstName}}, I think I have a pretty good understanding of what you were responsible for. Do you agree? Anything we have skipped?</td>
      </tr>

      <tr>
          <td colspan="2"><ol type="I" start="3"><li>HUMBLING.<br />
          <div class="red">&ldquo;Now let’s shift gears and talk about what makes you different. During the course of a typical month, I am responsible for evaluating “between” 10-20 (titles) that for the most part, have similar responsibilities as you. As a result it becomes critical to prioritize, which (title) gets exposure to our top tier of clients. Make sense?&rdquo;</div><br />
          <div class="red">&ldquo;So with that in mind, I’d like to take you through a series of questions that will help me to determine where you stand in this competitive market. Ok?&rdquo;</div>
          </li></ol></td>
      </tr>
      <tr>
          <td width="50%">&ldquo;What have you done that has caused you to <b>stand out</b> amongst other (titles)?&rdquo;</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.standout" [disabled]="!candidate" (change)="save('standout')" /></td>
      </tr>
      <tr>
          <td width="50%">&ldquo;What have you done that’s caused your department or company to <b>increase revenue?&rdquo;</b></td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.increaseRevenue" [disabled]="!candidate" (change)="save('increaseRevenue')" /></td>
      </tr>
      <tr>
          <td width="50%">&ldquo;What have you done that has <b>decreased costs</b> or <b>increased work flow</b>?&rdquo;</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.decreaseCosts" [disabled]="!candidate" (change)="save('decreaseCosts')" /></td>
      </tr>

      <tr><td colspan="2">
          <p><span class="red">***NOTE***</span> (If your candidate is POP quality and prepared for interviewing, <span class="underline">ask them</span> to turn the above questions above into a one page "case study" to be packaged in their assessment to clients.) </p></td></tr>
      <tr>
          <td colspan="2"><ol type="I" start="4"><li>CANDIDATE WISH LIST.<br />
          <div class="red">&ldquo;I think I have a strong understanding of your differentiators. Now what I’d like to talk with you about is clearly the most important part of our conversation.<br /><br />
It involves the 5 things I asked for you to think about earlier. It's important for you to know that the realism that goes into your wishlist will determine how successful I can be for you. Make sense?<br /><br />So I am going to take you through a checklist of topics and you tell me your thoughts.&rdquo;</div>
          </li></ol></td>
      </tr>
      <tr>
          <td width="50%"><b>a. Title:</b> &ldquo;What is the title of the next position you would accept? If not available, would you consider ---- (use title directly below)?&rdquo;</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.expectedTitle" [disabled]="!candidate" (change)="save('expectedTitle')" /></td>
      </tr>
      <tr>
          <td width="50%"><b>b. Function:</b> (% of time doing various functions within the next job)</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.function" [disabled]="!candidate" (change)="save('function')" /></td>
      </tr>
      <tr>
          <td width="50%"><b>c. Industry Preference:</b> &ldquo;Right now you serve the -?- Industry. Which other industries might you consider?&rdquo;</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.industry" [disabled]="!candidate" (change)="save('industry')" /></td>
      </tr>
      <tr>
          <td width="50%"><b>d. Size of company:</b> (head count or revenue? Public or private?)</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.companySize" [disabled]="!candidate" (change)="save('companySize')" /></td>
      </tr>
      <tr>
          <td width="50%"><b>e. Culture:</b> &ldquo;Describe the corporate culture in which you’d be most productive (attire, reporting structure, management style)?&rdquo;</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.culture" [disabled]="!candidate" (change)="save('culture')" /></td>
      </tr>
      <tr>
          <td width="50%"><b>f. Consulting:</b> &ldquo;Is full time status the only option or would you consider a consulting assignment?&rdquo;</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.consulting" [disabled]="!candidate" (change)="save('consulting')" /></td>
      </tr>
      <tr>
          <td width="50%"><b>g. Geographics:</b> &ldquo;Is your first preference not to move? If open to relo, where and why?&rdquo;</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.geographics" [disabled]="!candidate" (change)="save('geographics')" /></td>
      </tr>
      <tr>
          <td width="50%">1. &ldquo;Are you mentally ready to list your house, and pack up in leave within the next 6-8 weeks?&rdquo;</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.ready" [disabled]="!candidate" (change)="save('ready')" /></td>
      </tr>
      <tr>
          <td width="50%">2. &ldquo;Is your sig other working? &rdquo;</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.spouse" [disabled]="!candidate" (change)="save('spouse')" /></td>
      </tr>
      <tr>
          <td width="50%">3. &ldquo;Minimum relocation assistance?&rdquo;</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.relocation" [disabled]="!candidate" (change)="save('relocation')" /></td>
      </tr>
      <tr>
          <td width="50%"><b>h. Travel:</b> &ldquo;Your preference? Absolute maximum? What does that look like?&rdquo;</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.travel" [disabled]="!candidate" (change)="save('travel')" /></td>
      </tr>
      <tr>
          <td width="50%"><b>i. Commute:</b> &ldquo;What is your maximum daily commute time?&rdquo;</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.commute" [disabled]="!candidate" (change)="save('commute')" /></td>
      </tr>
      <tr>
          <td width="50%"><b>j. Respected Companies:</b> &ldquo;What are three companies or individual leaders you have an interest in working for or have great respect?&rdquo;</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.respectedCompanies" [disabled]="!candidate" (change)="save('respectedCompanies')" /></td>
      </tr>
      <tr>
          <td width="50%"><b>k. Compensation:</b> &ldquo;Now let’s talk about something that is important to all of us, future compensation. There are typically two ways to get a raise. One, same job more cash. Two, same cash or even less cash in a company that provides the professional exposure that can ultimately take you to higher levels in the future. What is more important to you right now?&rdquo;</td>
          <td width="50%"><input type="text" size="20" [(ngModel)]="fields.compensation" [disabled]="!candidate" (change)="save('compensation')" /></td>
      </tr>

      <tr>
          <td colspan="2">
              <p class="red"><b>Compensation Trial Close</b></p>
              <p class="quote">If all or most of your wish list was met, at what point dollar wise do we <span class="underline">not</span> even pick up the phone and make you aware of it?</p>
              <p class="quote">So at $<input type="text" class="micro-input" [(ngModel)]="fields.compensationAmount" [disabled]="!candidate" (change)="save('compensationAmount')" /> or above we can expect you to interview?</p>
              <p class="quote">This also means that at less than $<input type="text" class="micro-input" [(ngModel)]="fields.compensationPass" [disabled]="!candidate" (change)="save('compensationPass')" />, we pass and move to a different candidate, yes?</p>
          </td>
      </tr>

      <tr>
          <td colspan="2">
              <h3 class="underline text-center">Settle on a number. Now, release the pressure.</h3>
              <p class="quote">Please realize that the higher the compensation we negotiate for you, the larger the reward is to our company. We are absolutely on the same team. It’s just important for me to know at what point our back is against the wall, ok?</p>
              <hr />
              <p class="quote">In order to understand what constitutes a step forward in your career, what are the four main criteria or priorities that you need to see in a new company before you will accept a new position?</p>
              <ol type="1">
                <li>
                      <input type="text" [(ngModel)]="fields.priorities[0]" [disabled]="!candidate" (change)="save('priorities')" />
                  </li>
                  <li>
                      <input type="text" [(ngModel)]="fields.priorities[1]" [disabled]="!candidate" (change)="save('priorities')" />
                  </li>
                  <li>
                      <input type="text" [(ngModel)]="fields.priorities[2]" [disabled]="!candidate" (change)="save('priorities')" />
                  </li>
                  <li>
                      <input type="text" [(ngModel)]="fields.priorities[3]" [disabled]="!candidate" (change)="save('priorities')" />
                  </li>
              </ol>
          </td>
      </tr>

      <tr>
          <td colspan="2">
              <h3 class="underline text-center">POP CANDIDATES ONLY (Rock star, ready, willing and able to move?)</h3>
              <p class="quote usual">{{candidate?.firstName}}, I want to discuss something with you. Probably 1 out of 10 candidates are eligible for this process. It is a special relationship that we have with candidates we think are strong enough to take to market <span class="underline">regardless</span> of openings we are currently engaged to fill.</p>
              <p class="quote usual">This means we need to feel strongly about your commitment and urgency in making a change, as well as the realism of your wish list. Then, we will PROACTIVELY design a marketing plan to reach out to multiple companies on your behalf.</p>
              <p class="quote usual">This takes greater effort and multiple search associates on our end, but normally results in you starting your new position within 6-12 weeks. I believe you are strong enough to be eligible for this but it will take a “partner approval meeting” and your full commitment to activate.</p>
              <p class="quote usual">I'd like you to sleep on this tonight and involve everyone affected by your decision (family, advisors). Get with me tomorrow and if you are committed, I will arrange my presentation to our partner. Your thoughts?</p>
              <p class="red">(IF CANDIDATE RESPONDS POSTIVELY, EXPLAIN THE ETIQUETTE (CONTROL) SEGMENT AFTER THE “PARTNERS APPROVAL” MEETING.)</p>
          </td>
      </tr>

      <tr>
          <td colspan="2" class="noborderbottom">
            <ol type="I" start="4"><li>ETIQUETTE OF SEARCH EXPLANATION:<br />
              <p class="quote">We’ve completed most of our meeting today and quite frankly, based on my perceptions of your skills and realistic wish list, I’d like to invite you to join the high performance group of candidates that we represent. If you feel the same, we would be pleased to include you in our presentations to some pretty high performance companies. How do you feel about that type of relationship?</p>
              <p><textarea rows="5" [(ngModel)]="fields.notesEtiquette" [disabled]="!candidate" (change)="save('notesEtiquette')"></textarea></p>
              <h3 class="red underline">RULES OF ENGAGEMENT (All candidates)</h3>
              <p class="quote usual">In return for the work we’ll do to help you move forward and achieve your goals, there are 4 professional courtesies I ask of you. They make our relationship run really smoothly. Ready?</p>
              <ol type="1">
                  <li><b>No surprises:</b> <span class="quote usual">What I mean by that, if you’re interviewing with <b>other companies</b>, I should know about that. If your <b>sense of urgency</b> changes or your current title/position/experience within current job role changes, let me know. References and social vetting will be done on your background. If there are issues that will surface, we should probably discuss them now.</span></li>
                  <li><b>Immediate feedback:</b> <span class="quote usual">After an interview with a client you should 'beat' their call back to me. After the interview, the etiquette is that you ask for an office or conference room to make a quick call to me. I will get your general opinion on how the meeting went and later, a full debriefing when you return home and have collected your thoughts. However, the client will want to know if you have an interest in pursuing things after interview. Make sense?</span> (Swap cell numbers).</li>
                  <li><b>Decisiveness at the time of the offer:</b> <span class="quote usual">All of our debriefings will be detailed. Going into your last interview, you will know what questions or issues need to be satisfied before accepting or rejecting the offer.</span></li>
              </ol>
              <br />
              <p class="quote usual">Provided all of our ‘pre-work’ is done going into the final interview, you should theoretically be capable of making a decision on the job, the company, and the upside of the position. The only unknown is the actual offer amount. So, at this time you should be capable of saying, ‘at X amount I will not take this job.’ Or, ‘at X amount, I will.’ Make sense? This is what clients expect at this level of search.</p>
              <p class="quote usual bold">Because of our relationship with the client, 3 days prior to the official offer, I will ask what the offer will be. I will make you aware of the unofficial offer, giving you 3 days to weigh the pros and cons with your family, your advisors, etc... This will allow you to be as decisive as the client on the day of the offer. You’ll be able to mirror their enthusiasm and decision making skills</p>
              <p class="quote usual">We have no problems with a “NO”. They happen occasionally. We obviously have no problems with a “YES”. That’s how we make a living. But, we have a major problem with “Maybes”. That tells us you haven’t been presented with all the data you needed to be decisive. A “maybe” sends you back for a “clean up” meeting with the client. Everyone wants you to feel completely “whole” with your decision.</p>
              <p class="quote usual bold">What are your thoughts with that process?</p>
              <p><textarea rows="5" [(ngModel)]="fields.notesEngagement" [disabled]="!candidate" (change)="save('notesEngagement')"></textarea></p>
              <ol type="1" start="4">
                  <li><b>Referrals:</b> <span class="quote">Finally, in return for the work I’ll do to help you move forward, you have the ability to help my practice grow as well. You certainly are not obligated, but if you chose to help, here’s how you could reciprocate:</span></li></ol><br />
              <p class="quote usual"><b>There are two types of people I have an interest in moving forward professionally:</b>
                <br />
                <br />
                <b>First</b>, someone who clearly excels and is <b>"looking"</b> to make a change.
                <br />
                <br />
                <b>Second</b>, someone who clearly <b>excels</b> but is <b>"not looking"</b> at the time of my call.</p>
              <hr />
              <p class="quote">My question to you is... active or not actively looking? Who do you know in each of those categories?</p>
            </li>
            </ol>
          </td>
      </tr>

      <tr>
          <td width="50%" class="noborder">
              <h3 class="red underline text-center">Looking:</h3><br />
              <div class="source-card" *ngIf="fields.lookingCard">
                <div class="form-group">
                    <div class="col-12 p-0">
                      <input type="text" class="form-control" [(ngModel)]="fields.lookingCard.firstName" name="firstName" placeholder="First Name" [disabled]="!candidate" (change)="save('lookingCard')" />
                    </div>
                  </div>
                
                  <div class="form-group">
                    <div class="col-12 p-0">
                      <input type="text" class="form-control" [(ngModel)]="fields.lookingCard.lastName" name="lastName" placeholder="Last Name" [disabled]="!candidate" (change)="save('lookingCard')" />
                    </div>
                  </div>
                
                  <div class="form-group">
                    <div class="col-12 p-0">
                      <input type="text" class="form-control" [(ngModel)]="fields.lookingCard.company" name="company" placeholder="Company" [disabled]="!candidate" (change)="save('lookingCard')" />
                    </div>
                  </div>
                
                  <div class="form-group">
                    <div class="col-12 p-0">
                      <input type="text" class="form-control" [(ngModel)]="fields.lookingCard.title" name="title" placeholder="Title" [disabled]="!candidate" (change)="save('lookingCard')" />
                    </div>
                  </div>
                
                  <div class="form-group">
                    <div class="col-12 p-0">
                      <input type="text" class="form-control" [(ngModel)]="fields.lookingCard.phoneNumber.number" name="phone" mask="(000) 000-0000" [showMaskTyped]="true" placeholder="Phone" [disabled]="!candidate" (change)="save('lookingCard')" />
                    </div>
                  </div>
                
                  <div class="form-group">
                    <div class="col-12 p-0">
                      <input type="email" class="form-control" [(ngModel)]="fields.lookingCard.email.address" name="email" placeholder="Email" [disabled]="!candidate" (change)="save('lookingCard')" />
                    </div>
                  </div>
                
                  <div class="form-group">
                    <div class="col-12 p-0">
                      <textarea rows="3" class="form-control" [(ngModel)]="fields.lookingCard.notes" name="notes" rows="10" placeholder="Notes" [disabled]="!candidate" (change)="save('lookingCard')"></textarea>
                    </div>
                  </div>
                  <div class="form-group mb-3 small-text">
                    <div class="row m-0">
                      <div class="col-6 p-0">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="addToRec" name="addToRec" [(ngModel)]="fields.lookingCard.addToRec" />
                          <label class="form-check-label" for="addToRec">
                          Add to Rec. Calls / DB
                          </label>
                        </div>
                      </div>
                      <div class="col-6 p-0 text-right">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="addToMkt" name="addToMkt" [(ngModel)]="fields.lookingCard.addToMkt" />
                          <label class="form-check-label" for="addToMkt">
                            Add to Mkt. Calls / DB
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <button type="button" class="btn btn-secondary" (click)="addToPlan(fields.lookingCard)" [disabled]="!candidate || isSimulated || !validate(fields.lookingCard)">Add to Plan</button>
                </div>
          </td>
          <td width="50%" class="noborder">
              <h3 class="red underline text-center">Not Looking:</h3><br />
              <div class="source-card" *ngIf="fields.notLookingCard">
                <div class="form-group">
                    <div class="col-12 p-0">
                      <input type="text" class="form-control" [(ngModel)]="fields.notLookingCard.firstName" name="firstName" placeholder="First Name" [disabled]="!candidate" (change)="save('notLookingCard')" />
                    </div>
                  </div>
                
                  <div class="form-group">
                    <div class="col-12 p-0">
                      <input type="text" class="form-control" [(ngModel)]="fields.notLookingCard.lastName" name="lastName" placeholder="Last Name" [disabled]="!candidate" (change)="save('notLookingCard')" />
                    </div>
                  </div>
                
                  <div class="form-group">
                    <div class="col-12 p-0">
                      <input type="text" class="form-control" [(ngModel)]="fields.notLookingCard.company" name="company" placeholder="Company" [disabled]="!candidate" (change)="save('notLookingCard')" />
                    </div>
                  </div>
                
                  <div class="form-group">
                    <div class="col-12 p-0">
                      <input type="text" class="form-control" [(ngModel)]="fields.notLookingCard.title" name="title" placeholder="Title" [disabled]="!candidate" (change)="save('notLookingCard')" />
                    </div>
                  </div>
                
                  <div class="form-group">
                    <div class="col-12 p-0">
                      <input type="text" class="form-control" [(ngModel)]="fields.notLookingCard.phoneNumber.number" name="phone" mask="(000) 000-0000" [showMaskTyped]="true" placeholder="Phone" [disabled]="!candidate" (change)="save('notLookingCard')" />
                    </div>
                  </div>
                
                  <div class="form-group">
                    <div class="col-12 p-0">
                      <input type="email" class="form-control" [(ngModel)]="fields.notLookingCard.email.address" name="email" placeholder="Email" [disabled]="!candidate" (change)="save('notLookingCard')" />
                    </div>
                  </div>
                
                  <div class="form-group">
                    <div class="col-12 p-0">
                      <textarea rows="3" class="form-control" [(ngModel)]="fields.notLookingCard.notes" name="notes" rows="10" placeholder="Notes" [disabled]="!candidate" (change)="save('notLookingCard')"></textarea>
                    </div>
                  </div>

                  <div class="form-group mb-3 small-text">
                    <div class="row m-0">
                      <div class="col-6 p-0">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="addToRecNot" name="addToRecNot" [(ngModel)]="fields.notLookingCard.addToRec" />
                          <label class="form-check-label" for="addToRecNot">
                          Add to Rec. Calls / DB
                          </label>
                        </div>
                      </div>
                      <div class="col-6 p-0 text-right">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="addToMktNot" name="addToMktNot" [(ngModel)]="fields.notLookingCard.addToMkt" />
                          <label class="form-check-label" for="addToMktNot">
                            Add to Mkt. Calls / DB
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
            <div class="text-center">
                <button type="button" class="btn btn-secondary" (click)="addToPlan(fields.notLookingCard)" [disabled]="!candidate || isSimulated || !validate(fields.notLookingCard)">Add to Plan</button>
            </div>
          </td>
      </tr>

      <tr><td class="ending" colspan="2"></td></tr>
  </table>
</div>

<app-footer></app-footer>