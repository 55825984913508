import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';

import { forkJoin } from 'rxjs';

import { StringsActions } from './strings.actions';
import { SearchString } from './strings.model';
import { StringsService } from './strings.service';

import { Pagination } from 'src/app/shared/models/pagination.model';

export interface SearchStringStateModel {
  strings: SearchString[];
  currentStrings: {[key: string]: SearchString};
  selected: string[];
  pagination: Pagination;
}

const defaultState: SearchStringStateModel = {
  strings: [],
  currentStrings: {},
  selected: [],
  pagination: {
    search: '',
    limit: 100,
    offset: 0,
    count: 0,
    sort: '',
    order: '',
  }
};

@State<SearchStringStateModel>({
  name: 'searchstrings',
  defaults: { ...defaultState }
})

@Injectable()
export class StringsState {

  @Selector()
  static strings(state: SearchStringStateModel): SearchString[] {
    return state.strings;
  }

  @Selector()
  static pagination(state: SearchStringStateModel): Pagination {
    return state.pagination;
  }

  @Selector()
  static currentStrings(state: SearchStringStateModel): {[key: string]: SearchString} {
    return state.currentStrings;
  }

  @Selector()
  static selected(state: SearchStringStateModel): string[] {
    return state.selected;
  }

  constructor(
    private stringsService: StringsService
  ) { }

  @Action(StringsActions.Load)
  load(context: StateContext<SearchStringStateModel>): void {
    const state = context.getState();
    const pagination = state.pagination;

    this.stringsService.list(pagination.offset, pagination.limit, pagination.sort, pagination.order).subscribe(strings => {
      context.patchState({
        strings,
        pagination: {
          ...pagination,
          count: strings.length
        }
      });
    });
  }

  @Action(StringsActions.SetOrder)
  setOrder(context: StateContext<SearchStringStateModel>,
           { payload: { sort, order } }: StringsActions.SetOrder): void {
    const state = context.getState();

    const pagination = {
      ...state.pagination,
      sort,
      order
    };

    context.patchState({
      pagination
    });

    context.dispatch(new StringsActions.Load());
  }

  @Action(StringsActions.SetPagination)
  setPagination(context: StateContext<SearchStringStateModel>,
                { payload: { limit, page } }: StringsActions.SetPagination): void {
    const state = context.getState();

    const pagination = {
      ...state.pagination,
      limit,
      page
    };

    context.patchState({
      pagination
    });

    context.dispatch(new StringsActions.Load());
  }

  @Action(StringsActions.UpdateCurrent)
  updateCurrent(context: StateContext<SearchStringStateModel>,
                { payload: { uuid, searchString } }: StringsActions.UpdateCurrent): void {
    const state = context.getState();
    const currentStrings = {
      ...state.currentStrings
    };

    currentStrings[uuid] = {
      ...currentStrings[uuid],
      ...searchString
    };

    context.patchState({
      currentStrings
    });
  }

  @Action(StringsActions.Update)
  update(context: StateContext<SearchStringStateModel>, { payload: { uuid } }: StringsActions.Update): void {
    const state = context.getState();
    const currentStrings = {
      ...state.currentStrings
    };
    const searchString = currentStrings[uuid];

    if (!searchString) {
      return;
    }

    if (!!searchString.uuid) {
      this.stringsService.update(searchString).subscribe(() => {
        this.load(context);
      });
    } else {
      searchString.order = state.strings.length;

      this.stringsService.insert(searchString).subscribe(() => {
        this.load(context);
      });
    }
  }

  @Action(StringsActions.Delete)
  delete(context: StateContext<SearchStringStateModel>, { payload: { uuid } }: StringsActions.Delete): void {
    if (uuid) {
      this.stringsService.delete(uuid).subscribe(() => {
        this.load(context);

        this.select(context, { payload: { uuid, select: false } });
      });
    } else {
      const selectedUuids = context.getState().selected;

      forkJoin(selectedUuids.map(this.stringsService.delete.bind(this.stringsService))).subscribe(() => {
        this.load(context);

        selectedUuids.forEach(selectedUuid => this.select(context, { payload: { uuid: selectedUuid, select: false } }) );
      });
    }
  }

  @Action(StringsActions.Select)
  select(context: StateContext<SearchStringStateModel>, { payload: { uuid, select } }: StringsActions.Select): void {
    const state = context.getState();
    const selected = state.selected.slice();
    const index = selected.indexOf(uuid);

    if (select) {
      if (index === -1) {
        context.patchState({
          selected: selected.concat([uuid])
        });
      }
    } else {
      if (index > -1) {
        selected.splice(index, 1);

        context.patchState({
          selected
        });
      }
    }
  }

  @Action(StringsActions.Show)
  show(context: StateContext<SearchStringStateModel>,
       { payload: { uuid } }: StringsActions.Show): void {
    const state = context.getState();
    const currentStrings = {
      ...state.currentStrings
    };

    const str = state.strings.find(strng => strng.uuid === uuid);

    if (str) {
      currentStrings[uuid] = str;
    }

    context.patchState({
      currentStrings
    });
  }

  @Action(StringsActions.Finished)
  finished(context: StateContext<SearchStringStateModel>,
           { payload: { uuid } }: StringsActions.Finished): void {
    const state = context.getState();
    const currentStrings = {
      ...state.currentStrings
    };

    delete currentStrings[uuid];

    context.patchState({
      currentStrings
    });
  }

  private array_move(arr: any[], fromIndex: number, toIndex: number): any[] {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);

    return arr;
  }

  @Action(StringsActions.UpdateOrder)
  updateOrder(context: StateContext<SearchStringStateModel>,
              { payload: { initialDragIndex, finalDragIndex } }: StringsActions.UpdateOrder): void {
    const state = context.getState();

    const stringOrders = this.array_move(state.strings, initialDragIndex, finalDragIndex);

    this.stringsService.updateOrder(stringOrders).subscribe(() => {
      context.dispatch(new StringsActions.Load());
    });
  }
}
