import { Component, OnInit } from '@angular/core';
import { Tab } from '../../shared/tabs/tabs.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.scss']
})
export class UserActivityComponent implements OnInit {
  tabs: Tab[] = [
    {name: 'Activity', isActive: true, route: 'activity'},
    {name: 'Summary', isActive: false, route: 'summary'},
  ];
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.setActiveTab();
  }

  setActiveTab(): void {
    const url = this.router.url;
    this.tabs.forEach(t => {
      t.isActive = t.route ? url.includes('/' + t.route) : false;
    });
  }


  onTabsChange(tabs: Tab[]): void {
    const activeTab = tabs.find(t => t.isActive);
    if (!activeTab) {
      return;
    }
    if (activeTab.route) {
      this.router.navigate([activeTab.route], {relativeTo: this.route});
    }
  }

}
