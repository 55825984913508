import { Directive, OnInit, ElementRef } from '@angular/core';

declare var jQuery: any;
declare var $: any;

@Directive({
  selector: '[appAutoopen]'
})
export class AutoopenDirective implements OnInit {

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    $(this.el.nativeElement).on('mouseenter', () => {
      if (this.el.nativeElement.timer) {
        clearTimeout(this.el.nativeElement.timer);
      }

      $(this.el.nativeElement).find('> .dropdown-menu').addClass('show');
    });

    $(this.el.nativeElement).on('mouseleave', () => {
      this.el.nativeElement.timer = setTimeout(() => {
        this.el.nativeElement.timer = null;

        $(this.el.nativeElement).find('> .dropdown-menu').removeClass('show');
      }, 200);
    });
  }
}
