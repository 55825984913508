import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { Observable, Subscription, BehaviorSubject, of } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';

import * as moment from 'moment';

import { User, UserType, ExperienceType } from '../../../shared/models/user.model';
import { UsersState } from '../users.state';
import { UsersActions } from '../users.actions';

import { EmailType } from '../../../shared/models/email.model';
import { PhoneType } from '../../../shared/models/phone.model';
import { Address } from '../../../shared/models/address.model';

import { AddressDialogComponent } from '../../../shared/address-dialog/address-dialog.component';
import { UserPasswordComponent } from '../user-password/user-password.component';
import { CurrentUserPasswordComponent } from '../current-user-password/current-user-password.component';
import { CommonDialogComponent } from '../../../shared/common-dialog/common-dialog.component';

import { SessionStateService } from '../../../shared/session-state.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {

  // @ts-ignore
  @Select(UsersState.users) users$: Observable<User[]>;
  // @ts-ignore
  @Select(UsersState.currentUsers) currentUsers$: Observable<{[key: string]: User}>;

  isUnique$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  current$: Observable<User | null> =
    of({
      firstName: '',
      lastName: '',
      email: '',
      username: '',
      userType: UserType.recruiter,
      account_uuid: '',
      experienceLevel: ExperienceType.rookie,
      password: '',
      active: true,
      hireDate: '',
      division: '',
      roles: ['member'],
      loginAttempts: 0,
      lockUntil: '',
      lastLogin: '',
      phoneNumbers: [{ type: PhoneType.work, number: '' }],
      addresses: [],
      memo: ''
    });

  uuid = '';

  form: FormGroup;
  formSubscription: Subscription | null = null;
  initSubscription: Subscription | null = null;

  EmailType = EmailType;
  PhoneType = PhoneType;

  addresses: Address[] = [];
  accountId = '';
  userId: string | undefined = undefined;
  isAdmin = false;
  isEditUserSuperAdmin = false;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private sessionStateService: SessionStateService,
    private store: Store
  ) {
    const session = this.sessionStateService.get();
    const roles = (session.user as User)?.roles;
    this.isAdmin = (roles.indexOf('admin') > -1) || (roles.indexOf('account-admin') > -1) || (roles.indexOf('super-user') > -1);

    this.form = this.formBuilder.group({
      userName: ['', [Validators.required]],
      password: [''],
      firstName: [''],
      lastName: [''],
      division: [''],
      email: ['', [Validators.required]],
      active: [true],
      isAccountAdmin: [false],
      isUserGuideManager: [false],
      hireDate: [''],
      userType: [UserType.recruiter],
      experienceLevel: [ExperienceType.rookie],
      memo: ['']
    });

    if (this.isAdmin) {
      this.form.get('isAccountAdmin')?.enable();
      this.form.get('isUserGuideManager')?.enable();
      this.form.get('userType')?.enable();
      this.form.get('experienceLevel')?.enable();
    } else {
      this.form.get('isAccountAdmin')?.disable();
      this.form.get('isUserGuideManager')?.disable();
      this.form.get('userType')?.disable();
      this.form.get('experienceLevel')?.disable();
    }
  }

  ngOnInit(): void {
    const session = this.sessionStateService.get();
    const roles = (session.user as User)?.roles;

    this.current$ = this.currentUsers$.pipe(map(currentUsers => {
      return currentUsers[this.uuid] || {
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        userType: UserType.recruiter,
        account_uuid: '',
        experienceLevel: ExperienceType.rookie,
        password: '',
        active: true,
        hireDate: '',
        division: '',
        roles: ['member'],
        loginAttempts: 0,
        lockUntil: '',
        lastLogin: '',
        phoneNumbers: [{ type: PhoneType.work, number: '' }],
        addresses: [],
        memo: ''
      };
    }));

    this.users$.pipe(first()).subscribe(users => {
      const unique = users.filter(userCheck => (userCheck.uuid !== this.uuid) &&
        (userCheck.username === this.f.userName.value)).length === 0;
      this.isUnique$.next(unique);
    });

    // @ts-ignore
    this.initSubscription = this.current$.subscribe((user: User | null) => {
      if (user) {
        this.userId = user.uuid;

        const hireDateMoment = moment(user.hireDate);

        this.isEditUserSuperAdmin = (user.roles.indexOf('admin') > -1);

        this.form.patchValue({
          userName: user.username,
          firstName: user.firstName,
          lastName: user.lastName,
          division: user.division,
          email: user.email,
          active: user.active,
          isAccountAdmin: ((user.roles || []).indexOf('account-admin') > -1),
          isUserGuideManager: ((user.roles || []).indexOf('user-guide-manager') > -1),
          hireDate: { year: hireDateMoment.year(), month: hireDateMoment.month() + 1, day: hireDateMoment.date() },
          userType: user.userType,
          experienceLevel: user.experienceLevel,
          memo: user.memo
        });

        this.addresses = (user.addresses || []).slice();

        if (!user.uuid) {
          this.form.get('password')?.setValidators(shortPasswordValidator());

          this.form.get('userName')?.enable();
        } else {
          this.form.get('password')?.clearValidators();
          this.form.get('password')?.clearAsyncValidators();
          this.form.get('password')?.setErrors(null);

          if (roles.indexOf('super-user') === -1) {
            this.form.get('userName')?.disable();
          }
        }

        if (this.initSubscription) {
          this.initSubscription.unsubscribe();
          this.initSubscription = null;
        }
      } else {
        this.form.get('password')?.setValidators(shortPasswordValidator());
      }

      setTimeout(() => {
        Object.values(this.form.controls).forEach(control => {
          control.markAsDirty();
        });

        this.formSubscription = this.form.valueChanges.subscribe(() => {
          this.updateMethodBackend();

          this.users$.pipe(first()).subscribe(users => {
            const unique = users.filter(userCheck => (userCheck.uuid !== this.uuid) &&
              (userCheck.username === this.f.userName.value)).length === 0;
            this.isUnique$.next(unique);
          });
        });
      }, 200);
    });
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }

    if (this.initSubscription) {
      this.initSubscription.unsubscribe();
      this.initSubscription = null;
    }
  }

  switchUserAccountAdmin(target: EventTarget | null): void {
    if (target) {
      const checkbox = (target as HTMLInputElement);

      this.store.dispatch(new UsersActions.SwitchAdminRole({ uuid: this.uuid, turn: !!checkbox.checked }));
    }
  }

  switchUserGuideManager(target: EventTarget | null): void {
    if (target) {
      const checkbox = (target as HTMLInputElement);

      this.store.dispatch(new UsersActions.SwitchUserGuideManagerRole({ uuid: this.uuid, turn: !!checkbox.checked }));
    }
  }

  updateMethodBackend(): void {
    const hireDateObj = this.f.hireDate.value;

    const user: any = {
      username: this.f.userName.value,
      password: this.f.password.value,
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      division: this.f.division.value,
      email: this.f.email.value,
      active: this.f.active.value,
      hireDate: moment([hireDateObj.year, hireDateObj.month - 1, hireDateObj.day]).toISOString(),
      userType: this.f.userType.value,
      experienceLevel: this.f.experienceLevel.value,
      memo: this.f.memo.value,
      account_uuid: this.accountId,
      roles: ['member'],
    };
    
    if ( this.f.isAccountAdmin.value) {
      user.roles.push('account-admin');
    }
    if ( this.f.isUserGuideManager.value) {
      user.roles.push('user-guide-manager');
    }
    
    if ( this.isEditUserSuperAdmin) {
      user.roles.push('admin');
    }

    this.store.dispatch(new UsersActions.UpdateCurrent({ uuid: this.uuid, user }));
  }

  save(another?: boolean): void {
    this.updateMethodBackend();

    this.activeModal.close({ another });
  }

  close(del?: boolean): void {
    if (del) {
      const modalRef = this.modalService.open(CommonDialogComponent);

      modalRef.componentInstance.closeButtonText = 'Cancel';
      modalRef.componentInstance.actionButtonText = 'Delete';
      modalRef.componentInstance.headerText = 'Delete User';
      modalRef.componentInstance.bodyText = 'Are you sure you want to delete this user?';

      modalRef.result.then((result) => {
        this.activeModal.dismiss({ delete: true });
      }).catch((error) => { });
    } else {
      this.activeModal.dismiss();
    }
  }

  addPhone(): void {
    this.store.dispatch(new UsersActions.AddPhone({ uuid: this.uuid }));
  }

  setPhoneType(index: number, select: EventTarget | null): void {
    if (select) {
      const value = ((select as HTMLSelectElement).value as unknown);
      const type: PhoneType = (value as PhoneType);

      this.store.dispatch(new UsersActions.SetPhoneType({ uuid: this.uuid, index, type }));
    }
  }

  setPhoneNumber(index: number, input: EventTarget | null): void {
    const phone = (input as HTMLInputElement).value;

    this.store.dispatch(new UsersActions.SetPhoneNumber({ uuid: this.uuid, index, phoneNumber: phone }));
  }

  removePhone(index: number): void {
    this.store.dispatch(new UsersActions.RemovePhone({ uuid: this.uuid, index }));
  }

  addAddress(): void {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      size: 'lg'
    };

    const modalRef = this.modalService.open(AddressDialogComponent, ngbModalOptions);

    modalRef.componentInstance.address = null;

    modalRef.result.then((result) => {
      this.addresses.push(result.address);
      this.store.dispatch(new UsersActions.AddAddress({ uuid: this.uuid, address: result.address }));
    }).catch((error) => { });
  }

  editAddress(index: number): void {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      size: 'lg'
    };

    const modalRef = this.modalService.open(AddressDialogComponent, ngbModalOptions);

    modalRef.componentInstance.address = this.addresses[index];

    modalRef.result.then((result) => {
      this.addresses.splice(index, 1, result.address);
      this.store.dispatch(new UsersActions.UpdateAddress({ uuid: this.uuid, address: result.address, index }));
    }).catch((error) => { });
  }

  removeAddress(index: number): void {
    this.addresses.splice(index, 1);
    this.store.dispatch(new UsersActions.RemoveAddress({ uuid: this.uuid, index }));
  }

  changePassword(): void {
    const session = this.sessionStateService.get();
    const userId = (session.user as User).uuid;

    const modalRef = this.modalService.open(userId === this.userId ? CurrentUserPasswordComponent : UserPasswordComponent);

    modalRef.result.then((result) => {
      this.store.dispatch(new UsersActions.ChangePassword({ uuid: this.uuid, oldPassword: result.oldPassword, password: result.password }));
    }).catch((error) => { });
  }

  // convenience getter for easy access to form fields
  get f(): {[key: string]: AbstractControl} { return this.form.controls; }
}

function shortPasswordValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    return (control.value.length < 6) ? { shortPassword: true } : null;
  };
}
