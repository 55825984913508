<div class="modal-header">
  <h4 class="modal-title">Add To Planner</h4>
  <button type="button" class="close" aria-hidden="true" (click)="close()">&times;</button>
</div>

<div class="modal-body add-search-to-planner">
  <ng-container *ngIf="searchItem">
    <h6>Search item data:</h6>
    <table class="search-item-data-table mb-3">
      <tr>
        <td class="search-item-data-table_title">Title:</td>
        <td class="search-item-data-table_value">{{searchItem.searchTitle}}</td>
        <td><i class="fa fa-copy" role="button" (click)="copyToClipboard(searchItem.searchTitle)"></i></td>
      </tr>
      <tr>
        <td class="search-item-data-table_title">Link:</td>
        <td class="search-item-data-table_value text-truncate">{{searchItem.link}}</td>
        <td><i class="fa fa-copy" role="button" (click)="copyToClipboard(searchItem.link)"></i></td>
      </tr>
    </table>
    <hr>
  </ng-container>
  <app-source-card
    [ownerUUID]="user?.uuid || ''"
    [withHeader]="false"
    [firstName]="searchItem?.firstName || ''"
    [lastName]="searchItem?.lastName || ''"
    [company]="searchItem?.company || ''"
    [title]="searchItem?.title || ''"
    (savingIsSucceed)="onSucceedSaving()"></app-source-card>
</div>
