import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngxs/store';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { MenuService } from './menu.service';
import { SessionStateService } from '../shared/session-state.service';

import { UsersActions } from '../admin/users/users.actions';
import { UsersService } from '../shared/users.service';
import { UserDetailComponent } from '../admin/users/user-detail/user-detail.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  menu: Menu[] = [];
  isSimulated = false;

  constructor(
    public router: Router,
    private store: Store,
    private menuService: MenuService,
    private modalService: NgbModal,
    private usersService: UsersService,
    private sessionStateService: SessionStateService
  ) { }

  ngOnInit(): void {
    this.isSimulated = !!this.sessionStateService.get().session.isSimulated;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isSimulated = !!this.sessionStateService.get().session.isSimulated;
      }
    });

    setTimeout(() => {
      this.menuService.list().subscribe((response) => {
        this.usersService.loginStatus().subscribe((sessionData) => {
          const roles = sessionData.status.roles;

          // check every menu item:
          this.menu = this.checkMenu(response, roles);
        });
      });
    },
    1000);
  }

  checkMenu(menus: Menu[], roles: string[]): Menu[] {
    for (let i = 0; i < menus.length; i++) {
      const menu = menus[i];

      const roleIntersection = roles.filter(role => menu.roles.includes(role));

      if (roleIntersection.length === 0) {
        menus.splice(i--, 1);
      } else {
        if (menu.children && menu.children.length) {
          this.checkMenu(menu.children, roles);
        }
      }
    }

    return menus;
  }

  toolsResolver(route: string): void {
    this.router.navigate([route]);
  }

  myAccount(): void {
    this.usersService.currentUser().subscribe(user => {
      this.store.dispatch(new UsersActions.ShowWithUser({ uuid: user.uuid, user }));

      const ngbModalOptions: NgbModalOptions = {
        windowClass: 'free-floating-modal',
        backdrop: false,
        size: 'lg'
      };

      const modalRef = this.modalService.open(UserDetailComponent, ngbModalOptions);
      modalRef.componentInstance.uuid = user.uuid;
      modalRef.componentInstance.accountId = user.account_uuid;

      modalRef.result.then(() => {
        this.store.dispatch(new UsersActions.Update({ uuid: user.uuid }));
      }).catch(() => { });
    });
  }
}

interface Menu {
  name: string;
  route: string;
  roles: string[];
  children: Menu[];
}
