import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServerService} from '../shared/server.service';
import {AlertService} from '../shared/alert.service';
import {SavedSearch} from './esourcing.models';
import {Observable, throwError} from 'rxjs';
import {catchError, first, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EsourcingService {

  constructor(
    private http: HttpClient,
    private serverService: ServerService,
    private alertService: AlertService) { }

  addSavedSearch(savedSearch: SavedSearch): Observable<SavedSearch> {
    return this.http.post(this.serverService.api('/saved-searches'), { savedSearch }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to insert a saved search ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  updateSavedSearch(savedSearch: SavedSearch): Observable<SavedSearch> {
    return this.http.put(this.serverService.api('/saved-searches'), { savedSearch }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to update the saved search ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  delete(uuid: string): Observable<any> {
    return this.http.delete(this.serverService.api('/saved-searches', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to delete he saved search ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  listSavedSearches(): Observable<SavedSearch[]> {
    return this.http.get(this.serverService.api('/saved-searches', 'list'), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to list saved searches ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }
}
