<app-header></app-header>
<app-toolbar></app-toolbar>

<nav class="navbar navbar-default navbar-fixed-top navbar-global navbar-expand-md w-100 bg-white" role="navigation">
  <div class="container">
    <a class="navbar-brand text-dark" href="javascript:void"><i class="fas fa-phone"></i>&nbsp;&nbsp;<b>Morgan to the Rescue</b></a>

    <div class="ml-auto">
      <button type="button" class="btn btn-primary" [disabled]="!subject || !message" (click)="send()">Send</button>
    </div>
  </div>
</nav>

<div class="container-fluid main-container">
  <form>

    <br />
    <br />

    <div class="form-group row">
        <label class="col-3 control-label text-right">To:</label>
        <div class="col-6">
            <input type="text" class="form-control" name="rescue-to" [(ngModel)]="to" readonly />
        </div>
    </div>

    <div class="form-group row">
        <label class="col-3 control-label text-right">From:</label>
        <div class="col-6">
            <input type="text" class="form-control" name="rescue-from" [(ngModel)]="from" readonly />
        </div>
    </div>

    <div class="form-group row">
      <label class="col-3 control-label text-right">Subject:</label>
      <div class="col-6">
          <input type="text" class="form-control" name="rescue-subject" [(ngModel)]="subject" required />
      </div>
    </div>

    <div class="form-group row">
        <label class="col-3 control-label text-right">Message:</label>
        <div class="col-6">
          <textarea style="height: 200px" class="col-12 form-control" name="rescue-message" [(ngModel)]="message" required appAutofocus></textarea>
        </div>
    </div>

  </form>
</div>

<app-footer></app-footer>
