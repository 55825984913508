<app-header></app-header>
<app-toolbar></app-toolbar>

<nav class="navbar navbar-default navbar-fixed-top navbar-global navbar-expand-md bg-white" role="navigation">
    <div class="container">
      <a class="navbar-brand text-dark" href="javascript:void"><b>Reference Check</b></a>
  
      <div class="ml-auto">
        <button type="button" class="btn btn-default mr-3" (click)="showExample()">View Example</button>

        <button type="button" class="btn btn-default mr-3" [disabled]="!candidate" (click)="download()">Download</button>

        <button type="button" class="btn btn-primary mr-3" [disabled]="!candidate || isSimulated" (click)="accept()">Save</button>

        <div class="btn-group">
          <button type="button" class="btn btn-light" (click)="share()" [disabled]="!candidate || !shareList.length">Share</button>
          <div class="btn-group" ngbDropdown role="group">
            <button class="btn btn-light dropdown-toggle-split" ngbDropdownToggle></button>
            <div ngbDropdownMenu class="dropdown-menu pb-0 dropdown-right" id="dropdownShareDropdown" aria-labelledby="dropdownShare">
              <label *ngFor="let user of users" class="dropdown-item">
                <input type="checkbox" class="top1px" [value]="user.email" (click)="toggleSelection(user)" /> {{user.firstName}} {{user.lastName}}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
</nav>

<div class="container-fluid form-container mt-3 position-relative">

    <div class="internal">INTERNAL USE ONLY</div>

    <table class="head-table">
      <tr>
          <td class="head1" width="50%"></td>
          <td class="head2" width="50%">CANDIDATE REFERENCE CHECK</td>
      </tr>
  </table>

  <table class="main-table">
      <tr>
        <td width="50%">Candidate: <div class="float-right"><button class="btn btn-default mr-3" [disabled]="isSimulated" (click)="newCandidate()">Add</button><select [(ngModel)]="candidate" (ngModelChange)="changedCandidate()" [compareWith]="compareCandidates"><option *ngFor="let candidate of candidates; trackBy: trackByFullName" [ngValue]="candidate">{{ candidate.firstName + ' ' + candidate.lastName }}</option></select></div></td>
        <td width="50%">Position Considered: <input type="text" class="float-right" size="20" [(ngModel)]="fields.positionConsidered" [disabled]="!candidate" (change)="save('positionConsidered')" /></td>
      </tr>
      <tr>
          <td width="50%">Reference: <input type="text" class="float-right" size="20" [(ngModel)]="fields.reference" [disabled]="!candidate" (change)="save('reference')" /></td>
          <td width="50%">Reference Title: <input type="text" class="float-right" size="20" [(ngModel)]="fields.referenceTitle" [disabled]="!candidate" (change)="save('referenceTitle')" /></td>
      </tr>
      <tr *ngIf="!!candidate">
        <td colspan="2">Employer: <input type="text" class="ml-3 mr-3" size="20" [(ngModel)]="candidate.employer" readonly /> Title: <input type="text" class="ml-3 mr-3" size="20" [(ngModel)]="candidate.title" readonly /></td>
      </tr>
      <tr>
          <td width="50%">Reference Work Phone: <input type="phone" class="float-right" size="20" [(ngModel)]="fields.workPhone" [disabled]="!candidate" mask="(000) 000-0000" [showMaskTyped]="true" (change)="save('workPhone')" /></td>
          <td width="50%">Best Availability: <input type="text" class="float-right" size="20" [(ngModel)]="fields.bestAvailability" [disabled]="!candidate" (change)="save('bestAvailability')" /></td>
      </tr>
      <tr>
          <td width="50%">Consultant: <input type="text" class="float-right" size="20" [(ngModel)]="fields.consultant" [disabled]="!candidate" (change)="save('consultant')" /></td>
          <td width="50%">Reference Home Phone: <input type="phone" class="float-right" size="20" [(ngModel)]="fields.homePhone" [disabled]="!candidate" mask="(000) 000-0000" [showMaskTyped]="true" (change)="save('homePhone')" /></td>
      </tr>
      <tr>
          <td colspan="2" class="quote">The reason for my call is that earlier today I spent about an hour and a half with an individual that worked with you in the past. Part of our evaluation is based on their relationship with those they worked for and those that worked beneath them. This candidate apparently had great respect for your leadership. I was hoping you might reciprocate some of your thoughts... lets' make it simple...</td>
      </tr>
      <tr>
          <td colspan="2"><ol start="1"><li>In one word, how would you describe {{fields.firstName}} {{fields.lastName}} ?<br /><textarea rows="1" [(ngModel)]="fields.description" [disabled]="!candidate" textarea-grow (change)="save('description')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="2"><li>Describe your business relationship. (Boss, subordinate, co-worker, supplier, etc.) (Confirm title)<br /><textarea rows="1" [(ngModel)]="fields.businessRelationship" [disabled]="!candidate" textarea-grow (change)="save('businessRelationship')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="3"><li>How would you rate {{fields.firstName}} {{fields.lastName}}'s performance as compared to others you've worked with in the same role?<br /><textarea rows="1" [(ngModel)]="fields.performance" [disabled]="!candidate" textarea-grow (change)="save('performance')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="4"><li>What would you add or subtract to {{fields.firstName}} {{fields.lastName}}'s technical expertise to perfect their performance? (turn into strengths and limitations)<br /><textarea rows="1" [(ngModel)]="fields.addSubtract" [disabled]="!candidate" textarea-grow (change)="save('addSubtract')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="5"><li>How many positions above the one {{fields.firstName}} was doing for you, would {{fields.firstName}} {{fields.lastName}} be capable of rising to?<br /><textarea rows="1" [(ngModel)]="fields.positions" [disabled]="!candidate" textarea-grow (change)="save('positions')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="6"><li>If you were building a (Marketing/Engineering/IT/...) team that was broken into tight specialty, where would you utilize {{fields.firstName}}?<br /><textarea rows="1" [(ngModel)]="fields.teamUtilize" [disabled]="!candidate" textarea-grow (change)="save('teamUtilize')"></textarea></li></ol></td>
      </tr>
      <tr *ngIf="!!candidate">
          <td colspan="2"><ol start="7"><li>What caused {{fields.firstName}} to stand out amongst other {{candidate.title}}s?<br /><textarea rows="1" [(ngModel)]="fields.standout" [disabled]="!candidate" textarea-grow (change)="save('standout')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="8"><li>Describe a personality type that best represents {{fields.firstName}}.<br /><textarea rows="1" [(ngModel)]="fields.personalityType" [disabled]="!candidate" textarea-grow (change)="save('personalityType')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="9"><li>Describe {{fields.firstName}}'s imagery. (Big 4ish, boardroom acceptable, athletic, geekish, big city, rural, wine or beer?) Does {{fields.firstName}} look like anyone we know?<br /><textarea rows="1" [(ngModel)]="fields.imagery" [disabled]="!candidate" textarea-grow (change)="save('imagery')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="10"><li>Hand pick a culture for {{fields.firstName}}.<br /><textarea rows="1" [(ngModel)]="fields.culture" [disabled]="!candidate" textarea-grow (change)="save('culture')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="11"><li>Tolerance for business stress, deadline stress, human conflict?<br /><textarea rows="1" [(ngModel)]="fields.tolerance" [disabled]="!candidate" textarea-grow (change)="save('tolerance')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="12"><li>Resilience?<br /><textarea rows="1" [(ngModel)]="fields.resilience" [disabled]="!candidate" textarea-grow (change)="save('resilience')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="13"><li>What outside influences affected his work?<br /><textarea rows="1" [(ngModel)]="fields.outsideInfluences" [disabled]="!candidate" textarea-grow (change)="save('outsideInfluences')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="14"><li>Integrity... talk to me.<br /><textarea rows="1" [(ngModel)]="fields.integrity" [disabled]="!candidate" textarea-grow (change)="save('integrity')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="15"><li>What type of contributions did {{fields.firstName}} make that had a definite impact on the company's bottom line?<br /><textarea rows="1" [(ngModel)]="fields.contributions" [disabled]="!candidate" textarea-grow (change)="save('contributions')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="16"><li>Would you say that {{fields.firstName}} {{fields.lastName}} was more comfortable working with tasks, or with people?<br /><textarea rows="1" [(ngModel)]="fields.comfortable" [disabled]="!candidate" textarea-grow (change)="save('comfortable')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="17"><li>How many people reported to {{fields.firstName}}?<br /><textarea rows="1" [(ngModel)]="fields.reportTo" [disabled]="!candidate" textarea-grow (change)="save('reportTo')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="18"><li>How would you describe {{fields.firstName}}'s management style? (Hands-on, laid-back, delegator, etc.)<br /><textarea rows="1" [(ngModel)]="fields.managementStyle" [disabled]="!candidate" textarea-grow (change)="save('managementStyle')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="19"><li>Can {{fields.firstName}} communicate technical ideas to non-technical associates?<br /><textarea rows="1" [(ngModel)]="fields.communicateIdeas" [disabled]="!candidate" textarea-grow (change)="save('communicateIdeas')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="20"><li>Would fellow co-workers consider {{fields.firstName}} to be a leader or follower?<br /><textarea rows="1" [(ngModel)]="fields.leaderFollower" [disabled]="!candidate" textarea-grow (change)="save('leaderFollower')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="21"><li>Would you consider {{fields.firstName}} to be a "lone eagle" or a team player?<br /><textarea rows="1" [(ngModel)]="fields.teamPlayer" [disabled]="!candidate" textarea-grow (change)="save('teamPlayer')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="22"><li>(If not yet met) Describe the first impression {{fields.firstName}} makes.<br /><textarea rows="1" [(ngModel)]="fields.firstImpression" [disabled]="!candidate" textarea-grow (change)="save('firstImpression')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="23"><li>How did {{fields.firstName}} {{fields.lastName}} come to leave your firm?<br /><textarea rows="1" [(ngModel)]="fields.whyLeaveFirm" [disabled]="!candidate" textarea-grow (change)="save('whyLeaveFirm')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="24"><li>If you put {{fields.firstName}} {{fields.lastName}} in the perfect job, given his/her capabilities, what job would that be?<br /><textarea rows="1" [(ngModel)]="fields.perfectJob" [disabled]="!candidate" textarea-grow (change)="save('perfectJob')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="25"><li>Describe, if you would, the workplace culture where {{fields.firstName}} {{fields.lastName}} would thrive.<br /><textarea rows="1" [(ngModel)]="fields.workplaceCulture" [disabled]="!candidate" textarea-grow (change)="save('workplaceCulture')"></textarea></li></ol></td>
      </tr>
      <tr>
          <td colspan="2"><ol start="26"><li>Is there anything I should know that we've failed to cover?<br /><textarea rows="1" [(ngModel)]="fields.anythingElse" [disabled]="!candidate" textarea-grow (change)="save('anythingElse')"></textarea></li></ol></td>
      </tr>
      <tr><td class="ending" colspan="2"></td></tr>
  </table>
</div>

<app-footer></app-footer>
  