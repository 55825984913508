import {Candidate} from './candidate.model';
import {Email, EmailType} from '../../shared/models/email.model';
import {Phone, PhoneType} from '../../shared/models/phone.model';
import {MapField} from '../../shared/map-columns/map-columns.consts';

export const CANDIDATES_DESTINATION_FIELDS: MapField[] = [
  new MapField({name: 'firstName', isRequired: true}),
  new MapField({name: 'lastName'}),
  new MapField({name: 'title'}),
  new MapField({name: 'employer'}),
  new MapField({name: 'location'}),
  new MapField({name: 'address2'}),
  new MapField({name: 'state'}),
  new MapField({name: 'city'}),
  new MapField({name: 'zip'}),
  new MapField({name: 'country'}),
  new MapField({name: 'skills'}),
  new MapField({name: 'positions'}),
  new MapField({name: 'memo'}),
  new MapField({
    name: 'workEmail',
    isMultiple: true,
    customSetter: (c: Candidate, value: string) => {
      if (!c.emails) {
        c.emails = [];
      }
      const email: Email = {
        type: EmailType.work,
        address: value
      };
      c.emails.push(email);
      return c;
    }}
  ),
  new MapField({
    name: 'homeEmail',
    isMultiple: true,
    customSetter: (c: Candidate, value: string) => {
      if (!c.emails) {
        c.emails = [];
      }
      const email: Email = {
        type: EmailType.home,
        address: value
      };
      c.emails.push(email);
      return c;
    }}
  ),
  new MapField({
    name: 'otherEmail',
    isMultiple: true,
    customSetter: (c: Candidate, value: string) => {
      if (!c.emails) {
        c.emails = [];
      }
      const email: Email = {
        type: EmailType.other,
        address: value
      };
      c.emails.push(email);
      return c;
    }}
  ),
  new MapField({
    name: 'homePhone',
    isMultiple: true,
    customSetter: (c: Candidate, value: string) => {
      if (!c.phoneNumbers) {
        c.phoneNumbers = [];
      }
      const phoneNumber: Phone = {
        type: PhoneType.home,
        number: value
      };
      c.phoneNumbers.push(phoneNumber);
      return c;
    }}
  ),
  new MapField({
    name: 'workPhone',
    isMultiple: true,
    customSetter: (c: Candidate, value: string) => {
      if (!c.phoneNumbers) {
        c.phoneNumbers = [];
      }
      const phoneNumber: Phone = {
        type: PhoneType.work,
        number: value
      };
      c.phoneNumbers.push(phoneNumber);
      return c;
    }}
  ),
  new MapField({
    name: 'otherPhone',
    isMultiple: true,
    customSetter: (c: Candidate, value: string) => {
      if (!c.phoneNumbers) {
        c.phoneNumbers = [];
      }
      const phoneNumber: Phone = {
        type: PhoneType.other,
        number: value
      };
      c.phoneNumbers.push(phoneNumber);
      return c;
    }}
  ),
  new MapField({name: 'photo'}),
  new MapField({name: 'resume'}),
];


export const BULLHORN_CANDIDATES_FIELDS_MAP = {
  firstName: 'firstName',
  lastName: 'lastName',
  companyName: 'employer',
  'address.address1': 'location',
  'address.address2': 'address2',
  'address.State': 'state',
  'address.City': 'city',
  'address.Zip': 'zip',
  'address.CountryName': 'country',
  skillSet: 'skills',
  occupation: 'positions',
  description: 'memo',
  email: 'workEmail',
  email2: 'otherEmail',
  email3: 'otherEmail',
  workPhone: 'workPhone',
  phone: 'otherPhone',
  phone2: 'otherPhone',
  phone3: 'otherPhone',
};


export const LOXO_CANDIDATES_FIELDS_MAP = {
  name: 'firstName',
  company: 'employer',
  location: 'location',
  address: 'address2',
  city: 'city',
  country: 'country',
  state: 'state',
  zip: 'zip',
  email: 'otherEmail',
  personalEmail: 'homeEmail',
  workEmail: 'workEmail',
  phone: 'otherPhone',
  personalPhone: 'homePhone',
  workPhone: 'workPhone',
  title: 'positions',
};

export const WORKABLE_CANDIDATES_FIELDS_MAP = {
  address: 'location',
  email: 'workEmail',
  experience: 'description',
  job: 'title',
  name: 'firstName',
  phone: 'workPhone',
  skills: 'skills',
};


export const JAZZHR_CANDIDATES_FIELDS_MAP = {
  addressLine1: 'location',
  addressLine2: 'address2',
  city: 'city',
  state: 'state',
  zIPCode: 'zip',
  firstName: 'firstName',
  lastName: 'lastName',
  homeEmail: 'homeEmail',
  homePhone: 'homePhone',
  jobTitle: 'title'
};

export const PC_RECRUITER_CANDIDATES_FIELDS_MAP = {
  'address 1': 'location',
  'address 2': 'address2',
  city: 'city',
  'postal/zip': 'zip',
  state: 'state',
  'work phone': 'workPhone',
  'cell phone': 'otherPhone',
  'home phone': 'homePhone',
  'company name': 'employer',
  'email address': 'workEmail',
  title: 'positions',
  name: 'firstName',
};


export const CRELATE_CANDIDATES_FIELDS_MAP = {
  addressesCityHome: 'city',
  addressesCountryHome: 'country',
  addressesStateHome: 'state',
  addressesStreetAddressHome: 'location',
  addressesZipCodeHome: 'zip',
  currentPositionCompany: 'employer',
  currentPositionJobTitle: 'title',
  educationDegree: 'skills',
  emailAddressesOther: 'otherEmail',
  emailAddressesPersonal: 'homeEmail',
  emailAddressesWork: 'workEmail',
  firstName: 'firstName',
  lastName: 'lastName',
  lastReachOutBy: 'memo',
  phoneNumbersExtensionHome: 'homePhone',
  phoneNumbersExtensionMobile: 'workPhone',
  phoneNumbersExtensionOther: 'otherPhone',
  phoneNumbersExtensionSkype: 'otherPhone',
  phoneNumbersExtensionWorkDirect: 'workPhone',
  phoneNumbersExtensionWorkMain: 'workPhone',
  phoneNumbersExtensionWorkOther: 'workPhone',
  phoneNumbersFax: 'otherPhone',
  phoneNumbersHome: 'homePhone',
  phoneNumbersMobile: 'workPhone',
  phoneNumbersMobileOther: 'workPhone',
  phoneNumbersOther: 'otherPhone',
  phoneNumbersSkype: 'otherPhone',
  phoneNumbersWorkDirect: 'workPhone',
  phoneNumbersWorkMain: 'workPhone',
  phoneNumbersWorkOther: 'workPhone',
};
