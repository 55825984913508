import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '../../../../shared/users.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivityReportService } from '../../activity-report.service';
import { MonthReport, UserActivityReport } from '../../activity-report.model';
import * as moment from 'moment';
import { Columns } from 'ngx-easy-table/lib';
import { DefaultConfig } from 'ngx-easy-table';
import { NO_DATA_MONTH_KEY } from '../../activity-report.const';
import { PageSpinnerService } from '../../../../shared/services/page-spinner.service';
import { finalize } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-user-activity-report',
  templateUrl: './user-activity-report.component.html',
  styleUrls: ['./user-activity-report.component.scss']
})
export class UserActivityReportComponent implements OnInit, AfterViewInit {
  @ViewChild('sessionDurationTpl', { static: true }) sessionDurationTpl?: TemplateRef<any>;
  @ViewChild('innerViewsCountTpl', { static: true }) innerViewsCountTpl?: TemplateRef<any>;
  @ViewChild('searchAssignmentCountTpl', { static: true }) searchAssignmentCountTpl?: TemplateRef<any>;
  @ViewChild('candidateReferenceCountTpl', { static: true }) candidateReferenceCountTpl?: TemplateRef<any>;

  username?: string;
  report?: UserActivityReport;
  tableData: {key: string, value: MonthReport}[] = [];
  columns: Columns[] = [];
  public configuration = {
    ...DefaultConfig,
    paginationEnabled: false,
    rows: 1000 // to prevent pagination layout
  };

  constructor(
    private route: ActivatedRoute,
    private usersService: UsersService,
    private activityReportService: ActivityReportService,
    public pageSpinnerService: PageSpinnerService,
  ) { }

  ngOnInit(): void {
    const userId = this.route.snapshot.paramMap.get('id') || '';
    this.getReport(userId);
  }

  ngAfterViewInit(): void {
    this.setColumns();
  }

  setColumns(): void {
    this.columns = [
      { key: 'key', title: 'Month', width: '20%' },
      { key: 'value', title: 'Session Duration', cellTemplate: this.sessionDurationTpl, orderEnabled: false,  width: '20%' },
      { key: 'value', title: 'Number of InnerViews', cellTemplate: this.innerViewsCountTpl, orderEnabled: false, width: '20%' },
      { key: 'value', title: 'Number of Job Orders', cellTemplate: this.searchAssignmentCountTpl, orderEnabled: false, width: '20%' },
      { key: 'value', title: 'Number of Reference Checks', cellTemplate: this.candidateReferenceCountTpl, orderEnabled: false, width: '20%' },
    ];
  }
  getReport(userId: string): void {
    this.pageSpinnerService.startLoading();
    this.activityReportService.getUserActivityReport(userId)
      .pipe(untilDestroyed(this), finalize(() => this.pageSpinnerService.stopLoading()))
      .subscribe(report => {
        this.report = report;
        const user = report.user;
        this.username = `${user.firstName} ${user.lastName} (${user.username})`;
        this.setTableData(report);
      });
  }

  setTableData(report: UserActivityReport): void {
    const tableData: {key: string, value: MonthReport}[] = [];
    Object.keys(report.reportByMonth).forEach(monthKey => {
      let dateString = '';
      if (monthKey === NO_DATA_MONTH_KEY) {
        dateString = 'Earlier';
        const tableObj = {
          key: dateString,
          value: report.reportByMonth[monthKey],
        };
        tableData.unshift(tableObj);
      } else {
        const monthNumber = monthKey.split('-')[0];
        const year = monthKey.split('-')[1];
        const monthName = moment(monthNumber, 'M').format('MMMM');
        dateString = monthName + ' ' + year;
        const tableObj = {
          key: dateString,
          value: report.reportByMonth[monthKey],
        };
        tableData.push(tableObj);
      }
    });
    this.tableData = tableData;
    this.configuration.rows = tableData?.length;
  }
}
