<div class="modal-header">
    <h4 class="modal-title">Save Search</h4>
    <button type="button" class="close" aria-hidden="true" (click)="close()">&times;</button>
  </div>
  <div class="modal-body">
    <form [formGroup]="nameForm">
      <div class="container">

        <div class="form-group row">
          <label for="username" class="col-sm-3 col-form-label">Search Name</label>
          <div class="col-sm-9">
            <input type="text" name="searchname" id="searchname" class="form-control" placeholder="Search Name" formControlName="searchname" appAutofocus>
          </div>
        </div>

      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="submit"
      class="btn btn-primary"
      (click)="save()"
      [disabled]="!nameForm.valid">Save New Search</button>
    <button
      type="button"
      class="btn btn-default"
      (click)="close()">Cancel</button>
  </div>