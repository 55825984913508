import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ServerService } from '../shared/server.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    private http: HttpClient,
    private serverService: ServerService) { }

  list(): Observable<any> {
    return this.http.get(this.serverService.api('/menus'), this.serverService.apiConfig())
      .pipe(first());
  }
}
