import { EmailType } from 'src/app/shared/models/email.model';
import { PhoneType } from 'src/app/shared/models/phone.model';
import { Address } from 'src/app/shared/models/address.model';

export namespace AccountsActions {
  export class Load {
    static readonly type = 'AccountsActions.Load';
  }

  export class SetOrder {
    static readonly type = 'AccountsActions.SetOrder';
    constructor(public payload: {
      sort: string,
      order: string
    } ) {}
  }

  export class SetPagination {
    static readonly type = 'AccountsActions.SetPagination';
    constructor(public payload: {
      page: number,
      limit: number
    } ) {}
  }

  export class Search {
    static readonly type = 'AccountsActions.Search';
    constructor(public payload: {
      account: any
    } ) {}
  }

  export class UpdateCurrent {
    static readonly type = 'AccountsActions.UpdateCurrent';
    constructor(public payload: {
      uuid: string,
      account: any
    } ) {}
  }

  export class Update {
    static readonly type = 'AccountsActions.Update';
    constructor(public payload: {
      uuid: string
    } ) {}
  }

  export class Delete {
    static readonly type = 'AccountsActions.Delete';
    constructor(public payload: {
      uuid: string | null
    } ) {}
  }

  export class Select {
    static readonly type = 'AccountsActions.Select';
    constructor(public payload: {
      uuid: string,
      select: boolean
    } ) {}
  }

  export class UnselectAll {
    static readonly type = 'AccountsActions.UnselectAll';
  }

  export class AddEmail {
    static readonly type = 'AccountsActions.AddEmail';
    constructor(public payload: {
      uuid: string
    } ) {}
  }

  export class SetEmailType {
    static readonly type = 'AccountsActions.SetEmailType';
    constructor(public payload: {
      uuid: string,
      index: number,
      type: EmailType
    } ) {}
  }

  export class SetEmailAddress {
    static readonly type = 'AccountsActions.SetEmailAddress';
    constructor(public payload: {
      uuid: string,
      index: number,
      address: string
    } ) {}
  }

  export class RemoveEmail {
    static readonly type = 'AccountsActions.RemoveEmail';
    constructor(public payload: {
      uuid: string,
      index: number
    } ) {}
  }

  export class AddPhone {
    static readonly type = 'AccountsActions.AddPhone';
    constructor(public payload: {
      uuid: string
    } ) {}
  }

  export class SetPhoneType {
    static readonly type = 'AccountsActions.SetPhoneType';
    constructor(public payload: {
      uuid: string,
      index: number,
      type: PhoneType
    } ) {}
  }

  export class SetPhoneNumber {
    static readonly type = 'AccountsActions.SetPhoneNumber';
    constructor(public payload: {
      uuid: string,
      index: number,
      phoneNumber: string
    } ) {}
  }

  export class RemovePhone {
    static readonly type = 'AccountsActions.RemovePhone';
    constructor(public payload: {
      uuid: string,
      index: number
    } ) {}
  }

  export class AddAddress {
    static readonly type = 'AccountsActions.AddAddress';
    constructor(public payload: {
      uuid: string,
      address: Address
    } ) {}
  }

  export class UpdateAddress {
    static readonly type = 'AccountsActions.UpdateAddress';
    constructor(public payload: {
      uuid: string,
      address: Address,
      index: number
    } ) {}
  }

  export class RemoveAddress {
    static readonly type = 'AccountsActions.RemoveAddress';
    constructor(public payload: {
      uuid: string,
      index: number
    } ) {}
  }

  export class Show {
    static readonly type = 'AccountsActions.Show';
    constructor(public payload: {
      uuid: string
    } ) {}
  }

  export class Finished {
    static readonly type = 'AccountsActions.Finished';
    constructor(public payload: {
      uuid: string
    } ) {}
  }
}
