<app-page-title class="report-page-title" [pageTitle]="'User Activity Report' + (username ? (': ' + username) : '')" [iconClass]="'fa-chart-area'">
  <app-go-back-btn before></app-go-back-btn>
  <app-download-report-pdf
    after
    [fileName]="username + ' Activity Report.pdf'"
    [containerId]="'userReportsContainer'">
  </app-download-report-pdf>
</app-page-title>

<div class="container-fluid main-container report-container" id="userReportsContainer">

  <ng-container  *ngIf="tableData?.length; else noData">
    <ngx-table
      class="ngx-table__row-pointer"
      [data]="tableData"
      [configuration]="configuration"
      [columns]="columns">
    </ngx-table>
  </ng-container>

</div>

<ng-template #sessionDurationTpl let-row>
  <ng-container >
    <span>{{row.value?.sessionDuration ? (row.value?.sessionDuration | timestampToDuration) : '0 minutes'}} </span>
  </ng-container>
</ng-template>



<ng-template #innerViewsCountTpl let-row>
  <ng-container >
    <span>{{row.value?.innerViewsCount || 0}} </span>
  </ng-container>
</ng-template>


<ng-template #searchAssignmentCountTpl let-row>
  <ng-container >
    <span>{{row.value?.searchAssignmentCount || 0}} </span>
  </ng-container>
</ng-template>


<ng-template #candidateReferenceCountTpl let-row>
  <ng-container >
    <span>{{row.value?.candidateReferenceCount || 0}} </span>
  </ng-container>
</ng-template>

<ng-template #noData>
  <app-no-data *ngIf="!pageSpinnerService.isLoading" [text]="'There are no records'"></app-no-data>
</ng-template>
