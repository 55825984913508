import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';

import { AlertService } from './alert.service';
import { ServerService } from './server.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(
    private http: HttpClient,
    private serverService: ServerService,
    private alertService: AlertService) { }

  list(path: string): Observable<any> {
    return this.http.get(this.serverService.api('/content/list', path), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to list a content at path ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  root(): Observable<any> {
    return this.http.get(this.serverService.api('/content/root'), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to list a root content at path ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  lookup(path: string): Observable<any> {
    const url = this.serverService.api('/content/lookup', path);

    return this.http.get(url, this.serverService.apiConfig())
      .pipe(first()/*, catchError(() => {
        // this.alertService.error('Failed to lookup a content path ' + JSON.stringify(error.data));
        // return throwError(error);
      })*/, map((response: any) => {
        return response;
      }));
  }

  insert(content: any): Observable<any> {
    return this.http.post(this.serverService.api('/content/create'), { content }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to insert a content ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  update(content: any): Observable<any> {
    return this.http.put(this.serverService.api('/uontent'), { content }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to update a content ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  remove(path: string): Observable<any> {
    return this.http.delete(this.serverService.api('/content', path), this.serverService.apiConfig())
    .pipe(first(), catchError((error) => {
      this.alertService.error('Failed to remove a content ' + JSON.stringify(error.data));
      return throwError(error);
    }), map((response: any) => {
      return response;
    }));
  }

  userInsert(content: any): Observable<any> {
    return this.http.post(this.serverService.api('/ucontent/create'), { content }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to insert a user content ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  userUpdate(content: any): Observable<any> {
    return this.http.put(this.serverService.api('/ucontent'), { content }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to update a user content ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  upload(url: string, formData: FormData): Observable<any> {
    return this.http.post(url, formData, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to upload ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  download(path: string): Observable<any> {
    const url = this.serverService.api('/content/download', path);

    return this.http.get(url, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to download a path ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  getDownloadPath(path: string): string {
    return this.serverService.api('/content/download', path);
  }

  getUploadPath(): string {
      return this.serverService.api('/content/upload');
  }

  getUserUploadPath(): string {
    return this.serverService.api('/ucontent/upload');
  }

  uuid(): string {
    /* tslint:disable */
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0;
      const v = (c === 'x') ? r : (r & 0x3 | 0x8);
      return v.toString(16);
   });
   /* tslint:enable */
  }
}
