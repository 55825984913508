<div class="modal-header">
    <h4 class="modal-title">Login</h4>
    <button type="button" class="close" aria-hidden="true" (click)="close()">&times;</button>
</div>
<form [formGroup]="loginForm" (ngSubmit)="signin()">
    <div class="modal-body">
      <div class="container">

        <div class="form-group row">
          <label for="username" class="col-sm-3 col-form-label">User Name</label>
          <div class="col-sm-9">
            <input type="text" name="username" id="username" class="form-control" placeholder="User Name" formControlName="username" appAutofocus>
          </div>
        </div>

        <div class="form-group row">
          <label for="password" class="col-sm-3 col-form-label">Password</label>
          <div class="col-sm-9">
            <input type="password" name="password" id="password" class="form-control" placeholder="Password" formControlName="password">
          </div>
        </div>

      </div>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary mr-auto"
        (click)="logoutOthers()"
        [hidden]="!signedInOthers">Log Out on Other Devices</button>
      <button
        type="submit"
        class="btn btn-secondary"
        [disabled]="!loginForm.valid">Sign In</button>
      <button
        type="button"
        class="btn btn-default"
        (click)="close()">Cancel</button>
    </div>
</form>
