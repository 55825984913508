import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {COUNTRIES} from '../../../assets/data/countries';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {LocationAddress} from '../models/location-address.model';


@UntilDestroy()
@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit {
  @Input() set initialValue(v: LocationAddress) {
    if (!this.form) {
      this.initForm();
      if (this.form) {
        (this.form as FormGroup).patchValue(v);
      }
    }
  }
  @Input() isSimulated = false;

  @Output() onAddToPlanClick = new EventEmitter<void>();
  @Output() formValueChanged = new EventEmitter<LocationAddress>();

  form?: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    if (!this.form){
      this.initForm();
    }
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      location: [''],
      address2: [''],
      state: [''],
      city: [''],
      zip: [''],
      country: [''],
    });

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(v => {
      this.formValueChanged.emit(v);
    });
  }

  public searchCountry$ = (text$: Observable<string>) =>
    text$.pipe(
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : COUNTRIES.filter(c => c.name.toLowerCase().indexOf(term.toLowerCase()) > -1).map(c => c.name).slice(0, 10))
    )

  addToPlanClick(): void {
    this.onAddToPlanClick.emit();
  }

}
