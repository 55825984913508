<app-header></app-header>
<app-toolbar></app-toolbar>

<nav class="navbar navbar-default navbar-fixed-top navbar-global navbar-expand-md w-100 bg-white" role="navigation">
  <div class="container">
    <a class="navbar-brand text-dark" href="javascript:void"><i class="fas fa-users"></i>&nbsp;&nbsp;<b>Accounts</b></a>

    <div class="ml-auto" *ngIf="selected$ | async as selected">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Search" #accountsrch (input)="search($event.target)" />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" (click)="search(null); accountsrch.value = '';"><i class="fas fa-times-circle"></i></button>
          <button class="btn btn-outline-secondary" type="submit" (click)="search(accountsrch)"><i class="fas fa-search"></i></button>
        </div>

        <button type="button" class="btn btn-secondary mr-3 ml-3" (click)="add()" placement="bottom" ngbTooltip="Create Account"><i class="fas fa-user-plus"></i></button>
        <button type="button" class="btn btn-danger-inversed mr-3" (click)="delete()" [disabled]="!selected.length" placement="bottom" ngbTooltip="Delete Account(s)"><i class="fas fa-trash"></i></button>

        <div ngbDropdown class="d-inline-block">
          <button class="btn btn-secondary dropdown-toggle" type="button" ngbDropdownToggle id="dropdownReports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Reports
          </button>
          <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownReports">
            <a class="dropdown-item" href="javascript:void(0)" (click)="reportAccounts()">on Accounts</a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="reportUsers()">on Users</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<br />

<div class="container-fluid main-container" *ngIf="{ accounts: accounts$ | async, pagination: pagination$ | async } as data">
  <ngx-table
    [data]="data.accounts || []"
    [configuration]="configuration"
    [columns]="columns"
    [pagination]="data.pagination || { limit: 100, offset: 0, count: (data.accounts || []).length }"
    (event)="eventEmitted($event)"></ngx-table>
</div>

<app-footer></app-footer>

<ng-template #columnActionTemplate>
  <button class="text-nowrap" (click)="hideColumn($event)">Hide Column</button>
</ng-template>

<ng-template #nameTpl let-row>
  <span>{{row.name}}</span>
</ng-template>

<ng-template #seatsTpl let-row>
  <span>{{row.subscription.seatsUsed}} / {{row.subscription.totalSeats}}</span>
</ng-template>

<ng-template #subscriptionEndTpl let-row>
  <span>{{row.subscription.endDate | date:'MM/dd/yyyy'}}</span>
</ng-template>

<ng-template #activeTpl let-row>
  <span>{{row.active ? 'Yes' : 'No'}}</span>
</ng-template>

<ng-template #usersTpl let-row>
  <span class="link"><a [routerLink]="['/admin/users/', row.uuid]">Users</a></span>
</ng-template>

<ng-template #checkboxTpl let-row let-column="column">
  <div class="form-group">
    <label class="form-checkbox">
      <input type="checkbox" (change)="onChange(row, $event.target)" />
    </label>
  </div>
</ng-template>