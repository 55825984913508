import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';

import { ServerService } from '../shared/server.service';
import { AlertService } from '../shared/alert.service';

@Injectable({
  providedIn: 'root'
})
export class EguideService {

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private serverService: ServerService) { }

  listByMorgan(): Observable<any> {
    return this.http.get(this.serverService.api('/guides'), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to lookup Morgan guides ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  lookupByUser(name: string, owner: string): Observable<any> {
    return this.http.get(this.serverService.api('/uguides', name, owner), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        // this.alertService.error('Failed to lookup guide by user ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response.guide;
      }));
  }

  lookupByMorgan(name: string): Observable<any> {
    return this.http.get(this.serverService.api('/guides', name), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to lookup Morgan guide ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response.guide;
      }));
  }

  lookupByUuid(uuid: string): Observable<any> {
    return this.http.get(this.serverService.api('/uguides', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        // this.alertService.error('Failed to lookup user guide ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response.guide;
      }));
  }

  upsertByUser(rawguide: any, username: string, accountuuid: string): Observable<any> {
    const { _id, __v, ...guide } = rawguide;

    guide.owner = username;
    guide.account = accountuuid;

    return this.http.put(this.serverService.api('/uguides'), { guide }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to update User Guide ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response.guide;
      }));
  }

  upsertByMorgan(rawguide: any): Observable<any> {
    const { _id, __v, ...guide } = rawguide;

    return this.http.put(this.serverService.api('/guides'), { guide }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to update Morgan Guide ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response.guide;
      }));
  }

  approve(uuid: string): Observable<any> {
    return this.http.put(this.serverService.api('/uguides/approve', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to approve User Guide ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  reject(uuid: string): Observable<any> {
    return this.http.put(this.serverService.api('/uguides/reject', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to reject User Guide ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }
}
