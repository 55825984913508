import { Directive, OnInit, ElementRef, HostListener, Input } from '@angular/core';

declare var jQuery: any;
declare var $: any;

@Directive({
  selector: '[appFollowScroll]'
})
export class FollowScrollDirective implements OnInit {

  @Input() appFollowScroll: string;

  private linkedElement: Element | null = null;

  constructor(private el: ElementRef) { this.appFollowScroll = ''; }

  ngOnInit(): void {
    const expandoName = 'userScroll';

    this.linkedElement = $('#' + this.appFollowScroll).get(0);

    this.el.nativeElement?.addEventListener('scroll', (event: Event) => {
      // @ts-ignore
      if (this.linkedElement && this.linkedElement[expandoName] === true) {
        return;
      }

      this.el.nativeElement[expandoName] = true;

      if (this.linkedElement) {
        this.linkedElement.scrollTop = this.el.nativeElement.scrollTop;
      }

      setTimeout(() => {
        this.el.nativeElement[expandoName] = false;
      }, 100);
    }, false);
  }
}
