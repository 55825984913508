<div class="modal-header">
    <h4 class="modal-title">Position Title</h4>
    <button type="button" class="close" aria-hidden="true" (click)="close()">&times;</button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="container">

        <div class="form-group row">
          <label for="username" class="col-sm-3 col-form-label">Position Title</label>
          <div class="col-sm-9">
            <input type="text" name="position" id="position" class="form-control" placeholder="Position" formControlName="position" appAutofocus>
          </div>
        </div>

      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="submit"
      class="btn btn-secondary"
      (click)="save()"
      [disabled]="!form.valid">Save</button>
    <button
      type="button"
      class="btn btn-default"
      (click)="close()">Cancel</button>
  </div>