import { Directive, OnInit, ElementRef } from '@angular/core';

declare var jQuery: any;
declare var $: any;

@Directive({
  selector: '[appFocusNewInput]'
})
export class FocusNewInputDirective implements OnInit {

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    const element = $(this.el.nativeElement);

    element.on('click', () => {
      setTimeout(() => {
        element.closest('li').prev().find('input').focus();
      },
      100);
    });
  }
}
