import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { LoginService } from '../../login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginDialogComponent implements OnInit {

  loginForm: FormGroup;
  signedInOthers = false;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private loginService: LoginService
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
  }

  logoutOthers(): void {
    this.loginService.logoutOther(this.loginForm.get('username')?.value, this.loginForm.get('password')?.value).subscribe(() => {
      this.signedInOthers = false;
    });
  }

  signin(): void {
    if (!this.loginForm.valid) {
      return;
    }

    this.loginService.login(this.loginForm.get('username')?.value, this.loginForm.get('password')?.value).subscribe((result) => {
      if (!!result) {
        this.activeModal.close();

        this.loginService.loginStatus();
      }
    }, (err) => {
      if (err.error === 'User is already logged on to another device') {
        this.signedInOthers = true;
      }
    });
  }

  close(): void {
    this.activeModal.dismiss();
  }

  // convenience getter for easy access to form fields
  get f(): {[key: string]: AbstractControl} { return this.loginForm.controls; }
}
