import { Component, Input, OnInit } from '@angular/core';
import { jsPDF } from 'jspdf';
import { AlertService } from '../../../../shared/alert.service';

@Component({
  selector: 'app-download-report-pdf',
  templateUrl: './download-report-pdf.component.html',
  styleUrls: ['./download-report-pdf.component.scss']
})
export class DownloadReportPdfComponent implements OnInit {
  @Input() containerId?: string;
  @Input() fileName = 'Report.pdf';
  isLoading = false;

  constructor(private alertService: AlertService) { }

  ngOnInit(): void {
  }

  downloadPdf(): void {
    this.isLoading = true;
    if (!this.containerId) {
      this.showError();
      return;
    }
    const jsPdfObj = new jsPDF({ unit: 'px', hotfixes: ['px_scaling'] });
    const container = document.getElementById(this.containerId);
    if (!container) {
      this.showError();
      return;
    }
    try {
      const pdf = jsPdfObj.html(container, {
        x: 0,
        y: 0,
        width: 794,
        windowWidth: 1500,
        callback: (doc) => {
          this.isLoading = false;
        },
      });

      pdf.save(this.fileName);
    } catch {
      this.showError();
    }
  }
  
  showError(text?: string): void {
    if (!text) {
      text = 'Failed to download the PDF file. Try again later';
    }
    this.alertService.error(text);
    this.isLoading = false;
  }

}
