import {Component, EventEmitter, Input, Output} from '@angular/core';
export interface  ChecklistItem {
  name: string;
  checked: boolean;
  propKey: string;
}

export interface ChecklistCategory {
  name: string;
  checked: boolean;
  items: ChecklistItem[];
}

@Component({
  selector: 'app-nested-checklist',
  templateUrl: './nested-checklist.component.html',
  styleUrls: ['./nested-checklist.component.scss']
})
export class NestedChecklistComponent  {
  @Input() categories?: ChecklistCategory[];

  @Output() selectionChanged = new EventEmitter<ChecklistCategory[]>();

  onCategoryCheckboxChange(category: ChecklistCategory): void {
    const checked = category.checked;
    category.items.forEach(i => i.checked = checked);
    this.emitChecklist();
  }

  onItemCheckboxChange(category: ChecklistCategory, itemChecked: boolean): void {
    const allItemsChecked = category.items.every(i => i.checked === itemChecked);
    if (allItemsChecked) {
      category.checked = itemChecked;
    }
    this.emitChecklist();
  }

  emitChecklist(): void {
    this.selectionChanged.emit(this.categories);
  }
}
