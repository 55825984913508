<div class="filters">
  <div class="filter">
    <label>Search</label>
    <input
      class="form-control"
      [(ngModel)]="filters.search"
      (input)="onSearchChange()"
      autocomplete="off"
      placeholder="Search by user name, email, or account"
    />
  </div>

  <div class="filter">
    <label>Date range</label>
    <div class="input-group">
      <input
        #dateRangeInput
        class="form-control date-range-input"
        [owlDateTime]="dt1"
        [owlDateTimeTrigger]="dt1"
        [selectMode]="'range'"
        (dateTimeChange)="onDateRangeChange($event)"
        placeholder="Date range">
      <i class="fa fa-times clear-btn" ngbTooltip="Clear" (click)="onDateRangeClearClick(dateRangeInput, dt1)"></i>
    </div>

    <owl-date-time #dt1 [firstDayOfWeek]="1" [hour12Timer]="true" ></owl-date-time>
  </div>

</div>
<hr>

<ng-container *ngIf="userActivity$ | async as data">
  <ngx-table [data]="data" [configuration]="configuration" [columns]="columns"></ngx-table>
</ng-container>

<ng-template #loggedInAt let-row>
  <span>{{row.loggedInAt | date: 'medium'}}</span>
</ng-template>

<ng-template #loggedOutAt let-row>
  <span>{{row.loggedOutAt | date: 'medium'}}</span>
</ng-template>

<ng-template #sessionDuration let-row>
  <ng-container *ngIf="row.loggedInAt && row.loggedOutAt">
    <span>{{row.loggedInAt | dateDifference: row.loggedOutAt}} </span>
  </ng-container>
</ng-template>

