import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';

import { AlertService } from '../../shared/alert.service';
import { ServerService } from '../../shared/server.service';

import { SearchString } from './strings.model';

@Injectable({
  providedIn: 'root'
})
export class StringsService {

  constructor(
    private http: HttpClient,
    private serverService: ServerService,
    private alertService: AlertService) { }

  list(offset?: number, limit?: number, column?: string, order?: string): Observable<any> {
    const options = [];

    if (offset) {
      options.push('offset=' + offset);
    }

    if (limit) {
      options.push('limit=' + limit);
    }

    if (column) {
      options.push('column=' + column);
    }

    if (order) {
      options.push('order=' + order);
    }

    return this.http.get(this.serverService.api('/searchstrings/list') +
      (options.length ? '?' : '') + options.join('&'), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to list search strings ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  insert(searchString: SearchString): Observable<any> {
    return this.http.post(this.serverService.api('/searchstrings'), { searchString }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to insert a search string ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  update(searchString: SearchString): Observable<any> {
    return this.http.put(this.serverService.api('/searchstrings'), { searchString }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to insert a search string ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  updateOrder(strings: SearchString[]): Observable<any> {
    const order: {[key: string]: number} = {};

    strings.forEach((searchString, index) => {
      if (searchString.uuid) {
        order[searchString.uuid] = index;
      }
    });

    return this.http.put(this.serverService.api('/searchstrings/order'), { order }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to update search string order ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  delete(uuid: string): Observable<any> {
    return this.http.delete(this.serverService.api('/searchstrings', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to delete a search string ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }
}
