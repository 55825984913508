import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { Candidate } from '../../../admin/candidates/candidate.model';
import { CandidatesService } from '../../../admin/candidates/candidates.service';
import { CandidateDetailComponent } from '../../../admin/candidates/candidate-detail/candidate-detail.component';
import { CandidatesActions } from '../../../admin/candidates/candidates.actions';

import { SessionStateService } from '../../session-state.service';
import { ContentService } from '../../content.service';
import { EventBusService } from '../../event-bus.service';

import { User } from '../../models/user.model';

@Component({
  selector: 'app-widget-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss']
})
export class CandidatesWidgetComponent implements OnInit {

  candidates: Candidate[] = [];
  candidateUuid: string | undefined = '';
  candidate: Candidate | null = null;
  resumeLink: string | undefined = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private candidatesService: CandidatesService,
    private contentService: ContentService,
    private sessionStateService: SessionStateService,
    private eventBusService: EventBusService,
    private modalService: NgbModal
    ) { }

  ngOnInit(): void {
    this.store.dispatch(new CandidatesActions.Load());
    this.refreshCandidates();

    this.eventBusService.on('candidateChanged', (candidate: any) => {
      this.candidateUuid = candidate?.uuid;
      this.candidateChanged(true);
    });
  }

  refreshCandidates(): void {
    const session = this.sessionStateService.get();
    const uuid = (session.user as User).uuid;

    if (!uuid) {
      return;
    }

    const setCandidate: Candidate = this.eventBusService.getCandidate();

    this.candidatesService.list(uuid).subscribe(candidates => {
      this.candidates = candidates.sort((a, b) => (b.firstName?.toLowerCase() + ' ' + b.lastName?.toLowerCase()) >
        (a.firstName?.toLowerCase() + ' ' + a.lastName?.toLowerCase()) ? -1 : 1);

      this.candidates.map(candidate => {
        if (candidate.uuid === setCandidate?.uuid) {
          this.candidateUuid = candidate.uuid;
          this.candidate = candidate;
          this.candidateChanged();
        }

        return candidate;
      });

      if (!this.candidate && this.candidates.length) {
        this.candidateUuid = this.candidates[0].uuid;
        this.candidateChanged();
      }
    });
  }

  viewCandidate(): void {
    if (!this.candidate || !this.candidate.uuid) {
      return;
    }

    const uuid = this.candidate.uuid || this.contentService.uuid();
    this.store.dispatch(new CandidatesActions.Show({ uuid }));

    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      size: 'lg'
    };

    const urlSegments = this.route.snapshot.url;

    if (urlSegments && urlSegments[0] && (urlSegments[0].path === 'guides')) {
      ngbModalOptions.backdrop = false;
      ngbModalOptions.windowClass = 'free-floating-modal';
    }

    const modalRef = this.modalService.open(CandidateDetailComponent, ngbModalOptions);
    modalRef.componentInstance.uuid = uuid;

    modalRef.result.then((result) => {
      if (result.uploadedItem) {
        this.store.dispatch(new CandidatesActions.UploadResume({ uuid, file: result.uploadedItem }));
      } else {
        this.store.dispatch(new CandidatesActions.Update({ uuid }));

        this.store.dispatch(new CandidatesActions.Finished({ uuid }));
      }

      setTimeout(() => {
        this.refreshCandidates();
      }, 1000);
    }).catch((error) => {
      if (error && error.delete) {
        this.store.dispatch(new CandidatesActions.Delete({ uuid }));

        setTimeout(() => {
          this.refreshCandidates();
        }, 1000);
      }
    });
  }

  goToInnerView(): void {
    this.eventBusService.emit({ event: 'candidateChanged', value: this.candidate });

    const newRelativeUrl = this.router.createUrlTree(['innerview']);
    const baseUrl = window.location.href.replace(this.router.url, '');

    window.open(baseUrl + newRelativeUrl + '?candidateUuid=' + this.candidate?.uuid, '_blank');
  }

  candidateChanged(doNotEmit: boolean = false): void {
    if (!this.candidateUuid) {
      this.candidate = null;
      this.resumeLink = undefined;
      return;
    }

    this.candidatesService.lookup(this.candidateUuid).subscribe(fullcandidate => {
      this.candidate = fullcandidate;

      const csrfToken = this.sessionStateService.getSessionToken();

      this.resumeLink = !this.candidate.resume ? '' :
        this.contentService.getDownloadPath('users/' + this.candidate.owner + '/resumes/' + this.candidate.resume + '?csrf=' + csrfToken);

      if (!doNotEmit) {
        this.eventBusService.emit({ event: 'candidateChanged', value: this.candidate });
      }
    });
  }

  candidateHasInnerview(): boolean {
    if (!this.candidateUuid) {
      return false;
    }

    const candidate = this.candidates.find(cndt => cndt.uuid === this.candidateUuid);

    if (candidate) {
      return !!candidate.innerviews && !!candidate.innerviews.length;
    }

    return false;
  }

  trackByUuid(index: number, candidate: Candidate): string {
    return candidate.uuid || '';
  }

  download(url?: string): void {
    if (url) {
      const link = document.createElement('a');
      link.setAttribute('href', url);
      document.body.appendChild(link); // Required for FF
      link.click();
    }
  }
}
