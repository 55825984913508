<div>
  <div
    class="upload-file"
    [ngClass]="{'upload-file__dragover': isDragover}"
    (drop)="onFileDropped($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave()">
    <div class="upload-file_left">
      <div class="upload-file_header">
        <i class="fas fa-cloud-upload-alt"></i>
        <h4>Drag and drop file here or upload</h4>
      </div>
      <p *ngIf="acceptedText" class="upload-file_hint">{{acceptedText}}</p>
    </div>
    <div class="upload-file_right">
      <input
        hidden
        #fileInput
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        (change)="onFileSelected($event)"/>
      <button
        type="button"
        class="btn btn-primary"
        (click)="fileInput.click()"
      >Upload</button>
    </div>
  </div>


  <p *ngIf="isFileSizeError" class="error-message text-danger mb-0 mt-1">The file is too large. The maximum file size is {{maximumFileSize}} MB</p>

  <div class="uploaded-file" *ngIf="fileName">
    <div class="file-row">
      <div class="file-row_left">
        <p class="file-row_name">{{fileName}}</p>
      </div>
      <div class="delete-icon float-right" (click)="deleteFileClick(fileInput)" placement="left" [ngbTooltip]="'Delete file'"><i class="fas fa-times-circle text-danger" role="button"></i></div>
    </div>
  </div>
</div>
