import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Columns } from 'ngx-easy-table/lib';
import { DefaultConfig } from 'ngx-easy-table';
import { Account } from '../../../accounts/accounts.model';
import { AccountsService } from '../../../accounts/accounts.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityReportService } from '../../activity-report.service';
import { GeneralActivityReport } from '../../activity-report.model';
import { PageSpinnerService } from '../../../../shared/services/page-spinner.service';
import { finalize } from 'rxjs/operators';
import { CalculateTotalValue } from '../../../../shared/utils/calculate-total-value.util';

@UntilDestroy()
@Component({
  selector: 'app-global-activity-report',
  templateUrl: './global-activity-report.component.html',
  styleUrls: ['./global-activity-report.component.scss']
})
export class GlobalActivityReportComponent implements OnInit, AfterViewInit {

  @ViewChild('nameTpl', { static: true }) nameTpl?: TemplateRef<any>;
  @ViewChild('displayNameTpl', { static: true }) displayNameTpl?: TemplateRef<any>;
  @ViewChild('sessionDurationTpl', { static: true }) sessionDurationTpl?: TemplateRef<any>;
  @ViewChild('numberOfUsersTpl', { static: true }) numberOfUsersTpl?: TemplateRef<any>;

  columns: Columns[] = [];
  public configuration = {
    ...DefaultConfig,
    paginationEnabled: false,
    rows: 1000 // to prevent pagination layout
  };
  reports: GeneralActivityReport[] = [];
  totalSessionDuration = 0;
  totalInnerViewsCount = 0;
  totalSearchAssignmentCount = 0;
  totalReferenceChecksCount = 0;
  constructor(
    private accountsService: AccountsService,
    private activityReportService: ActivityReportService,
    private router: Router,
    private route: ActivatedRoute,
    private pageSpinnerService: PageSpinnerService,
  ) { }

  ngOnInit(): void {
    this.getReports();
  }

  ngAfterViewInit(): void {
    this.setColumns();
  }

  setColumns(): void {
    this.columns = [
      { key: 'name', title: 'Account', cellTemplate: this.nameTpl, orderEnabled: false, width: '15%' },
      { key: 'displayName', title: 'Display Name', cellTemplate: this.displayNameTpl, orderEnabled: false, width: '15%' },
      { key: 'users', title: 'Number of Users', cellTemplate: this.numberOfUsersTpl, orderEnabled: false, width: '11%' },
      { key: 'innerViewsCount', title: 'Number of InnerViews', width: '15%' },
      { key: 'searchAssignmentCount', title: 'Number of Job Orders', width: '15%' },
      { key: 'candidateReferenceCount', title: 'Number of Reference Checks', width: '15%' },
      { key: 'activeSessionsTotal', title: 'Session Duration', cellTemplate: this.sessionDurationTpl, width: '15%' },
    ];
  }

  getReports(): void {
    this.pageSpinnerService.startLoading();
    this.activityReportService.getGeneralActivityReport()
      .pipe(untilDestroyed(this), finalize(() => this.pageSpinnerService.stopLoading()))
      .subscribe(reports => {
        this.reports = reports;
        this.calculateTotalSessionDuration();
        this.calculateTotalInnerViewsCount();
        this.calculateTotalJobOrders();
        this.calculateTotalReferenceChecks();
        this.configuration.rows = reports?.length;
      });
  }

  calculateTotalSessionDuration(): void {
    this.totalSessionDuration = CalculateTotalValue(this.reports, 'activeSessionsTotal');
  }

  calculateTotalInnerViewsCount(): void {
    this.totalInnerViewsCount = CalculateTotalValue(this.reports, 'innerViewsCount');
  }

  calculateTotalJobOrders(): void {
    this.totalSearchAssignmentCount = CalculateTotalValue(this.reports, 'searchAssignmentCount');
  }

  calculateTotalReferenceChecks(): void {
    this.totalReferenceChecksCount = CalculateTotalValue(this.reports, 'candidateReferenceCount');
  }

  onRowClick(event: any): void {
    const accountId = event?.value?.row?.account?.uuid;
    if (!accountId) {
      return;
    }
    this.navigateTo(`../company/${accountId}`);
  }

  navigateTo(route: string): void {
    this.router.navigate([route], {relativeTo: this.route});
  }



}
