import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DefaultConfig } from 'ngx-easy-table';

import { Account } from './accounts.model';
import { AccountsState } from './accounts.state';
import { AccountsActions } from './accounts.actions';

import { CommonDialogComponent } from '../../shared/common-dialog/common-dialog.component';
import { AccountDetailComponent } from './account-detail/account-detail.component';

import { AccountsService } from './accounts.service';
import { UsersService } from '../../shared/users.service';
import { ContentService } from '../../shared/content.service';

import { Pagination } from 'src/app/shared/models/pagination.model';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {

  // @ts-ignore
  @Select(AccountsState.accounts) accounts$: Observable<Account[]>;
  // @ts-ignore
  @Select(AccountsState.pagination) pagination$: Observable<Pagination>;
  // @ts-ignore
  @Select(AccountsState.selected) selected$: Observable<string[]>;

  // @ts-ignore
  @ViewChild('checkboxTpl', { static: true }) checkboxTpl: TemplateRef<any>;
  // @ts-ignore
  @ViewChild('nameTpl', { static: true }) nameTpl: TemplateRef<any>;
  // @ts-ignore
  @ViewChild('subscriptionEndTpl', { static: true }) subscriptionEndTpl: TemplateRef<any>;
  // @ts-ignore
  @ViewChild('activeTpl', { static: true }) activeTpl: TemplateRef<any>;
  // @ts-ignore
  @ViewChild('seatsTpl', { static: true }) seatsTpl: TemplateRef<any>;
  // @ts-ignore
  @ViewChild('usersTpl', { static: true }) usersTpl: TemplateRef<any>;

  // @ts-ignore
  @ViewChild('columnActionTemplate', { static: true }) columnActionTemplate: TemplateRef<any>;

  public configuration = {
    ...DefaultConfig,
    rows: 100,
    threeWaySort: true
  };

  public columns = [];

  constructor(
    private store: Store,
    private modalService: NgbModal,
    private contentService: ContentService,
    private accountsService: AccountsService,
    private usersService: UsersService) {
  }

  ngOnInit(): void {
    this.columns = [
      // @ts-ignore
      { key: '', width: '5%', cellTemplate: this.checkboxTpl },
      // @ts-ignore
      { key: 'name', title: 'Account', width: '10%', cellTemplate: this.nameTpl, headerActionTemplate: this.columnActionTemplate },
      // @ts-ignore
      { key: 'primaryContact', title: 'Primary Contact', width: '15%', headerActionTemplate: this.columnActionTemplate },
      // @ts-ignore
      { key: 'displayName', title: 'Display Name', width: '15%', headerActionTemplate: this.columnActionTemplate },
      // @ts-ignore
      { key: 'subscription.endDate', title: 'Subscription Ends', width: '160px',
      // @ts-ignore
        cellTemplate: this.subscriptionEndTpl, headerActionTemplate: this.columnActionTemplate },
      // @ts-ignore
      { key: 'active', title: 'Active', width: '80px', cellTemplate: this.activeTpl, headerActionTemplate: this.columnActionTemplate },
      // @ts-ignore
      { key: 'subscription.seatsUsed', title: 'Seats', width: '80px',
      // @ts-ignore
        cellTemplate: this.seatsTpl, headerActionTemplate: this.columnActionTemplate },
      // @ts-ignore
      { title: '', width: '80px', cellTemplate: this.usersTpl },
    ];

    this.store.dispatch(new AccountsActions.Load());
  }

  add(): void {
    const uuid = this.contentService.uuid();
    this.store.dispatch(new AccountsActions.Show({ uuid }));

    const ngbModalOptions: NgbModalOptions = {
      windowClass: 'free-floating-modal',
      backdrop: false,
      size: 'lg'
    };

    const modalRef = this.modalService.open(AccountDetailComponent, ngbModalOptions);
    modalRef.componentInstance.uuid = uuid;

    modalRef.result.then((result) => {
      this.store.dispatch(new AccountsActions.Update({ uuid }));

      this.store.dispatch(new AccountsActions.Finished({ uuid }));

      if (result.another) {
        this.add();
      }
    }).catch((error) => {
      this.store.dispatch(new AccountsActions.Finished({ uuid }));

      this.store.dispatch(new AccountsActions.Load());
    });
  }

  edit(uuid: string): void {
    this.store.dispatch(new AccountsActions.Show({ uuid }));

    const ngbModalOptions: NgbModalOptions = {
      windowClass: 'free-floating-modal',
      backdrop: false,
      size: 'lg'
    };

    const modalRef = this.modalService.open(AccountDetailComponent, ngbModalOptions);
    modalRef.componentInstance.uuid = uuid;

    modalRef.result.then((result) => {
      this.store.dispatch(new AccountsActions.Update({ uuid }));

      this.store.dispatch(new AccountsActions.Finished({ uuid }));
    }).catch((error) => {
      if (error && error.delete) {
        this.store.dispatch(new AccountsActions.Delete({ uuid }));
      } else {
        this.store.dispatch(new AccountsActions.Load());
      }

      this.store.dispatch(new AccountsActions.Finished({ uuid }));
    });
  }

  eventEmitted($event: { event: string; value: any }): void {
    switch ($event.event)
    {
      case 'onClick':
        if ($event.value.row && $event.value.row.uuid) {
          if (!$event.value.key) {
            return;
          }

          this.edit($event.value.row.uuid);
        }
        break;

      case 'onOrder':
        this.store.dispatch(new AccountsActions.SetOrder({sort: $event.value.key, order: $event.value.order}));
        break;

      case 'onPagination':
        this.store.dispatch(new AccountsActions.SetPagination({limit: $event.value.limit, page: $event.value.page}));
        break;
    }
  }

  delete(): void {
    const modalRef = this.modalService.open(CommonDialogComponent);

    modalRef.componentInstance.closeButtonText = 'Cancel';
    modalRef.componentInstance.actionButtonText = 'Delete';
    modalRef.componentInstance.headerText = 'Delete Account';
    modalRef.componentInstance.bodyText = 'Are you sure you want to delete the selected accounts?';

    modalRef.result.then((result) => {
      this.store.dispatch(new AccountsActions.Delete({ uuid: null }));
    }).catch((error) => { });
  }

  search(element: EventTarget | null): void {
    if (element) {
      const name = (element as HTMLInputElement).value;

      this.store.dispatch(new AccountsActions.Search({ account: name }));
    } else {
      this.store.dispatch(new AccountsActions.Search({ account: null }));
    }
  }

  onChange(row: any, checkbox: EventTarget | null): void {
    if (checkbox) {
      this.store.dispatch(new AccountsActions.Select({uuid: row.uuid, select: (checkbox as HTMLInputElement).checked}));
    }
  }

  reportAccounts(): void {
    this.accountsService.report();
  }

  reportUsers(): void {
    this.usersService.report();
  }

  hideColumn(event: any): void {
    let target = event.target as HTMLElement;

    while (target.parentElement && target.tagName.toLowerCase() !== 'th') {
      target = target.parentElement;
    }

    let index = 0;

    while (target.previousSibling) {
      target = target.previousSibling as HTMLElement;

      if (target.tagName && target.tagName.toLowerCase() === 'th') {
        index++;
      }
    }

    this.columns.splice(index, 1);
  }
}
