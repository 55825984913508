import { SearchString } from './strings.model';

export namespace StringsActions {
  export class Load {
    static readonly type = 'StringsActions.Load';
  }

  export class SetOrder {
    static readonly type = 'StringsActions.SetOrder';
    constructor(public payload: {
      sort: string,
      order: string
    } ) {}
  }

  export class SetPagination {
    static readonly type = 'StringsActions.SetPagination';
    constructor(public payload: {
      page: number,
      limit: number
    } ) {}
  }

  export class Show {
    static readonly type = 'StringsActions.Show';
    constructor(public payload: {
      uuid: string
    } ) {}
  }

  export class Finished {
    static readonly type = 'StringsActions.Finished';
    constructor(public payload: {
      uuid: string
    } ) {}
  }

  export class UpdateCurrent {
    static readonly type = 'StringsActions.UpdateCurrent';
    constructor(public payload: {
      uuid: string,
      searchString: any
    } ) {}
  }

  export class Update {
    static readonly type = 'StringsActions.Update';
    constructor(public payload: {
      uuid: string
    } ) {}
  }

  export class Delete {
    static readonly type = 'StringsActions.Delete';
    constructor(public payload: {
      uuid: string | null
    } ) {}
  }

  export class Select {
    static readonly type = 'StringsActions.Select';
    constructor(public payload: {
      uuid: string,
      select: boolean
    } ) {}
  }

  export class UpdateOrder {
    static readonly type = 'StringsActions.UpdateOrder';
    constructor(public payload: {
      initialDragIndex: number,
      finalDragIndex: number
    } ) {}
  }
}
