<div class="modal-header">
  <h4 class="modal-title">Set New Password</h4>
  <button type="button" class="close" aria-hidden="true" (click)="close()">&times;</button>
</div>
<div class="modal-body">
  <form [formGroup]="passwordForm">
    <div class="container">

      <div class="form-group row">
        <label for="username" class="col-sm-3 col-form-label pl-0 pr-0">Old Password</label>
        <div class="col-sm-9">
          <input autocomplete="current-password" type="password" name="oldpassword" id="oldpassword" class="form-control" placeholder="Old Password" formControlName="oldpassword" appAutofocus>
        </div>
      </div>

      <div class="form-group row">
        <label for="username" class="col-sm-3 col-form-label pl-0 pr-0">New Password</label>
        <div class="col-sm-9">
          <input autocomplete="new-password" type="password" name="password" id="password" class="form-control" placeholder="New Password" formControlName="password">
        </div>
      </div>

      <div class="form-group row">
        <label for="confirmpassword" class="col-sm-3 col-form-label pl-0 pr-0">Confirm Password</label>
        <div class="col-sm-9">
          <input autocomplete="new-password" type="password" name="confirmpassword" id="confirmpassword" class="form-control" placeholder="Confirm New Password" formControlName="confirmpassword">
        </div>
      </div>
      <span *ngIf="passwordForm.value.password && passwordForm.value.confirmpassword && (passwordForm.value.password != passwordForm.value.confirmpassword)"
            class="row error-message text-danger">The Confirm password does not match the New password.
      </span>

    </div>
  </form>
</div>

<div class="modal-footer">
  <button
    type="submit"
    class="btn btn-secondary"
    (click)="change()"
    [disabled]="!passwordForm.valid || (passwordForm.value.password != passwordForm.value.confirmpassword)">Change Password</button>
  <button
    type="button"
    class="btn btn-default"
    (click)="close()">Cancel</button>
</div>
