import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, of, Subscription } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';

import { SearchString } from './strings.model';
import { StringsState } from './strings.state';
import { StringsActions } from './strings.actions';

import { CommonDialogComponent } from '../../shared/common-dialog/common-dialog.component';
import { StringDetailComponent } from './string-detail/string-detail.component';

import { Pagination } from 'src/app/shared/models/pagination.model';

import { ContentService } from '../../shared/content.service';

@Component({
  selector: 'app-strings',
  templateUrl: './strings.component.html',
  styleUrls: ['./strings.component.scss']
})
export class StringsComponent implements OnInit {

  // @ts-ignore
  @Select(StringsState.strings) strings$: Observable<SearchString[]>;
  // @ts-ignore
  @Select(StringsState.pagination) pagination$: Observable<Pagination>;
  // @ts-ignore
  @Select(StringsState.selected) selected$: Observable<string[]>;

  // @ts-ignore
  @ViewChild('checkboxTpl', { static: true }) checkboxTpl: TemplateRef<any>;
  // @ts-ignore
  @ViewChild('nameTpl', { static: true }) nameTpl: TemplateRef<any>;
  // @ts-ignore
  @ViewChild('columnActionTemplate', { static: true }) columnActionTemplate: TemplateRef<any>;

  public configuration = {
    ...DefaultConfig,
    rows: 100,
    threeWaySort: true
  };

  public columns = [];

  constructor(
    private store: Store,
    private modalService: NgbModal,
    private contentService: ContentService) {
  }

  ngOnInit(): void {
    this.columns = [
      // @ts-ignore
      { key: '', width: '5%', cellTemplate: this.checkboxTpl },
      // @ts-ignore
      { key: 'name', title: 'Name', width: '30%', cellTemplate: this.nameTpl, headerActionTemplate: this.columnActionTemplate },
      // @ts-ignore
      { key: 'displayName', title: 'Display Name', width: '65%', headerActionTemplate: this.columnActionTemplate }
    ];

    this.store.dispatch(new StringsActions.Load());
  }

  add(): void {
    const uuid = this.contentService.uuid();
    this.store.dispatch(new StringsActions.Show({ uuid }));

    const ngbModalOptions: NgbModalOptions = {
      windowClass: 'free-floating-modal',
      backdrop: false,
    };

    const modalRef = this.modalService.open(StringDetailComponent, ngbModalOptions);
    modalRef.componentInstance.uuid = uuid;

    modalRef.result.then((result) => {
      this.store.dispatch(new StringsActions.Update({ uuid }));

      this.store.dispatch(new StringsActions.Finished({ uuid }));
    }).catch((error) => {
      this.store.dispatch(new StringsActions.Load());

      this.store.dispatch(new StringsActions.Finished({ uuid }));
    });
  }

  edit(uuid: string): void {
    this.store.dispatch(new StringsActions.Show({ uuid }));

    const ngbModalOptions: NgbModalOptions = {
      windowClass: 'free-floating-modal',
      backdrop: false,
    };

    const modalRef = this.modalService.open(StringDetailComponent, ngbModalOptions);
    modalRef.componentInstance.uuid = uuid;

    modalRef.result.then((result) => {
      this.store.dispatch(new StringsActions.Update({ uuid }));

      this.store.dispatch(new StringsActions.Finished({ uuid }));
    }).catch((error) => {
      this.store.dispatch(new StringsActions.Load());

      this.store.dispatch(new StringsActions.Finished({ uuid }));
    });
  }

  eventEmitted($event: { event: string; value: any }): void {
    switch ($event.event)
    {
      case 'onClick':
        if ($event.value.row && $event.value.row.uuid) {
          if (!$event.value.key) {
            return;
          }

          this.edit($event.value.row.uuid);
        }
        break;

      case 'onOrder':
        this.store.dispatch(new StringsActions.SetOrder({sort: $event.value.key, order: $event.value.order}));
        break;

      case 'onPagination':
        this.store.dispatch(new StringsActions.SetPagination({limit: $event.value.limit, page: $event.value.page}));
        break;
    }
  }

  delete(): void {
    const modalRef = this.modalService.open(CommonDialogComponent);

    modalRef.componentInstance.closeButtonText = 'Cancel';
    modalRef.componentInstance.actionButtonText = 'Delete';
    modalRef.componentInstance.headerText = 'Delete Search Strings';
    modalRef.componentInstance.bodyText = 'Are you sure you want to delete the selected search strings?';

    modalRef.result.then((result) => {
      this.store.dispatch(new StringsActions.Delete({ uuid: null }));
    }).catch((error) => { });
  }

  onChange(row: any, checkbox: EventTarget | null): void {
    if (checkbox) {
      this.store.dispatch(new StringsActions.Select({uuid: row.uuid, select: (checkbox as HTMLInputElement).checked}));
    }
  }

  hideColumn(event: any): void {
    let target = event.target as HTMLElement;

    while (target.parentElement && target.tagName.toLowerCase() !== 'th') {
      target = target.parentElement;
    }

    let index = 0;

    while (target.previousSibling) {
      target = target.previousSibling as HTMLElement;

      if (target.tagName && target.tagName.toLowerCase() === 'th') {
        index++;
      }
    }

    this.columns.splice(index, 1);
  }

  reorder(initialDragIndex: number, finalDragIndex: number): void {
    this.store.dispatch(new StringsActions.UpdateOrder({
      initialDragIndex, finalDragIndex
    }));
  }
}
