import {Email, EmailType} from '../../shared/models/email.model';
import {Phone, PhoneType} from '../../shared/models/phone.model';
import {Client} from './clients.model';
import {MapField} from '../../shared/map-columns/map-columns.consts';

export const CLIENTS_DESTINATION_FIELDS: MapField[] = [
  new MapField({name: 'firstName'}),
  new MapField({name: 'lastName'}),
  new MapField({name: 'title'}),
  new MapField({name: 'company', isRequired: true}),
  new MapField({name: 'location'}),
  new MapField({name: 'address2'}),
  new MapField({name: 'state'}),
  new MapField({name: 'city'}),
  new MapField({name: 'zip'}),
  new MapField({name: 'country'}),
  new MapField({name: 'positions'}),
  new MapField({name: 'memo'}),
  new MapField({
    name: 'workEmail',
    isMultiple: true,
    customSetter: (c: Client, value: string) => {
      if (!c.emails) {
        c.emails = [];
      }
      const email: Email = {
        type: EmailType.work,
        address: value
      };
      c.emails.push(email);
      return c;
    }}
  ),
  new MapField({
    name: 'homeEmail',
    isMultiple: true,
    customSetter: (c: Client, value: string) => {
      if (!c.emails) {
        c.emails = [];
      }
      const email: Email = {
        type: EmailType.home,
        address: value
      };
      c.emails.push(email);
      return c;
    }}
  ),
  new MapField({
    name: 'otherEmail',
    isMultiple: true,
    customSetter: (c: Client, value: string) => {
      if (!c.emails) {
        c.emails = [];
      }
      const email: Email = {
        type: EmailType.other,
        address: value
      };
      c.emails.push(email);
      return c;
    }}
  ),
  new MapField({
    name: 'homePhone',
    isMultiple: true,
    customSetter: (c: Client, value: string) => {
      if (!c.phoneNumbers) {
        c.phoneNumbers = [];
      }
      const phoneNumber: Phone = {
        type: PhoneType.home,
        number: value
      };
      c.phoneNumbers.push(phoneNumber);
      return c;
    }}
  ),
  new MapField({
    name: 'workPhone',
    isMultiple: true,
    customSetter: (c: Client, value: string) => {
      if (!c.phoneNumbers) {
        c.phoneNumbers = [];
      }
      const phoneNumber: Phone = {
        type: PhoneType.work,
        number: value
      };
      c.phoneNumbers.push(phoneNumber);
      return c;
    }}
  ),
  new MapField({
    name: 'otherPhone',
    isMultiple: true,
    customSetter: (c: Client, value: string) => {
      if (!c.phoneNumbers) {
        c.phoneNumbers = [];
      }
      const phoneNumber: Phone = {
        type: PhoneType.other,
        number: value
      };
      c.phoneNumbers.push(phoneNumber);
      return c;
    }}
  ),
  new MapField({name: 'photo'}),
  new MapField({name: 'resume'}),
];


export const BULLHORN_CLIENTS_FIELDS_MAP = {
  firstName: 'firstName',
  lastName: 'lastName',
  name: 'company',
  'address.Address1': 'location',
  'address.Address2': 'address2',
  'address.State': 'state',
  'address.City': 'city',
  'address.Zip': 'zip',
  'address.CountryName': 'country',
  occupation: 'positions',
  description: 'memo',
  email: 'workEmail',
  email2: 'otherEmail',
  email3: 'otherEmail',
  workPhone: 'workPhone',
  phone: 'otherPhone',
  phone2: 'otherPhone',
  phone3: 'otherPhone',
};


export const LOXO_CLIENTS_FIELDS_MAP = {
  email: 'workEmail',
  fullAddress: 'location',
  location: 'address2',
  name: 'company',
  phone: 'workPhone',
  uRL: 'memo',
};


export const PC_RECRUITER_CLIENTS_FIELDS_MAP = {
  'Cell Phone': 'workPhone',
  'City/State': 'location',
  'Company Name': 'company',
  'Home Phone': 'homePhone',
  Name: 'firstName',
  'Quick Comments': 'memo',
  Title: 'title',
  'Work Phone': 'workPhone'
};
