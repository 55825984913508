<app-header></app-header>
<app-toolbar>

  <div class="ml-auto">
    <div class="btn-group btn-group-toggle mr-4" role="group" name="scriptTypeSwitches" ngbRadioGroup [(ngModel)]="scriptType">
      <label ngbButtonLabel class="btn-light btn-outline-secondary">
        <input ngbButton type="radio" value="morgan" (click)="scriptTypeChanged('morgan')"> Morgan Script
      </label>
      <label ngbButtonLabel class="btn-light btn-outline-secondary">
        <input ngbButton type="radio" value="user" (click)="scriptTypeChanged('user')"> Customized Script
      </label>
    </div>

    <div ngbDropdown [autoClose]="'outside'" class="d-inline-block">
      <button class="btn btn-light dropdown-toggle" type="button" ngbDropdownToggle id="dropdownWidgets" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Widgets
      </button>
      <div ngbDropdownMenu class="dropdown-menu pb-0" id="dropdownWidgetsDropdown" aria-labelledby="dropdownWidgets">
        <label *ngFor="let widget of widgetList" class="dropdown-item">
          <input type="checkbox" class="top1px" [checked]="widgets[widget]" (click)="switchWidget(widget)" /> {{widget}}
        </label>
      </div>
    </div>

  </div>

</app-toolbar>

<div class="container-fluid main-container">
  <div class="row h-100">

    <as-split direction="horizontal">
      <as-split-area [size]="80" id="guide-pane">

        <div class="panel editing-panel">
          <div class="panel-header text-center">
            <span class="header-title" [ngClass]="{ 'd-inline-block': !(!!guideForAproval && guideProcessed && guideProcessedCopy) }"><span class="font-weight-bold">{{ guideName }}</span>
              <span class="text-danger text-smaller ml-3" *ngIf="user && guide.owner === user.username && guide.approved === false && guide.rejected === false && !!guideForAproval">This copy of script was not approved by administrator.</span>
            </span>
            <span class='text-danger' *ngIf="requiresUpdate"><strong> -- A Software Update is available (<span>{{ softwareVersion }}</span>). <a href="javascript:void(0)" (click)="installUpdate()">Click to Install ...</a></strong></span>

            <div class="approval" *ngIf="(scriptType !== 'morgan') && !!guideForAproval && !!canApprove">
              &nbsp;&nbsp;
              <button class="btn btn-default plusOneTop" (click)="approveGuide()" [disabled]="actionInProgress">Approve</button>
              &nbsp;&nbsp;
              <button class="btn btn-danger plusOneTop" (click)="rejectGuide()" [disabled]="actionInProgress">Reject</button>
            </div>

            <ul class="nav navbar-nav navbar-right float-right mr-4" [hidden]="guideForAproval || ((scriptType == 'morgan') && !isAdmin)">
              <span class="mt-2">
                <button type="button" #eguideSaveGuide class="btn btn-danger" (click)="save()" [hidden]="!controls.editing">Save</button>&nbsp;&nbsp;
                <ng-toggle #editing [value]="controls.editing" [color]="{ unchecked: '#cfcfcf', checked: '#A80E00' }" (change)="toggleEditing($event)"></ng-toggle>&nbsp;<span class="switch-label">Editing</span>
              </span>
            </ul>
          </div>
          <div class="panel-content guide-container" #guideContainer>
              <div *ngIf="controls.editing">
                <editor [(ngModel)]="guideSource" [init]="tinymceOptions"></editor>
              </div>
              <div *ngIf="!controls.editing">
                <ng-container *ngIf="!!guideForAproval && guideProcessed && guideProcessedCopy">
                  <as-split direction="horizontal">
                    <as-split-area [size]="50">
                      <p class="mt-3">Moderated script:</p>
                      <ng-container *ngFor="let guidePart of guideProcessed; let i = index;">
                        <ngb-accordion #acc="ngbAccordion" activeIds="ngb-call-prep" *ngIf="!!callPrepTitles[i] && !!callPrepTexts[i]">
                          <ngb-panel id="ngb-call-prep">
                            <ng-template ngbPanelHeader let-opened="opened">
                              <div class="d-flex align-items-left">
                                <h5 class="m-0">{{callPrepTitles[i]}}</h5>
                                <div>
                                  <button ngbPanelToggle class="btn btn-outline-secondary">
                                    <i [hidden]="opened" class="fas fa-plus"></i>
                                    <i [hidden]="!opened" class="fas fa-minus"></i>
                                  </button>
                                </div>
                              </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                              <div [innerHTML]="callPrepTexts[i]"></div>
                            </ng-template>
                          </ngb-panel>
                        </ngb-accordion>
    
                        <div class="guide" [innerHTML]="guidePart"></div>
    
                        <div *ngIf="(guideId === 'recruiting-call') && ((i + 1) === (guideProcessed?.length || 0))" class="p-3">
                          <form class="form-inline">
                            <div class="form-group">
                              <div class="input-group">
                                <input class="form-control" placeholder="MM/DD/YYYY"
                                       name="selectedDate" [(ngModel)]="selectedDate" ngbDatepicker #d="ngbDatepicker">
                                <div class="input-group-append">
                                  <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><i class="far fa-calendar-plus"></i></button>
                                </div>
                              </div>
                            </div>
    
                            <span class="d-inline-block mr-3 ml-3">at</span>
    
                            <div class="natural-width-inputs">
                              <input class="form-control" placeholder="HH" size="2" name="selectedHour" [(ngModel)]="selectedHour" />
                              &nbsp;:
                            </div>
    
                            <select [(ngModel)]="selectedMinute" class="minute-selector" name="selectedMinute">
                              <option value="00">00</option>
                              <option value="30">30</option>
                            </select>
    
                            <div class="natural-width-inputs">
                              <button class="btn btn-primary" (click)="switchAmPm()" type="button">{{ selectedAmPm }}</button>
                            </div>
                          </form>
                        </div>
                      </ng-container>    
                    </as-split-area>
                    <as-split-area [size]="50" [minSize]="200">
                      <p class="mt-3">Original script:</p>
                      <ng-container *ngFor="let guidePart of guideProcessedCopy; let i = index;">
                        <ngb-accordion #acc="ngbAccordion" activeIds="ngb-call-prep" *ngIf="!!callPrepTitlesCopy[i] && !!callPrepTextsCopy[i]">
                          <ngb-panel id="ngb-call-prep">
                            <ng-template ngbPanelHeader let-opened="opened">
                              <div class="d-flex align-items-left">
                                <h5 class="m-0">{{callPrepTitlesCopy[i]}}</h5>
                                <div>
                                  <button ngbPanelToggle class="btn btn-outline-secondary">
                                    <i [hidden]="opened" class="fas fa-plus"></i>
                                    <i [hidden]="!opened" class="fas fa-minus"></i>
                                  </button>
                                </div>
                              </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                              <div [innerHTML]="callPrepTextsCopy[i]"></div>
                            </ng-template>
                          </ngb-panel>
                        </ngb-accordion>
    
                        <div class="guide" [innerHTML]="guidePart"></div>
    
                        <div *ngIf="(guideId === 'recruiting-call') && ((i + 1) === (guideProcessedCopy?.length || 0))" class="p-3">
                          <form class="form-inline">
                            <div class="form-group">
                              <div class="input-group">
                                <input class="form-control" placeholder="MM/DD/YYYY"
                                       name="selectedDate" [(ngModel)]="selectedDate" ngbDatepicker #d="ngbDatepicker">
                                <div class="input-group-append">
                                  <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><i class="far fa-calendar-plus"></i></button>
                                </div>
                              </div>
                            </div>
    
                            <span class="d-inline-block mr-3 ml-3">at</span>

                            <div class="natural-width-inputs">
                              <input class="form-control" placeholder="HH" size="2" name="selectedHour" [(ngModel)]="selectedHour" />
                              &nbsp;:
                            </div>
    
                            <select [(ngModel)]="selectedMinute" class="minute-selector" name="selectedMinute">
                              <option value="00">00</option>
                              <option value="30">30</option>
                            </select>
    
                            <div class="natural-width-inputs">
                              <button class="btn btn-primary" (click)="switchAmPm()" type="button">{{ selectedAmPm }}</button>
                            </div>    
                          </form>
                        </div>
                      </ng-container> 
                    </as-split-area>
                  </as-split>
                </ng-container>

                <ng-container *ngIf="!(!!guideForAproval && guideProcessed && guideProcessedCopy)">
                  <ng-container *ngFor="let guidePart of guideProcessed; let i = index;">
                    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-call-prep" *ngIf="!!callPrepTitles[i] && !!callPrepTexts[i]">
                      <ngb-panel id="ngb-call-prep">
                        <ng-template ngbPanelHeader let-opened="opened">
                          <div class="d-flex align-items-left">
                            <h5 class="m-0">{{callPrepTitles[i]}}</h5>
                            <div>
                              <button ngbPanelToggle class="btn btn-outline-secondary">
                                <i [hidden]="opened" class="fas fa-plus"></i>
                                <i [hidden]="!opened" class="fas fa-minus"></i>
                              </button>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div [innerHTML]="callPrepTexts[i]"></div>
                        </ng-template>
                      </ngb-panel>
                    </ngb-accordion>

                    <div class="guide" [innerHTML]="guidePart"></div>

                    <div *ngIf="(guideId === 'recruiting-call') && ((i + 1) === (guideProcessed?.length || 0))" class="p-3">
                      <button
                        class="btn btn-secondary ml-5"
                        [disabled]="!fieldValues.Candidate || !dateTimePickerDate"
                        (click)="addToInnerView()"
                        [ngbTooltip]="isDatetimeInputPresent ?
                        (!!dateTimePickerDate ? '': 'Please, select date and time first') : 'Please, insert DateTime input in the editor first'"
                        type="button">
                        Add to Plan
                      </button>
                    </div>

                    <div *ngIf="(guideId === 'pop-marketing') && ((i + 2) === (guideProcessed?.length || 0))" class="p-3">
                      <form class="form-inline">
                        <div class="natural-width-inputs"><input class="form-control" placeholder="Candidate Name" name="candidateName" [(ngModel)]="candidateName" /></div>
                        &nbsp;
                        <div class="natural-width-inputs"><input class="form-control" placeholder="Hiring Manager" name="hiringManager" [(ngModel)]="hiringManager" /></div>
                        &nbsp;
                        <div class="form-group">
                          <div class="input-group">
                            <input class="form-control" placeholder="MM/DD/YYYY"
                                   name="selectedDate" [(ngModel)]="selectedDate" ngbDatepicker #d="ngbDatepicker">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><i class="far fa-calendar-plus"></i></button>
                            </div>
                          </div>
                        </div>

                        <span class="d-inline-block mr-3 ml-3">at</span>

                        <div class="natural-width-inputs">
                          <input class="form-control" placeholder="HH" size="2" name="selectedHour" [(ngModel)]="selectedHour" />
                          &nbsp;:
                        </div>

                        <select [(ngModel)]="selectedMinute" class="minute-selector" name="selectedMinute">
                          <option value="00">00</option>
                          <option value="30">30</option>
                        </select>

                        <div class="natural-width-inputs">
                          <button class="btn btn-primary" (click)="switchAmPm()" type="button">{{ selectedAmPm }}</button>
                        </div>

                        <button class="btn btn-secondary ml-3" [disabled]="!candidateName || !selectedDate || !selectedDate.year || !selectedTimeIsValid()" (click)="addToSendout()" type="button">Add to Plan</button>
                      </form>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
          </div>
        </div> 

      </as-split-area>
      <as-split-area [size]="20" [minSize]="310" id="sidebar-pane">

        <div class="panel">
          <div class="panel-header position-static">&nbsp;</div>
          <div class="panel-content pb-4">
            <ng-container *ngFor="let widget of widgetList">
              <ng-container [ngSwitch]="widget">
   
                <div class="widget-placeholder" id="candidate-widget" *ngSwitchCase="'Candidates'" [hidden]="!widgets['Candidates']">
                  <app-widget-candidates></app-widget-candidates>
                </div>
 
                <div class="widget-placeholder" id="client-widget" *ngSwitchCase="'Clients'" [hidden]="!widgets['Clients']">
                  <app-widget-clients></app-widget-clients>
                </div>
                <div class="widget-placeholder" id="source-card-widget" *ngSwitchCase="'Source Card'" [hidden]="!widgets['Source Card']">
                  <app-source-card [ownerUUID]="user?.uuid || ''" [withHeader]="true"></app-source-card>
                </div>

                <div class="widget-placeholder" id="objections-widget" *ngSwitchCase="'Objections'" [hidden]="!widgets['Objections']">
                  <app-objections [ownerUUID]="user?.uuid || ''" [guideName]="guideId" [withHeader]="true"></app-objections>
                </div>

                <div class="widget-placeholder" id="notes-widget" *ngSwitchCase="'Notes'" [hidden]="!user || !user.uuid || !widgets['Notes']">

                  <ng-container [ngSwitch]="guideId">

                    <ng-container *ngSwitchCase="'recruiting-call'">
                      <ng-container *ngIf="widgets['Candidates'] && fieldValues.Candidate && fieldValues.Candidate?.uuid">
                        <app-notes [ownerUUID]="fieldValues.Candidate?.uuid" [withHeader]="true"></app-notes>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'candidate-prep-innerview'">
                      <ng-container *ngIf="widgets['Candidates'] && fieldValues.Candidate && fieldValues.Candidate?.uuid">
                        <app-notes [ownerUUID]="fieldValues.Candidate?.uuid" [withHeader]="true"></app-notes>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'candidate-prep-final'">
                      <ng-container *ngIf="widgets['Candidates'] && fieldValues.Candidate && fieldValues.Candidate?.uuid">
                        <app-notes [ownerUUID]="fieldValues.Candidate?.uuid" [withHeader]="true"></app-notes>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'candidate-debrief'">
                      <ng-container *ngIf="widgets['Candidates'] && fieldValues.Candidate && fieldValues.Candidate?.uuid">
                        <app-notes [ownerUUID]="fieldValues.Candidate?.uuid" [withHeader]="true"></app-notes>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'candidate-debrief-trial-closing'">
                      <ng-container *ngIf="widgets['Candidates'] && fieldValues.Candidate && fieldValues.Candidate?.uuid">
                        <app-notes [ownerUUID]="fieldValues.Candidate?.uuid" [withHeader]="true"></app-notes>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'closing-candidate'">
                      <ng-container *ngIf="widgets['Candidates'] && fieldValues.Candidate && fieldValues.Candidate?.uuid">
                        <app-notes [ownerUUID]="fieldValues.Candidate?.uuid" [withHeader]="true"></app-notes>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'resignation'">
                      <ng-container *ngIf="widgets['Candidates'] && fieldValues.Candidate && fieldValues.Candidate?.uuid">
                        <app-notes [ownerUUID]="fieldValues.Candidate?.uuid" [withHeader]="true"></app-notes>
                      </ng-container>
                    </ng-container>

                    <!--  -->

                    <ng-container *ngSwitchCase="'bizdev'">
                      <ng-container *ngIf="widgets['Clients'] && fieldValues.Client && fieldValues.Client?.uuid">
                        <app-notes [ownerUUID]="fieldValues.Client?.uuid" [withHeader]="true"></app-notes>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'pop-marketing'">
                      <ng-container *ngIf="widgets['Clients'] && fieldValues.Client && fieldValues.Client?.uuid">
                        <app-notes [ownerUUID]="fieldValues.Client?.uuid" [withHeader]="true"></app-notes>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'client-prep-first'">
                      <ng-container *ngIf="widgets['Clients'] && fieldValues.Client && fieldValues.Client?.uuid">
                        <app-notes [ownerUUID]="fieldValues.Client?.uuid" [withHeader]="true"></app-notes>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'client-prep-final'">
                      <ng-container *ngIf="widgets['Clients'] && fieldValues.Client && fieldValues.Client?.uuid">
                        <app-notes [ownerUUID]="fieldValues.Client?.uuid" [withHeader]="true"></app-notes>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'client-debrief'">
                      <ng-container *ngIf="widgets['Clients'] && fieldValues.Client && fieldValues.Client?.uuid">
                        <app-notes [ownerUUID]="fieldValues.Client?.uuid" [withHeader]="true"></app-notes>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'client-debrief-trial-closing'">
                      <ng-container *ngIf="widgets['Clients'] && fieldValues.Client && fieldValues.Client?.uuid">
                        <app-notes [ownerUUID]="fieldValues.Client?.uuid" [withHeader]="true"></app-notes>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'closing-client'">
                      <ng-container *ngIf="widgets['Clients'] && fieldValues.Client && fieldValues.Client?.uuid">
                        <app-notes [ownerUUID]="fieldValues.Client?.uuid" [withHeader]="true"></app-notes>
                      </ng-container>
                    </ng-container>

                  </ng-container>
                </div>
  
              </ng-container>
            </ng-container>
          </div>
        </div> 

      </as-split-area>
    </as-split>

  </div>
</div>

<app-footer></app-footer>
