<div class="modal-header">
    <h4 class="modal-title">{{headerText}}</h4>
    <button type="button" class="close" aria-hidden="true" (click)="close()">&times;</button>
</div>

<div class="modal-body">
    <p>{{bodyText}}</p>
    <div *ngIf="!!list">
        <ul>
            <li *ngFor="let item of list">{{ item }}</li>
        </ul>
    </div>
</div>

<div class="modal-footer">
<button
    type="submit"
    class="btn btn-secondary"
    (click)="save()">{{actionButtonText}}</button>
<button
    type="button"
    class="btn btn-default"
    (click)="close()">{{closeButtonText}}</button>
</div>
  