import { GeneralActivityReport } from '../../admin/activity-report/activity-report.model';

export const CalculateTotalValue = (arr: any[], propName: string): number => {
  if (!arr?.length) {
    return 0;
  }
  return arr.reduce((acc: number, report: GeneralActivityReport) => {
    acc += ((report as any)[propName] || 0);
    return acc;
  }, 0);
};
