<div class="container-fluid">
    <div class="row" *ngIf="withHeader">
        <div class="col-sm-12 widget-header">Objections <div class="right-block"><img src="/assets/images/dashboard/panic-button.png" /></div></div>
    </div>
    <div class="widget-body p-0">
        <div class="row">
            <div class="col-sm-12 p-0">
                <ul class="list-group">
                    <li class="list-group-item one-liner" *ngFor="let objection of objections; index as i" (click)="edit(i)">{{ objection.script }}</li>
                    <li class="list-group-item" id="add-objection" (click)="add()" [hidden]="!ownerUUID"><i class="fas fa-plus mr-2"></i>&nbsp;New Objection</li>
                </ul>
            </div>
        </div>
    </div>
</div>
