export namespace SessionActions {
  export class Load {
    static readonly type = 'SessionActions.Load';
  }

  export class Delete {
    static readonly type = 'SessionActions.Delete';
  }

  export class Select {
    static readonly type = 'SessionActions.Select';
    constructor(public payload: {
      sessionKey: string,
      select: boolean
    } ) {}
  }
}
