import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';

import { forkJoin } from 'rxjs';

import { AccountsActions } from './accounts.actions';
import { Account } from './accounts.model';
import { AccountsService } from './accounts.service';
import { EmailType } from 'src/app/shared/models/email.model';
import { PhoneType } from 'src/app/shared/models/phone.model';

import { Pagination } from 'src/app/shared/models/pagination.model';

export interface AccountsStateModel {
  accounts: Account[];
  currentAccounts: {[key: string]: Account};
  selected: string[];
  pagination: Pagination;
}

const defaultState: AccountsStateModel = {
  accounts: [],
  currentAccounts: {},
  selected: [],
  pagination: {
    search: '',
    limit: 100,
    offset: 0,
    count: 0,
    sort: '',
    order: '',
  }
};

@State<AccountsStateModel>({
  name: 'accounts',
  defaults: { ...defaultState }
})

@Injectable()
export class AccountsState {

  @Selector()
  static accounts(state: AccountsStateModel): Account[] {
    return state.accounts;
  }

  @Selector()
  static pagination(state: AccountsStateModel): Pagination {
    return state.pagination;
  }

  @Selector()
  static currentAccounts(state: AccountsStateModel): {[key: string]: Account} {
    return state.currentAccounts;
  }

  @Selector()
  static selected(state: AccountsStateModel): string[] {
    return state.selected;
  }
/*
  @Selector()
  static isUnique(state: AccountsStateModel): boolean {
    const current = state.current;

    const unique = state.accounts.filter(account =>
      (account.uuid !== current?.uuid ) && (account.name === current?.name)).length === 0;

    return unique;
  }
*/
  constructor(private accountsService: AccountsService) { }

  @Action(AccountsActions.Load)
  load(context: StateContext<AccountsStateModel>): void {
    const state = context.getState();
    const pagination = state.pagination;

    this.accountsService.list(
        pagination.search, pagination.offset, pagination.limit, pagination.sort, pagination.order).subscribe(accounts => {
      context.patchState({
        accounts,
        pagination: {
          ...pagination,
          count: accounts.length
        }
      });
    });
  }

  @Action(AccountsActions.SetOrder)
  setOrder(context: StateContext<AccountsStateModel>,
           { payload: { sort, order } }: AccountsActions.SetOrder): void {
    const state = context.getState();

    const pagination = {
      ...state.pagination,
      sort,
      order
    };

    context.patchState({
      pagination
    });

    context.dispatch(new AccountsActions.Load());
  }

  @Action(AccountsActions.SetPagination)
  setPagination(context: StateContext<AccountsStateModel>,
                { payload: { limit, page } }: AccountsActions.SetPagination): void {
    const state = context.getState();

    const pagination = {
      ...state.pagination,
      limit,
      page
    };

    context.patchState({
      pagination
    });

    context.dispatch(new AccountsActions.Load());
  }

  @Action(AccountsActions.Search)
  search(context: StateContext<AccountsStateModel>,
         { payload: { account } }: AccountsActions.Search): void {
    const state = context.getState();

    const pagination = {
      ...state.pagination,
      search: account || ''
    };

    context.patchState({
      pagination
    });

    context.dispatch(new AccountsActions.Load());
  }

  @Action(AccountsActions.UpdateCurrent)
  updateCurrent(context: StateContext<AccountsStateModel>,
                { payload: { uuid, account } }: AccountsActions.UpdateCurrent): void {
    const state = context.getState();

    const currentAccounts = {
      ...state.currentAccounts
    };

    currentAccounts[uuid] = {
      ...currentAccounts[uuid],
      ...account
    };

    context.patchState({
      currentAccounts
    });
  }

  @Action(AccountsActions.AddEmail)
  addEmail(context: StateContext<AccountsStateModel>, { payload: { uuid } }: AccountsActions.AddEmail): void {
    const state = context.getState();
    const currentAccounts = {
      ...state.currentAccounts
    };
    const account = currentAccounts[uuid];

    if (!account) {
      return;
    }

    const emails = (account.emails || []).slice();
    emails.push({ type: EmailType.work, address: '' });

    account.emails = emails;
    currentAccounts[uuid] = account;

    context.patchState({
      currentAccounts
    });
  }

  @Action(AccountsActions.SetEmailType)
  setEmailType(context: StateContext<AccountsStateModel>,
               { payload: { uuid, index, type } }: AccountsActions.SetEmailType): void {
    const state = context.getState();
    const currentAccounts = {
      ...state.currentAccounts
    };
    const account = currentAccounts[uuid];

    if (!account) {
      return;
    }

    const emails = (account.emails || []).slice();
    emails[index].type = type;

    account.emails = emails;
    currentAccounts[uuid] = account;

    context.patchState({
      currentAccounts
    });
  }

  @Action(AccountsActions.SetEmailAddress)
  setEmailAddress(context: StateContext<AccountsStateModel>,
                  { payload: { uuid, index, address } }: AccountsActions.SetEmailAddress): void {
    const state = context.getState();
    const currentAccounts = {
      ...state.currentAccounts
    };
    const account = currentAccounts[uuid];

    if (!account) {
      return;
    }

    const emails = (account.emails || []).slice();
    emails[index].address = address;

    account.emails = emails;
    currentAccounts[uuid] = account;

    context.patchState({
      currentAccounts
    });
  }

  @Action(AccountsActions.RemoveEmail)
  removeEmail(context: StateContext<AccountsStateModel>,
              { payload: { uuid, index } }: AccountsActions.RemoveEmail): void {
    const state = context.getState();
    const currentAccounts = {
      ...state.currentAccounts
    };
    const account = currentAccounts[uuid];

    if (!account) {
      return;
    }

    const emails = (account.emails || []).slice();
    emails.splice(index, 1);

    account.emails = emails;
    currentAccounts[uuid] = account;

    context.patchState({
      currentAccounts
    });
  }

  @Action(AccountsActions.AddPhone)
  addPhone(context: StateContext<AccountsStateModel>, { payload: { uuid } }: AccountsActions.AddPhone): void {
    const state = context.getState();
    const currentAccounts = {
      ...state.currentAccounts
    };
    const account = currentAccounts[uuid];

    if (!account) {
      return;
    }

    const phoneNumbers = (account.phoneNumbers || []).slice();
    phoneNumbers.push({ type: PhoneType.work, number: '' });

    account.phoneNumbers = phoneNumbers;
    currentAccounts[uuid] = account;

    context.patchState({
      currentAccounts
    });
  }

  @Action(AccountsActions.SetPhoneType)
  setPhoneType(context: StateContext<AccountsStateModel>,
               { payload: { uuid, index, type } }: AccountsActions.SetPhoneType): void {
    const state = context.getState();
    const currentAccounts = {
      ...state.currentAccounts
    };
    const account = currentAccounts[uuid];

    if (!account) {
      return;
    }

    const phoneNumbers = (account.phoneNumbers || []).slice();
    phoneNumbers[index].type = type;

    account.phoneNumbers = phoneNumbers;
    currentAccounts[uuid] = account;

    context.patchState({
      currentAccounts
    });
  }

  @Action(AccountsActions.SetPhoneNumber)
  setPhoneNumber(context: StateContext<AccountsStateModel>,
                 { payload: { uuid, index, phoneNumber } }: AccountsActions.SetPhoneNumber): void {
    const state = context.getState();
    const currentAccounts = {
      ...state.currentAccounts
    };
    const account = currentAccounts[uuid];

    if (!account) {
      return;
    }

    const phoneNumbers = (account.phoneNumbers || []).slice();
    phoneNumbers[index].number = phoneNumber;

    account.phoneNumbers = phoneNumbers;
    currentAccounts[uuid] = account;

    context.patchState({
      currentAccounts
    });
  }

  @Action(AccountsActions.RemovePhone)
  removePhone(context: StateContext<AccountsStateModel>,
              { payload: { uuid, index } }: AccountsActions.RemovePhone): void {
    const state = context.getState();
    const currentAccounts = {
      ...state.currentAccounts
    };
    const account = currentAccounts[uuid];

    if (!account) {
      return;
    }

    const phoneNumbers = (account.phoneNumbers || []).slice();
    phoneNumbers.splice(index, 1);

    account.phoneNumbers = phoneNumbers;
    currentAccounts[uuid] = account;

    context.patchState({
      currentAccounts
    });
  }

  @Action(AccountsActions.AddAddress)
  addAddress(context: StateContext<AccountsStateModel>,
             { payload: { uuid, address } }: AccountsActions.AddAddress): void {
    const state = context.getState();
    const currentAccounts = {
      ...state.currentAccounts
    };
    const account = currentAccounts[uuid];

    if (!account) {
      return;
    }

    const addresses = (account.addresses || []).slice();
    addresses.push(address);

    account.addresses = addresses;
    currentAccounts[uuid] = account;

    context.patchState({
      currentAccounts
    });
  }

  @Action(AccountsActions.UpdateAddress)
  updateAddress(context: StateContext<AccountsStateModel>,
                { payload: { uuid, address, index } }: AccountsActions.UpdateAddress): void {
    const state = context.getState();
    const currentAccounts = {
      ...state.currentAccounts
    };
    const account = currentAccounts[uuid];

    if (!account) {
      return;
    }

    const addresses = (account.addresses || []).slice();
    addresses.splice(index, 1, address);

    account.addresses = addresses;
    currentAccounts[uuid] = account;

    context.patchState({
      currentAccounts
    });
  }

  @Action(AccountsActions.RemoveAddress)
  removeAddress(context: StateContext<AccountsStateModel>,
                { payload: { uuid, index } }: AccountsActions.RemoveAddress): void {
    const state = context.getState();
    const currentAccounts = {
      ...state.currentAccounts
    };
    const account = currentAccounts[uuid];

    if (!account) {
      return;
    }

    const addresses = (account.addresses || []).slice();
    addresses.splice(index, 1);

    account.addresses = addresses;
    currentAccounts[uuid] = account;

    context.patchState({
      currentAccounts
    });
  }

  @Action(AccountsActions.Update)
  update(context: StateContext<AccountsStateModel>, { payload: { uuid } }: AccountsActions.Update): void {
    const state = context.getState();
    const currentAccounts = {
      ...state.currentAccounts
    };
    const account = currentAccounts[uuid];

    if (!account) {
      return;
    }

    if (!account.emails) {
      account.emails = [];
    }

    if (!account.addresses) {
      account.addresses = [];
    }

    if (!account.phoneNumbers) {
      account.phoneNumbers = [];
    }

    if (!!account.uuid) {
      this.accountsService.update(account).subscribe(() => {
        this.load(context);
      });
    } else {
      this.accountsService.insert(account).subscribe(() => {
        this.load(context);
      });
    }
  }

  @Action(AccountsActions.Delete)
  delete(context: StateContext<AccountsStateModel>, { payload: { uuid } }: AccountsActions.Delete): void {
    if (uuid) {
      this.accountsService.delete(uuid).subscribe(() => {
        this.load(context);

        this.select(context, { payload: { uuid, select: false } });
      });
    } else {
      const selectedUuids = context.getState().selected;

      forkJoin(selectedUuids.map(this.accountsService.delete.bind(this.accountsService))).subscribe(() => {
        this.load(context);

        selectedUuids.forEach(selectedUuid => this.select(context, { payload: { uuid: selectedUuid, select: false } }) );
      });
    }
  }

  @Action(AccountsActions.Select)
  select(context: StateContext<AccountsStateModel>, { payload: { uuid, select } }: AccountsActions.Select): void {
    const state = context.getState();
    const selected = state.selected.slice();
    const index = selected.indexOf(uuid);

    if (select) {
      if (index === -1) {
        context.patchState({
          selected: selected.concat([uuid])
        });
      }
    } else {
      if (index > -1) {
        selected.splice(index, 1);

        context.patchState({
          selected
        });
      }
    }
  }

  @Action(AccountsActions.UnselectAll)
  unselectAll(context: StateContext<AccountsStateModel>): void {
    context.patchState({
      selected: []
    });
  }

  @Action(AccountsActions.Show)
  show(context: StateContext<AccountsStateModel>,
       { payload: { uuid } }: AccountsActions.Show): void {
    const state = context.getState();
    const currentAccounts = {
      ...state.currentAccounts
    };

    const acc = state.accounts.find(acct => acct.uuid === uuid);

    if (acc) {
      this.accountsService.lookup(uuid).subscribe(account => {
        currentAccounts[uuid] = account;

        context.patchState({
          currentAccounts
        });
      });
    } else {
      context.patchState({
        currentAccounts
      });
    }
  }

  @Action(AccountsActions.Finished)
  finished(context: StateContext<AccountsStateModel>,
           { payload: { uuid } }: AccountsActions.Finished): void {
    const state = context.getState();
    const currentAccounts = {
      ...state.currentAccounts
    };

    delete currentAccounts[uuid];

    context.patchState({
      currentAccounts
    });
  }

}
