import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Note } from '../note.model';

import { CommonDialogComponent } from '../../../common-dialog/common-dialog.component';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {

  note = '';
  isNew = false;

  constructor(private activeModal: NgbActiveModal, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  save(): void {
    this.activeModal.close(this.note);
  }

  delete(): void {
      const modalRef = this.modalService.open(CommonDialogComponent);

      modalRef.componentInstance.closeButtonText = 'Cancel';
      modalRef.componentInstance.actionButtonText = 'Delete';
      modalRef.componentInstance.headerText = 'Delete Note';
      modalRef.componentInstance.bodyText = 'Are you sure you want to delete this note?';

      modalRef.result.then((result) => {
        this.activeModal.dismiss({ delete: true });
      }).catch((error) => { });
  }

  close(): void {
    this.activeModal.dismiss();
  }
}
