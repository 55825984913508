import {ChecklistCategory} from '../../shared/nested-checklist/nested-checklist.component';

export enum CandidateChecklistProp {
  FirstName = 'firstName',
  LastName = 'lastName',
  Title = 'title',
  Company = 'employer',
  PhoneNumber = 'phoneNumber', // custom
  Email = 'email',  // custom
  Location = 'location',
  Address2 = 'address2',
  State = 'state', // custom
  City = 'city',
  Zip = 'zip',
  Country = 'country',
  Notes = 'notes',  // custom
  Memo = 'memo',
  Skill = 'skills',
}
export const ExportCandidatesChecklistCategories: ChecklistCategory[] = [
  {
    name: 'Contact Details',
    checked: false,
    items: [
      {name: 'First Name', checked: false, propKey: CandidateChecklistProp.FirstName},
      {name: 'Last Name', checked: false, propKey: CandidateChecklistProp.LastName},
      {name: 'Job title', checked: false, propKey: CandidateChecklistProp.Title},
      {name: 'Company', checked: false, propKey: CandidateChecklistProp.Company},
      {name: 'Phone number', checked: false, propKey: CandidateChecklistProp.PhoneNumber},
      {name: 'Email address', checked: false, propKey: CandidateChecklistProp.Email},
    ]
  },
  {
    name: 'Location Details',
    checked: false,
    items: [
      {name: 'Address Line 1', checked: false, propKey: CandidateChecklistProp.Location},
      {name: 'Address Line 2', checked: false, propKey: CandidateChecklistProp.Address2},
      {name: 'City', checked: false, propKey: CandidateChecklistProp.City},
      {name: 'State/Province', checked: false, propKey: CandidateChecklistProp.State},
      {name: 'Zip/Postal code', checked: false, propKey: CandidateChecklistProp.Zip},
      {name: 'Country', checked: false, propKey: CandidateChecklistProp.Country},
    ]
  },
  {
    name: 'Custom Fields',
    checked: false,
    items: [
      {name: 'Notes', checked: false, propKey: CandidateChecklistProp.Notes},
      {name: 'Memo', checked: false, propKey: CandidateChecklistProp.Memo},
      {name: 'Skill', checked: false, propKey: CandidateChecklistProp.Skill},
    ]
  }
];

