import { Injectable } from '@angular/core';
import { User } from '../shared/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class SessionStateService {

  static readonly SESSION_STATE_LOCALSTORAGE_ITEM = 'sessionState';
  static readonly DEFAULT_CSRF_TOKEN = 'a0660e408a986fd796455c7c1880a2a1e0cc61737ffd0d48960a9248a4bf80e120777629f88eb164da3d89662d4e440131d951e750bd3ce306cb051503c74656';

  constructor() { }

  /**
   * Get session state.
   */
  public get(): CurrentSessionState {
    const item = localStorage.getItem(SessionStateService.SESSION_STATE_LOCALSTORAGE_ITEM);
    const itemState = item ? JSON.parse(item) : { session: { csrfToken: SessionStateService.DEFAULT_CSRF_TOKEN } };
    return itemState as CurrentSessionState;
  }

  /**
   * Update session state.
   */
  public set(state: CurrentSessionState): CurrentSessionState {
    let itemState = this.get();
    if (state) {
      itemState = Object.assign({}, itemState, state);
      localStorage.setItem(SessionStateService.SESSION_STATE_LOCALSTORAGE_ITEM, JSON.stringify(itemState));
    }
    return itemState;
  }

  /**
   * Clear session state.
   */
  public clear(): void {
    localStorage.removeItem(SessionStateService.SESSION_STATE_LOCALSTORAGE_ITEM);
  }

  public getUser(): User {
    return this.get().user as User;
  }

  public getSession(): Session {
    const session = this.get().session;

    if (session.isSimulated && session.simulatedSession) {
      return session.simulatedSession;
    }

    return session;
  }

  public getSessionToken(): string {
    const session = this.get().session;

    return session ? (session.csrfToken || '') : '';
  }
}

export interface Session {
  username: string;
  roles: string[];
  firstName?: string;
  lastName?: string;
  email?: string;
  account?: string;
  account_uuid?: string;
  user_uuid?: string;
  csrfToken?: string;
  isSimulated?: boolean;
  simulatedSession?: Session;
}

export interface CurrentSessionState {
  session: Session;
  version: string;
  user: {};
}
