import { Injectable } from '@angular/core';
import * as Papa from 'papaparse';
import * as _ from 'lodash';
import {ImportPlatform} from '../enums/import-platform.enum';

@Injectable({
  providedIn: 'root'
})
export class ImportDataService {

  csvToJson(csvData: string, platform: ImportPlatform): Promise<any[]> {
    if (platform === ImportPlatform.PCRecruiter || platform === ImportPlatform.Other) {
        return this.papaConversion(csvData);
      } else {
        return new Promise((resolve, reject) => {
          this.regularConversion(csvData)
            .then(res => resolve(res))
            .catch(() => {
              this.papaConversion(csvData)
                .then(res => resolve(res))
                .catch(() => reject());
            });
        });
      }
  }

  regularConversion(csvData: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      try {
        const rows = csvData.split(/\r|\n|\r/).filter(r => r.length);
        const headers = rows[0].split(',').map(header => _.camelCase(header.trim()));
        const jsonArray = [];

        for (let i = 1; i < rows.length; i++) {
          const values = rows[i].split(',');
          const obj: any = {};

          for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = values[j].trim();
          }

          jsonArray.push(obj);
        }

        resolve(jsonArray);
      } catch {
        reject();
      }
    });
  }


  papaConversion(csvData: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      Papa.parse(csvData, {
        header: true,
        complete: (result) => {
          resolve(result.data);
        },
        error: () => {
          reject();
        },
      });
    });
  }
}
