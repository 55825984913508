<app-page-title class="report-page-title" [pageTitle]="'Global Activity Report'" [iconClass]="'fa-chart-area'">
  <app-download-report-pdf
    after
    [fileName]="'Global Activity Report.pdf'"
    [containerId]="'globalReportsContainer'">
  </app-download-report-pdf>
</app-page-title>

<div class="container-fluid main-container report-container" id="globalReportsContainer">

  <ng-container *ngIf="reports?.length">
    <ngx-table
      [data]="reports"
      [configuration]="configuration"
      [columns]="columns"
      [summaryTemplate]="summaryTemplateRef"
      (event)="onRowClick($event)">
    </ngx-table>
  </ng-container>
</div>

<ng-template #nameTpl let-row>
  <span>{{row.account.name}}</span>
</ng-template>


<ng-template #displayNameTpl let-row>
  <span>{{row.account.displayName}}</span>
</ng-template>
<ng-template #sessionDurationTpl let-row>
  <ng-container >
    <span>{{row.activeSessionsTotal ? (row.activeSessionsTotal | timestampToDuration) : '0 minutes'}} </span>
  </ng-container>
</ng-template>

<ng-template #numberOfUsersTpl let-row>
  <ng-container >
    <span>{{row.account?.subscription?.seatsUsed || 0}} </span>
  </ng-container>
</ng-template>


<ng-template #summaryTemplateRef let-total="total" let-limit="limit" let-page="page">
  <th colspan="3">Total:</th>
  <th>{{totalInnerViewsCount || 0}}</th>
  <th>{{totalSearchAssignmentCount || 0}}</th>
  <th>{{totalReferenceChecksCount || 0}}</th>
  <th>{{totalSessionDuration ? (totalSessionDuration | timestampToDuration) : '0 minutes'}}</th>
</ng-template>
