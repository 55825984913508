import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import * as _ from 'lodash';

import { PlannerService } from '../../../dailyplanner/planner.service';
import { SessionStateService } from '../../../shared/session-state.service';

import { CandidatesService } from '../../../admin/candidates/candidates.service';
import { ClientsService } from '../../../admin/clients/clients.service';

import { AlertService } from '../../../shared/alert.service';
import { EventBusService } from '../../event-bus.service';

import { Candidate } from '../../../admin/candidates/candidate.model';
import { Client } from '../../../admin/clients/clients.model';
import { User } from '../../models/user.model';
import { PhoneType } from '../../models/phone.model';
import { EmailType } from '../../models/email.model';

@Component({
  selector: 'app-source-card',
  templateUrl: './source-card.component.html',
  styleUrls: ['./source-card.component.css']
})
export class SourceCardComponent implements OnInit, OnChanges {

  // @ts-ignore
  @ViewChild('sourceCardSaveBtn', { static: false }) sourceCardSaveBtn: ElementRef<any>;

  @Input() ownerUUID = '';
  @Input() withHeader = true;

  @Output() savingIsSucceed = new EventEmitter();

  @Input() firstName = '';
  @Input() lastName = '';
  @Input() company = '';
  @Input() title = '';
  phone = '';
  email = '';
  notes = '';

  addToRec = false;
  addToMkt = false;

  activeId = '';
  actionInProgress = false;

  constructor(
    private sessionStateService: SessionStateService,
    private candidatesService: CandidatesService,
    private eventBusService: EventBusService,
    private clientsService: ClientsService,
    private plannerService: PlannerService,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.url.pipe(first()).subscribe((segments) => {
      const lastSegment = segments.slice(-1)[0];

      if (lastSegment && lastSegment.path === 'telesourcing') {
        this.activeId = 'ngb-source-card';
      }

      this.updateData();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.withHeader && !this.withHeader) {
      this.activeId = 'ngb-source-card';
    }
  }

  updateData(): void {
    const data = this.eventBusService.getData();

    if (data && ('company' in data) && ('phone' in data)) {
      this.company = data.company;
      this.phone = data.phone;
    }
  }

  isDisabled(): boolean {
    if (!this.firstName) {
      return true;
    }

    if (this.addToMkt && !this.company) {
      return true;
    }

    if (!this.addToRec && !this.addToMkt) {
      return true;
    }

    if (this.actionInProgress) {
      return true;
    }

    return false;
  }

  save(): void {
    this.actionInProgress = true;

    const session = this.sessionStateService.get();
    const user = (session.user as User);

    const saveToCandidatePromise = new Promise((resolve) => {
      if (this.addToRec) {
        const candidate: Candidate = {
          firstName: this.firstName,
          lastName: this.lastName,
          title: this.title,
          employer: this.company,
          active: true,
          private: false,
          memo: this.notes,
          emails: [],
          phoneNumbers: [],
          addresses: [],
          owner: user.uuid,
          account: user.account_uuid
        };

        if (this.email) {
          candidate.emails.push({ type: EmailType.work, address: this.email });
        }

        if (this.phone) {
          candidate.phoneNumbers.push({ type: PhoneType.work, number: this.phone });
        }

        this.candidatesService.insert(candidate).subscribe(() => {
          resolve(true);
        });
      } else {
        resolve(false);
      }
    });

    const saveToClientPromise = new Promise((resolve) => {
      if (this.addToMkt) {
        const client: Client = {
          firstName: this.firstName,
          lastName: this.lastName,
          title: this.title,
          company: this.company,
          active: true,
          private: false,
          memo: this.notes,
          emails: [],
          phoneNumbers: [],
          addresses: [],
          owner: user.uuid,
          account: user.account_uuid
        };

        if (this.email) {
          client.emails.push({ type: EmailType.work, address: this.email });
        }

        if (this.phone) {
          client.phoneNumbers.push({ type: PhoneType.work, number: this.phone });
        }

        this.clientsService.insert(client).subscribe(() => {
          resolve(true);
        });
      } else {
        resolve(false);
      }
    });

    const addToRecPromise = new Promise((resolve) => {
      if (this.addToRec) {
        this.plannerService.get().subscribe((planner) => {
          planner.recruitingCalls.push({
            status: 'none',
            project: '',
            candidate: this.firstName + ' ' + this.lastName,
            title: this.title,
            company: this.company,
            phone: this.phone,
            hiringManager: user.firstName + ' ' + user.lastName,
            memo: '',
            action: '',
            notes: this.notes
          });

          this.plannerService.update( _.pick(planner, ['uuid', 'recruitingCalls'])).subscribe(() => {
            resolve(true);
          });
        });
      } else {
        resolve(false);
      }
    });

    const addToMktPromise = new Promise((resolve) => {
      if (this.addToMkt) {
        this.plannerService.get().subscribe((planner) => {
          planner.newMarketing.push({
            status: 'none',
            company: this.company,
            phone: this.phone,
            email: this.email,
            title: this.title,
            hiringManager: this.firstName + ' ' + this.lastName,
            result: '',
            notes: this.notes
          });

          this.plannerService.update( _.pick(planner, ['uuid', 'newMarketing'])).subscribe(() => {
            resolve(true);
          });
        });
      } else {
        resolve(false);
      }
    });

    Promise.all([
      saveToCandidatePromise,
      saveToClientPromise,
      addToRecPromise,
      addToMktPromise
    ]).then(() => {
      this.firstName = '';
      this.lastName = '';
      this.company = '';
      this.title = '';
      this.phone = '';
      this.email = '';
      this.notes = '';

      this.addToRec = false;
      this.addToMkt = false;

      this.alertService.success('Got it!', this.sourceCardSaveBtn?.nativeElement);
      this.actionInProgress = false;
      this.savingIsSucceed.emit();

      this.updateData();
    });
  }
}
