import { Directive, OnInit, ElementRef, Input } from '@angular/core';

declare var jQuery: any;
declare var $: any;

@Directive({
  selector: '[appDraggable]'
})
export class DraggableDirective implements OnInit {

  @Input() appDraggable = '';

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    setTimeout(() => {
      const handle = $(this.el.nativeElement);

      // @ts-ignore
      handle.closest(this.appDraggable).draggable({
        handle: '.' + handle.get(0).className,
        containment: 'window'
      });
    }, 250);
  }
}
