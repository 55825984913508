import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { Client } from '../clients.model';
import { ClientsActions } from '../clients.actions';
import { ClientsState } from '../clients.state';

import { EmailType } from '../../../shared/models/email.model';
import { PhoneType } from '../../../shared/models/phone.model';

import { SessionStateService } from '../../../shared/session-state.service';

import { CommonDialogComponent } from '../../../shared/common-dialog/common-dialog.component';
import {LocationAddress} from '../../../shared/models/location-address.model';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss']
})
export class ClientDetailComponent implements OnInit, OnDestroy {

  // @ts-ignore
  @Select(ClientsState.clients) clients$: Observable<Client[]>;
  // @ts-ignore
  @Select(ClientsState.currentClients) currentClients$: Observable<{[key: string]: Client}>;

  isUnique$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  current$: Observable<Client | null> =
    of({ firstName: '', lastName: '', title: '', location: '', company: '',
      emails: [], phoneNumbers: [], addresses: [], active: false, private: false });

  uuid = '';

  form: FormGroup;
  formSubscription: Subscription | null = null;
  initSubscription: Subscription | null = null;

  EmailType = EmailType;
  PhoneType = PhoneType;

  isSimulated = false;

  constructor(
    private sessionStateService: SessionStateService,
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private store: Store
  ) {
    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      title: ['', [Validators.required]],
      location: [''],
      address2: [''],
      state: [''],
      city: [''],
      zip: [''],
      country: [''],
      company: ['', [Validators.required]],
      source: [''],
      positions: [''],
      memo: ['']
    });
  }

  ngOnInit(): void {
    const session = this.sessionStateService.get();
    this.isSimulated = !!session.session.simulatedSession;

    this.current$ = this.currentClients$.pipe(map(currentClients => {
      return currentClients[this.uuid] ||
        { firstName: '', lastName: '', title: '', location: '', company: '',
          emails: [], phoneNumbers: [], addresses: [], active: false, private: false };
    }));

    this.clients$.pipe(first()).subscribe(clients => {
      const unique = clients.filter(clientCheck => (clientCheck.uuid !== this.uuid) &&
        (clientCheck.firstName === this.f.firstName.value && clientCheck.lastName === this.f.lastName.value)).length === 0;
      this.isUnique$.next(unique);
    });

    // @ts-ignore
    this.initSubscription = this.current$.subscribe((client: Client | null) => {
      if (client) {
        this.form.patchValue({
          firstName: client.firstName,
          lastName: client.lastName,
          title: client.title,
          location: client.location,
          address2: client.address2,
          state: client.state,
          city: client.city,
          zip: client.zip,
          country: client.country,
          company: client.company,
          source: client.source,
          positions: client.positions,
          memo: client.memo
        });

        if (this.initSubscription) {
          this.initSubscription.unsubscribe();
          this.initSubscription = null;
        }
      }

      setTimeout(() => {
        Object.values(this.form.controls).forEach(control => {
          control.markAsDirty();
        });
      }, 200);
    });

    this.formSubscription = this.form.valueChanges.subscribe(() => {
      this.updateMethodBackend();

      this.clients$.pipe(first()).subscribe(clients => {
        const unique = clients.filter(clientCheck => (clientCheck.uuid !== this.uuid) &&
          (clientCheck.firstName === this.f.firstName.value && clientCheck.lastName === this.f.lastName.value)).length === 0;
        this.isUnique$.next(unique);
      });
    });
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }

    if (this.initSubscription) {
      this.initSubscription.unsubscribe();
      this.initSubscription = null;
    }
  }

  updateMethodBackend(): void {
    if (this.isSimulated) {
      return;
    }

    const client: any = {
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      title: this.f.title.value,
      location: this.f.location.value,
      address2: this.f.address2.value,
      state: this.f.state.value,
      city: this.f.city.value,
      zip: this.f.zip.value,
      country: this.f.country.value,
      company: this.f.company.value,
      source: this.f.source.value,
      positions: this.f.positions.value,
      memo: this.f.memo.value,
    };

    this.store.dispatch(new ClientsActions.UpdateCurrent({ uuid: this.uuid, client }));
  }

  save(another?: boolean): void {
    if (this.isSimulated) {
      return;
    }

    this.updateMethodBackend();

    this.activeModal.close({ another });
  }

  close(del?: boolean): void {
    if (del) {
      const modalRef = this.modalService.open(CommonDialogComponent);

      modalRef.componentInstance.closeButtonText = 'Cancel';
      modalRef.componentInstance.actionButtonText = 'Delete';
      modalRef.componentInstance.headerText = 'Delete Client';
      modalRef.componentInstance.bodyText = 'Are you sure you want to delete this client?';

      modalRef.result.then((result) => {
        this.activeModal.dismiss({ delete: true });
      }).catch((error) => { });
    } else {
      this.activeModal.dismiss();
    }
  }

  addEmail(): void {
    if (this.isSimulated) {
      return;
    }

    this.store.dispatch(new ClientsActions.AddEmail({ uuid: this.uuid }));
  }

  setEmailType(index: number, select: EventTarget | null): void {
    if (select) {
      const value = ((select as HTMLSelectElement).value as unknown);
      const type: EmailType = (value as EmailType);

      this.store.dispatch(new ClientsActions.SetEmailType({ uuid: this.uuid, index, type }));
    }
  }

  setEmailAddress(index: number, input: EventTarget | null): void {
    const address = (input as HTMLInputElement).value;

    this.store.dispatch(new ClientsActions.SetEmailAddress({ uuid: this.uuid, index, address }));
  }

  removeEmail(index: number): void {
    if (this.isSimulated) {
      return;
    }

    this.store.dispatch(new ClientsActions.RemoveEmail({ uuid: this.uuid, index }));
  }

  addPhone(): void {
    if (this.isSimulated) {
      return;
    }

    this.store.dispatch(new ClientsActions.AddPhone({ uuid: this.uuid }));
  }

  setPhoneType(index: number, select: EventTarget | null): void {
    if (select) {
      const value = ((select as HTMLSelectElement).value as unknown);
      const type: PhoneType = (value as PhoneType);

      this.store.dispatch(new ClientsActions.SetPhoneType({ uuid: this.uuid, index, type }));
    }
  }

  setPhoneNumber(index: number, input: EventTarget | null): void {
    const phone = (input as HTMLInputElement).value;

    this.store.dispatch(new ClientsActions.SetPhoneNumber({ uuid: this.uuid, index, phoneNumber: phone }));
  }

  removePhone(index: number): void {
    if (this.isSimulated) {
      return;
    }

    this.store.dispatch(new ClientsActions.RemovePhone({ uuid: this.uuid, index }));
  }

  addToPlan(): void {
    if (this.isSimulated) {
      return;
    }

    this.store.dispatch(new ClientsActions.AddToPlan({ uuid: this.uuid }));
  }

  // convenience getter for easy access to form fields
  get f(): {[key: string]: AbstractControl} { return this.form.controls; }

  onAddressFormValueChange(v: LocationAddress): void {
    this.form.patchValue({...this.form.value, ...v});
  }
}
