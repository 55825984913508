import { Component, OnInit } from '@angular/core';

import { Session } from '../shared/session-state.service';
import { User } from '../shared/models/user.model';
import { UsersService } from '../shared/users.service';
import { AlertService } from '../shared/alert.service';
import { SessionStateService } from '../shared/session-state.service';

@Component({
  selector: 'app-morganrescue',
  templateUrl: './morganrescue.component.html',
  styleUrls: ['./morganrescue.component.scss']
})
export class MorganrescueComponent implements OnInit {

  to = 'Morgan Mentor';
  from = '';
  subject = '';
  message = '';

  constructor(
    private usersService: UsersService,
    private alertService: AlertService,
    private sessionStateService: SessionStateService) { }

  ngOnInit(): void {
    const session = this.sessionStateService.get();
    const user = (session.session.simulatedSession || session.user) as User & Session;

    if (user.user_uuid) {
      user.uuid = user.user_uuid;
    }

    const firstName = (user.firstName || '');
    const lastName = (user.lastName || '');
    const email = (user.email || 'Morgan');

    this.from = email;
    this.subject = 'Help with Closing Deal';
    this.message = 'Hi Peter,\n\nWe are stuck in a placement and cannot make any progress. Could you help us with closing a deal?\n\nRegards,\n\n---\n' + firstName + ' ' + lastName + '\n' + email + '\n';
  }

  send(): void {
    const session = this.sessionStateService.get();

    const email = {
      username: (session.user as User).username,
      subject: this.subject,
      body: this.message
    };

    this.usersService.sendRescueEmail(email).subscribe(() => {
      this.subject = '';
      this.message = '';

      this.alertService.success('Email sent, Peter will contact you soon!');
    });
  }
}
