import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';

import { Account } from '../accounts/accounts.model';
import { User } from '../../shared/models/user.model';
import { UsersState } from './users.state';
import { UsersActions } from './users.actions';

import { CommonDialogComponent } from '../../shared/common-dialog/common-dialog.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { AdminNotesComponent } from './admin-notes/admin-notes.component';

import { UsersService } from '../../shared/users.service';
import { AccountsService } from '../accounts/accounts.service';
import { SessionStateService } from '../../shared/session-state.service';
import { ContentService } from '../../shared/content.service';

import { Pagination } from 'src/app/shared/models/pagination.model';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  // @ts-ignore
  @Select(UsersState.users) users$: Observable<User[]>;
  // @ts-ignore
  @Select(UsersState.pagination) pagination$: Observable<Pagination>;
  // @ts-ignore
  @Select(UsersState.selected) selected$: Observable<string[]>;

  // @ts-ignore
  @ViewChild('checkboxTpl', { static: true }) checkboxTpl: TemplateRef<any>;
  // @ts-ignore
  @ViewChild('nameTpl', { static: true }) nameTpl: TemplateRef<any>;
  // @ts-ignore
  @ViewChild('fullNameTpl', { static: true }) fullNameTpl: TemplateRef<any>;
  // @ts-ignore
  @ViewChild('loginTpl', { static: true }) loginTpl: TemplateRef<any>;
  // @ts-ignore
  @ViewChild('activeTpl', { static: true }) activeTpl: TemplateRef<any>;
  // @ts-ignore
  @ViewChild('notesTpl', { static: true }) notesTpl: TemplateRef<any>;
  // @ts-ignore
  @ViewChild('simulateTpl', { static: true }) simulateTpl: TemplateRef<any>;

  // @ts-ignore
  @ViewChild('columnActionTemplate', { static: true }) columnActionTemplate: TemplateRef<any>;

  public configuration = {
    ...DefaultConfig,
    rows: 100,
    threeWaySort: true
  };

  public columns = [];

  public accountId = '';
  public myAccountId = '';
  public isSuperUser = false;
  public userId: string | undefined;

  public accounts: Account[] = [];

  constructor(
    private route: ActivatedRoute,
    private actions$: Actions,
    private store: Store,
    private modalService: NgbModal,
    private usersService: UsersService,
    private accountsService: AccountsService,
    private contentService: ContentService,
    private sessionStateService: SessionStateService
  ) { }

  ngOnInit(): void {
    this.accountId = this.route.snapshot.paramMap.get('id') || '';

    const session = this.sessionStateService.get();

    this.userId = (session.user as User).uuid;
    this.myAccountId = (session.user as User).account_uuid;

    const roles = (session.user as User).roles;
    this.isSuperUser = !!roles && roles.includes('super-user');

    this.columns = [
      // @ts-ignore
      { key: '', width: '5%', cellTemplate: this.checkboxTpl },
      // @ts-ignore
      { key: 'userName', title: 'Username', cellTemplate: this.nameTpl, width: '15%', headerActionTemplate: this.columnActionTemplate },
      // @ts-ignore
      { key: 'fullName', title: 'Full Name',
      // @ts-ignore
        cellTemplate: this.fullNameTpl, width: '20%', headerActionTemplate: this.columnActionTemplate },
      // @ts-ignore
      { key: 'email', title: 'Email', width: '15%', headerActionTemplate: this.columnActionTemplate },
      // @ts-ignore
      { key: 'lastLogin', title: 'Last Login', cellTemplate: this.loginTpl, width: '20%', headerActionTemplate: this.columnActionTemplate },
      // @ts-ignore
      { key: 'active', title: 'Active', cellTemplate: this.activeTpl, width: '110px', headerActionTemplate: this.columnActionTemplate },
      // @ts-ignore
      { key: 'userType', title: 'User Type', width: '250px', headerActionTemplate: this.columnActionTemplate },
    ];

    if (this.usersService.isAccountAdmin()) {
      // @ts-ignore
      this.columns.push({ key: '', width: '150px', cellTemplate: this.notesTpl });
      // @ts-ignore
      this.columns.push({ key: '', width: '250px', cellTemplate: this.simulateTpl });
    }

    this.store.dispatch(new UsersActions.Load({ accountId: this.accountId }));

    this.actions$.pipe(ofActionSuccessful(UsersActions.NeedsReload)).subscribe(() => {
      this.store.dispatch(new UsersActions.Load({ accountId: this.accountId }));
    });

    this.accountsService.list().subscribe(accounts => {
      this.accounts = accounts;
    });
  }

  switchToAccount(target: EventTarget | null): void {
    if (target) {
      const select = (target as HTMLSelectElement);

      const value = select.value;
      this.accountId = value;

      this.store.dispatch(new UsersActions.Load({ accountId: value }));
    }
  }

  add(): void {
    if (!this.accountId) {
      return;
    }

    const uuid = this.contentService.uuid();
    this.store.dispatch(new UsersActions.Show({ uuid }));

    const ngbModalOptions: NgbModalOptions = {
      windowClass: 'free-floating-modal',
      backdrop: false,
      size: 'lg'
    };

    const modalRef = this.modalService.open(UserDetailComponent, ngbModalOptions);
    modalRef.componentInstance.uuid = uuid;
    modalRef.componentInstance.accountId = this.accountId;

    modalRef.result.then((result) => {
      this.store.dispatch(new UsersActions.Update({ uuid }));

      this.store.dispatch(new UsersActions.Finished({ uuid }));

      if (result.another) {
        this.add();
      }
    }).catch((error) => {
      this.store.dispatch(new UsersActions.Finished({ uuid }));

      this.store.dispatch(new UsersActions.Load({ accountId: this.accountId }));
    });
  }

  edit(uuid: string): void {
    this.usersService.lookupByUUID(uuid).subscribe(user => {
      this.store.dispatch(new UsersActions.ShowWithUser({ uuid, user }));

      const ngbModalOptions: NgbModalOptions = {
        windowClass: 'free-floating-modal',
        backdrop: false,
        size: 'lg'
      };

      const modalRef = this.modalService.open(UserDetailComponent, ngbModalOptions);
      modalRef.componentInstance.uuid = uuid;
      modalRef.componentInstance.accountId = user.account_uuid;

      modalRef.result.then((result) => {
        this.store.dispatch(new UsersActions.Update({ uuid }));

        this.store.dispatch(new UsersActions.Finished({ uuid }));
      }).catch((error) => {
        if (error && error.delete) {
          this.store.dispatch(new UsersActions.Delete({ uuid }));
        }

        this.store.dispatch(new UsersActions.Finished({ uuid }));
      });
    });
  }

  note(uuid: string): void {
    this.usersService.lookupByUUID(uuid).subscribe(user => {
      this.store.dispatch(new UsersActions.ShowWithUser({ uuid, user }));

      const ngbModalOptions: NgbModalOptions = {
        backdrop: true,
        size: 'lg'
      };

      const modalRef = this.modalService.open(AdminNotesComponent, ngbModalOptions);
      modalRef.componentInstance.uuid = uuid;
      modalRef.componentInstance.accountId = user.account_uuid;

      modalRef.result.then((result) => {
        this.store.dispatch(new UsersActions.Update({ uuid }));

        this.store.dispatch(new UsersActions.Finished({ uuid }));
      }).catch((error) => {
        this.store.dispatch(new UsersActions.Finished({ uuid }));
      });
    });
  }

  eventEmitted($event: { event: string; value: any }): void {
    switch ($event.event)
    {
      case 'onClick':
        if ($event.value.row && $event.value.row.uuid) {
          if (!$event.value.key) {
            return;
          }

          this.edit($event.value.row.uuid);
        }
        break;

      case 'onOrder':
        this.store.dispatch(new UsersActions.SetOrder({sort: $event.value.key, order: $event.value.order}));
        break;

      case 'onPagination':
        this.store.dispatch(new UsersActions.SetPagination({limit: $event.value.limit, page: $event.value.page}));
        break;
    }
  }

  delete(): void {
    const modalRef = this.modalService.open(CommonDialogComponent);

    modalRef.componentInstance.closeButtonText = 'Cancel';
    modalRef.componentInstance.actionButtonText = 'Delete';
    modalRef.componentInstance.headerText = 'Delete User';
    modalRef.componentInstance.bodyText = 'Are you sure you want to delete the selected users?';

    modalRef.result.then((result) => {
      this.store.dispatch(new UsersActions.Delete({ uuid: null }));
    }).catch((error) => { });
  }

  search(element: EventTarget | null): void {
    if (element) {
      const name = (element as HTMLInputElement).value;

      this.store.dispatch(new UsersActions.Search({ user: name, accountId: this.accountId }));
    } else {
      this.store.dispatch(new UsersActions.Search({ user: null, accountId: this.accountId }));
    }
  }

  onChange(row: any, checkbox: EventTarget | null): void {
    if (checkbox) {
      this.store.dispatch(new UsersActions.Select({uuid: row.uuid, select: (checkbox as HTMLInputElement).checked}));
    }
  }

  simulate(uuid: string): void {
    this.usersService.simulateLogin(uuid).subscribe(() => {});
  }

  hideColumn(event: any): void {
    let target = event.target as HTMLElement;

    while (target.parentElement && target.tagName.toLowerCase() !== 'th') {
      target = target.parentElement;
    }

    let index = 0;

    while (target.previousSibling) {
      target = target.previousSibling as HTMLElement;

      if (target.tagName && target.tagName.toLowerCase() === 'th') {
        index++;
      }
    }

    this.columns.splice(index, 1);
  }
}
