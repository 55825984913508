import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

import { AlertService } from '../../shared/alert.service';
import { ServerService } from '../../shared/server.service';

import { Account } from './accounts.model';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(
    private http: HttpClient,
    private serverService: ServerService,
    private alertService: AlertService) { }

  lookup(uuid: string): Observable<Account> {
    return this.http.get(this.serverService.api('/accounts/lookup', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to fetch account ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  list(search?: string, offset?: number, limit?: number, column?: string, order?: string): Observable<Account[]> {
    const options = [];

    if (search) {
      options.push('search=' + search);
    }

    if (offset) {
      options.push('offset=' + offset);
    }

    if (limit) {
      options.push('limit=' + limit);
    }

    if (column) {
      options.push('column=' + column);
    }

    if (order) {
      options.push('order=' + order);
    }

    return this.http.get(this.serverService.api('/accounts/list') +
      (options.length ? '?' : '') + options.join('&'), this.serverService.apiConfig())
      .pipe(first(), map((response: any) => {
        return response;
      }));
  }

  insert(account: Account): Observable<Account> {
    return this.http.post(this.serverService.api('/accounts'), { account }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        if (error && error.error && error.error.message) {
          this.alertService.error(error.error.message);
        } else {
          this.alertService.error('Failed to insert an account ' + JSON.stringify(error.data));
        }
        return throwError(error.message || error);
      }), map((response: any) => {
        return response;
      }));
  }

  update(account: Account): Observable<Account> {
    return this.http.put(this.serverService.api('/accounts'), { account }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        if (error && error.error && error.error.message) {
          this.alertService.error(error.error.message);
        } else {
          this.alertService.error('Failed to update an account ' + JSON.stringify(error.data));
        }
        return throwError(error.message || error);
      }), map((response: any) => {
        return response;
      }));
  }

  delete(uuid: string): Observable<any> {
    return this.http.delete(this.serverService.api('/accounts', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        if (error && error.error && error.error.message) {
          this.alertService.error(error.error.message);
        } else {
          this.alertService.error('Failed to delete an account ' + JSON.stringify(error.data));
        }
        return throwError(error.message || error);
      }), map((response: any) => {
        return response;
      }));
  }

  report(): void {
    const url = this.serverService.api('/reports/accounts');

    this.http.get(url, {
      ...this.serverService.apiConfig(),
      responseType: 'text'
    }).subscribe((response: any) => {
      const encodedUri = encodeURI(response);
      const link = document.createElement('a');

      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodedUri);
      link.setAttribute('download', 'accounts_report.csv');
      document.body.appendChild(link); // Required for FF
      link.click();
    }, (error: any) => {
      this.alertService.error('Failed to download a report ' + JSON.stringify(error));
      return throwError(error);
    });
  }
}
