import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { first } from 'rxjs/operators';

import * as _ from 'lodash';

import { CandidatesState } from '../../admin/candidates/candidates.state';
import { Candidate } from 'src/app/admin/candidates/candidate.model';
import { User } from 'src/app/shared/models/user.model';
import { Session } from 'src/app/shared/session-state.service';

import { Client } from 'src/app/admin/clients/clients.model';
import { ClientsService } from '../../admin/clients/clients.service';

import { UsersService } from '../../shared/users.service';
import { CandidatesService } from 'src/app/admin/candidates/candidates.service';
import { AlertService } from '../../shared/alert.service';
import { ServerService } from '../../shared/server.service';
import { SessionStateService } from '../../shared/session-state.service';
import { EventBusService } from '../../shared/event-bus.service';
import { DocumentsService } from '../documents.service';
import { PlannerService } from '../../dailyplanner/planner.service';
import { ContentService } from '../../shared/content.service';

import { CandidatesActions } from 'src/app/admin/candidates/candidates.actions';
import { CandidateDetailComponent } from 'src/app/admin/candidates/candidate-detail/candidate-detail.component';

import { CommonDialogComponent } from '../../shared/common-dialog/common-dialog.component';

import { PhoneType } from '../../shared/models/phone.model';
import { EmailType } from '../../shared/models/email.model';

@Component({
  selector: 'app-innerview',
  templateUrl: './innerview.component.html',
  styleUrls: ['./innerview.component.scss']
})
export class InnerviewComponent implements OnInit, AfterViewInit {

  // @ts-ignore
  @Select(CandidatesState.current) current$: Observable<Candidate | null>;

  // @ts-ignore
  @ViewChild('innerviewDownload', { static: true }) innerviewDownload: ElementRef<any>;

  // @ts-ignore
  @ViewChild('innerviewSave', { static: true }) innerviewSave: ElementRef<any>;

  candidates: Candidate[] = [];
  candidate: Candidate | null | undefined = null;
  latestCandidate: Candidate | null | undefined = null;

  fields: any = {};
  fieldsPrev: any = {};

  defaultFields = {
    candidate_uuid: '',
    account_uuid: '',
    user_uuid: '',

    // Top Section
    candidateFirstName: '',      // From Candidate
    candidateLastName: '',       // From Candidate
    candidateTitle: '',          // From Candidate
    candidateCompany: '',        // From Candidate (Employer)
    recruiter: '',
    date: '',

    skillsAssessment: '',

    // Current department breakdown:
    departmentHead: '',
    departmentStaff: [],

    // Current Job Breakdown
    title: '',
    company: '',
    duties: [{microDuties: []}, {microDuties: []}, {microDuties: []}, {microDuties: []}],
    incomeProgression: '',
    reasonExploring: '',

    // Previous Job
    previousTitle: '',
    previousCompany: '',
    previousDuties: [{microDuties: []}, {microDuties: []}, {microDuties: []}, {microDuties: []}],
    previousIncomeProgression: '',
    previousReasonExploring: '',

    // Humbling
    standout: '',
    increaseRevenue: '',
    decreaseCosts: '',

    // WishList
    expectedTitle: '',
    function: '',
    industry: '',
    companySize: '',
    culture: '',
    consulting: '',

    // Geographics
    geographics: '',
    ready: '',
    spouse: '',
    relocation: '',

    // More WishList h-k
    travel: '',
    commute: '',
    respectedCompanies: '',
    compensation: '',

    // Compensation Trial close
    compensationAmount: 0,
    compensationPass: 0,

    // 4 Main Criteria or Priorities
    priorities: [],

    // Etiqueutte of Search Explanation
    notesEtiquette: '',

    // Rules of Engagement
    notesEngagement: '',

    lookingCard: {
      firstName: '',
      lastName: '',
      company: '',
      title: '',
      email: { type: 'work', address: '' },
      phoneNumber: { type: 'work', number: '' },
      notes: '',
      addToRec: false,
      addToMkt: false
    },
    notLookingCard: {
      firstName: '',
      lastName: '',
      company: '',
      title: '',
      email: { type: 'work', address: '' },
      phoneNumber: { type: 'work', number: '' },
      notes: '',
      addToRec: false,
      addToMkt: false
    }
  };

  users: User[] = [];
  shareList: string[] = [];
  shareListFull: any[] = [];

  isSimulated = false;
  actionInProgress = false;

  constructor(
    private store: Store,
    private actions$: Actions,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private candidatesService: CandidatesService,
    private clientsService: ClientsService,
    private serverService: ServerService,
    private sessionStateService: SessionStateService,
    private documentsService: DocumentsService,
    private eventBusService: EventBusService,
    private plannerService: PlannerService,
    private contentService: ContentService,
    private elementRef: ElementRef,
    private renderer2: Renderer2,
    // @ts-ignore
    @Inject(DOCUMENT) private document) { }

  ngOnInit(): void {
    const session = this.sessionStateService.get();
    this.isSimulated = !!session.session.simulatedSession;

    this.fields = {};
    _.merge(this.fields, this.defaultFields);

    this.usersService.loginStatus().subscribe((sessionData) => {
      this.defaultFields.account_uuid = sessionData.status.account_uuid;
      this.defaultFields.user_uuid = sessionData.status.user_uuid;
      this.defaultFields.recruiter = sessionData.status.firstName + ' ' + sessionData.status.lastName;

      this.usersService.listByAccount(sessionData.status.account_uuid).subscribe((users) => {
        this.users = users;
      });

      this.refreshCandidates();
    });

    this.actions$.pipe(ofActionSuccessful(CandidatesActions.NeedsReload)).subscribe(() => {
      this.refreshCandidates();
    });

    this.candidatesService.listInnerviews((session.user as any)['uuid'] as any).subscribe(res => {
      console.log('----', res);
    })
  }

  ngAfterViewInit(): void {
    const s = this.document.createElement('script');
    s.type = 'text/javascript';
    s.src = '/assets/scripts/innerview-lines.js';

    this.elementRef.nativeElement.appendChild(s);
  }

  toggleSelection(user: User): void {
    const idx = this.shareList.indexOf(user.email);

    if (idx > -1) {
      this.shareList.splice(idx, 1);
      this.shareListFull.splice(idx, 1);
    }
    else {
      this.shareList.push(user.email);
      this.shareListFull.push({email: user.email, firstName: user.firstName, lastName: user.lastName});
    }
  }

  trackByFullName(index: number, candidate: Candidate): string {
    return candidate.firstName + ' ' + candidate.lastName;
  }

  save(fieldName: string): void {
    if (this.isSimulated) {
      return;
    }

    this.savePartially(fieldName);
  }

  private savePartially(fieldName: string): void {
    if (this.isSimulated) {
      return;
    }

    if (['lookingCard', 'notLookingCard'].includes(fieldName)) {
      return;
    }

    this.alertService.clear();

    if (!this.fields.uuid) {
      this.candidatesService.upsertInnerview(this.fields).subscribe((result: any) => {
        if (result.innerview.uuid) {
          this.fields.uuid = result.innerview.uuid;
        }
      });
    } else {
      const fields = _.pick(this.fields, ['uuid', 'client_uuid', 'position', fieldName]);

      this.candidatesService.upsertInnerview(fields).subscribe(() => { });
    }
  }

  download(): void {
    if (this.isSimulated) {
      const link = document.createElement('a');
      link.setAttribute('href', this.serverService.api('/documents/merge/Innerview/' + this.fields.uuid));
      document.body.appendChild(link); // Required for FF
      link.click();

      this.alertService.success('Downloaded and saved.', this.innerviewDownload.nativeElement);
    } else {
      this.candidatesService.upsertInnerview(this.fields).subscribe((result: any) => {
        if (result.innerview.uuid) {
          this.fields.uuid = result.innerview.uuid;

          const link = document.createElement('a');
          link.setAttribute('href', this.serverService.api('/documents/merge/Innerview/' + this.fields.uuid));
          document.body.appendChild(link); // Required for FF
          link.click();

          this.alertService.success('Downloaded and saved.', this.innerviewDownload.nativeElement);
        }
      });
    }
  }

  accept(): void {
    if (this.isSimulated) {
      return;
    }

    this.candidatesService.upsertInnerview(this.fields).subscribe((result: any) => {
      if (result.innerview.uuid) {
        this.fields.uuid = result.innerview.uuid;
      }

      this.alertService.success('Got it!', this.innerviewSave.nativeElement);
    });
  }

  share(): void {
    if (this.isSimulated) {
      const modalRef = this.modalService.open(CommonDialogComponent);

      modalRef.componentInstance.closeButtonText = 'Cancel';
      modalRef.componentInstance.actionButtonText = 'Send';
      modalRef.componentInstance.headerText = 'Share the InnerView Form';
      modalRef.componentInstance.bodyText = 'Do you want to email a copy of the InnerView form to the following people? ';
      modalRef.componentInstance.list = this.shareList;

      modalRef.result.then(() => {
        const session = this.sessionStateService.get();
        const user = (session.session.simulatedSession || session.user) as User & Session;

        if (user.user_uuid) {
          user.uuid = user.user_uuid;
        }

        this.documentsService.share({
          name: 'Innerview',
          uuid: this.fields.uuid,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          recipients: this.shareListFull
        }).subscribe(() => {
          this.alertService.success('InnerView was shared with selected recipients');
        });
      }).catch(() => { });
    } else {
      this.candidatesService.upsertInnerview(this.fields).subscribe((result: any) => {
        if (result.innerview.uuid) {
          this.fields.uuid = result.innerview.uuid;

          const modalRef = this.modalService.open(CommonDialogComponent);

          modalRef.componentInstance.closeButtonText = 'Cancel';
          modalRef.componentInstance.actionButtonText = 'Send';
          modalRef.componentInstance.headerText = 'Share the InnerView Form';
          modalRef.componentInstance.bodyText = 'Do you want to email a copy of the InnerView form to the following people? ';
          modalRef.componentInstance.list = this.shareList;

          modalRef.result.then(() => {
            const session = this.sessionStateService.get();
            const user = (session.session.simulatedSession || session.user) as User & Session;

            if (user.user_uuid) {
              user.uuid = user.user_uuid;
            }

            this.documentsService.share({
              name: 'Innerview',
              uuid: result.innerview.uuid,
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              recipients: this.shareListFull
            }).subscribe(() => {
              this.alertService.success('InnerView was shared with selected recipients');
            });
          }).catch(() => { });
        }

        this.alertService.success('InnerView saved');
      });
    }
  }

  changedCandidate(): void {
    if (this.candidate) {
      this.fields = {};
      _.merge(this.fields, this.defaultFields);

      this.candidatesService.lookupInnerview(this.candidate.uuid || '').subscribe((response: any) => {
        const reference = response.innerview;

        if (!reference.error) {
          reference.date = new Date(reference.date).toISOString().split('T')[0];

          _.merge(this.fields, _.omit(reference, ['lookingCard', 'notLookingCard']));
        }
      });

      this.candidatesService.lookup(this.candidate?.uuid || '').subscribe((fullcandidate) => {
        this.fields.candidate_uuid = fullcandidate.uuid;

        if (!this.fields.candidateFirstName) {
          this.fields.candidateFirstName = fullcandidate.firstName;
        }

        if (!this.fields.candidateLastName) {
          this.fields.candidateLastName = fullcandidate.lastName;
        }

        if (!this.fields.candidateTitle) {
          this.fields.candidateTitle = fullcandidate.title;
        }

        if (!this.fields.candidateCompany) {
          this.fields.candidateCompany = fullcandidate.employer;
        }

        if (fullcandidate.phoneNumbers) {
          for (let i = 0, iLimit = fullcandidate.phoneNumbers.length; i < iLimit; i++) {
            const phone = fullcandidate.phoneNumbers[i];

            switch (phone.type) {
              case 'work':
                if (!this.fields.workPhone || !this.fields.workPhone.length) {
                  this.fields.workPhone = phone.number;
                }
                break;

              case 'home':
                if (!this.fields.homePhone || !this.fields.homePhone.length) {
                  this.fields.homePhone = phone.number;
                }
                break;
            }
          }
        }
      });
    }
  }

  refreshCandidates(): void {
    const session = this.sessionStateService.get();
    const user = (session.session.simulatedSession || session.user) as User & Session;

    if (user.user_uuid) {
      user.uuid = user.user_uuid;
    }

    this.candidatesService.list(user.uuid || '').subscribe((candidates) => {
      if (!candidates || !candidates.length) {
        candidates = [];
      }

      this.candidates = candidates.sort((a, b) => (b.firstName?.toLowerCase() + ' ' + b.lastName?.toLowerCase()) >
        (a.firstName?.toLowerCase() + ' ' + a.lastName?.toLowerCase()) ? -1 : 1);

      if (this.latestCandidate) {
        this.candidatesService.lookup(this.latestCandidate.uuid || '').subscribe((fullcandidate) => {
          this.candidate = fullcandidate;
          this.changedCandidate();
        });

        this.latestCandidate = null;
      } else {
        const currentCandidate = this.eventBusService.getCandidate();

        if (currentCandidate) {
          this.candidatesService.lookup(currentCandidate.uuid).subscribe((fullcandidate) => {
            this.candidate = fullcandidate;
            this.changedCandidate();
          });
        } else {
          const candidateUuid = this.route.snapshot.queryParamMap.get('candidateUuid') ||
            (candidates.length === 1 ? candidates[0].uuid : null);

          if (candidateUuid) {
            this.candidatesService.lookup(candidateUuid).subscribe((fullcandidate) => {
              this.candidate = fullcandidate;
              this.changedCandidate();
            });
          }
        }
      }
    });
  }

  newCandidate(): void {
    if (this.isSimulated) {
      return;
    }

    const uuid = this.contentService.uuid();
    this.store.dispatch(new CandidatesActions.Show({ uuid }));

    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      size: 'lg'
    };

    const modalRef = this.modalService.open(CandidateDetailComponent, ngbModalOptions);
    modalRef.componentInstance.uuid = uuid;

    modalRef.result.then((result) => {
      this.current$.pipe(first()).subscribe((current: Candidate) => {
        this.latestCandidate = current;
      });

      if (result.uploadedItem) {
        this.store.dispatch(new CandidatesActions.UploadResume({ uuid, file: result.uploadedItem, initialNotes: result.initialNotes }));
      } else {
        this.store.dispatch(new CandidatesActions.Update({ uuid, initialNotes: result.initialNotes }));

        this.store.dispatch(new CandidatesActions.Finished({ uuid }));
      }

      if (result.another) {
        this.newCandidate();
      }
    }).catch(() => { });
  }

  showExample(): void {
    const url = this.serverService.path('public/content/documents/samples/CandidateInnerviewExample.html');

    window.open(url, '_blank', 'popup=yes,width=850,height=700');
  }

  addToPlan(obj: any): void {
    if (this.isSimulated) {
      return;
    }

    this.actionInProgress = true;

    const session = this.sessionStateService.get();
    const user = (session.user as User);

    const saveToCandidatePromise = new Promise((resolve) => {
      if (obj.addToRec) {
        const candidate: Candidate = {
          firstName: obj.firstName,
          lastName: obj.lastName,
          title: obj.title,
          employer: obj.company,
          active: true,
          private: false,
          memo: obj.notes,
          emails: [],
          phoneNumbers: [],
          addresses: [],
          owner: user.uuid,
          account: user.account_uuid
        };

        if (obj.email.address) {
          candidate.emails.push({ type: EmailType.work, address: obj.email.address });
        }

        if (obj.phoneNumber.number) {
          candidate.phoneNumbers.push({ type: PhoneType.work, number: obj.phoneNumber.number });
        }

        this.candidatesService.insert(candidate).subscribe(() => {
          resolve(true);
        });
      } else {
        resolve(false);
      }
    });

    const saveToClientPromise = new Promise((resolve) => {
      if (obj.addToMkt) {
        const client: Client = {
          firstName: obj.firstName,
          lastName: obj.lastName,
          title: obj.title,
          company: obj.company,
          active: true,
          private: false,
          memo: obj.notes,
          emails: [],
          phoneNumbers: [],
          addresses: [],
          owner: user.uuid,
          account: user.account_uuid
        };

        if (obj.email.address) {
          client.emails.push({ type: EmailType.work, address: obj.email.address });
        }

        if (obj.phoneNumber.number) {
          client.phoneNumbers.push({ type: PhoneType.work, number: obj.phoneNumber.number });
        }

        this.clientsService.insert(client).subscribe(() => {
          resolve(true);
        });
      } else {
        resolve(false);
      }
    });

    const addToRecPromise = new Promise((resolve) => {
      if (obj.addToRec) {
        this.plannerService.get().subscribe((planner) => {
          planner.recruitingCalls.push({
            status: 'none',
            project: '',
            candidate: obj.firstName + ' ' + obj.lastName,
            title: obj.title,
            company: obj.company,
            phone: obj.phoneNumber.number,
            hiringManager: user.firstName + ' ' + user.lastName,
            memo: '',
            action: '',
            notes: obj.notes
          });

          this.plannerService.update( _.pick(planner, ['uuid', 'recruitingCalls'])).subscribe(() => {
            resolve(true);
          });
        });
      } else {
        resolve(false);
      }
    });

    const addToMktPromise = new Promise((resolve) => {
      if (obj.addToMkt) {
        this.plannerService.get().subscribe((planner) => {
          planner.newMarketing.push({
            status: 'none',
            company: obj.company,
            phone: obj.phoneNumber.number,
            email: obj.email.address,
            title: obj.title,
            hiringManager: obj.firstName + ' ' + obj.lastName,
            result: '',
            notes: obj.notes
          });

          this.plannerService.update( _.pick(planner, ['uuid', 'newMarketing'])).subscribe(() => {
            resolve(true);
          });
        });
      } else {
        resolve(false);
      }
    });

    Promise.all([
      saveToCandidatePromise,
      saveToClientPromise,
      addToRecPromise,
      addToMktPromise
    ]).then(() => {
      obj.firstName = '';
      obj.lastName = '';
      obj.company = '';
      obj.title = '';
      obj.phoneNumber.number = '';
      obj.email.address = '';
      obj.notes = '';

      obj.addToRec = false;
      obj.addToMkt = false;

      this.alertService.success('Got it!');
      this.actionInProgress = false;
    });
    /*
    this.plannerService.get().subscribe((planner) => {
      planner.recruitingCalls.push({
        status: 'none',
        project: '',
        candidate: (obj.firstName || '') + ' ' + (obj.lastName || ''),
        title: obj.title || '',
        company: obj.company || '',
        phone: obj.phoneNumber?.number,
        hiringManager: '',
        memo: '',
        action: '',
        notes: obj.notes || ''
      });

      this.plannerService.update( _.pick(planner, ['uuid', 'recruitingCalls'])).subscribe(() => {
        this.alertService.success('Record was added to the Recruiting Calls section of the Daily Planner.');
      });
    });
    */
  }

  validate(obj: any): boolean {
    return !this.actionInProgress && (!!obj.firstName && !!obj.lastName) && (!!obj.addToRec || !!obj.addToMkt);
  }

  compareCandidates(a: Candidate, b: Candidate): boolean {
    return !!a && !!b && a.uuid === b.uuid;
  }
}
