import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';

import { AlertService } from '../../shared/alert.service';
import { ServerService } from '../../shared/server.service';

import { Client } from './clients.model';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(
    private http: HttpClient,
    private serverService: ServerService,
    private alertService: AlertService) { }

  lookup(uuid: string): Observable<Client> {
    return this.http.get(this.serverService.api('/clients', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to fetch client ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  list(owner: string, search?: string, offset?: number, limit?: number, column?: string, order?: string): Observable<Client[]> {
    const options = [];

    if (search) {
      options.push('search=' + search);
    }

    if (offset) {
      options.push('offset=' + offset);
    }

    if (limit) {
      options.push('limit=' + limit);
    }

    if (column) {
      options.push('column=' + column);
    }

    if (order) {
      options.push('order=' + order);
    }

    return this.http.get(this.serverService.api('/clients', 'byowner', owner) +
      (options.length ? '?' : '') + options.join('&'), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to list clients ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  listByAccount(account: string, search?: string, offset?: number, limit?: number, column?: string, order?: string): Observable<Client[]> {
    const options = [];

    if (search) {
      options.push('search=' + search);
    }

    if (offset) {
      options.push('offset=' + offset);
    }

    if (limit) {
      options.push('limit=' + limit);
    }

    if (column) {
      options.push('column=' + column);
    }

    if (order) {
      options.push('order=' + order);
    }

    return this.http.get(this.serverService.api('/clients', 'byaccount', account) +
      (options.length ? '?' : '') + options.join('&'), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to list clients ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  insert(client: Client): Observable<Client> {
    return this.http.post(this.serverService.api('/clients'), { client }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to insert a client ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  update(client: Client): Observable<Client> {
    return this.http.put(this.serverService.api('/clients'), { client }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to update a client ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  delete(uuid: string): Observable<any> {
    return this.http.delete(this.serverService.api('/clients', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to delete a client ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  //

  lookupSearchAssignment(uuid: string): Observable<any> {
    return this.http.get(this.serverService.api('/searchassignments', 'byuuid', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to lookup SearchAssignment ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  lookupSearchAssignmentPosition(clientuuid: string, position: string): Observable<any> {
    return this.http.get(this.serverService.api('/searchassignments', 'byclient', clientuuid, position), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to lookup SearchAssignment position ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  listSearchAssignments(useruuid: string): Observable<any[]> {
    return this.http.get(this.serverService.api('/searchassignments/list', useruuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to list SearchAssignment ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  listSearchAssignmentsByClient(clientuuid: string): Observable<any[]> {
    return this.http.get(this.serverService.api('/searchassignments/listbyclient', clientuuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to list SearchAssignment ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  upsertSearchAssignment(searchAssignment: any): Observable<any> {
    return this.http.put(this.serverService.api('/searchassignments'), searchAssignment, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to upsert a search assignment ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  removeSearchAssignment(uuid: string): Observable<any> {
    return this.http.delete(this.serverService.api('/searchassignments', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to delete a search assignment ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  bulkInsert(clients: Client[]): Observable<{client: {clients: Client[], areDuplicatesAvoided: boolean}}> {
    return this.http.post(this.serverService.api('/clients/batch'), { clients }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to create clients ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }
}
