import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';

import { Rebuttal } from './objection.model';
import { AlertService } from '../../alert.service';
import { ServerService } from '../../server.service';

@Injectable({
  providedIn: 'root'
})
export class RebuttalsService {

  constructor(
    private http: HttpClient,
    private serverService: ServerService,
    private alertService: AlertService) { }

  lookup(uuid: string): Observable<Rebuttal> {
    return this.http.get(this.serverService.api('/rebuttals/lookup', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to fetch a rebuttal ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  listByMorgan(objectionuuid: string): Observable<Rebuttal[]> {
    return this.http.get(this.serverService.api('/rebuttals/list', objectionuuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to list rebuttals ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  listByUser(objectionuuid: string, owner: string): Observable<Rebuttal[]> {
    return this.http.get(this.serverService.api('/rebuttals/list', objectionuuid, owner), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to list rebuttals ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  insertByMorgan(rebuttal: Rebuttal): Observable<Rebuttal> {
    return this.http.post(this.serverService.api('/rebuttals/bymorgan'), { rebuttal }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to insert a rebuttal ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  insertByUser(rebuttal: Rebuttal): Observable<Rebuttal> {
    return this.http.post(this.serverService.api('/rebuttals/byowner'), { rebuttal }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to insert a rebuttal ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  updateByMorgan(rebuttal: Rebuttal): Observable<Rebuttal> {
    return this.http.put(this.serverService.api('/rebuttals/bymorgan'), rebuttal, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to update a rebuttal ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  updateByUser(rebuttal: Rebuttal): Observable<Rebuttal> {
    return this.http.put(this.serverService.api('/rebuttals/byowner'), { rebuttal }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to update a rebuttal ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  removeByMorgan(uuid: string): Observable<any> {
    return this.http.delete(this.serverService.api('/rebuttals/bymorgan', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to delete a rebuttal ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  removeByUser(uuid: string): Observable<any> {
    return this.http.delete(this.serverService.api('/rebuttals/byowner', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to delete a rebuttal ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  upvote(uuid: string): Observable<Rebuttal> {
    return this.http.get(this.serverService.api('/rebuttals/upvote', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to upvote a rebuttal ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  downvote(uuid: string): Observable<Rebuttal> {
    return this.http.get(this.serverService.api('/rebuttals/downvote', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to downvote a rebuttal ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }
}
