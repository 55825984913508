import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Columns } from 'ngx-easy-table/lib';
import { DefaultConfig } from 'ngx-easy-table';
import { Observable, of } from 'rxjs';
import { UserActivity } from '../../user-activity.model';
import { OwlDateTimeComponent } from 'ng-pick-datetime';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit, AfterViewInit {
  @ViewChild('loggedInAt', { static: true }) loggedInAt?: TemplateRef<any>;
  @ViewChild('loggedOutAt', { static: true }) loggedOutAt?: TemplateRef<any>;
  @ViewChild('sessionDuration', { static: true }) sessionDuration?: TemplateRef<any>;
  @ViewChild('dt1') dateTimePicker?: OwlDateTimeComponent<any>;

  columns: Columns[] = [];
  public configuration = {
    ...DefaultConfig,
    rows: 100
  };


  userActivity$?: Observable<UserActivity[]> = of(
    [
      new UserActivity(
        'Anna Moron',
        'anna@gmail.com',
        'annamoron',
        new Date(2024, 3, 10, 1, 0, 0).toString(),
        new Date(2024, 3, 10, 5, 30, 0).toString(),
      )
    ]
  );

  filters: {
    search?: string;
  } = {};

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.setColumns();
  }

  setColumns(): void {
    this.columns = [
      { key: 'username', title: 'User', width: '15%' },
      { key: 'email', title: 'Email', width: '15%' },
      { key: 'account', title: 'Account', width: '15%' },
      { key: 'loggedInAt', title: 'Logged In', width: '15%', cellTemplate: this.loggedInAt },
      { key: 'loggedOutAt', title: 'Logged out', width: '15%', cellTemplate: this.loggedOutAt },
      { key: 'sessionDuration', title: 'Session duration', width: '15%', cellTemplate: this.sessionDuration },
    ];
  }

  onSearchChange(): void {
    console.log(this.filters);
  }

  onDateRangeChange(e: any): void {
    console.log(e);
    const fromDate = e.value[0];
    console.log(fromDate, new Date(fromDate).getTime());
  }


  onDateRangeClearClick(dateRangeInput: HTMLInputElement, dateTimePicker: OwlDateTimeComponent<any>): void {
    dateRangeInput.value = '';
    dateRangeInput.dispatchEvent(new Event('input'));
    dateTimePicker.selecteds = [];
    dateTimePicker.confirmSelect();
    dateTimePicker.opened = false;
  }


}
