import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { User } from '../../../models/user.model';

import { Objection, Rebuttal } from '../objection.model';
import { RebuttalsService } from '../rebuttals.service';
import { SessionStateService } from '../../../session-state.service';

import { CommonDialogComponent } from '../../../common-dialog/common-dialog.component';

@Component({
  selector: 'app-objection',
  templateUrl: './objection.component.html',
  styleUrls: ['./objection.component.scss']
})
export class ObjectionComponent implements OnInit {

  objection: Objection = { createdBy: '', script: '', private: true };
  rebuttals: Rebuttal[] = [];
  isNew = true;
  isNotAdmin = true;
  isNotAccountAdmin = true;
  isSuperUser = false;
  isOwner = false;

  userUuid: string | undefined = '';

  rebuttal: Rebuttal | null = null;
  rebuttalIndex = -1;
  delayedRebuttals: Rebuttal[] = [];

  tinymceOptions = {
    base_url: '/tinymce',
    suffix: '.min',
    plugins: 'link spellchecker image table pagebreak charmap textcolor colorpicker hr lists',
    content_css: '/assets/styles/editor.css',
    toolbar: ['cut copy paste | link unlink | undo redo | image table pagebreak hr charmap | spellchecker | fullscreen | bullist numlist outdent indent | forecolor backcolor',
              'bold italic strikethrough underline | alignleft aligncenter alignright alignjustify | styleselect | formatselect'],
    height: '300px',
    menu: false,
    menubar: false,
    statusbar: false
  };

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private rebuttalsService: RebuttalsService,
    private sessionStateService: SessionStateService) { }

  ngOnInit(): void {
    this.reload();
  }

  reload(): void {
    this.isNew = !this.objection.uuid;

    const session = this.sessionStateService.get();
    const user = (session.user as User);
    this.userUuid = user.uuid;

    this.isNotAdmin = (user.roles.indexOf('admin') === -1);
    this.isNotAccountAdmin = (user.roles.indexOf('account-admin') === -1);
    this.isSuperUser = (user.roles.indexOf('super-user') > -1);
    this.isOwner = this.isNew || (user.uuid === this.objection.user_uuid);

    if (!this.isNotAdmin && !this.objection.uuid) {
      this.objection.private = false;
    }

    if (this.objection.uuid && user.uuid) {
      if (this.isNotAdmin) {
        this.rebuttalsService.listByUser(this.objection.uuid, user.uuid)
          .subscribe(rebuttals => { this.rebuttals = rebuttals; });
      }
      else {
        this.rebuttalsService.listByMorgan(this.objection.uuid)
          .subscribe(rebuttals => { this.rebuttals = rebuttals; });
      }
    }
  }

  get objectionIsPrivate(): boolean {
    return !this.objection.private;
  }

  set objectionIsPrivate(newValue: boolean) {
    this.objection.private = !newValue;
  }

  addRebuttal(): void {
    const session = this.sessionStateService.get();
    const user = (session.user as User);

    this.rebuttal = {
      private: this.isNotAdmin,
      script: '',
      objection_uuid: this.objection.uuid || '',
      user_uuid: user.uuid || '',
      account_uuid: this.isNotAdmin ? user.account_uuid : null,
      upvotes: 0,
      downvotes: 0,
      order: this.rebuttals.length + 1,
      createdBy: user.username
    };
  }

  editRebuttal(index: number): void {
    this.rebuttal = this.rebuttals[index];
    this.rebuttalIndex = index;
  }

  get rebuttalIsPrivate(): boolean {
    return !this.rebuttal?.private;
  }

  set rebuttalIsPrivate(newValue: boolean) {
    if (this.rebuttal) {
      this.rebuttal.private = !newValue;
    }
  }

  accept(): void {
    const session = this.sessionStateService.get();
    const user = (session.user as User);

    if (this.rebuttal) {
      if (!this.isNew) {
        if (this.rebuttal.uuid) {
          this.rebuttals[this.rebuttalIndex].script = this.rebuttal.script;

          if (this.isNotAdmin) {
            this.rebuttals[this.rebuttalIndex].private = this.rebuttal.private;
            this.rebuttals[this.rebuttalIndex].account_uuid = user.account_uuid;

            this.rebuttalsService.updateByUser(this.rebuttals[this.rebuttalIndex]).subscribe(() => {
              this.rebuttal = null;
              this.rebuttalIndex = -1;
            });
          }
          else {
            this.rebuttals[this.rebuttalIndex].private = false;

            this.rebuttalsService.updateByMorgan(this.rebuttals[this.rebuttalIndex]).subscribe(() => {
              this.rebuttal = null;
              this.rebuttalIndex = -1;
            });
          }
        } else {
          if (this.isNotAdmin) {
            this.rebuttalsService.insertByUser(this.rebuttal).subscribe(() => {
              // @ts-ignore
              this.rebuttals.push(this.rebuttal);
              this.rebuttal = null;
              this.rebuttalIndex = -1;
            });
          }
          else {
            this.rebuttalsService.insertByMorgan(this.rebuttal).subscribe(() => {
              // @ts-ignore
              this.rebuttals.push(this.rebuttal);
              this.rebuttal = null;
              this.rebuttalIndex = -1;
            });
          }
        }
      } else {
        this.delayedRebuttals.push(this.rebuttal);
        this.rebuttal = null;
        this.rebuttalIndex = -1;
      }
    }
  }

  cancel(): void {
    this.rebuttal = null;
  }

  deleteRebuttal(index: number): void {
    const modalRef = this.modalService.open(CommonDialogComponent);

    modalRef.componentInstance.closeButtonText = 'Cancel';
    modalRef.componentInstance.actionButtonText = 'Delete';
    modalRef.componentInstance.headerText = 'Delete Rebuttal';
    modalRef.componentInstance.bodyText = 'Are you sure you want to delete this rebuttal?';

    modalRef.result.then(() => {
      if (!this.isNew) {
        const rebuttal = this.rebuttals[index];

        if (rebuttal.uuid) {
          if (this.isNotAdmin) {
            this.rebuttalsService.removeByUser(rebuttal.uuid).subscribe(() => {
              this.rebuttals.splice(index, 1);
            });
          }
          else {
            this.rebuttalsService.removeByMorgan(rebuttal.uuid).subscribe(() => {
              this.rebuttals.splice(index, 1);
            });
          }
        }
      } else {
        this.delayedRebuttals.splice(index, 1);
      }
    }).catch(() => { });
  }

  upvoteRebuttal(rebuttal: Rebuttal): void {
    if (rebuttal.uuid) {
      this.rebuttalsService.upvote(rebuttal.uuid)
        .subscribe(() => {
          rebuttal.upvotes++;
        });
    }
  }

  downvoteRebuttal(rebuttal: Rebuttal): void {
    if (rebuttal.uuid) {
      this.rebuttalsService.downvote(rebuttal.uuid)
        .subscribe(() => {
          rebuttal.downvotes++;
        });
    }
  }

  update(): void {
    this.activeModal.close({ objection: this.objection, rebuttals: this.delayedRebuttals });
  }

  remove(): void {
    const modalRef = this.modalService.open(CommonDialogComponent);

    modalRef.componentInstance.closeButtonText = 'Cancel';
    modalRef.componentInstance.actionButtonText = 'Delete';
    modalRef.componentInstance.headerText = 'Delete Objection';
    modalRef.componentInstance.bodyText = 'Are you sure you want to delete this objection?';

    modalRef.result.then(() => {
      this.activeModal.close('remove');
    }).catch(() => { });
  }

  close(): void {
    this.activeModal.dismiss();
  }
}
