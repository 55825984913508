<div class="modal-header" *ngIf="{ candidate: current$ | async } as data" appDraggable=".modal-dialog">
    <h4 class="modal-title">{{ data.candidate && data.candidate.uuid ? 'Edit' : 'New' }} Candidate</h4>
    <button type="button" class="close" aria-hidden="true" (click)="close()">&times;</button>
</div>
<div class="modal-body" *ngIf="{ isUnique: isUnique$ | async } as data">
    <form [formGroup]="form">

        <div class="form-group row">
            <label class="col-3 control-label text-right">First Name:</label>
            <div class="col-9">
                <input type="text" class="form-control" formControlName="firstName" appAutofocus />
                <small [hidden]="data.isUnique" class="form-text text-danger">Candidate with this first name and/or last name already exists; please change the name.</small>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 control-label text-right">Last Name:</label>
            <div class="col-9">
                <input type="text" class="form-control" formControlName="lastName" />
                <small [hidden]="data.isUnique" class="form-text text-danger">Candidate with this first name and/or last name already exists; please change the name.</small>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 control-label text-right">Title:</label>
            <div class="col-9">
                <input type="text" class="form-control" formControlName="title" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 control-label text-right">Employer:</label>
            <div class="col-9">
                <input type="text" class="form-control" formControlName="employer" />
            </div>
        </div>

        <app-address-form
          [initialValue]="form.value"
          [isSimulated]="isSimulated"
          (formValueChanged)="onAddressFormValueChange($event)"
          (onAddToPlanClick)="addToPlan()">
        </app-address-form>

        <div class="form-group row" *ngIf="{ candidate: current$ | async } as data">
            <label class="col-3 control-label text-right">Resume:</label>
            <div class="col-9">
                <span *ngIf="!!data.candidate" [hidden]="!data.candidate.resume"><span class="form-control-static">{{data.candidate.resume}}</span>&nbsp;&nbsp;&nbsp;<a class="link" href="javascript:void(0)" (click)="resumeDownload(data.candidate)">View Resume</a><br /><br /></span>
                <input type="file" [disabled]="isSimulated" (change)="onFileChange($event)" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 control-label text-right">Skills:</label>
            <div class="col-9">
                <textarea style="height: 200px" class="col-sm-12 form-control" formControlName="skills"></textarea>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3 control-label text-right">Memo:</label>
            <div class="col-9">
                <textarea style="height: 200px" class="col-sm-12 form-control" formControlName="memo"></textarea>
            </div>
        </div>
    </form>

    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-candidate-emails,ngb-candidate-phones" *ngIf="current$ | async as candidate">
        <ngb-panel id="ngb-candidate-emails">
            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="m-0">Emails</h5>
                    <div>
                        <button ngbPanelToggle class="btn btn-outline-primary">
                            <i [hidden]="opened" class="fas fa-plus"></i>
                            <i [hidden]="!opened" class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <ul class="list-group p-0 col-12">
                    <li class="list-group-item" *ngFor="let email of candidate.emails; index as i">
                        <div class="row">
                            <div class="col-sm-4">
                                <select class="form-control" [value]="email.type" [disabled]="isSimulated" (change)="setEmailType(i, $event.target)">
                                    <option value="work">Work</option>
                                    <option value="home">Home</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            <div class="col-sm-6 p-0">
                                <input class="form-control" type="email" [value]="email.address" (input)="setEmailAddress(i, $event.target)" required />
                            </div>
                            <div class="col-sm-2">
                                <div class="delete-icon float-right" [hidden]="isSimulated" (click)="removeEmail(i)"><i class="fas fa-times-circle"></i></div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item"><input class="btn btn-primary" type="button" value="New" [disabled]="isSimulated" (click)="addEmail()" appFocusNewInput /></li>
                </ul>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="ngb-candidate-phones">
            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="m-0">Phone Numbers</h5>
                    <div>
                        <button ngbPanelToggle class="btn btn-outline-primary">
                            <i [hidden]="opened" class="fas fa-plus"></i>
                            <i [hidden]="!opened" class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <ul class="list-group p-0 col-12">
                    <li class="list-group-item" *ngFor="let phone of candidate.phoneNumbers; index as i">
                        <div class="row">
                            <div class="col-sm-4">
                                <select class="form-control" [value]="phone.type" [disabled]="isSimulated" (change)="setPhoneType(i, $event.target)">
                                    <option value="work">Work</option>
                                    <option value="home">Home</option>
                                    <option value="mobile">Mobile</option>
                                    <option value="fax">Fax</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            <div class="col-sm-6 p-0">
                                <input class="form-control" type="text" [(ngModel)]="phone.number" mask="(000) 000-0000" [showMaskTyped]="true" (input)="setPhoneNumber(i, $event.target)" required />
                            </div>
                            <div class="col-sm-2">
                                <div class="delete-icon float-right" [hidden]="isSimulated" (click)="removePhone(i)"><i class="fas fa-times-circle"></i></div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item"><input class="btn btn-primary" type="button" value="New" [disabled]="isSimulated" (click)="addPhone()" appFocusNewInput /></li>
                </ul>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="ngb-candidate-notes">
            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="m-0">Notes</h5>
                    <div>
                        <button ngbPanelToggle class="btn btn-outline-primary">
                            <i [hidden]="opened" class="fas fa-plus"></i>
                            <i [hidden]="!opened" class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-notes [ownerUUID]="candidate.uuid || ''" (notesChangedEvent)="notesChanged($event)"></app-notes>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>

<div class="modal-footer" *ngIf="{ candidate: current$ | async, isUnique: isUnique$ | async } as data">
    <button
        type="submit"
        class="btn btn-secondary"
        (click)="save(true)"
        [hidden]="data.candidate && data.candidate.uuid"
        [disabled]="isSimulated || !data.isUnique || !form.valid">Save and Create Another</button>
    <button
        type="submit"
        class="btn btn-secondary"
        (click)="save()"
        [disabled]="isSimulated || !data.isUnique || !form.valid">Save</button>
    <button
        type="button"
        class="btn btn-danger"
        [hidden]="isSimulated || !data.candidate || !data.candidate.uuid"
        (click)="close(true)">Delete</button>
    <button
        type="button"
        class="btn btn-default"
        (click)="close()">Cancel</button>
</div>
