<app-page-title class="report-page-title" [pageTitle]="'Company Activity Report' + (companyName ? ': ' + companyName : '')" [iconClass]="'fa-chart-area'">
  <div before>
    <ng-container *ngIf="isAdmin"><app-go-back-btn ></app-go-back-btn></ng-container>
  </div>
  <app-download-report-pdf
    after
    [fileName]="companyName + ' Activity Report.pdf'"
    [containerId]="'companyReportsContainer'">
  </app-download-report-pdf>
</app-page-title>

<div class="container-fluid main-container report-container" id="companyReportsContainer">
  <ng-container *ngIf="reports?.length; else noData">
    <ngx-table
      class="ngx-table__row-pointer"
      [data]="reports"
      [configuration]="configuration"
      [columns]="columns"
      [summaryTemplate]="summaryTemplateRef"
      (event)="onRowClick($event)">
    </ngx-table>
  </ng-container>
</div>


<ng-template #nameTpl let-row>
  <span>{{row.user?.username}}</span>
</ng-template>

<ng-template #fullNameTpl let-row>
  <span>{{row.user?.firstName}} {{row.user?.lastName}}</span>
</ng-template>

<ng-template #sessionDurationTpl let-row>
  <ng-container >
    <span>{{row.activeSessionsTotal ? (row.activeSessionsTotal | timestampToDuration) : '0 minutes'}} </span>
  </ng-container>
</ng-template>

<ng-template #loginTpl let-row>
  <span>{{row.user?.lastLogin | date: 'medium'}}</span>
</ng-template>

<ng-template #summaryTemplateRef let-total="total" let-limit="limit" let-page="page">
  <th colspan="2">Total:</th>
  <th>{{totalInnerViewsCount || 0}}</th>
  <th>{{totalSearchAssignmentCount || 0}}</th>
  <th>{{totalReferenceChecksCount || 0}}</th>
  <th>{{totalSessionDuration ? (totalSessionDuration | timestampToDuration) : '0 minutes'}}</th>
  <th></th>
</ng-template>

<ng-template #noData>
  <app-no-data *ngIf="!pageSpinnerService.isLoading" [text]="'There are no records'"></app-no-data>
</ng-template>
