import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerService } from '../../shared/server.service';
import { AlertService } from '../../shared/alert.service';
import { catchError, first, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import {
  AccountActivityReport,
  GeneralActivityReport,
  UserActivity,
  UserActivityReport
} from './activity-report.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityReportService {

  constructor(
    private http: HttpClient,
    private serverService: ServerService,
    private alertService: AlertService) { }

  list(): Observable<UserActivity[]> {
    return this.http.get(this.serverService.api('/useractivity'), this.serverService.apiConfig())
      .pipe(map((response: any) => {
        return response;
      }));
  }

  findByUserUuid(uuid: string): Observable<UserActivity[]> {
    return this.http.get(this.serverService.api(`/useractivity/${uuid}`), this.serverService.apiConfig())
      .pipe(map((response: any) => {
        return response;
      }));
  }

  deleteAll(): Observable<any> {
    return this.http.delete(this.serverService.api('/useractivity'), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to delete user activity ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  getUserActivityReport(uuid: string): Observable<UserActivityReport> {
    return this.http.get(this.serverService.api(`/activityreports/user/${uuid}`), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to load user activity report ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  getAccountUsersActivityReport(accountUuid: string): Observable<AccountActivityReport[]> {
    return this.http.get(this.serverService.api(`/activityreports/account/${accountUuid}`), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to load account activity report ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  getGeneralActivityReport(): Observable<GeneralActivityReport[]> {
    return this.http.get(this.serverService.api(`/activityreports/general`), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to load general account activity report ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }
}
