import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UsersService } from '../shared/users.service';
import { LoginDialogComponent } from './dialogs/login/login.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loggedIn = false;

  constructor(
    private modalService: NgbModal,
    private usersService: UsersService
  ) { }

  ngOnInit(): void {
    this.usersService.loginStatus().subscribe((sessionData) => {
      if (sessionData.status.loggedIn) {
        this.loggedIn = true;
      }
    });
  }

  login(): void {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static'
    };

    this.modalService.open(LoginDialogComponent, ngbModalOptions);
  }
}
