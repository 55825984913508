import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ChecklistCategory, ChecklistItem} from '../nested-checklist/nested-checklist.component';

@Component({
  selector: 'app-export-data-dialog',
  templateUrl: './export-data-dialog.component.html',
  styleUrls: ['./export-data-dialog.component.scss']
})
export class ExportDataDialogComponent {
  @Input() categories?: ChecklistCategory[];
  @Output() exportClicked = new EventEmitter<ChecklistItem[]>();

  isSelection = false;
  constructor(private activeModal: NgbActiveModal) { }

  save(): void {
    const selectedItems = this.getSelectedItems();
    this.exportClicked.emit(selectedItems);
  }

  getSelectedItems(): ChecklistItem[] {
    const selectedItems: ChecklistItem[] = [];
    this.categories?.forEach(c => {
      c.items.forEach(i => {
        if (i.checked) {
          selectedItems.push(i);
        }
      });
    });
    return selectedItems;
  }

  close(): void {
    this.activeModal.dismiss();
  }

  resetFields(): void {
    if (!this.categories) {
      return;
    }
    this.categories?.forEach(c => {
      c.checked = false;
      c.items.forEach(i => i.checked = false);
    });
  }

  onCategoriesSelectionChanged(categories: ChecklistCategory[]): void {
    this.setSelection();
  }

  setSelection(): void {
    this.isSelection = false;
    this.categories?.forEach(c => {
      if (c.items.some(i => i.checked)) {
        this.isSelection = true;
      }
    });
  }

}
