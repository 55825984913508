import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';

import { Alert, AlertType, AlertService } from '../alert.service';

@Component({
    selector: 'app-alert',
    templateUrl: 'alert.component.html',
    styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {
    @Input() id = '';

    alerts: Alert[] = [];

    constructor(private alertService: AlertService, private cdr: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.alertService.alertQueue.subscribe((alert: Alert) => {
            if (!alert.message) {
                // clear alerts when an empty alert is received
                this.alerts = [];
                this.cdr.detectChanges();
                return;
            }

            // add alert to array
            this.alerts.push(alert);
            this.cdr.detectChanges();

            // Success message should disappear after 5 seconds
            if (alert.type === AlertType.Success) {
                setTimeout(() => {
                    this.removeAlert(alert);
                }, (alert.anchor ? 8000 : 5000));
            }
        });
    }

    removeAlert(alert: Alert): void {
        this.alerts = this.alerts.filter(x => x !== alert);
    }

    cssClass(alert: Alert): string {
        if (!alert) {
            return '';
        }

        // return css class based on alert type
        const classNames = ['alert'];

        if (!!alert.anchor) {
            classNames.push('alert-anchored');
        }

        switch (alert.type) {
            case AlertType.Success:
                classNames.push('alert-success');
                break;

            case AlertType.Error:
                classNames.push('alert-danger');
                break;

            case AlertType.Info:
                classNames.push('alert-info');
                break;

            case AlertType.Warning:
                classNames.push('alert-warning');
                break;
        }

        return classNames.join(' ');
    }

    isClient(alert: Alert): boolean {
        return !!alert.message && (alert.message.indexOf('client database') > -1);
    }

    isCandidate(alert: Alert): boolean {
        return !!alert.message && (alert.message.indexOf('candidate database') > -1);
    }
}
