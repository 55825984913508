import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { Observable, Subscription, BehaviorSubject, of } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';

import { User, UserType, ExperienceType } from '../../../shared/models/user.model';
import { UsersState } from '../users.state';
import { UsersActions } from '../users.actions';

import { PhoneType } from '../../../shared/models/phone.model';

import { SessionStateService } from '../../../shared/session-state.service';

@Component({
  selector: 'app-admin-notes',
  templateUrl: './admin-notes.component.html',
  styleUrls: ['./admin-notes.component.scss']
})
export class AdminNotesComponent implements OnInit, OnDestroy {

  // @ts-ignore
  @Select(UsersState.currentUsers) currentUsers$: Observable<{[key: string]: User}>;

  form: FormGroup;
  formSubscription: Subscription | null = null;
  initSubscription: Subscription | null = null;

  accountId = '';
  userId: string | undefined = undefined;

  current$: Observable<User | null> =
    of({
      firstName: '',
      lastName: '',
      email: '',
      username: '',
      userType: UserType.recruiter,
      account_uuid: '',
      experienceLevel: ExperienceType.rookie,
      password: '',
      active: true,
      hireDate: '',
      division: '',
      roles: ['member'],
      loginAttempts: 0,
      lockUntil: '',
      lastLogin: '',
      phoneNumbers: [{ type: PhoneType.work, number: '' }],
      addresses: [],
      memo: ''
    });

  uuid = '';

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private sessionStateService: SessionStateService,
    private store: Store
  ) {
    this.form = this.formBuilder.group({
      adminMemo: ['']
    });
  }

  ngOnInit(): void {
    const session = this.sessionStateService.get();
    const roles = (session.user as User)?.roles;

    this.current$ = this.currentUsers$.pipe(map(currentUsers => {
      return currentUsers[this.uuid];
    }));

    // @ts-ignore
    this.initSubscription = this.current$.subscribe((user: User | null) => {
      if (user) {
        this.userId = user.uuid;

        this.form.patchValue({
          adminMemo: user.adminMemo
        });

        if (this.initSubscription) {
          this.initSubscription.unsubscribe();
          this.initSubscription = null;
        }
      }

      setTimeout(() => {
        Object.values(this.form.controls).forEach(control => {
          control.markAsDirty();
        });

        this.formSubscription = this.form.valueChanges.subscribe(() => {
          this.updateMethodBackend();
        });
      }, 200);
    });
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }

    if (this.initSubscription) {
      this.initSubscription.unsubscribe();
      this.initSubscription = null;
    }
  }

  updateMethodBackend(): void {
    const user: any = {
      adminMemo: this.f.adminMemo.value
    };

    this.store.dispatch(new UsersActions.UpdateCurrent({ uuid: this.uuid, user }));
  }

  save(another?: boolean): void {
    this.updateMethodBackend();

    this.activeModal.close();
  }

  close(del?: boolean): void {
    this.activeModal.dismiss();
  }

  // convenience getter for easy access to form fields
  get f(): {[key: string]: AbstractControl} { return this.form.controls; }
}
