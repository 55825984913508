import { Address } from './address.model';
import { Phone } from './phone.model';
import { Settings } from './settings.model';

export enum UserType {
    'recruiter' = 'recruiter',
    'bizDev' = 'bizDev',
    'acctManager' = 'acctManager',
    'ownerManager' = 'ownerManager'
  }

export enum ExperienceType {
    'rookie' = 'rookie',
    'staff' = 'staff',
    'senior' = 'senior'
}

export interface User {
    firstName?: string;
    lastName?: string;

    email: string;
    username: string;
    userType: UserType;
    account_uuid: string;
    experienceLevel: ExperienceType;
    uuid?: string;
    password: string;
    active: boolean;
    createdBy?: string;
    hireDate?: string;
    division?: string;

    roles: string[];

    loginAttempts: number;
    lockUntil: '';
    lastLogin: '';

    phoneNumbers: Phone[];
    addresses: Address[];

    adminMemo?: string;
    memo: string;

    settings?: Settings;
}
