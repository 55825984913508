import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Injectable()
export class AlertService {
    private subject = new Subject<Alert>();
    private keepAfterRouteChange = false;

    public alertQueue: Observable<Alert> = this.subject.asObservable();

    constructor(private router: Router) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                } else {
                    // clear alert messages
                    this.clear();
                }
            }
        });
    }

    // convenience methods
    success(message: string, anchor?: HTMLElement): void {
        this.alert(new Alert({ message, type: AlertType.Success, anchor }));
    }

    error(message: string, keepAfterRouteChange: boolean = false): void {
      this.alert(new Alert({
        message,
        keepAfterRouteChange,
        type: AlertType.Error
      }));
    }

    info(message: string): void {
        this.alert(new Alert({ message, type: AlertType.Info }));
    }

    warn(message: string): void {
        this.alert(new Alert({ message, type: AlertType.Warning }));
    }

    // main alert method
    alert(alert: Alert): void {
        this.keepAfterRouteChange = alert.keepAfterRouteChange;
        this.subject.next(alert);
    }

    // clear alerts
    clear(alertId?: string): void {
        this.subject.next(new Alert({ alertId }));
    }
}

export class Alert {
    type: AlertType;
    message: string;
    alertId: string;
    keepAfterRouteChange: boolean;

    anchor: HTMLElement | null;
    top: number | undefined;
    left: number | undefined;

    constructor(init?: Partial<Alert>) {
        this.type = AlertType.Error;
        this.message = '';
        this.alertId = '';
        this.keepAfterRouteChange = false;

        this.anchor = null;
        this.top = undefined;
        this.left = undefined;

        Object.assign(this, init);

        if (this.anchor) {
            this.top = this.getOffsetTop(this.anchor as HTMLElement);
            this.left = this.getOffsetLeft(this.anchor as HTMLElement);
        }
    }

    getOffsetTop(elem: HTMLElement | null): number {
        let offsetTop = (elem?.offsetHeight || 0) + 3;

        do {
            if (elem && !isNaN(elem.offsetTop)) {
                offsetTop += elem.offsetTop - elem.scrollTop;
            }

            elem = (elem?.offsetParent as HTMLElement) || null;
        }
        while (elem);

        return offsetTop;
    }

    getOffsetLeft(elem: HTMLElement | null): number {
        let offsetLeft = 0;

        do {
            if (elem && !isNaN(elem.offsetLeft)) {
                offsetLeft += elem.offsetLeft - elem.scrollLeft;
            }

            elem = (elem?.offsetParent as HTMLElement) || null;
        }
        while (elem);

        return offsetLeft;
    }
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}
