<nav class="navbar navbar-default navbar-fixed-top navbar-global navbar-expand-md w-100" role="navigation">
  <div class="container-fluid">
    <div class="navbar-header">
      <button type="button" class="navbar-toggler" data-toggle="collapse" data-target=".navbar-collapse">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>

    <div class="flex-bar">
      <div class="branding-block">
        <img src="assets/images/MorganMentor2.png" class="header-logo" />
        <a class="navbar-brand" routerLink="/dashboard">RecruitersPlayBook&trade;</a>
      </div>
      <p class="dashboard-slogan" [hidden]="router.url !== '/dashboard'">Everything you need. Nothing more.</p>
    </div>

    <div class="navbar-collapse collapse justify-content-end" *ngIf="!isSimulated">
      <ul class="nav navbar-nav navbar-right">
        <li ngbDropdown *ngFor="let item of menu" class="ml-4 dropdown" appAutoopen>
          <a ngbDropdownToggle class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{{ item.name }} <span class="caret"></span></a>
          <ul ngbDropdownMenu class="dropdown-menu">
            <li role="presentation" [ngClass]="{'divider': (subitem.name.indexOf('separator') === 0), 'dropdown-submenu': (subitem.children && subitem.children.length)}" *ngFor="let subitem of item.children">
              <a role="menuitem" tabindex="-1" *ngIf="(subitem.name.indexOf('separator') === -1) && (subitem.name.indexOf('Tall Pony Ranch') === -1) && (subitem.name.indexOf('My Account') === -1)" (click)="toolsResolver(subitem.route)">{{ subitem.name }}</a>
              <a role="menuitem" tabindex="-1" *ngIf="(subitem.name.indexOf('Tall Pony Ranch') === 0)" href="{{subitem.route}}" target="_blank">{{ subitem.name }}</a>
              <a role="menuitem" tabindex="-1" *ngIf="(subitem.name.indexOf('My Account') === 0)" (click)="myAccount()">{{ subitem.name }}</a>
              <ul class="dropdown-menu" *ngIf="subitem.children">
                <li [ngClass]="{'divider': (subsubitem.name.indexOf('separator') === 0)}" *ngFor="let subsubitem of subitem.children">
                  <a tabindex="-1" *ngIf="(subsubitem.name.indexOf('separator') === -1)" (click)="toolsResolver(subsubitem.route)">{{ subsubitem.name }}</a>
		            </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

    </div>
  </div>
</nav>
