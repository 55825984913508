import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-summary-activity',
  templateUrl: './summary-activity.component.html',
  styleUrls: ['./summary-activity.component.scss']
})
export class SummaryActivityComponent implements OnInit {
  search = '';
  constructor() { }

  ngOnInit(): void {
  }

  onSearchChange(): void {

  }

}
