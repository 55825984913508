import {Component, EventEmitter, Input, Output} from '@angular/core';

export interface Tab {
  name: string;
  isActive: boolean;
  route?: string;
}
@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {
  @Input() tabs: Tab[] = [];
  @Output() onTabsChange = new EventEmitter<Tab[]>();

  onTabClick(tab: Tab): void {
    this.tabs.forEach(t => t.isActive = false);
    tab.isActive = true;
    this.onTabsChange.emit(this.tabs);
  }

}
