import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';

import { Objection, Rebuttal } from './objection.model';
import { AlertService } from '../../alert.service';
import { ServerService } from '../../server.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectionsService {

  constructor(
    private http: HttpClient,
    private serverService: ServerService,
    private alertService: AlertService) { }

  lookup(uuid: string): Observable<Objection> {
    return this.http.get(this.serverService.api('/objections/lookup', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to fetch an objection ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  listByMorgan(guide: string): Observable<Objection[]> {
    return this.http.get(this.serverService.api('/objections/list', guide), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to list objections ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  listByUser(guide: string, owner: string): Observable<Objection[]> {
    return this.http.get(this.serverService.api('/objections/list', guide, owner), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to list objections ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  insertByMorgan(objection: Objection, rebuttals: Rebuttal[]): Observable<Objection> {
    return this.http.post(this.serverService.api('/objections/bymorgan'), { objection, rebuttals }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to insert an objection ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  insertByUser(objection: Objection, rebuttals: Rebuttal[]): Observable<Objection> {
    return this.http.post(this.serverService.api('/objections/byowner'), { objection, rebuttals }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to insert an objection ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  updateByMorgan(objection: Objection): Observable<Objection> {
    return this.http.put(this.serverService.api('/objections/bymorgan'), { objection }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error(error.message);
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  updateByUser(objection: Objection): Observable<Objection> {
    return this.http.put(this.serverService.api('/objections/byowner'), { objection }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error(error.message);
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  removeByMorgan(uuid: string): Observable<any> {
    return this.http.delete(this.serverService.api('/objections/bymorgan', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to delete an objection ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  removeByUser(uuid: string): Observable<any> {
    return this.http.delete(this.serverService.api('/objections/byowner', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to delete an objection ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }
}
