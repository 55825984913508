import { Component, OnInit, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {

  @Input() blockToken = '';
  @Input() timeSlots: any;
  // @ts-ignore
  @Input() timeslotUpdater: BehaviorSubject<number>;
  @Output() timeSlotsOutput: any;

  timeValue = '';

  constructor() { }

  ngOnInit(): void {
    if (this.blockToken) {
      const params: string[] = this.blockToken.split('-');

      if (!this.timeSlots) {
        return;
      }

      const slots = this.timeSlots[params[0]];

      if (! slots) {
        return;
      }

      const slotValues = slots.split(' - ');

      switch (params[1]) {
        case 'start':
          this.timeValue = slotValues[0];
          break;

        case 'end':
          this.timeValue = slotValues[1];
          break;
      }
    }
  }

  setValue(): void {
    const params: string[] = this.blockToken.split('-');
    const slots = this.timeSlots[params[0]];

    const slotValues = slots.split(' - ');

    switch (params[1]) {
      case 'start':
        slotValues[0] = this.timeValue;
        this.timeSlots[params[0]] = slotValues.join(' - ');

        if (this.timeslotUpdater) {
          this.timeslotUpdater.next(new Date().getTime());
        }
        break;

      case 'end':
        slotValues[1] = this.timeValue;
        this.timeSlots[params[0]] = slotValues.join(' - ');

        if (this.timeslotUpdater) {
          this.timeslotUpdater.next(new Date().getTime());
        }
        break;
    }
  }
}
