<app-header></app-header>
<app-toolbar>
    <div class="btn-group float-right">
        <button type="button" class="btn btn-light" (click)="share()" [disabled]="!shareList.length">Share</button>
        <div class="btn-group" ngbDropdown role="group">
            <button class="btn btn-light dropdown-toggle-split" ngbDropdownToggle></button>
            <div ngbDropdownMenu class="dropdown-menu pb-0 dropdown-right" id="dropdownShareDropdown" aria-labelledby="dropdownShare">
                <label *ngFor="let user of users" class="dropdown-item">
                    <input type="checkbox" class="top1px" [value]="user.email" (click)="toggleSelection(user)" /> {{user.firstName}} {{user.lastName}}
                </label>
            </div>
        </div>
    </div>    
</app-toolbar>

<div class="container-fluid main-container mt-1" id="planner-container">
    <div class="container-fluid planner-block">
        <div class="left-block">Consultant: <span class="label label-default"><span>{{ user?.firstName }}</span>&nbsp;<span>{{ user?.lastName }}</span></span></div>
        <div class="right-block">Date: <span class="label label-default"><span>{{ date.weekday }}</span>&nbsp;<span>{{ date.date }}</span></span></div>
        <h4 class="text-center">My Daily Plan</h4>
    </div>
    
    <div class="container-fluid planner-block" *ngIf="planner">
        <div class="left-block">Suggested Time: <app-time-picker [timeSlots]="planner.timeSlots" [timeslotUpdater]="timeslotUpdateBus$" blockToken="existingCheckin-start"></app-time-picker>&nbsp;-&nbsp;<app-time-picker [timeSlots]="planner.timeSlots" [timeslotUpdater]="timeslotUpdateBus$" blockToken="existingCheckin-end"></app-time-picker></div>
        <h4 class="text-center wide">IN-PROGRESS CLIENT/CANDIDATE CHECK-IN</h4>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th>Candidate</th>
                    <th>Phone</th>
                    <th>Hiring Mgr. / Title</th>
                    <th>Phone</th>
                    <th>Issue</th>
                    <th>Result</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let checkin of planner.existingCheckin; let i = index" [ngClass]="{'complete': checkin.status == 'contacted'}">
                    <td class="no-border smaller">{{ (i + 1) + '.' }}</td>
                    <td class="call-status" [ngClass]="checkin.status"></td>
                    <td><input class="has-action" [(ngModel)]="checkin.candidate" (input)="update('existingCheckin')" [ngbTypeahead]="searchCandidates" autocomplete="off" [resultFormatter]="candidateFullname" (selectItem)="applyCandidate($event, checkin, 'existingCheckin')" /><!-- <div class="input-action" (click)="lookupPopMarketingClient(checkin)" placement="bottom" ngbTooltip="Add/Edit Client"><img class="toolbar-img" src="/assets/images/dashboard/client.png" /></div>--></td>
                    <td class="phone"><input type="phone" mask="(000) 000-0000" [showMaskTyped]="true" [(ngModel)]="checkin.phone" (input)="update('existingCheckin')" /></td>
                    <td><input [(ngModel)]="checkin.hiringManager" (input)="update('existingCheckin')" [ngbTypeahead]="searchClients" autocomplete="off" [resultFormatter]="clientManager" (selectItem)="applyClientMgr($event, checkin, 'existingCheckin')" /></td>
                    <td class="phone"><input type="phone" mask="(000) 000-0000" [showMaskTyped]="true" [(ngModel)]="checkin.phoneMgr" (input)="update('existingCheckin')" /></td>
                    <td><input class="results" [(ngModel)]="checkin.memo" (input)="update('existingCheckin')" /></td>
                    <td class="phone phone-long"><input type="text" [(ngModel)]="checkin.email" (input)="update('existingCheckin')" /></td>
                    <td><select [(ngModel)]="checkin.result" (change)="updateWithResult(checkin, 'existingCheckin'); update('existingCheckin', true)">
                        <option value="" selected></option>
                        <option value="LVM">Left VM</option>
                        <option value="EM">Left Email</option>
                        <option value="COV">Completed</option>
                    </select></td>
                    <td class="remove" (click)="removeRecord('existingCheckin', i)"><i class="fas fa-times"></i></td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-sm btn-default btn-new" [disabled]="isSimulated" (click)="addRecord('existingCheckin')">New...</button>
    </div>
    
    <div class="container-fluid planner-block" *ngIf="planner">
        <div class="left-block">Suggested Time: <app-time-picker [timeSlots]="planner.timeSlots" [timeslotUpdater]="timeslotUpdateBus$" blockToken="generatedLeads-start"></app-time-picker>&nbsp;-&nbsp;<app-time-picker [timeSlots]="planner.timeSlots" [timeslotUpdater]="timeslotUpdateBus$" blockToken="generatedLeads-end"></app-time-picker></div>
        <h4 class="text-center wide">JOB ORDER LEAD FOLLOW-UP&nbsp;&nbsp;<a [routerLink]="['/searchassignment']" placement="bottom" tooltip-popup-delay="250" container="body" ngbTooltip="Search Intake"><img class="toolbar-img" src="/assets/images/SO-JO-small.png" /></a></h4>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th>Company</th>
                    <th>Phone</th>
                    <th>Position</th>
                    <th>Source</th>
                    <th>Hiring Mgr.</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let lead of planner.generatedLeads; let i = index" [ngClass]="{'complete': lead.status == 'contacted'}">
                    <td class="no-border smaller">{{ (i + 1) + '.' }}</td>
                    <td class="call-status" [ngClass]="lead.status"></td>
                    <td><input class="has-action" [(ngModel)]="lead.company" (input)="update('generatedLeads')" [ngbTypeahead]="searchClients" autocomplete="off" [resultFormatter]="clientCompany" (selectItem)="applyClient($event, 'company', lead, 'generatedLeads')" /><!-- <div class="input-action" (click)="lookupGeneratedLeadClient(lead)" placement="bottom" ngbTooltip="Add/Edit Client"><img class="toolbar-img" src="/assets/images/dashboard/client.png" /></div>--></td>
                    <td class="phone"><input [(ngModel)]="lead.phone" (input)="update('generatedLeads')" type="phone" mask="(000) 000-0000" [showMaskTyped]="true" /></td>
                    <td><input [(ngModel)]="lead.position" (input)="update('generatedLeads')" /></td>
                    <td class="smaller"><select [(ngModel)]="lead.source" (change)="update('generatedLeads', true)">
                        <option value="" selected></option>
                        <option value="App Lead" selected>App Lead</option>
                        <option value="Client Lead" selected>Client Lead</option>
                        <option value="Job Board Lead" selected>Job Board Lead</option>
                    </select></td>
                    <td><input [(ngModel)]="lead.hiringManager" (input)="update('generatedLeads')" /></td>
                    <td class="remove" (click)="removeRecord('generatedLeads', i)"><i class="fas fa-times"></i></td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-sm btn-default btn-new" [disabled]="isSimulated" (click)="addRecord('generatedLeads')">New...</button>
    </div>
    
    <div class="container-fluid planner-block" *ngIf="planner">
        <div class="left-block">Suggested Time: <app-time-picker [timeSlots]="planner.timeSlots" [timeslotUpdater]="timeslotUpdateBus$" blockToken="popMarketing-start"></app-time-picker>&nbsp;-&nbsp;<app-time-picker [timeSlots]="planner.timeSlots" [timeslotUpdater]="timeslotUpdateBus$" blockToken="popMarketing-end"></app-time-picker></div>
        <h4 class="text-center">P.O.P./M.P.C. MARKETING&nbsp;&nbsp;<a [routerLink]="['/guides/pop-marketing']" placement="bottom" tooltip-popup-delay="250" container="body" ngbTooltip="MPC/POP Call"><img class="toolbar-img" src="/assets/images/pop_small.png" /></a></h4>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th>Candidate To</th>
                    <th>Company</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Hiring Mgr.</th>
                    <th>Title</th>
                    <th>Result</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let pop of planner.popMarketing; let i = index" [ngClass]="{'complete': pop.status == 'contacted'}">
                    <td class="no-border smaller">{{ (i + 1) + '.' }}</td>
                    <td class="call-status" [ngClass]="pop.status"></td>
                    <td><input class="has-action" [(ngModel)]="pop.candidate" (input)="update('popMarketing')" [ngbTypeahead]="searchCandidates" autocomplete="off" [resultFormatter]="candidateFullname" (selectItem)="applyCandidate($event, pop, 'popMarketing')" /><!--<div class="input-action" (click)="lookupPopMarketingCandidate(pop)" placement="bottom" ngbTooltip="Add/Edit Candidate"><img class="toolbar-img" src="/assets/images/dashboard/candidate.png" /></div>--></td>
                    <td><input class="has-action" [(ngModel)]="pop.company" (input)="update('popMarketing')" [ngbTypeahead]="searchClients" autocomplete="off" [resultFormatter]="clientCompany" (selectItem)="applyClient($event, 'company', pop, 'popMarketing')" /><!--<div class="input-action" (click)="lookupPopMarketingClient(pop)" placement="bottom" ngbTooltip="Add/Edit Client"><img class="toolbar-img" src="/assets/images/dashboard/client.png" /></div>--></td>
                    <td class="phone"><input [(ngModel)]="pop.phone" (input)="update('popMarketing')" type="phone" mask="(000) 000-0000" [showMaskTyped]="true" /></td>
                    <td class="phone phone-long"><input [(ngModel)]="pop.email" (input)="update('popMarketing')" type="text" /></td>
                    <td><input [(ngModel)]="pop.hiringManager" (input)="update('popMarketing')" /></td>
                    <td><input [(ngModel)]="pop.title" (input)="update('popMarketing')" /></td>
                    <td><select [(ngModel)]="pop.result" (change)="upgradePopStatus(pop, 'popMarketing')">
                        <option value="" selected></option>
                        <option value="NO">No Interest</option>
                        <option value="CB">Interest / Call Back</option>
                        <option value="LVM">Left VM</option>
                        <option value="EM">Send-out set</option>
                    </select></td>
                    <td class="remove" (click)="removeRecord('popMarketing', i)"><i class="fas fa-times"></i></td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-sm btn-default btn-new" [disabled]="isSimulated" (click)="addRecord('popMarketing')">New...</button>
    </div>
    
    <div class="container-fluid planner-block" *ngIf="planner">
        <div class="left-block">Suggested Time: <app-time-picker [timeSlots]="planner.timeSlots" [timeslotUpdater]="timeslotUpdateBus$" blockToken="newMarketing-start"></app-time-picker>&nbsp;-&nbsp;<app-time-picker [timeSlots]="planner.timeSlots" [timeslotUpdater]="timeslotUpdateBus$" blockToken="newMarketing-end"></app-time-picker></div>
        <h4 class="text-center wide">NEW CLIENT CALL&nbsp;&nbsp;<a [routerLink]="['/guides/bizdev']" placement="bottom" tooltip-popup-delay="250" container="body" ngbTooltip="New Client Call"><img class="toolbar-img" src="/assets/images/prep_small.png" /></a></h4>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th><i class="fas fa-info-circle" placement="top" tooltip-popup-delay="250" container="body" ngbTooltip="To auto-populate applicable information into your script, click the target of your call (candidate or company), then click the script icon."></i>&nbsp;Company</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Hiring Mgr.</th>
                    <th>Title</th>
                    <th>Result</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let newm of planner.newMarketing; let i = index" [ngClass]="{'complete': newm.status == 'contacted'}">
                    <td class="no-border smaller">{{ (i + 1) + '.' }}</td>
                    <td class="call-status" [ngClass]="newm.status"></td>
                    <td><input class="has-action" [(ngModel)]="newm.company" (input)="update('newMarketing')" [ngbTypeahead]="searchClients" autocomplete="off" [resultFormatter]="clientCompany" (selectItem)="applyClient($event, 'company', newm, 'newMarketing')" (click)="selectNewMarketingClient(newm)" />
                    <td class="phone"><input [(ngModel)]="newm.phone" (input)="update('newMarketing')" type="phone" mask="(000) 000-0000" [showMaskTyped]="true" (click)="selectNewMarketingClient(newm)" /></td>
                    <td class="phone phone-long"><input [(ngModel)]="newm.email" (input)="update('newMarketing')" type="text" (click)="selectNewMarketingClient(newm)" /></td>
                    <td><input [(ngModel)]="newm.hiringManager" (input)="update('newMarketing')"  [ngbTypeahead]="searchClients" autocomplete="off" [resultFormatter]="clientManager" (selectItem)="applyClient($event, 'company', newm, 'newMarketing')" (click)="selectNewMarketingClient(newm)" /></td>
                    <td><input [(ngModel)]="newm.title" (input)="update('newMarketing')" (click)="selectNewMarketingClient(newm)" /></td>
                    <td><select [(ngModel)]="newm.result" (change)="upgradeNewClientDevStatus(newm, 'newMarketing');">
                        <option value="" selected></option>
                        <option value="NO">No Interest</option>
                        <option value="CB">Interest / Call Back</option>
                        <option value="LVM">Left VM</option>
                        <option value="EM">Job Order!</option>
                    </select></td>
                    <td class="remove" (click)="removeRecord('newMarketing', i)"><i class="fas fa-times"></i></td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-sm btn-default btn-new" [disabled]="isSimulated" (click)="addRecord('newMarketing')">New...</button>
    </div>
    
    <div class="container-fluid planner-block" *ngIf="planner">
        <div class="left-block">Suggested Time: <app-time-picker [timeSlots]="planner.timeSlots" [timeslotUpdater]="timeslotUpdateBus$" blockToken="innerViews-start"></app-time-picker>&nbsp;-&nbsp;<app-time-picker [timeSlots]="planner.timeSlots" [timeslotUpdater]="timeslotUpdateBus$" blockToken="innerViews-end"></app-time-picker></div>
        <h4 class="text-center wide"><i>Inner</i>Views&trade; SCHEDULED&nbsp;&nbsp;<a [routerLink]="['/innerview']" placement="bottom" tooltip-popup-delay="250" container="body" ngbTooltip="InnerView"><img class="toolbar-img" src="/assets/images/candidate_innerview_small.png" /></a></h4>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th>Date / Time</th>
                    <th>Candidate</th>
                    <th>Title</th>
                    <th>Company</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let innerView of planner.innerViews; let i = index" [ngClass]="{today: isToday(innerView.date)}">
                    <td class="no-border smaller">{{ (i + 1) + '.' }}</td>
                    <td class="datetime datetime-long">
                        <input type="date" class="half-width" name="hireDate" [(ngModel)]="innerView.date" (change)="update('innerViews')" />
                        <select class="half-width-exact" [(ngModel)]="innerView.time" (change)="update('innerViews')">
                            <option value=""></option>
                            <option value="07:00 AM">7:00 AM</option>
                            <option value="07:30 AM">7:30 AM</option>
                            <option value="08:00 AM">8:00 AM</option>
                            <option value="08:30 AM">8:30 AM</option>
                            <option value="09:00 AM">9:00 AM</option>
                            <option value="09:30 AM">9:30 AM</option>
                            <option value="10:00 AM">10:00 AM</option>
                            <option value="10:30 AM">10:30 AM</option>
                            <option value="11:00 AM">11:00 AM</option>
                            <option value="11:30 AM">11:30 AM</option>
                            <option value="12:00 PM">12:00 PM</option>
                            <option value="12:30 PM">12:30 PM</option>
                            <option value="01:00 PM">1:00 PM</option>
                            <option value="01:30 PM">1:30 PM</option>
                            <option value="02:00 PM">2:00 PM</option>
                            <option value="02:30 PM">2:30 PM</option>
                            <option value="03:00 PM">3:00 PM</option>
                            <option value="03:30 PM">3:30 PM</option>
                            <option value="04:00 PM">4:00 PM</option>
                            <option value="04:30 PM">4:30 PM</option>
                            <option value="05:00 PM">5:00 PM</option>
                            <option value="05:30 PM">5:30 PM</option>
                            <option value="06:00 PM">6:00 PM</option>
                            <option value="06:30 PM">6:30 PM</option>
                            <option value="07:00 PM">7:00 PM</option>
                        </select>
                    </td>
                    <td><input class="has-action" [(ngModel)]="innerView.candidate" (input)="update('innerViews')" [ngbTypeahead]="searchCandidates" autocomplete="off" [resultFormatter]="candidateFullname" (selectItem)="applyCandidate($event, innerView, 'innerViews')" /><!--<div class="input-action" (click)="lookupPopMarketingCandidate(innerView)" placement="bottom" ngbTooltip="Add/Edit Candidate"><img class="toolbar-img" src="/assets/images/dashboard/candidate.png" /></div>--></td>
                    <td><input [(ngModel)]="innerView.title" (input)="update('innerViews')" /></td>
                    <td><input [(ngModel)]="innerView.company" (input)="update('innerViews')" /></td>
                    <td class="phone"><input [(ngModel)]="innerView.phone" (input)="update('innerViews')" type="phone" mask="(000) 000-0000" [showMaskTyped]="true" /></td>
                    <td class="phone phone-long"><input [(ngModel)]="innerView.email" (input)="update('innerViews')" type="text" /></td>
                    <td class="remove" (click)="removeRecord('innerViews', i)"><i class="fas fa-times"></i></td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-sm btn-default btn-new" [disabled]="isSimulated" (click)="addRecord('innerViews')">New...</button>
    </div>
    
    <div class="container-fluid planner-block" *ngIf="planner">
        <div class="left-block">Suggested Time: <app-time-picker [timeSlots]="planner.timeSlots" [timeslotUpdater]="timeslotUpdateBus$" blockToken="telesourcing-start"></app-time-picker>&nbsp;-&nbsp;<app-time-picker [timeSlots]="planner.timeSlots" [timeslotUpdater]="timeslotUpdateBus$" blockToken="telesourcing-end"></app-time-picker></div>
        <h4 class="text-center">TELE-SOURCING&nbsp;&nbsp;<a [routerLink]="['/guides/telesourcing']" placement="bottom" tooltip-popup-delay="250" container="body" ngbTooltip="Tele-Sourcing"><img class="toolbar-img" src="/assets/images/telesourcing_small.png" /></a></h4>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th><i class="fas fa-info-circle" placement="top" tooltip-popup-delay="250" container="body" ngbTooltip="To auto-populate applicable information into your script, click the target of your call (candidate or company), then click the script icon."></i>&nbsp;Company Name</th>
                    <th>Phone</th>
                    <th>Result</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let ts of planner.telesourcing; let i = index" [ngClass]="{'contacted': ts.result == 'completed', 'dialed': ts.result == 'incomplete'}">
                    <td class="no-border smaller">{{ (i + 1) + '.' }}</td>
                    <td class="call-status" [ngClass]="ts.status"></td>
                    <td><input type="text" [(ngModel)]="ts.company" (input)="update('telesourcing')" (click)="selectTelesourcingCandidate(ts)" /></td>
                    <td class="phone phone-long"><input [(ngModel)]="ts.phone" (input)="update('telesourcing')" type="phone" mask="(000) 000-0000" [showMaskTyped]="true" (click)="selectTelesourcingCandidate(ts)" /></td>
                    <td><select [(ngModel)]="ts.result" (change)="upgradeTelesourcingStatus(ts, 'telesourcing');">
                        <option value="" selected></option>
                        <option value="completed">Completed</option>
                        <option value="incomplete">Incomplete</option>
                    </select></td>
                    <td class="remove" (click)="removeRecord('telesourcing', i)"><i class="fas fa-times"></i></td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-sm btn-default btn-new" [disabled]="isSimulated" (click)="addRecord('telesourcing')">New...</button>
    </div>
    
    <div class="container-fluid planner-block" *ngIf="planner">
        <div class="left-block">Suggested Time: <app-time-picker [timeSlots]="planner.timeSlots" [timeslotUpdater]="timeslotUpdateBus$" blockToken="recruitingCalls-start"></app-time-picker>&nbsp;-&nbsp;<app-time-picker [timeSlots]="planner.timeSlots" [timeslotUpdater]="timeslotUpdateBus$" blockToken="recruitingCalls-end"></app-time-picker></div>
        <h4 class="text-center">RECRUITING CALLS&nbsp;&nbsp;<a [routerLink]="['/guides/recruiting-call']" placement="bottom" tooltip-popup-delay="250" container="body" ngbTooltip="Recruiting Call"><img class="toolbar-img" src="/assets/images/recruiting_call_small.png" /></a></h4>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th>For Client</th>
                    <th><i class="fas fa-info-circle" placement="top" tooltip-popup-delay="250" container="body" ngbTooltip="To auto-populate applicable information into your script, click the target of your call (candidate or company), then click the script icon."></i>&nbsp;Candidate</th>
                    <th>Title</th>
                    <th>Company</th>
                    <th>Phone</th>
                    <th>Source</th>
                    <th>Result</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let call of planner.recruitingCalls; let i = index" [ngClass]="{'complete': call.status == 'contacted'}">
                    <td class="no-border smaller">{{ (i + 1) + '.' }}</td>
                    <td class="call-status" [ngClass]="call.status"></td>
                    <td><input [(ngModel)]="call.project" (input)="update('recruitingCalls')" /></td>
                    <td><input class="has-action" [(ngModel)]="call.candidate" (input)="update('recruitingCalls')" [ngbTypeahead]="searchCandidates" autocomplete="off" [resultFormatter]="candidateFullname" (selectItem)="applyCandidate($event, call, 'recruitingCalls')" (click)="selectRecruitingCallCandidate(call)" /></td>
                    <td><input [(ngModel)]="call.title" (input)="update('recruitingCalls')" /></td>
                    <td><input class="has-action" [(ngModel)]="call.company" (input)="update('recruitingCalls')" [ngbTypeahead]="searchClients" autocomplete="off" [resultFormatter]="clientCompany" (selectItem)="applyClient($event, 'company', call, 'recruitingCalls')" /></td>
                    <td class="phone"><input [(ngModel)]="call.phone" (input)="update('recruitingCalls')" type="phone" mask="(000) 000-0000" [showMaskTyped]="true" /></td>
                    <td><select [(ngModel)]="call.source" (change)="update('recruitingCalls')">
                        <option value="" selected></option>
                        <option value="Tele-Sourced">Tele-Sourced</option>
                        <option value="Cand. Referral">Cand. Referral</option>
                        <option value="LinkedIn">LinkedIn</option>
                        <option value="Job Board">Job Board</option>
                        <option value="e-Sources">e-Sourced</option>
                    </select></td>
                    <td><select [(ngModel)]="call.result" (change)="upgradeRecruitingCallStatus(call, 'recruitingCalls');">
                        <option value="" selected></option>
                        <option value="Left VM">Left VM</option>
                        <option value="InnerView Set">InnerView&trade; Set</option>
                        <option value="No Interest">No Interest</option>
                        <option value="Got Referral">Got Referral</option>
                    </select></td>
                    <td class="remove" (click)="removeRecord('recruitingCalls', i)"><i class="fas fa-times"></i></td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-sm btn-default btn-new" [disabled]="isSimulated" (click)="addRecord('recruitingCalls')">New...</button>
    </div>
    
    <div class="container-fluid planner-block" *ngIf="planner">
        <div class="left-block">Priority: <input type="text" size="5" [(ngModel)]="planner.sendouts[0].priority" (input)="update('sendouts')" /></div>
        <h4 class="text-center">SEND OUTS TO ARRANGE</h4>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th>Candidate</th>
                    <th>Phone</th>
                    <th>To Hiring Mgr.</th>
                    <th>Company</th>
                    <th>Phone</th>
                    <th>Date/Time Set</th>
                    <th>Result</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let sendout of planner.sendouts; let i = index" [ngClass]="{'complete': sendout.status == 'contacted'}">
                    <td class="no-border smaller">{{ (i + 1) + '.' }}</td>
                    <td class="call-status" [ngClass]="{ contacted: sendout.status === 'contacted', dialed: (sendout.candidate || sendout.hiringManager || sendout.company || sendout.phone || sendout.date || sendout.time) }"></td>
                    <td><input class="has-action" [(ngModel)]="sendout.candidate" (input)="update('sendouts')" [ngbTypeahead]="searchCandidates" autocomplete="off" [resultFormatter]="candidateFullname" (selectItem)="applyCandidate($event, sendout, 'sendouts')" /></td>
                    <td class="phone"><input [(ngModel)]="sendout.candidatePhone" (input)="update('sendouts')" type="phone" mask="(000) 000-0000" [showMaskTyped]="true" /></td>
                    <td><input [(ngModel)]="sendout.hiringManager" (input)="update('sendouts')" type="text" /></td>
                    <td><input class="has-action" [(ngModel)]="sendout.company" (input)="update('sendouts')" [ngbTypeahead]="searchClients" autocomplete="off" [resultFormatter]="clientCompany" (selectItem)="applyClient($event, 'company', sendout, 'sendouts')" /></td>
                    <td class="phone"><input [(ngModel)]="sendout.phone" (input)="update('sendouts')" type="phone" mask="(000) 000-0000" [showMaskTyped]="true" /></td>
                    <td class="datetime datetime-long">
                        <input type="date" class="half-width" [(ngModel)]="sendout.date" (change)="update('sendouts')" />
                        <select class="half-width-exact" [(ngModel)]="sendout.time" (change)="update('sendouts')">
                            <option value=""></option>
                            <option value="07:00 AM">7:00 AM</option>
                            <option value="07:30 AM">7:30 AM</option>
                            <option value="08:00 AM">8:00 AM</option>
                            <option value="08:30 AM">8:30 AM</option>
                            <option value="09:00 AM">9:00 AM</option>
                            <option value="09:30 AM">9:30 AM</option>
                            <option value="10:00 AM">10:00 AM</option>
                            <option value="10:30 AM">10:30 AM</option>
                            <option value="11:00 AM">11:00 AM</option>
                            <option value="11:30 AM">11:30 AM</option>
                            <option value="12:00 PM">12:00 PM</option>
                            <option value="12:30 PM">12:30 PM</option>
                            <option value="01:00 PM">1:00 PM</option>
                            <option value="01:30 PM">1:30 PM</option>
                            <option value="02:00 PM">2:00 PM</option>
                            <option value="02:30 PM">2:30 PM</option>
                            <option value="03:00 PM">3:00 PM</option>
                            <option value="03:30 PM">3:30 PM</option>
                            <option value="04:00 PM">4:00 PM</option>
                            <option value="04:30 PM">4:30 PM</option>
                            <option value="05:00 PM">5:00 PM</option>
                            <option value="05:30 PM">5:30 PM</option>
                            <option value="06:00 PM">6:00 PM</option>
                            <option value="06:30 PM">6:30 PM</option>
                            <option value="07:00 PM">7:00 PM</option>
                        </select>
                    </td>
                    <td><select [(ngModel)]="sendout.action" (change)="updateWithAction(sendout, 'sendouts'); update('sendouts')">
                        <option value="" selected></option><option value="Both Confirmed">Both Confirmed</option>
                    </select></td>
                    <td class="remove" (click)="removeRecord('sendouts', i)"><i class="fas fa-times"></i></td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-sm btn-default btn-new" [disabled]="isSimulated" (click)="addRecord('sendouts')">New...</button>
    </div>
    
    <div class="container-fluid planner-block" *ngIf="planner">
        <div class="left-block">Priority: <input type="text" size="5" [(ngModel)]="planner.preps[0].priority" (input)="update('preps')" /></div>
        <h4 class="text-center wide">PREPS / DEBRIEFS / CLOSING / REF. <i class="fas fa-check"></i></h4>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Call</th>
                    <th>Phone</th>
                    <th>Result</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let prep of planner.preps; let i = index" [ngClass]="{'complete': prep.status == 'contacted'}">
                    <td class="no-border smaller">{{ (i + 1) + '.' }}</td>
                    <td class="call-status" [ngClass]="prep.status"></td>
                    <td><input class="has-action" [(ngModel)]="prep.name" (input)="update('preps')" [ngbTypeahead]="searchCandidates" autocomplete="off" [resultFormatter]="candidateFullname" (selectItem)="applyCandidate($event, prep, 'preps')" /><!--<div class="input-action" (click)="lookupNewLeadCandidate(followUp)" placement="bottom" ngbTooltip="Add/Edit Candidate"><img class="toolbar-img" src="/assets/images/dashboard/candidate.png" /></div>--></td>
                    <td><select [(ngModel)]="prep.type" (change)="update('preps')">
                        <option value="" selected></option>
                        <option value="Cand. Prep">Cand. Prep for 1<sup>st</sup> IV</option>
                        <option value="Cand. Prep Final">Cand. Prep for Final IV</option>
                        <option value="Cand. Debrief">Cand. Debrief for 1<sup>st</sup> IV</option>
                        <option value="Cand. Debrief Final">Cand. Debrief for Final IV</option>
                        <option value="Close">Candidate After Acceptance</option>
                        <option value="Resignation">Prep for Resignation</option>
                        <option value="Client Prep">Client Prep for 1<sup>st</sup> IV</option>
                        <option value="Client Prep Final">Client Prep for Final IV</option>
                        <option value="Client Debrief">Client Debrief for 1<sup>st</sup> IV</option>
                        <option value="Ref">Reference Check</option>
                        <option value="Client Debrief Final">Client Debrief for Final IV</option>
                        <option value="Client Close">Client Offer/Acceptance Closing</option>
                    </select></td>
                    <td class="call-status" [ngClass]="callTypeToClass(prep.type)" (click)="gotoGuide(prep.type)"></td>
                    <td class="phone"><input [(ngModel)]="prep.phone" (input)="update('preps')" type="phone" mask="(000) 000-0000" [showMaskTyped]="true" /></td>
                    <td>
                        <select class="results" [(ngModel)]="prep.memo" (change)="update('preps'); upgradePrepStatus(prep, 'preps')">
                            <option value="" selected></option>
                            <option value="Complete">Complete</option>
                            <option value="Incomplete">Incomplete</option>
                        </select>
                    </td>
                    <td class="remove" (click)="removeRecord('preps', i)"><i class="fas fa-times"></i></td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-sm btn-default btn-new" [disabled]="isSimulated" (click)="addRecord('preps')">New...</button>
    </div>
    
    <div class="container-fluid planner-block" *ngIf="planner">
        <div class="left-block">Priority: <input type="text" size="5" [(ngModel)]="planner.followUps[0].priority" (input)="update('followUps')" /></div>
        <h4 class="text-center wide">FOLLOW-UP</h4>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Issue</th>
                    <th>Phone</th>
                    <th>Result</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let fu of planner.followUps; let i = index" [ngClass]="{'complete': fu.status == 'contacted'}">
                    <td class="no-border smaller">{{ (i + 1) + '.' }}</td>
                    <td class="call-status" [ngClass]="fu.status"></td>
                    <td><input [(ngModel)]="fu.name" (input)="update('followUps')" type="text" /></td>
                    <td><select [(ngModel)]="fu.type" (change)="update('followUps')">
                        <option value="" selected></option>
                        <option value="Candidate">Candidate</option>
                        <option value="Client">Client</option>
                    </select></td>
                    <td><input [(ngModel)]="fu.issue" (input)="update('followUps')" /></td>
                    <td class="phone"><input [(ngModel)]="fu.phone" (input)="update('followUps')" type="phone" mask="(000) 000-0000" [showMaskTyped]="true" /></td>
                    <td><select [(ngModel)]="fu.result" (change)="updateWithResult(fu, 'followUps'); update('followUps', true)">
                        <option value="" selected></option>
                        <option value="Complete">Complete</option>
                        <option value="Incomplete">Incomplete / Left VM / Email</option>
                    </select></td>
                    <td class="remove" (click)="removeRecord('followUps', i)"><i class="fas fa-times"></i></td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-sm btn-default btn-new" [disabled]="isSimulated" (click)="addRecord('followUps')">New...</button>
    </div>

    <div class="container-fluid planner-block" *ngIf="planner">
        <h4 class="text-center">MY DAILY ACTIVITY</h4>
        <div class="row">
            <div class="col-2">
            </div>
            <div class="col-2">
            APPs
            </div>
            <div class="col-2">
            Jos
            </div>
            <div class="col-2">
            SOs
            </div>
            <div class="col-2">
            Mkt Calls
            </div>
            <div class="col-2">
            Rec Calls
            </div>
        </div>
        <div class="row">
            <div class="col-2 text-right">
            Goal:
            </div>
            <div class="col-2">
            <input [(ngModel)]="planner.activitySummary.goals.apps" (input)="update('activitySummary')" size="4" />
            </div>
            <div class="col-2">
            <input [(ngModel)]="planner.activitySummary.goals.jos" (input)="update('activitySummary')" size="4" />
            </div>
            <div class="col-2">
            <input [(ngModel)]="planner.activitySummary.goals.sos" (input)="update('activitySummary')" size="4" />
            </div>
            <div class="col-2">
            <input [(ngModel)]="planner.activitySummary.goals.marketingCalls" (input)="update('activitySummary')" size="4" />
            </div>
            <div class="col-2">
            <input [(ngModel)]="planner.activitySummary.goals.receivedCalls" (input)="update('activitySummary')" size="4" />
            </div>
        </div>
        <div class="row">
            <div class="col-2 text-right">
            Actual:
            </div>
            <div class="col-2">
            <input [(ngModel)]="planner.activitySummary.actual.apps" (input)="update('activitySummary')" size="4" />
            </div>
            <div class="col-2">
            <input [(ngModel)]="planner.activitySummary.actual.jos" (input)="update('activitySummary')" size="4" />
            </div>
            <div class="col-2">
            <input [(ngModel)]="planner.activitySummary.actual.sos" (input)="update('activitySummary')" size="4" />
            </div>
            <div class="col-2">
            <input [(ngModel)]="planner.activitySummary.actual.marketingCalls" (input)="update('activitySummary')" size="4"/>
            </div>
            <div class="col-2">
            <input [(ngModel)]="planner.activitySummary.actual.receivedCalls" (input)="update('activitySummary')" size="4" />
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-2 text-right">
              Today's Cash-in:
            </div>
            <div class="col-2">
              <input currencyMask [(ngModel)]="planner.activitySummary.production" (input)="update('activitySummary')" size="10" />
            </div>
            <div class="col-4">
            </div>
            <div class="col-2">
              YTD Cash-in:
            </div>
            <div class="col-2">
              <input currencyMask [(ngModel)]="planner.activitySummary.productionGoal" (input)="update('activitySummary')" size="10" />
            </div>
        </div>
    </div>
    
    <div class="container-fluid p-0" data-html2canvas-ignore="true">
        <button class="btn btn-danger float-left" [disabled]="isSimulated" (click)="clear()">Clear</button>
        <button class="btn btn-success float-right" (click)="print()">Print</button>
    </div>
</div>

<app-footer></app-footer>
