import { Component, OnInit } from '@angular/core';
import { User } from '../../shared/models/user.model';
import { UsersService } from '../../shared/users.service';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-activity-report',
  templateUrl: './activity-report.component.html',
  styleUrls: ['./activity-report.component.scss']
})
export class ActivityReportComponent implements OnInit {

  constructor(
    private usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getCurrentUser();
  }

  getCurrentUser(): void {
    this.usersService.currentUser().pipe(take(1)).subscribe((user: User) => {
      if (user.roles.indexOf('admin') > -1) {
        if (this.isInsideReportScope()) {
          return;
        }
        this.navigateToGeneralReport();
        return;
      }
      if (user.roles.indexOf('account-admin') > -1) {
        if (this.isInsideReportScope()) {
          return;
        }
        this.navigateToCompanyReport(user.account_uuid);
        return;
      }
     this.navigateToDashboard();
    });
  }

  isInsideReportScope(): boolean {
    const url = this.router.url;
    return url.includes('/company/') || url.includes('/user/');
  }

  navigateToGeneralReport(): void {
    this.navigateTo('general');
  }


  navigateToCompanyReport(accountUuid: string): void {
    if (!accountUuid) {
      this.navigateToDashboard();
      return;
    }
    this.navigateTo(`company/${accountUuid}`);
  }

  navigateToDashboard(): void {
    this.router.navigate(['./dashboard']);
  }

  navigateTo(route: string): void {
    this.router.navigate([route], {relativeTo: this.route});
  }

}
