import { Component } from '@angular/core';
import {PageSpinnerService} from '../services/page-spinner.service';

@Component({
  selector: 'app-page-spinner',
  templateUrl: './page-spinner.component.html',
  styleUrls: ['./page-spinner.component.scss']
})
export class PageSpinnerComponent {

  constructor(public pageSpinnerService: PageSpinnerService) { }

}
