import { Injectable, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {

  private lastClient: any;
  private lastCandidate: any;
  private lastData: any;

  private subject$ = new Subject<any>();

  constructor() {
    this.on('clientChanged', (client: any) => {
      this.lastClient = client;
    });

    this.on('candidateChanged', (candidate: any) => {
      this.lastCandidate = candidate;
    });

    this.on('dataChanged', (data: any) => {
      this.lastData = data;
    });
  }

  on(event: string, action: any): Subscription {
    return this.subject$
      .pipe(
        filter((e: any) => {
          return e.event === event;
        }),
        map((e: any) => {
          return e.value;
        })
      ).subscribe(action);
  }

  emit(event: any): void {
    this.subject$.next(event);
  }

  getClient(): any { return this.lastClient; }

  getCandidate(): any { return this.lastCandidate; }

  getData(): any { return this.lastData; }
}
