import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';

import { Note } from './note.model';
import { NotesService } from './notes.service';
import { NoteComponent } from './note/note.component';

import { SessionStateService } from '../../session-state.service';
import { UsersService } from '../../users.service';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  @Input() withHeader = false;

  private OwnerUUID: string | undefined = '';

  @Input() set ownerUUID(value: string | undefined) {
    this.OwnerUUID = value;

    this.reloadNotes();
  }
  get ownerUUID(): string | undefined {
    return this.OwnerUUID;
  }

  @Output() notesChangedEvent = new EventEmitter<Note[]>();

  notes: Note[] = [];

  isSimulated = false;

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private notesService: NotesService,
    /* private usersService: UsersService, */
    private sessionStateService: SessionStateService
    ) {}

  ngOnInit(): void {
    const session = this.sessionStateService.get();
    this.isSimulated = !!session.session.simulatedSession;
  }

  reloadNotes(): void {
    if (this.OwnerUUID) {
      this.notesService.list(this.OwnerUUID || '').subscribe((notes) => {
        this.notes = notes;
        this.notesChangedEvent.emit(notes);
      });
    }
  }

  add(): void {
    if (this.isSimulated) {
      return;
    }

    let ngbModalOptions: NgbModalOptions = { };
    const urlSegments = this.route.snapshot.url;

    if (urlSegments && urlSegments[0] && (urlSegments[0].path === 'guides')) {
      ngbModalOptions = {
        windowClass: 'free-floating-modal',
        backdrop: false
      };
    }

    const modalRef = this.modalService.open(NoteComponent, ngbModalOptions);
    modalRef.componentInstance.isNew = true;

    modalRef.result.then((result) => {
      const session = this.sessionStateService.get();
      const user = (session.user as User);

      if (this.OwnerUUID) {
        this.notesService.insert({
          text: result,
          owner_uuid: this.OwnerUUID,
          user_uuid: user.uuid,
          account_uuid: user.account_uuid
         }, user.uuid || '').subscribe((response) => {
          this.notes.push(response.note);
          this.notesChangedEvent.emit(this.notes);
        });
      } else {
        this.notes.push({ text: result });
        this.notesChangedEvent.emit(this.notes);
      }
    }).catch((error) => { });
  }

  edit(index: number): void {
    if (this.isSimulated) {
      return;
    }

    let ngbModalOptions: NgbModalOptions = { };
    const urlSegments = this.route.snapshot.url;

    if (urlSegments && urlSegments[0] && (urlSegments[0].path === 'guides')) {
      ngbModalOptions = {
        windowClass: 'free-floating-modal',
        backdrop: false
      };
    }

    const modalRef = this.modalService.open(NoteComponent, ngbModalOptions);
    modalRef.componentInstance.note = this.notes[index].text;
    modalRef.componentInstance.isNew = false;

    modalRef.result.then((result) => {
      this.notes[index].text = result;
      this.notesChangedEvent.emit(this.notes);

      if (this.OwnerUUID) {
        this.notesService.update(this.notes[index]).subscribe((response) => {
          this.notes.splice(index, 1, response.note);
          this.notesChangedEvent.emit(this.notes);
        });
      }
    }).catch((error) => {
      const uuid: string | undefined = this.notes[index].uuid;

      if (uuid && error && error.delete) {
        this.notes.splice(index, 1);
        this.notesChangedEvent.emit(this.notes);

        if (this.OwnerUUID) {
          this.notesService.delete(uuid).subscribe(() => { });
        }
      }
    });
  }
}
