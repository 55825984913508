import { Injectable, Component, OnInit, AfterViewInit, ViewChild, TemplateRef, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { NgbTimepickerConfig, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import * as _ from 'lodash';

import { Client } from '../admin/clients/clients.model';
import { Candidate } from '../admin/candidates/candidate.model';
import { User } from '../shared/models/user.model';

import { EguideService } from './eguide.service';
import { UsersService } from '../shared/users.service';
import { SessionStateService } from '../shared/session-state.service';
import { EventBusService } from '../shared/event-bus.service';
import { PlannerService } from '../dailyplanner/planner.service';
import { AlertService } from 'src/app/shared/alert.service';
import { NotificationService } from '../shared/notifications.service';

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

   readonly DELIMITER = '/';

   parse(value: string): NgbDateStruct | null {
     if (value) {
       const date = value.split(this.DELIMITER);
       return {
         day : parseInt(date[1], 10),
         month : parseInt(date[0], 10),
         year : parseInt(date[2], 10)
       };
     }
     return null;
   }

   format(date: NgbDateStruct | null): string {
     return date ? (this.pad(date.month) + this.DELIMITER + this.pad(date.day) + this.DELIMITER + date.year) : '';
   }

   pad(num: number): string {
    return (num < 10) ? ('0' + num) : ('' + num);
   }
}

@Component({
  selector: 'app-eguide',
  templateUrl: './eguide.component.html',
  styleUrls: ['./eguide.component.scss'],
  providers: [NgbTimepickerConfig, { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }]
})
export class EguideComponent implements OnInit, OnDestroy, AfterViewInit {

  // @ts-ignore
  @ViewChild('guideContainer', { static: true }) guideContainer: TemplateRef<any>;
  // @ts-ignore
  @ViewChild('editing', { static: true }) editing: TemplateRef<any>;
  // @ts-ignore
  @ViewChild('eguideSaveGuide', { static: true }) eguideSaveGuide: ElementRef<any>;

  isAdmin = false;
  canApprove = false;
  accountUuid = '';
  requiresUpdate = false;
  softwareVersion = '0.0.2';

  scriptType = 'morgan';
  guideId = '';
  guideName = '';
  guide: any = {};
  guideSource = '';
  guideProcessed: SafeHtml[] | null = null;
  callPrepTitles: string[] = [];
  callPrepTexts: SafeHtml[] = [];

  guideProcessedCopy: SafeHtml[] | null = null;
  callPrepTitlesCopy: string[] = [];
  callPrepTextsCopy: SafeHtml[] = [];

  fieldValues: {  Candidate: Candidate | null, Client: Client | null, User: User | null } = {
    Candidate: null,
    Client: null,
    User: null
  };
  tinymceOptions: any = null;

  user: User | null = null;
  widgets: any = {};
  widgetList: string[] = [];

  controls = {
    editing: false,
    editor: true,
    sidebar: true
  };

  client: any = null;
  candidate: any = null;
  settings: any = {};

  dateTimePickerDate: undefined | Date;
  selectedDate: any = {};
  selectedHour = '';
  selectedMinute = '00';
  selectedAmPm = 'AM';
  candidateName = '';
  hiringManager = '';
  meridian = true;

  eventClientSub: Subscription | null = null;
  eventCandidateSub: Subscription | null = null;
  eventRoutingSub: Subscription | null = null;

  guideForAproval: any = null;

  actionInProgress = false;
  isDatetimeInputPresent = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private eguideService: EguideService,
    private sessionStateService: SessionStateService,
    private notificationService: NotificationService,
    private eventBusService: EventBusService,
    private plannerService: PlannerService,
    private alertService: AlertService,
    private domSanitizer: DomSanitizer,
    private config: NgbTimepickerConfig) {
      config.spinners = false;

      this.eventRoutingSub = router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          const name = this.route.snapshot.paramMap.get('name');

          if (name === 'telesourcing') {
            this.eventBusService.emit({ event: 'dataChanged', value: null });
          }
        }
        else if (event instanceof NavigationEnd) {
          this.ngAfterViewInit();
        }
      });
  }

  ngOnInit(): void {
    this.usersService.loginStatus().subscribe((sessionData) => {
      const roles = sessionData.status.roles;

      this.isAdmin = roles.indexOf('account-admin') > -1;
      this.canApprove = this.isAdmin || (roles.indexOf('user-guide-manager') > -1);
      this.accountUuid = sessionData.status.account_uuid;

      this.requiresUpdate = (sessionData && sessionData.requiresUpdate);

      if (sessionData && sessionData.version) {
        this.softwareVersion = sessionData.version;
      }
    });
  }

  ngOnDestroy(): void {
    this.eventClientSub?.unsubscribe();
    this.eventCandidateSub?.unsubscribe();
    this.eventRoutingSub?.unsubscribe();
  }

  ngAfterViewInit(): void {
    const session = this.sessionStateService.get();
    const accountUuid = (session.user as User).account_uuid;

    if (!accountUuid) {
      return;
    }

    this.tinymceOptions = {
        base_url: '/tinymce',
        suffix: '.min',
        plugins: 'link spellchecker image table pagebreak charmap textcolor colorpicker hr lists',
        content_css: '/assets/styles/editor.css',
        toolbar: ['cut copy paste | link unlink | undo redo | image table pagebreak hr charmap | spellchecker | fullscreen | bullist numlist outdent indent | fontsizeselect fontselect forecolor backcolor',
                  'bold italic strikethrough underline | alignleft aligncenter alignright alignjustify | styleselect | formatselect | fieldsList inputList | dateTimePicker'],
        height: 737,
        menu: false,
        menubar: false,
        statusbar: false,
        setup: (editor: any) => {
          editor.ui.registry.addMenuButton('fieldsList', {
            text: 'Fields',
            fetch(callback: (items: any[]) => void): void {
              const items = [
              {
                type: 'menuitem',
                text: 'Candidate.FirstName',
                value: 'Candidate.FirstName',
                onAction(): void {
                  editor.insertContent('{{Candidate.FirstName}}');
                }
              },
              {
                type: 'menuitem',
                text: 'Candidate.LastName',
                value: 'Candidate.LastName',
                onAction(): void {
                  editor.insertContent('{{Candidate.LastName}}');
                }
              },
              {
                type: 'menuitem',
                text: 'Candidate.Title',
                value: 'Candidate.Title',
                onAction(): void {
                  editor.insertContent('{{Candidate.Title}}');
                }
              },
              {
                type: 'menuitem',
                text: 'Client.FirstName',
                value: 'Client.FirstName',
                onAction(): void {
                  editor.insertContent('{{Client.FirstName}}');
                }
              },
              {
                type: 'menuitem',
                text: 'Client.LastName',
                value: 'Client.LastName',
                onAction(): void {
                  editor.insertContent('{{Client.LastName}}');
                }
              },
              {
                type: 'menuitem',
                text: 'Client.Company',
                value: 'Client.Company',
                onAction(): void {
                  editor.insertContent('{{Client.Company}}');
                }
              },
              {
                type: 'menuitem',
                text: 'User.FirstName',
                value: 'User.FirstName',
                onAction(): void {
                  editor.insertContent('{{User.FirstName}}');
                }
              },
              {
                type: 'menuitem',
                text: 'User.LastName',
                value: 'User.LastName',
                onAction(): void {
                  editor.insertContent('{{User.LastName}}');
                }
              }];

              callback(items);
            }
          });

          editor.ui.registry.addMenuButton('inputList', {
            text: 'Inputs',
            fetch(callback: (items: any[]) => void): void {
              const items = [
              {
                type: 'menuitem',
                text: 'Short Field',
                value: 'Input.10',
                onAction(): void {
                  editor.insertContent('{{Input.10}}');
                }
              },
              {
                type: 'menuitem',
                text: 'Medium Field',
                value: 'Input.20',
                onAction(): void {
                  editor.insertContent('{{Input.20}}');
                }
              },
              {
                type: 'menuitem',
                text: 'Long Field',
                value: 'Input.30',
                onAction(): void {
                  editor.insertContent('{{Input.30}}');
                }
              }];

              callback(items);
            }
          });
          editor.ui.registry.addButton('dateTimePicker', {
            icon: 'insert-time',
            tooltip: 'Datetime Field',
            onSetup(buttonApi: any): void {
              const editorEventCallback =  () => {
                buttonApi.setDisabled(editor.getContent().includes('{{DatetimeInput}}'));
              };
              editor.on('NodeChange', editorEventCallback);
            },
            onAction(buttonApi: any): void {
              if (editor.getContent().includes('{{DatetimeInput}}')) {
                buttonApi.setDisabled(true);
                return;
              }
              buttonApi.setDisabled(false);
              editor.insertContent('{{DatetimeInput}}');
            },
          });
        },
      };

    this.usersService.currentUser().pipe(first()).subscribe(user => {
        this.user = user;

        const name = this.route.snapshot.paramMap.get('name');
        this.guideId = name || '';

        this.settings = user.settings;
        this.settings.guide = (this.settings.guides || []).find((guide: any) => guide.name === name);
        delete this.settings.guides;

        // AppService.updateSetting('username', vm.user.username);
        // AppService.updateSetting('guide.name', $stateParams.guide);

        this.controls = {
          editing: false,
          editor: !!this.settings.guide && ((this.settings.guide.maximized === 'left') || (this.settings.guide.maximized === 'none')),
          sidebar: !!this.settings.guide && ((this.settings.guide.maximized === 'right') || (this.settings.guide.maximized === 'none'))
        };

        // @ts-ignore
        this.editing.toggled = false;
        // @ts-ignore
        this.editing.onInput(false);

        const userKey = 'user';
        const queryUser = this.route.snapshot.queryParams[userKey];

        this.scriptType = queryUser ? userKey : ((!!this.settings.guide && this.settings.guide.script) || 'morgan');

        this.fieldValues.User = user;

        this.eventClientSub =
          this.eventBusService.on('clientChanged', (client: any) => {
            this.fieldValues.Client = client;
            this.initializeGuide(this.guide, this.settings);
          });

        if (this.widgetList.includes('Clients') && this.eventBusService.getClient()) {
          this.fieldValues.Client = this.eventBusService.getClient();
        }

        this.eventCandidateSub =
          this.eventBusService.on('candidateChanged', (candidate: any) => {
            this.fieldValues.Candidate = candidate;
            this.initializeGuide(this.guide, this.settings);
          });

        if (this.widgetList.includes('Candidates') && this.eventBusService.getCandidate()) {
          this.fieldValues.Candidate = this.eventBusService.getCandidate();
        }

        this.scriptTypeChanged(this.scriptType);
      });
  }

  installUpdate(): void {
    window.location.reload();
  }

  private onlyUnique(value: any, index: number, self: any[]): boolean {
    return self.indexOf(value) === index;
  }

  private getPlaceholders(str: string): string[] {
    const regex = /{{(.*?)}}/g;
    const result = [];
    let match = regex.exec(str);

    while (match) {
        result.push(match[1]);

        match = regex.exec(str);
    }

    return result;
  }

  processGuide(copy: boolean = false, guideSource?: string): void {
    let guideProcessed = guideSource || this.guideSource;

    if (!guideProcessed || !guideProcessed.length) {
      if (copy) {
        this.guideProcessedCopy = null;
      } else {
        this.guideProcessed = null;
      }

      return;
    }

    const names = this.getPlaceholders(this.guideSource).filter(this.onlyUnique);

    for (let i = 0, iLimit = names.length; i < iLimit; i++) {
      const namesI = names[i];

      if (namesI === 'DatetimeInput') {
        const re = new RegExp('{{' + namesI + '}}', 'g');
        guideProcessed = guideProcessed.replace(re, '<input type="datetime-local" id="datetimePickerElement"></input>');
      }
      if (namesI.indexOf('.') !== -1) {
        const nameTokens = namesI.split('.');
        const re = new RegExp('{{' + namesI + '}}', 'g');
        const camelCaseProperty = nameTokens[1].charAt(0).toLowerCase() + nameTokens[1].substr(1);

        switch (nameTokens[0]) {
          case 'Input':
            guideProcessed = guideProcessed.replace(re, '<input size="' + nameTokens[1] + '"></input>');
            break;

          case 'Candidate':
            if (this.fieldValues.Candidate) {
              // @ts-ignore
              guideProcessed = guideProcessed.replace(re, (this.fieldValues.Candidate[camelCaseProperty] || ''));
            }
            break;

          case 'Client':
            if (this.fieldValues.Client) {
              // @ts-ignore
              guideProcessed = guideProcessed.replace(re, (this.fieldValues.Client[camelCaseProperty] || ''));
            }
            break;

          case 'User':
            if (this.fieldValues.User) {
              // @ts-ignore
              guideProcessed = guideProcessed.replace(re, (this.fieldValues.User[camelCaseProperty] || ''));
            }
            break;
        }
      }
    }

    if (copy) {
      this.callPrepTitlesCopy = [];
      this.callPrepTextsCopy = [];
      this.guideProcessedCopy = [];
    } else {
      this.callPrepTitles = [];
      this.callPrepTexts = [];
      this.guideProcessed = [];
    }

    const regexTitle = /<p class=\"prep-title\".*>.*?<\/p>|<p class=\"interview-date-time\">.*?<\/p>/g;
    const regexTitleText = /<p.*class="prep"[\s\S]+?<\/p>/g;

    let match = regexTitle.exec(guideProcessed);
    let prevEnd = 0;

    while (match !== null) {
      // Call Prep title:
      const titleHTML = match[0];

      if (copy) {
        if (!!prevEnd && (prevEnd < match.index)) {
          this.guideProcessedCopy?.push(this.domSanitizer.bypassSecurityTrustHtml(guideProcessed.substring(prevEnd, match.index)));
        } else {
          this.callPrepTitlesCopy.push('');
          this.callPrepTextsCopy.push(this.domSanitizer.bypassSecurityTrustHtml(''));
          this.guideProcessedCopy?.push(this.domSanitizer.bypassSecurityTrustHtml(guideProcessed.substring(0, match.index)));
        }
      } else {
        if (!!prevEnd && (prevEnd < match.index)) {
          this.guideProcessed?.push(this.domSanitizer.bypassSecurityTrustHtml(guideProcessed.substring(prevEnd, match.index)));
        } else {
          this.callPrepTitles.push('');
          this.callPrepTexts.push(this.domSanitizer.bypassSecurityTrustHtml(''));
          this.guideProcessed?.push(this.domSanitizer.bypassSecurityTrustHtml(guideProcessed.substring(0, match.index)));
        }
      }

      const tmproot = document.createElement('div');
      tmproot.innerHTML = titleHTML;

      if (copy) {
        this.callPrepTitlesCopy.push(tmproot.textContent || tmproot.innerText || '');
      } else {
        this.callPrepTitles.push(tmproot.textContent || tmproot.innerText || '');
      }

      prevEnd = match.index + titleHTML.length;

      // Call prep text:
      const matchText = regexTitleText.exec(guideProcessed);

      if (matchText) {
        const titleTextHTML = matchText[0];

        const tmproot2 = document.createElement('div');
        tmproot2.innerHTML = titleTextHTML;

        if (copy) {
          this.callPrepTextsCopy.push(this.domSanitizer.bypassSecurityTrustHtml(tmproot2.innerHTML || ''));
        } else {
          this.callPrepTexts.push(this.domSanitizer.bypassSecurityTrustHtml(tmproot2.innerHTML || ''));
        }

        prevEnd = matchText.index + titleTextHTML.length;
      }

      match = regexTitle.exec(guideProcessed);
    }

    if (copy) {
      this.guideProcessedCopy?.push(this.domSanitizer.bypassSecurityTrustHtml(guideProcessed.substring(prevEnd)));
    } else {
      this.guideProcessed?.push(this.domSanitizer.bypassSecurityTrustHtml(guideProcessed.substring(prevEnd)));
    }
    this.checkDatetimeInput();
  }

  copyMorganToUserGuide(morgan: any): any {
    const {__v, _id, uuid, created, createdBy, modified, owner, ...therest} = morgan;

    return therest;
  }

  initializeGuide(guide: any, settings: any): void {
    this.guideName = guide.displayName;
    this.guideSource = guide.script;

    if (this.controls.editing === false) {
      this.processGuide();
    }
    else {
      this.controls.editing = false;
    }

    this.widgets = {};
    this.widgetList = [];

    guide.widgets.map((widget: string) => {
      if (widget === 'painPoints') {
        return;
      }

      let name = widget.charAt(0).toUpperCase() + widget.substr(1);

      for (let i = 1, iLimit = name.length; i < iLimit; i++) {
        if (name.charAt(i) === name.charAt(i).toUpperCase()) {
          name = name.substring(0, i) + ' ' + name.substr(i);
          break;
        }
      }

      this.widgetList.push(name);

      switch (name) {
        case 'Source Card':
          this.widgets[name] = settings.guide &&
            (typeof settings.guide.viewSource !== 'undefined') ? settings.guide.viewSource : true;
          break;

        case 'Clients':
          this.widgets[name] = settings.guide &&
            (typeof settings.guide.viewClient !== 'undefined') ? settings.guide.viewClient : true;
          break;

        case 'Candidates':
          this.widgets[name] = settings.guide &&
            (typeof settings.guide.viewCandidate !== 'undefined') ? settings.guide.viewCandidate : true;
          break;

        case 'Objections':
          this.widgets[name] = settings.guide &&
            (typeof settings.guide.viewObjections !== 'undefined') ? settings.guide.viewObjections : true;
          break;

        case 'Notes':
          this.widgets[name] = settings.guide &&
            (typeof settings.guide.viewNotes !== 'undefined') ? settings.guide.viewNotes : true;
          break;
      }
    });
    setTimeout(() => {
      this.subscribeOnDatetimePicker();
    });

    this.setGuideForApproval();
  }

  subscribeOnDatetimePicker(): void {
    const htmlElement: any = this.getDatetimePickerElement();
    if (!htmlElement) {
      this.dateTimePickerDate = undefined;
      return;
    }
    htmlElement.addEventListener('input', () => {
      this.dateTimePickerDate = new Date(htmlElement.value);
    });
  }

  getDatetimePickerElement(): HTMLElement | null {
    return document.getElementById('datetimePickerElement');
  }

  switchWidget(name: string): void {
    this.widgets[name] = !this.widgets[name];
  }

  scriptTypeChanged(scriptType: string): void {
    this.controls.editing = false;
    // @ts-ignore
    this.editing.toggled = false;
    // @ts-ignore
    this.editing.onInput(false);

    const name = this.route.snapshot.paramMap.get('name');

    const userKey = 'user';
    const queryUser = this.route.snapshot.queryParams[userKey];

    if (name && this.user) {
      if (scriptType === 'morgan') {
        this.eguideService.lookupByMorgan(name)
          .subscribe((guide) => {
            this.guide = guide;
            this.initializeGuide(guide, this.settings);
          });
      } else {
        this.eguideService.lookupByUser(name, queryUser || this.user.username)
          .subscribe((guide) => {
            this.guide = guide;
            this.initializeGuide(guide, this.settings);
          }, err => {
            if (err && err.status === 404) {
              if (this.guide && this.guide.length) {
                this.initializeGuide(this.guide, this.settings);
              }
              else {
                this.eguideService.lookupByMorgan(name)
                  .subscribe((guide) => {
                    const newGuide = this.copyMorganToUserGuide(guide);
                    this.guide = newGuide;
                    this.initializeGuide(newGuide, this.settings);
                  });
              }
            }
          });
      }
    }
  }

  setGuideForApproval(): void {
    if (this.canApprove && !!this.guide.scriptCopy && (this.guide.script !== this.guide.scriptCopy)) {
      this.guideForAproval = this.guide;
      this.processGuide(true, this.guide.scriptCopy);
    } else {
      this.guideForAproval = null;
    }
  }

  approveGuide(): void {
    if (this.actionInProgress) {
      return;
    }

    this.actionInProgress = true;

    const guideId = this.guideForAproval.uuid;

    this.eguideService.approve(guideId)
      .subscribe(() => {
        const session = this.sessionStateService.get();
        const username = (session.user as User).username;

        this.notificationService.create({
          guideId,
          account_uuid: this.accountUuid,
          sender: username,
          receiver: this.guideForAproval.owner,
          approved: true,
          message: 'Nice Job. Approved.',
          notificationType: 'guide',
          timestamp: new Date(),
          read: false
        }).pipe(first()).subscribe(() => {
          this.guideForAproval = null;
          this.actionInProgress = false;

          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { user: null },
            queryParamsHandling: 'merge'
          });

          this.scriptTypeChanged(this.scriptType);
        });
      });
  }

  rejectGuide(): void {
    if (this.actionInProgress) {
      return;
    }

    this.actionInProgress = true;

    const guideId = this.guideForAproval.uuid;

    this.eguideService.reject(guideId)
      .subscribe(() => {
        const session = this.sessionStateService.get();
        const username = (session.user as User).username;

        this.notificationService.create({
          guideId,
          account_uuid: this.accountUuid,
          sender: username,
          receiver: this.guideForAproval.owner,
          approved: false,
          message: 'This script moderation request has been rejected. Please contact your administrator/manager to discuss.',
          notificationType: 'guide',
          timestamp: new Date(),
          read: false
        }).pipe(first()).subscribe(() => {
          this.guideForAproval = null;
          this.actionInProgress = false;

          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { user: null },
            queryParamsHandling: 'merge'
          });

          this.scriptTypeChanged(this.scriptType);
        });
      });
  }

  toggleEditing(event: Event): void {
    const editing = (event?.target as HTMLInputElement).checked;
    this.controls.editing = editing;

    if (editing) {
      this.guideProcessed = [];
    }
    else {
      this.processGuide();
    }
    setTimeout(() => {
      this.checkDatetimeInput();
      this.subscribeOnDatetimePicker();
    });
  }

  checkDatetimeInput(): void {
    setTimeout(() => {
      this.isDatetimeInputPresent = !!this.getDatetimePickerElement();
      if (!this.isDatetimeInputPresent) {
         this.dateTimePickerDate = undefined;
      }
    });
  }

  save(): void {
    this.guide.script = this.guideSource;

    if (this.isAdmin && (this.scriptType === 'morgan')) {
      this.eguideService.upsertByMorgan(this.guide).subscribe((guide) => {
        this.guide = guide;
      });
    }
    else {
      const session = this.sessionStateService.get();
      const username = (session.user as User).username;

      this.eguideService.upsertByUser(this.guide, username, this.accountUuid).subscribe((guide) => {
        this.guide = guide;

        if (!guide.approved) {
          this.alertService.success
            ('Your changes have been sent to the account admin for approval. Please check back later.', this.eguideSaveGuide.nativeElement);
        } else {
          this.alertService.success
            ('Your changes have been automatically approved. Nice job!', this.eguideSaveGuide.nativeElement);
        }
      });
    }
  }

  addToInnerView(): void {
    const candidate = this.fieldValues.Candidate as Candidate | null;

    const date = this.getFormatedDatepickerDate();
    const time = this.getFormatedDatepickerTime();

    if (candidate && date && time) {
      this.plannerService.get().subscribe((planner) => {
        if (!planner.innerViews) {
          planner.innerViews = [];
        }

        planner.innerViews.push({
          date,
          time,
          candidate: candidate.firstName + ' ' + candidate.lastName,
          title: candidate.title,
          phone: ((candidate.phoneNumbers && candidate.phoneNumbers.length) ? candidate.phoneNumbers[0].number : ''),
          email: ((candidate.emails && candidate.emails.length) ? candidate.emails[0].address : ''),
          source: '',
          form: '',
          notes: ''
        });

        this.plannerService.update(_.pick(planner, ['uuid', 'innerViews'])).subscribe(() => {
          this.alertService.success('InnerView record was added to Daily Planner');
        });
      });
    }
  }

  getFormatedDatepickerDate(): string {
    if (!this.dateTimePickerDate) {
      return '';
    }
    const year = this.dateTimePickerDate.getUTCFullYear();
    const month = this.dateTimePickerDate.getUTCMonth() + 1; // months from 1-12
    const day = this.dateTimePickerDate.getUTCDate();
    return `${year}-${this.padWithZero(month)}-${this.padWithZero(day)}`;
  }

  getFormatedDatepickerTime(): string {
    if (!this.dateTimePickerDate) {
      return '';
    }
    let hours = this.dateTimePickerDate.getHours();
    const minutes = this.dateTimePickerDate.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    if (hours > 12) {
      ampm  = 'PM';
      hours -= 12;
    }
    return `${this.padWithZero(hours)}:${this.padWithZero(minutes)} ${ampm}`;
  }


  addToSendout(): void {
    const client = this.fieldValues.Client as Client | null;

    let ampm = this.selectedAmPm;
    let hour = +this.selectedHour;

    if (hour > 12) {
      ampm  = 'PM';
      hour -= 12;
    }

    if (client) {
      this.plannerService.get().subscribe((planner) => {
        if (!planner.sendouts) {
          planner.sendouts = [];
        }

        planner.sendouts.push({
          priority: '',
          status: 'none',
          date: this.selectedDate.year + '-' + this.padWithZero(this.selectedDate.month) + '-' + this.padWithZero(this.selectedDate.day),
          time: this.padWithZero(hour) + ':' + this.padWithZero(this.selectedMinute) + ' ' + ampm,
          candidate: this.candidateName,
          hiringManager: this.hiringManager,
          company: client.company,
          phone: ((client.phoneNumbers && client.phoneNumbers.length) ? client.phoneNumbers[0].number : ''),
          notes: ''
        });

        this.plannerService.update(_.pick(planner, ['uuid', 'sendouts'])).subscribe(() => {
          this.alertService.success('Sendout record was added to Daily Planner');
        });
      });
    }
  }

  selectedTimeIsValid(): boolean {
    if (!this.selectedHour || (typeof +this.selectedHour !== 'number')) {
      return false;
    }

    return true;
  }

  padWithZero(input: any): string {
    const stringInput = input.toString();

    if (stringInput.length === 1) {
      return '0' + stringInput;
    }

    return stringInput;
  }

  switchAmPm(): void {
    this.selectedAmPm = (this.selectedAmPm === 'AM' ? 'PM' : 'AM');
  }
}
