import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, BehaviorSubject, of } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';

import * as moment from 'moment';

import { Account } from '../accounts.model';
import { AccountsActions } from '../accounts.actions';
import { AccountsState } from '../accounts.state';

import { EmailType } from '../../../shared/models/email.model';
import { PhoneType } from '../../../shared/models/phone.model';
import { Address } from '../../../shared/models/address.model';

import { AddressDialogComponent } from '../../../shared/address-dialog/address-dialog.component';
import { CommonDialogComponent } from '../../../shared/common-dialog/common-dialog.component';

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss']
})
export class AccountDetailComponent implements OnInit, OnDestroy {

  // @ts-ignore
  @Select(AccountsState.accounts) accounts$: Observable<Account[]>;
  // @ts-ignore
  @Select(AccountsState.currentAccounts) currentAccounts$: Observable<{[key: string]: Account}>;

  isUnique$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  current$: Observable<Account | null> =
    of({ name: '', displayName: '', primaryContact: '', active: false,
      subscription: { startDate: '', endDate: '', totalSeats: 0, seatsUsed: 0 },
        emails: [], phoneNumbers: [], addresses: [] });

  uuid = '';

  form: FormGroup;
  formSubscription: Subscription | null = null;
  initSubscription: Subscription | null = null;

  EmailType = EmailType;
  PhoneType = PhoneType;

  addresses: Address[] = [];

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private store: Store
  ) {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      displayName: ['', [Validators.required]],
      description: [''],
      primaryContact: ['', [Validators.required]],
      active: [true],
      created: [''],
      modified: [''],
      totalSeats: [0],
      seatsUsed: [0],
      startDate: [''],
      endDate: ['']
    });
  }

  ngOnInit(): void {
    this.current$ = this.currentAccounts$.pipe(map(currentAccounts => {
      return currentAccounts[this.uuid] ||
        { name: '', displayName: '', primaryContact: '', active: false,
          subscription: { startDate: '', endDate: '', totalSeats: 0, seatsUsed: 0 },
            emails: [], phoneNumbers: [], addresses: [] };
    }));

    this.accounts$.pipe(first()).subscribe(accounts => {
      const unique = accounts.filter(accountCheck => (accountCheck.uuid !== this.uuid) &&
        (accountCheck.name === this.f.name.value)).length === 0;
      this.isUnique$.next(unique);
    });

    // @ts-ignore
    this.initSubscription = this.current$.subscribe((account: Account | null) => {
      if (account) {
        const startDateMoment = moment(account.subscription.startDate);
        const endDateMoment = moment(account.subscription.endDate);

        this.form.patchValue({
          name: account.name,
          displayName: account.displayName,
          description: account.description,
          primaryContact: account.primaryContact,
          active: account.active,
          created: moment(account.created).format('MM/DD/YYYY'),
          modified: moment(account.modified).format('MM/DD/YYYY'),
          totalSeats: account.subscription.totalSeats,
          seatsUsed: account.subscription.seatsUsed,
          startDate: { year: startDateMoment.year(), month: startDateMoment.month() + 1, day: startDateMoment.date() },
          endDate: { year: endDateMoment.year(), month: endDateMoment.month() + 1, day: endDateMoment.date() }
        });

        this.addresses = (account.addresses || []).slice();

        if (!account.uuid) {
          this.form.get('name')?.enable();
        } else {
          this.form.get('name')?.disable();
        }

        if (this.initSubscription) {
          this.initSubscription.unsubscribe();
          this.initSubscription = null;
        }
      }

      setTimeout(() => {
        Object.values(this.form.controls).forEach(control => {
          control.markAsDirty();
        });

        this.formSubscription = this.form.valueChanges.subscribe(() => {
          this.updateMethodBackend();

          this.accounts$.pipe(first()).subscribe(accounts => {
            const unique = accounts.filter(accountCheck => (accountCheck.uuid !== this.uuid) &&
              (accountCheck.name === this.f.name.value)).length === 0;
            this.isUnique$.next(unique);
          });
        });
      }, 200);
    });
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }

    if (this.initSubscription) {
      this.initSubscription.unsubscribe();
      this.initSubscription = null;
    }
  }

  updateMethodBackend(): void {
    const startDateObj = this.f.startDate.value;
    const endDateObj = this.f.endDate.value;

    const account: any = {
      name: this.f.name.value,
      displayName: this.f.displayName.value,
      description: this.f.description.value,
      primaryContact: this.f.primaryContact.value,
      active: this.f.active.value,
      subscription: {
        startDate: moment([startDateObj.year, startDateObj.month - 1, startDateObj.day]).toISOString(),
        endDate: moment([endDateObj.year, endDateObj.month - 1, endDateObj.day]).toISOString(),
        totalSeats: this.f.totalSeats.value,
        seatsUsed: this.f.seatsUsed.value
      }
    };

    this.store.dispatch(new AccountsActions.UpdateCurrent({ uuid: this.uuid, account }));
  }

  save(another?: boolean): void {
    this.updateMethodBackend();

    this.activeModal.close({ another });
  }

  close(del?: boolean): void {
    if (del) {
      const modalRef = this.modalService.open(CommonDialogComponent);

      modalRef.componentInstance.closeButtonText = 'Cancel';
      modalRef.componentInstance.actionButtonText = 'Delete';
      modalRef.componentInstance.headerText = 'Delete Account';
      modalRef.componentInstance.bodyText = 'Are you sure you want to delete the this account?';

      modalRef.result.then(() => {
        this.activeModal.dismiss({ delete: true });
      }).catch((error) => { });
    } else {
      this.activeModal.dismiss();
    }
  }

  addEmail(): void {
    this.store.dispatch(new AccountsActions.AddEmail({ uuid: this.uuid }));
  }

  setEmailType(index: number, select: EventTarget | null): void {
    if (select) {
      const value = ((select as HTMLSelectElement).value as unknown);
      const type: EmailType = (value as EmailType);

      this.store.dispatch(new AccountsActions.SetEmailType({ uuid: this.uuid, index, type }));
    }
  }

  setEmailAddress(index: number, input: EventTarget | null): void {
    const address = (input as HTMLInputElement).value;

    this.store.dispatch(new AccountsActions.SetEmailAddress({ uuid: this.uuid, index, address }));
  }

  removeEmail(index: number): void {
    this.store.dispatch(new AccountsActions.RemoveEmail({ uuid: this.uuid, index }));
  }

  addPhone(): void {
    this.store.dispatch(new AccountsActions.AddPhone({ uuid: this.uuid }));
  }

  setPhoneType(index: number, select: EventTarget | null): void {
    if (select) {
      const value = ((select as HTMLSelectElement).value as unknown);
      const type: PhoneType = (value as PhoneType);

      this.store.dispatch(new AccountsActions.SetPhoneType({ uuid: this.uuid, index, type }));
    }
  }

  setPhoneNumber(index: number, input: EventTarget | null): void {
    const phone = (input as HTMLInputElement).value;

    this.store.dispatch(new AccountsActions.SetPhoneNumber({ uuid: this.uuid, index, phoneNumber: phone }));
  }

  removePhone(index: number): void {
    this.store.dispatch(new AccountsActions.RemovePhone({ uuid: this.uuid, index }));
  }

  addAddress(): void {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      size: 'lg'
    };

    const modalRef = this.modalService.open(AddressDialogComponent, ngbModalOptions);

    modalRef.componentInstance.address = null;

    modalRef.result.then((result) => {
      this.addresses.push(result.address);
      this.store.dispatch(new AccountsActions.AddAddress({ uuid: this.uuid, address: result.address }));
    }).catch((error) => { });
  }

  editAddress(index: number): void {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      size: 'lg'
    };

    const modalRef = this.modalService.open(AddressDialogComponent, ngbModalOptions);

    modalRef.componentInstance.address = this.addresses[index];

    modalRef.result.then((result) => {
      this.addresses.splice(index, 1, result.address);
      this.store.dispatch(new AccountsActions.UpdateAddress({ uuid: this.uuid, address: result.address, index }));
    }).catch((error) => { });
  }

  removeAddress(index: number): void {
    this.addresses.splice(index, 1);
    this.store.dispatch(new AccountsActions.RemoveAddress({ uuid: this.uuid, index }));
  }

  // convenience getter for easy access to form fields
  get f(): {[key: string]: AbstractControl} { return this.form.controls; }
}
