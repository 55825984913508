import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import { ServerService } from './server.service';

import { AlertService } from '../shared/alert.service';
import { SessionStateService } from '../shared/session-state.service';

import { User } from './models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private defaultSession = {
    loggedIn: false,
    session: { username: '', roles: [], csrfToken: SessionStateService.DEFAULT_CSRF_TOKEN },
    version: '',
    user: { firstName: '', lastName: '' }
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private alertService: AlertService,
    private serverService: ServerService,
    private sessionStateService: SessionStateService) { }

  isSecure(): boolean {
    return (location.protocol === 'https:');
  }

  loginStatus(): Observable<any> {
    return this.http.get(this.serverService.api('/loginstatus'), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed for retrieve login status');
        return throwError(error);
      }));
  }

  currentUser(): Observable<any> {
    return this.http.get(this.serverService.api('/users/current'), this.serverService.apiConfig())
      .pipe(first(), map((response: any) => {
        return response;
      }));
  }

  login(username: string, password: string): Observable<any> {
    return this.http.post(this.serverService.api('/login'), { username, password }, this.serverService.apiConfig())
      .pipe(first());
  }

  logout(): Observable<any> {
    return this.http.post(this.serverService.api('/logout'), null, this.serverService.apiConfig())
      .pipe(first(), tap(() => {
        this.sessionStateService.set(this.defaultSession);

        setTimeout(() => {
          this.router.navigate(['/']);
        }, 200);
      }));
  }

  logoutOther(contact: any): Observable<any> {
    return this.http.post(this.serverService.api('/logoutother'), contact, this.serverService.apiConfig())
      .pipe(first());
  }

  //

  sendRescueEmail(email: any): Observable<any> {
    return this.http.post(this.serverService.api('/admin/rescue-email'), email, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed sending rescue email ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  //

  lookup(username: string): Observable<User> {
    return this.http.get(
      !!username ?
        this.serverService.api('/users', 'lookup', username) :
        this.serverService.api('/users/current'),
        this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to fetch user ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  lookupByUUID(uuid: string): Observable<User> {
    return this.http.get(this.serverService.api('/users/byuuid', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to fetch user ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  list(offset?: number, limit?: number, column?: string, order?: string): Observable<User[]> {
    const options = [];

    if (offset) {
      options.push('offset=' + offset);
    }

    if (limit) {
      options.push('limit=' + limit);
    }

    if (column) {
      options.push('column=' + column);
    }

    if (order) {
      options.push('order=' + order);
    }

    return this.http.get(this.serverService.api('/users/list') +
      (options.length ? '?' : '') + options.join('&'), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to list users ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  listByAccount(account: string, offset?: number, limit?: number, column?: string, order?: string): Observable<User[]> {
    const options = [];

    if (offset) {
      options.push('offset=' + offset);
    }

    if (limit) {
      options.push('limit=' + limit);
    }

    if (column) {
      options.push('column=' + column);
    }

    if (order) {
      options.push('order=' + order);
    }

    return this.http.get(this.serverService.api('/users/listByAccount', account) +
      (options.length ? '?' : '') + options.join('&'), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to list users ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  insert(user: User): Observable<User> {
    return this.http.post(this.serverService.api('/users'), { user }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        if (error.error && error.error.statusCode === 400) {
          this.alertService.error(error.error.message);
        } else {
          this.alertService.error('Failed to insert a user ' + JSON.stringify(error.data));
        }
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  update(user: Partial<User>): Observable<User> {
    return this.http.put(this.serverService.api('/users'), { user }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to update a user ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  updateCurrentUser(user: User): Observable<User> {
    return this.http.put(this.serverService.api('/current-user'), { user }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to update a user ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  updatePassword(uuid: string, newPassword: string): Observable<User> {
    return this.http.put(this.serverService.api('/users-password'), { uuid, newPassword, notify: true }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to update user password ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response.user;
      }));
  }

  updateCurrentUserPassword(oldPassword: string, newPassword: string): Observable<User> {
    return this.http.put(this.serverService.api('/current-password'), { oldPassword, newPassword }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to update current user password ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  delete(uuid: string): Observable<any> {
    return this.http.delete(this.serverService.api('/users', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to delete a user ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  isAccountAdmin(): boolean {
    const session = this.sessionStateService.get();
    const roles = (session.user as User).roles;

    return roles.indexOf('account-admin') > -1;
  }

  simulateLogin(userUuid: string): Observable<any> {
    return this.http.post(this.serverService.api('/simulate/login'), { userUuid }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to impersonate a user ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        const sessionState = this.sessionStateService.get();
        sessionState.session = response;

        this.sessionStateService.set(sessionState);
        this.router.navigate(['/dashboard']);
        return response;
      }));
  }

  simulateLogout(): Observable<any> {
    return this.http.post(this.serverService.api('/simulate/logout'), undefined, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to logout of impersonated user ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        const sessionState = this.sessionStateService.get();
        sessionState.session = response;

        this.sessionStateService.set(sessionState);
        this.router.navigate(['/admin/users/' + response.account_uuid]);
        return response;
      }));
  }

  report(): void {
    const url = this.serverService.api('/reports/users');

    this.http.get(url, {
      ...this.serverService.apiConfig(),
      responseType: 'text'
    })
    .pipe(first(), catchError((error) => {
      this.alertService.error('Failed for retrieve report on users.');
      return throwError(error);
    })).subscribe((response: any) => {
      const encodedUri = encodeURI(response);
      const link = document.createElement('a');

      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodedUri);
      link.setAttribute('download', 'users_report.csv');
      document.body.appendChild(link); // Required for FF
      link.click();
    });
  }

  sendWelcomeEmail(username: string, password: string): Observable<any> {
    return this.http.post(this.serverService.api('/admin/welcome-email'), {username, password}, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed sending welcome email ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }
}
