import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  Inject,
  Renderer2,
  ElementRef,
  QueryList,
  OnDestroy
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal, NgbModalOptions, NgbAccordion } from '@ng-bootstrap/ng-bootstrap';

import { UsersService } from '../shared/users.service';
import { AccountsService } from '../admin/accounts/accounts.service';
import { SearchString } from '../admin/strings/strings.model';
import { StringsService } from '../admin/strings/strings.service';
import { AlertService } from '../shared/alert.service';

import { SearchNameComponent } from './search-name/search-name.component';
import { CommonDialogComponent } from '../shared/common-dialog/common-dialog.component';
import {SavedSearch} from './esourcing.models';
import {EsourcingService} from './esourcing.service';
import {Subscription} from 'rxjs';
import {SOURCING_IFRAME_ID} from '../shared/consts/search-result-events.const';

@Component({
  selector: 'app-esourcing',
  templateUrl: './esourcing.component.html',
  styleUrls: ['./esourcing.component.scss']
})
export class EsourcingComponent implements OnInit, OnDestroy {

  // @ts-ignore
  @ViewChild('esourcingacc', { static: true }) esourcingacc: NgbAccordion;

  // @ts-ignore
  @ViewChild('tipactual', { static: true }) tipactual: ElementRef<any>;

  // @ts-ignore
  @ViewChild('esourcingSaveBtn', { static: true }) esourcingSaveBtn: ElementRef<any>;

  // @ts-ignore
  @ViewChildren('titleinputs', { read: ElementRef }) titleinputs: QueryList<ElementRef>;

  // @ts-ignore
  @ViewChildren('industryinputs', { read: ElementRef }) industryinputs: QueryList<ElementRef>;

  // @ts-ignore
  @ViewChildren('dutyinputs', { read: ElementRef }) dutyinputs: QueryList<ElementRef>;

  // @ts-ignore
  @ViewChildren('locationinputs', { read: ElementRef }) locationinputs: QueryList<ElementRef>;

  // @ts-ignore
  @ViewChildren('companyinputs', { read: ElementRef }) companyinputs: QueryList<ElementRef>;

  // @ts-ignore
  @ViewChild('information', { static: true }) information: ElementRef<any>;

  readonly sourcingIFrameId = SOURCING_IFRAME_ID;
  private isAdmin = false;

  searchStrings: SearchString[] = [];
  showTips = false;

  searchString = '';
  searchStringName = '';
  queryString: SafeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('');

  titles = [''];
  industries = [''];
  criterias = [''];
  locations = [''];
  companies = [''];

  titlesOperator = 'OR';
  industryOperator = 'OR';
  criteriasOperator = 'AND';
  locationsOperator = 'OR';
  companiesOperator = 'OR';

  titlesShown = false;
  industryShown = false;
  criteriasShown = false;
  locationsShown = false;
  companiesShown = false;

  savedSearches: SavedSearch[] = [];

  subscriptions: Subscription[] = [];

  constructor(
    private modalService: NgbModal,
    private usersService: UsersService,
    private accountsService: AccountsService,
    private stringsService: StringsService,
    private alertService: AlertService,
    private domSanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private renderer2: Renderer2,
    private esourcingService: EsourcingService,
    // @ts-ignore
    @Inject(DOCUMENT) private document
    ) {}

  ngOnInit(): void {
    this.fetchSavedSearches();
    this.usersService.loginStatus().subscribe((sessionData) => {
      const roles = sessionData.status.roles;

      this.isAdmin = roles.indexOf('admin') > -1;
    });

    this.refreshStrings();

    this.titles     = [''];
    this.industries = [''];
    this.criterias  = [''];
    this.locations  = [''];
    this.companies  = [''];
  }

  searchStringChanged(searchString: string, searchStringName: string): void {
    this.searchString = searchString;
    this.searchStringName = searchStringName;
    this.queryString = this.domSanitizer.bypassSecurityTrustResourceUrl('');

    this.processQuery();
  }

  refreshStrings(): void {
    this.searchStrings = [];

    this.stringsService.list().subscribe((searchStrings: SearchString[]) => {
      this.searchStrings = searchStrings.slice();
    });
  }

  showTipsPage(): void {
    this.showTips = !this.showTips;

    if (!this.showTips) {
      return;
    }

    this.titlesShown = true;
    this.esourcingacc.expand('ngb-esourcing-title');

    this.industryShown = true;
    this.esourcingacc.expand('ngb-esourcing-industry');

    this.criteriasShown = true;
    this.esourcingacc.expand('ngb-esourcing-criteria');

    this.locationsShown = true;
    this.esourcingacc.expand('ngb-esourcing-location');

    this.companiesShown = true;
    this.esourcingacc.expand('ngb-esourcing-company');
  }

  correctTips(): void {
    setTimeout(() => {
      if (this.showTips) {
        this.titles     = ['Senior Developer', 'Senior Programmer', 'Senior Engineer'];
        this.industries = ['healthcare'];
        this.criterias  = ['javascript', 'HTML', 'CSS', 'python'];
        this.locations  = ['New York City', 'New Jersey'];
        this.companies  = [''];
      } else {
        this.titles     = [''];
        this.industries = [''];
        this.criterias  = [''];
        this.locations  = [''];
        this.companies  = [''];
      }

      setTimeout(() => {
        this.alignTips(this.showTips);
      },
      500);
    },
    100);
  }

  clear(): void {
    const modalRef = this.modalService.open(CommonDialogComponent);

    modalRef.componentInstance.closeButtonText = 'Cancel';
    modalRef.componentInstance.actionButtonText = 'Clear';
    modalRef.componentInstance.headerText = 'Clear Search';
    modalRef.componentInstance.bodyText = 'Are you sure you want to clear all fields?';

    modalRef.result.then((result) => {
      for (let i = 0, iLimit = this.titles.length; i < iLimit; i++) {
        this.titles[i] = '';
      }

      for (let i = 0, iLimit = this.industries.length; i < iLimit; i++) {
        this.industries[i] = '';
      }

      for (let i = 0, iLimit = this.criterias.length; i < iLimit; i++) {
        this.criterias[i] = '';
      }

      for (let i = 0, iLimit = this.locations.length; i < iLimit; i++) {
        this.locations[i] = '';
      }

      for (let i = 0, iLimit = this.companies.length; i < iLimit; i++) {
        this.companies[i] = '';
      }

      if (this.searchString) {
        this.searchStringChanged(this.searchString, this.searchStringName);
      }
    }).catch((error) => { });
  }

  showTitles(): void {
    setTimeout(() => {
      if (this.titlesShown) {
        this.titleinputs.last.nativeElement.focus();
      }
    }, 500);
  }

  addTitle(): void {
    this.titles.push('');

    setTimeout(() => {
      this.titleinputs.last.nativeElement.focus();
    }, 250);
  }

  showIndustries(): void {
    setTimeout(() => {
      if (this.industryShown) {
        this.industryinputs.last.nativeElement.focus();
      }
    }, 500);
  }

  addIndustry(): void {
    this.industries.push('');

    setTimeout(() => {
      this.industryinputs.last.nativeElement.focus();
    }, 250);
  }

  showDuties(): void {
    setTimeout(() => {
      if (this.criteriasShown) {
        this.dutyinputs.last.nativeElement.focus();
      }
    }, 500);
  }

  addCriteria(): void {
    this.criterias.push('');

    setTimeout(() => {
      this.dutyinputs.last.nativeElement.focus();
    }, 250);
  }

  showLocations(): void {
    setTimeout(() => {
      if (this.locationsShown) {
        this.locationinputs.last.nativeElement.focus();
      }
    }, 500);
  }

  addLocation(): void {
    this.locations.push('');

    setTimeout(() => {
      this.locationinputs.last.nativeElement.focus();
    }, 250);
  }

  showCompanies(): void {
    setTimeout(() => {
      if (this.companiesShown) {
        this.companyinputs.last.nativeElement.focus();
      }
    }, 500);
  }

  addCompany(): void {
    this.companies.push('');

    setTimeout(() => {
      this.companyinputs.last.nativeElement.focus();
    }, 250);
  }

  removeTitle(index: number): void {
    if (this.titles.length === 1) {
      this.titles[0] = '';
    } else {
      this.titles.splice(index, 1);
    }
  }

  removeIndustry(index: number): void {
    if (this.industries.length === 1) {
      this.industries[0] = '';
    } else {
      this.industries.splice(index, 1);
    }
  }

  removeCriteria(index: number): void {
    if (this.criterias.length === 1) {
      this.criterias[0] = '';
    } else {
      this.criterias.splice(index, 1);
    }
  }

  removeLocation(index: number): void {
    if (this.locations.length === 1) {
      this.locations[0] = '';
    } else {
      this.locations.splice(index, 1);
    }
  }

  removeCompany(index: number): void {
    if (this.companies.length === 1) {
      this.companies[0] = '';
    } else {
      this.companies.splice(index, 1);
    }
  }

  processQuery(): void {
    const titles    = this.titles.filter(title => !!title.length);
    const industries = this.industries.filter(industry => !!industry.length);
    const criterias = this.criterias.filter(criteria => !!criteria.length);
    const locations = this.locations.filter(location => !!location.length);
    const companies = this.companies.filter(company => !!company.length);

    let titlesQuery = '';

    if (titles.length) {
      const parts = [];

      for (let i = 0, iLimit = titles.length; i < iLimit; i++) {
        const item = titles[i];

        parts.push((item.indexOf(' ') > -1) ? ('"' + item + '"') : item);
      }

      titlesQuery = parts.join(' ' + this.titlesOperator + ' ');
    }

    let industryQuery = '';

    if (industries.length) {
      const parts = [];

      for (let i = 0, iLimit = industries.length; i < iLimit; i++) {
        const item = industries[i];

        parts.push((item.indexOf(' ') > -1) ? ('"' + item + '"') : item);
      }

      industryQuery = parts.join(' ' + this.industryOperator + ' ');
    }

    let criteriasQuery = '';

    if (criterias.length) {
      const parts = [];

      for (let i = 0, iLimit = criterias.length; i < iLimit; i++) {
        const item = criterias[i];

        parts.push((item.indexOf(' ') > -1) ? ('"' + item + '"') : item);
      }

      criteriasQuery = parts.join(' ' + this.criteriasOperator + ' ');
    }

    let locationsQuery = '';

    if (locations.length) {
      const parts = [];

      for (let i = 0, iLimit = locations.length; i < iLimit; i++) {
        const item = locations[i];

        parts.push((item.indexOf(' ') > -1) ? ('"' + item + '"') : item);
      }

      locationsQuery = parts.join(' ' + this.locationsOperator + ' ');
    }

    let companiesQuery = '';

    if (companies.length) {
      const parts = [];

      for (let i = 0, iLimit = companies.length; i < iLimit; i++) {
        const item = companies[i];

        parts.push((item.indexOf(' ') > -1) ? ('"' + item + '"') : item);
      }

      companiesQuery = parts.join(' ' + this.companiesOperator + ' ');
    }

    let fullSearchString = this.searchStringName?.toLowerCase() !== 'diversity' ? (this.searchString + ' ') : '';

    if (this.titlesShown && titlesQuery.length) {
      fullSearchString += '(' + titlesQuery + ') ';
    }

    if (this.industryShown && industryQuery.length) {
      fullSearchString += '(' + industryQuery + ') ';
    }

    if (this.criteriasShown && criteriasQuery.length) {
      fullSearchString += '(' + criteriasQuery + ') ';
    }

    fullSearchString += this.searchStringName?.toLowerCase() === 'diversity' ? (this.searchString + ' ') : '';

    if (this.locationsShown && locationsQuery.length) {
      fullSearchString += '(' + locationsQuery + ') ';
    }

    if (this.companiesShown && companiesQuery.length) {
      fullSearchString += '(' + companiesQuery + ')';
    }

    this.queryString =
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/searchresults.html?showbar=' + this.isAdmin + '&q=' + encodeURIComponent(fullSearchString));
  }

  trackByFn(index: number, item: any): number {
    return index;
  }

  fetchSavedSearches(): void {
    this.esourcingService.listSavedSearches()
      .subscribe(res => {
        this.savedSearches = res || [];
      });
  }

  saveSearchClicked(): void {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static'
    };

    const modalRef = this.modalService.open(SearchNameComponent, ngbModalOptions);

    modalRef.result.then((result) => {
      if (!result) {
        return;
      }

      const now = new Date(); // for now

      const savedSearch: SavedSearch = {
        title: result, // 'Search on ' + now.toLocaleTimeString() + ', ' + now.toLocaleDateString(),
        searchString: this.searchString,
        titles: this.titles.slice().filter(item => !!item),
        industries: this.industries.slice().filter(item => !!item),
        criterias: this.criterias.slice().filter(item => !!item),
        locations: this.locations.slice().filter(item => !!item),
        companies: this.companies.slice().filter(item => !!item),
        titlesOperator: this.titlesOperator,
        industryOperator: this.industryOperator,
        criteriasOperator: this.criteriasOperator,
        locationsOperator: this.locationsOperator,
        companiesOperator: this.companiesOperator
      };

      this.saveSavedSearch(savedSearch);
    });
  }

  saveSavedSearch(savedSearch: SavedSearch): void {
    this.subscriptions.push(this.esourcingService.addSavedSearch(savedSearch)
      .subscribe(() => {
        this.savedSearches.push(savedSearch);
        this.alertService.success('Your search has been saved.', this.esourcingSaveBtn.nativeElement);
      })
    );
  }

  recallSearch(searchObj: SavedSearch): void {
    this.searchString = searchObj.searchString;

    this.titles = searchObj.titles;
    this.titlesShown = !!this.titles.length;
    this.titlesOperator = searchObj.titlesOperator;

    this.industries = searchObj.industries;
    this.industryShown = !!this.industries.length;
    this.industryOperator = searchObj.industryOperator;

    this.criterias = searchObj.criterias;
    this.criteriasShown = !!this.criterias.length;
    this.criteriasOperator = searchObj.criteriasOperator;

    this.locations = searchObj.locations;
    this.locationsShown = !!this.locations.length;
    this.locationsOperator = searchObj.locationsOperator;

    this.companies = searchObj.companies;
    this.companiesShown = !!this.companies.length;
    this.companiesOperator = searchObj.companiesOperator;

    this.processQuery();

    if (this.esourcingacc) {
      if (this.titlesShown) {
        this.esourcingacc.expand('ngb-esourcing-title');
      }

      if (this.industryShown) {
        this.esourcingacc.expand('ngb-esourcing-industry');
      }

      if (this.criteriasShown) {
        this.esourcingacc.expand('ngb-esourcing-criteria');
      }

      if (this.locationsShown) {
        this.esourcingacc.expand('ngb-esourcing-location');
      }

      if (this.companiesShown) {
        this.esourcingacc.expand('ngb-esourcing-company');
      }
    }
  }

  removeSearchClicked(index: number): void {
    const modalRef = this.modalService.open(CommonDialogComponent);

    modalRef.componentInstance.closeButtonText = 'Cancel';
    modalRef.componentInstance.actionButtonText = 'Delete';
    modalRef.componentInstance.headerText = 'Delete a Saved Search';
    modalRef.componentInstance.bodyText = 'Are you sure you want to delete the search "' + this.savedSearches[index].title + '"?';

    modalRef.result.then((result) => {
      const uuid = this.savedSearches[index].uuid;
      if (!uuid) {
        return;
      }
      this.removeSavedSearch(uuid, index);
    });
  }

  removeSavedSearch(uuid: string, index: number): void {
    this.subscriptions.push(this.esourcingService.delete(uuid)
      .subscribe(() => {
        this.savedSearches.splice(index, 1);
        this.alertService.success('Your search has been removed.', this.esourcingSaveBtn.nativeElement);
      })
    );
  }

  alignTips(align: boolean): void {
    this.searchString = '';
    this.searchStringName = '';
    this.searchStringChanged(this.searchString, this.searchStringName);

    const toolsContainer = document.getElementById('esourcing-tools');
    const tipContainer = document.getElementById('tip-actual');

    if (align && tipContainer && toolsContainer) {
      (tipContainer.firstChild?.nextSibling as HTMLElement).style.height = toolsContainer.scrollHeight + 'px';
    } else if (!align && tipContainer) {
      (tipContainer.firstChild?.nextSibling as HTMLElement).style.height = 'auto';
    }

    const topCoord = this.information.nativeElement.getBoundingClientRect().top;
    const sidebarContainer = document.getElementById('esourcing-sidebar');

    if (sidebarContainer) {
      sidebarContainer.style.top = this.showTips ? (topCoord + 'px') : '0px';
    }

    const sidebarHint = document.getElementById('search-hint');

    if (sidebarHint) {
      sidebarHint.style.top = this.showTips ? (topCoord + 'px') : '0px';
    }

    const sidebarAction = document.getElementById('search-action');

    if (sidebarAction) {
      sidebarAction.style.top = this.showTips ? (topCoord + 'px') : '0px';
    }

    const dataTransferComponent = document.getElementById('data-transfer-component');

    if (dataTransferComponent) {
      dataTransferComponent.style.top = this.showTips ? (topCoord + 'px') : '0px';
    }


    if (!this.showTips) {
      return;
    }

    setTimeout(() => {

    const titleTip = document.getElementById('title-tip');
    const industryTip = document.getElementById('industry-tip');
    const dutiesTip = document.getElementById('duties-tip');
    const locationsTip = document.getElementById('locations-tip');
    const competitorTip = document.getElementById('competitor-tip');

    const titleCard = document.getElementById('ngb-esourcing-title-header')?.parentElement;
    const industryCard = document.getElementById('ngb-esourcing-industry-header')?.parentElement;
    const dutiesCard = document.getElementById('ngb-esourcing-criteria-header')?.parentElement;
    const locationsCard = document.getElementById('ngb-esourcing-location-header')?.parentElement;
    const competitorCard = document.getElementById('ngb-esourcing-company-header')?.parentElement;

    if (titleTip && titleCard) {
      if (align && !titleTip.style.top) {
        const diff = titleCard.getBoundingClientRect().top - titleTip.getBoundingClientRect().top;

        titleTip.style.top = diff + 'px';
      }
    }

    if (industryTip && industryCard) {
      if (align && !industryTip.style.top) {
        const diff = industryCard.getBoundingClientRect().top - industryTip.getBoundingClientRect().top;

        industryTip.style.top = diff + 'px';
      }
    }

    if (dutiesTip && dutiesCard) {
      if (align && !dutiesTip.style.top) {
        const diff = dutiesCard.getBoundingClientRect().top - dutiesTip.getBoundingClientRect().top;

        dutiesTip.style.top = diff + 'px';
      }
    }

    if (locationsTip && locationsCard) {
      if (align && !locationsTip.style.top) {
        const diff = locationsCard.getBoundingClientRect().top - locationsTip.getBoundingClientRect().top;

        locationsTip.style.top = diff + 'px';
      }
    }

    if (competitorTip && competitorCard) {
      if (align && !competitorTip.style.top) {
        const diff = competitorCard.getBoundingClientRect().top - competitorTip.getBoundingClientRect().top;

        competitorTip.style.top = diff + 'px';
      }
    }

  }, 250);
  }

  ngOnDestroy(): void {
    if (this.subscriptions?.length) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }
}



