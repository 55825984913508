import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';

import { AlertService } from '../../shared/alert.service';
import { ServerService } from '../../shared/server.service';

import { Candidate } from './candidate.model';

@Injectable({
  providedIn: 'root'
})
export class CandidatesService {

  constructor(
    private http: HttpClient,
    private serverService: ServerService,
    private alertService: AlertService) { }

  lookup(uuid: string): Observable<Candidate> {
    return this.http.get(this.serverService.api('/candidates', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        // No alert here because 404 error here is normal
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  list(owner: string, search?: string, offset?: number, limit?: number, column?: string, order?: string): Observable<Candidate[]> {
    const options = [];

    if (search) {
      options.push('search=' + search);
    }

    if (offset) {
      options.push('offset=' + offset);
    }

    if (limit) {
      options.push('limit=' + limit);
    }

    if (column) {
      options.push('column=' + column);
    }

    if (order) {
      options.push('order=' + order);
    }

    return this.http.get(this.serverService.api('/candidates', 'byowner', owner) +
      (options.length ? '?' : '') + options.join('&'), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to list candidates ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  listByAccount(
    account: string, search?: string, offset?: number, limit?: number, column?: string, order?: string): Observable<Candidate[]> {

    const options = [];

    if (search) {
      options.push('search=' + search);
    }

    if (offset) {
      options.push('offset=' + offset);
    }

    if (limit) {
      options.push('limit=' + limit);
    }

    if (column) {
      options.push('column=' + column);
    }

    if (order) {
      options.push('order=' + order);
    }

    return this.http.get(this.serverService.api('/candidates', 'byaccount', account) +
      (options.length ? '?' : '') + options.join('&'), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to list candidates ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  insert(candidate: Candidate): Observable<Candidate> {
    return this.http.post(this.serverService.api('/candidates'), { candidate }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to insert a candidate ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  update(candidate: Candidate): Observable<Candidate> {
    return this.http.put(this.serverService.api('/candidates'), { candidate }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to update a candidate ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  delete(uuid: string): Observable<any> {
    return this.http.delete(this.serverService.api('/candidates', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to delete a candidate ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  upload(url: string, formData: FormData): Observable<any> {
    return this.http.post(url, formData, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to upload ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  //

  lookupReference(uuid: string): Observable<any> {
    return this.http.get(this.serverService.api('/candidatereferences', 'bycandidate', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        // this.alertService.error('Failed to lookup candidate reference ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  listReferences(useruuid: string): Observable<any[]> {
    return this.http.get(this.serverService.api('/candidatereferences', useruuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to list candidate reference ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  upsertReference(candidateReference: any): Observable<any> {
    return this.http.put(this.serverService.api('/candidatereferences'), candidateReference, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to upsert a candidate reference ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  lookupInnerview(uuid: string): Observable<any> {
    return this.http.get(this.serverService.api('/innerviews', 'bycandidate', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        // this.alertService.error('Failed to lookup innerview ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  listInnerviews(useruuid: string): Observable<any[]> {
    return this.http.get(this.serverService.api('/innerviews', 'list', useruuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to list innerviews ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  upsertInnerview(candidateReference: any): Observable<any> {
    return this.http.put(this.serverService.api('/innerviews'), candidateReference, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to upsert an innerview ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  bulkInsert(candidates: Candidate[]): Observable<{candidate: {candidates: Candidate[], areDuplicatesAvoided: boolean}}> {
    return this.http.post(this.serverService.api('/candidates/batch'), { candidates }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }
}
