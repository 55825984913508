import { User } from 'src/app/shared/models/user.model';
import { PhoneType } from 'src/app/shared/models/phone.model';
import { Address } from 'src/app/shared/models/address.model';

export namespace UsersActions {
  export class Load {
    static readonly type = 'UsersActions.Load';
    constructor(public payload: {
      accountId: string
    } ) {}
  }

  export class SetOrder {
    static readonly type = 'UsersActions.SetOrder';
    constructor(public payload: {
      sort: string,
      order: string
    } ) {}
  }

  export class SetPagination {
    static readonly type = 'UsersActions.SetPagination';
    constructor(public payload: {
      page: number,
      limit: number
    } ) {}
  }

  export class Search {
    static readonly type = 'UsersActions.Search';
    constructor(public payload: {
      user: string | null,
      accountId?: string
    } ) {}
  }

  export class Show {
    static readonly type = 'UsersActions.Show';
    constructor(public payload: {
      uuid: string
    } ) {}
  }

  export class Finished {
    static readonly type = 'UsersActions.Finished';
    constructor(public payload: {
      uuid: string
    } ) {}
  }

  export class SwitchAdminRole {
    static readonly type = 'UsersActions.SwitchAdminRole';
    constructor(public payload: {
      uuid: string,
      turn: boolean
    } ) {}
  }

  export class SwitchUserGuideManagerRole {
    static readonly type = 'UsersActions.SwitchUserGuideManagerRole';
    constructor(public payload: {
      uuid: string,
      turn: boolean
    } ) {}
  }

  export class ChangePassword {
    static readonly type = 'UsersActions.ChangePassword';
    constructor(public payload: {
      uuid: string,
      oldPassword?: string,
      password: string
    } ) {}
  }

  export class UpdateCurrent {
    static readonly type = 'UsersActions.UpdateCurrent';
    constructor(public payload: {
      uuid: string,
      user: any
    } ) {}
  }

  export class Update {
    static readonly type = 'UsersActions.Update';
    constructor(public payload: {
      uuid: string
    } ) {}
  }

  export class Delete {
    static readonly type = 'UsersActions.Delete';
    constructor(public payload: {
      uuid: string | null
    } ) {}
  }

  export class Select {
    static readonly type = 'UsersActions.Select';
    constructor(public payload: {
      uuid: string,
      select: boolean
    } ) {}
  }

  export class UnselectAll {
    static readonly type = 'UsersActions.UnselectAll';
  }

  export class ShowWithUser {
    static readonly type = 'UsersActions.ShowWithUser';
    constructor(public payload: {
      uuid: string,
      user: User
    } ) {}
  }

  export class AddPhone {
    static readonly type = 'UsersActions.AddPhone';
    constructor(public payload: {
      uuid: string
    } ) {}
  }

  export class SetPhoneType {
    static readonly type = 'UsersActions.SetPhoneType';
    constructor(public payload: {
      uuid: string,
      index: number,
      type: PhoneType
    } ) {}
  }

  export class SetPhoneNumber {
    static readonly type = 'UsersActions.SetPhoneNumber';
    constructor(public payload: {
      uuid: string,
      index: number,
      phoneNumber: string
    } ) {}
  }

  export class RemovePhone {
    static readonly type = 'UsersActions.RemovePhone';
    constructor(public payload: {
      uuid: string,
      index: number
    } ) {}
  }

  export class AddAddress {
    static readonly type = 'UsersActions.AddAddress';
    constructor(public payload: {
      uuid: string,
      address: Address
    } ) {}
  }

  export class UpdateAddress {
    static readonly type = 'UsersActions.UpdateAddress';
    constructor(public payload: {
      uuid: string,
      address: Address,
      index: number
    } ) {}
  }

  export class RemoveAddress {
    static readonly type = 'UsersActions.RemoveAddress';
    constructor(public payload: {
      uuid: string,
      index: number
    } ) {}
  }

  export class NeedsReload {
    static readonly type = 'UsersActions.NeedsReload';
  }
  export class SendWelcomeEmail {
    static readonly type = 'UsersActions.SendWelcomeEmail';
    constructor(public payload: {user: User} ) {}
  }
}
