import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-search-name',
  templateUrl: './search-name.component.html',
  styleUrls: ['./search-name.component.scss']
})
export class SearchNameComponent implements OnInit {

  nameForm: FormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) {
    this.nameForm = this.formBuilder.group({
      searchname: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  save(): void {
    this.activeModal.close(this.nameForm.get('searchname')?.value);
  }

  close(): void {
    this.activeModal.dismiss();
  }
}
