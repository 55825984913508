import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';

import { of, Observable } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';

import * as _ from 'lodash';

import { ClientsState } from '../../admin/clients/clients.state';
import { Client } from 'src/app/admin/clients/clients.model';
import { User } from 'src/app/shared/models/user.model';
import { Session } from 'src/app/shared/session-state.service';

import { CommonDialogComponent } from '../../shared/common-dialog/common-dialog.component';
import { ClientDetailComponent } from '../../admin/clients/client-detail/client-detail.component';
import { ClientsActions } from '../../admin/clients/clients.actions';

import { PositionComponent } from './position/position.component';

import { AlertService } from '../../shared/alert.service';
import { ServerService } from '../../shared/server.service';
import { UsersService } from '../../shared/users.service';
import { ClientsService } from '../../admin/clients/clients.service';
import { DocumentsService } from '../documents.service';
import { SessionStateService } from '../../shared/session-state.service';
import { EventBusService } from '../../shared/event-bus.service';
import { ContentService } from '../../shared/content.service';

@Component({
  selector: 'app-searchassignment',
  templateUrl: './searchassignment.component.html',
  styleUrls: ['./searchassignment.component.scss']
})
export class SearchassignmentComponent implements OnInit, AfterViewInit {

  // @ts-ignore
  @Select(ClientsState.current) current$: Observable<Client | null>;

  // @ts-ignore
  @ViewChild('searchAssignmentDownload', { static: true }) searchAssignmentDownload: ElementRef<any>;

  // @ts-ignore
  @ViewChild('searchAssignmentSave', { static: true }) searchAssignmentSave: ElementRef<any>;

  clients: Client[] = [];
  client: Client | null | undefined = null;
  latestClient: Client | null | undefined = null;

  positions: any[] = [];
  position: any = null;

  fields: any = {};
  fieldsPrev: any = {};

  defaultFields = {
    client_uuid: '',
    account_uuid: '',
    user_uuid: '',

    // Top Section
    company: '',               // From Client (company field) (Client Name)
    position: '',              // unique key across Client + User
    location: '',
    date: '',

    contactFirstName: '',        // From Client (contact)
    contactLastName: '',         // From Client (contact)
    contactTitle: '',            // From Client (contact)

    recruiter: '',	        // From User (associate)
    source: '',
    percentFee: 0,

    // Block 2:
    urgentPosition: '',
    topTier: '',
    downsideCompany: '',
    downsidePersonal: '',
    searchPulled: '',

    objective: '',
    problemToBeSolved: '',
    desiredResults: '',

    // Block 4 - Org Chart
    orgTop: '',
    orgLevel2: [],
    orgLevel3: [
      {position: '', team: []},
      {position: '', team: []},
      {position: '', team: []},
      {position: '', team: []},
      {position: '', team: []},
      {position: '', team: []}
    ],

    // Block 5 - Duties
    title: '',
    duties: [{microDuties: []}, {microDuties: []}, {microDuties: []}, {microDuties: []}],

    // Block 7, Technical Qualifications
    education: '',
    experience: '',
    idealExperience: '',
    communicationSkills: '',
    technology: '',
    // Compatibility radio button pairs, if
    energetic: '',
    profile: '',
    taskOriented: '',

    // Block 8
    technicalQuestions: [],

    // Block 9 Compensation Benefits
    salaryRange: '',
    doNotSubmitAt: '',
    hourlyRate: '',
    overtime: '',

    salaryReviewDate: '',
    performanceReviewDate: '',
    bonus: '',
    profitSharing: '',

    mm: false,
    dental: false,
    vision: false,
    rxcard: false,
    life: false,
    eligibilityDate: '',

    vacation: false,
    stDisability: false,
    ltDisability: false,
    tuition: false,
    training: false,
    parkingPaid: false,

    copay: false,
    paidTimeOff: false,
    additionalBenefits: false,
    relocationPackage: '',

    // Block 10 Hiring process
    interviewName1: '',
    interviewTitle1: '',
    personalityFocus1: '',
    testing: '',
    lengthOfMeeting: '',
    interviewName2: '',
    interviewTitle2: '',
    personalityFocus2: '',
    timeBetween: '',
    multiPersonInterviews: '',
    timeBetweenInterviews: '',
    whoMakesOffer: '',
    offerOnSpot: '',
    approvesDecision: '',
    preferredStartDate: '',

    // Urgency...
    whyPositionOpen: '',
    incumbentsName: '',
    incumbentSkills: '',
    commonThemes: '',
    competingAgainst: '',
    postings: '',
    internalCandidates: '',
    exclusiveBenefits: '',
    approval: '',

    // Block 11 Fee
    goodToGo: '',

    // Block 12
    corporateInfo: '',

    // Block 13 Employer Biography
    biography: '',

    // Block 14, meetings
    meetingNotes: ''
  };

  users: User[] = [];
  shareList: string[] = [];
  shareListFull: any[] = [];

  isSimulated = false;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private actions$: Actions,
    private alertService: AlertService,
    private modalService: NgbModal,
    private usersService: UsersService,
    private serverService: ServerService,
    private clientsService: ClientsService,
    private documentsService: DocumentsService,
    private sessionStateService: SessionStateService,
    private eventBusService: EventBusService,
    private contentService: ContentService,
    private elementRef: ElementRef,
    private renderer2: Renderer2,
    // @ts-ignore
    @Inject(DOCUMENT) private document) { }

  ngOnInit(): void {
    const session = this.sessionStateService.get();
    this.isSimulated = !!session.session.simulatedSession;

    this.fields = {};
    _.merge(this.fields, this.defaultFields);

    this.usersService.loginStatus().subscribe((sessionData) => {
      this.defaultFields.account_uuid = sessionData.status.account_uuid;
      this.defaultFields.user_uuid = sessionData.status.user_uuid;
      this.defaultFields.recruiter = sessionData.status.firstName + ' ' + sessionData.status.lastName;

      this.usersService.listByAccount(sessionData.status.account_uuid).subscribe((users) => {
        this.users = users;
      });

      this.refreshClients();
    });

    this.actions$.pipe(ofActionSuccessful(ClientsActions.NeedsReload)).subscribe(() => {
      this.refreshClients();
    });
  }

  ngAfterViewInit(): void {
    const s = this.document.createElement('script');
    s.type = 'text/javascript';
    s.src = '/assets/scripts/searchassignment-lines.js';

    this.elementRef.nativeElement.appendChild(s);
  }

  changedClient(): void {
    if (this.client) {
      this.fields = {};
      _.merge(this.fields, this.defaultFields);
      this.fields.client_uuid = this.client.uuid;

      this.clientsService.listSearchAssignmentsByClient(this.client.uuid || '').subscribe((assignments) => {
        this.positions = assignments;

        if (assignments.length) {
          this.position = assignments[0];

          this.changedPosition();
        }
      });
    }
  }

  changedPosition(): Observable<any> {
    if (!this.position || !this.position.position || !this.client) {
      return of(null);
    }

    const ob = this.clientsService.lookupSearchAssignmentPosition(this.client?.uuid || '', this.position.position)
      .pipe(switchMap((assignment) => {
        if (assignment && Array.isArray(assignment)) {
          assignment = assignment[0];
        }

        this.fields = {};
        _.merge(this.fields, this.defaultFields);

        this.fields.position = this.position.position;

        if (this.client) {
          this.fields.client_uuid = this.client.uuid;
        }

        if (assignment && !assignment.error) {
          _.merge(this.fields, assignment);
        }

        const ob2 = this.clientsService.lookup(this.client?.uuid || '')
          .pipe(switchMap((fullclient) => {
            this.fields.client_uuid = fullclient.uuid;
            this.fields.company = fullclient.company;               // From Client (company field) (Client Name)

            if (!this.fields.contactFirstName || !this.fields.contactFirstName.length) {
              this.fields.contactFirstName = fullclient.firstName + ' ' + fullclient.lastName;        // From Client (contact)
            }

            this.fields.contactLastName = fullclient.lastName;         // From Client (contact)
            this.fields.contactTitle = fullclient.title;            // From Client (contact)

            return of(1);
          }));

        ob2.subscribe(() => {});

        return ob2;
    }));

    ob.subscribe(() => {});

    return ob;
  }

  toggleSelection(user: User): void {
    const idx = this.shareList.indexOf(user.email);

    if (idx > -1) {
      this.shareList.splice(idx, 1);
      this.shareListFull.splice(idx, 1);
    }
    else {
      this.shareList.push(user.email);
      this.shareListFull.push({email: user.email, firstName: user.firstName, lastName: user.lastName});
    }
  }

  trackByFullName(index: number, client: Client): string {
    return client.firstName + ' ' + client.lastName;
  }

  save(fieldName: string): void {
    if (this.isSimulated) {
      return;
    }

    this.savePartially(fieldName);
  }

  private savePartially(fieldName: string): void {
    if (this.isSimulated) {
      return;
    }

    this.alertService.clear();

    const session = this.sessionStateService.get();
    const user = (session.session.simulatedSession || session.user) as User & Session;

    if (user.user_uuid) {
      user.uuid = user.user_uuid;
    }

    this.fields.createdBy = user.username;

    if (!this.fields.uuid) {
      this.clientsService.upsertSearchAssignment(this.fields).subscribe((result: any) => {
        if (result.searchAssignment.uuid) {
          this.fields.uuid = result.searchAssignment.uuid;
        }
      });
    } else {
      const fieldList = ['client_uuid', 'position', fieldName];

      if (fieldName !== 'position') {
        fieldList.push('uuid');
      } else {
        fieldList.push('account_uuid');
      }

      const fields = _.pick(this.fields, fieldList);

      this.clientsService.upsertSearchAssignment(fields).subscribe(() => { });
    }
  }

  download(): void {
    const session = this.sessionStateService.get();
    const user = (session.session.simulatedSession || session.user) as User & Session;

    if (user.user_uuid) {
      user.uuid = user.user_uuid;
    }

    this.fields.createdBy = user.username;

    if (this.isSimulated) {
      const link = document.createElement('a');
      link.setAttribute('href', this.serverService.api('/documents/merge/SearchAssignment/' + this.fields.uuid));
      document.body.appendChild(link); // Required for FF
      link.click();

      this.alertService.success('Downloaded and saved.', this.searchAssignmentDownload.nativeElement);
    } else {
      this.clientsService.upsertSearchAssignment(this.fields).subscribe((result: any) => {
        if (result.searchAssignment.uuid) {
          this.fields.uuid = result.searchAssignment.uuid;

          const link = document.createElement('a');
          link.setAttribute('href', this.serverService.api('/documents/merge/SearchAssignment/' + this.fields.uuid));
          document.body.appendChild(link); // Required for FF
          link.click();

          this.alertService.success('Downloaded and saved.', this.searchAssignmentDownload.nativeElement);
        }
      });
    }
  }

  accept(): void {
    if (this.isSimulated) {
      return;
    }

    const session = this.sessionStateService.get();
    const user = (session.session.simulatedSession || session.user) as User & Session;

    if (user.user_uuid) {
      user.uuid = user.user_uuid;
    }

    this.fields.createdBy = user.username;

    this.clientsService.upsertSearchAssignment(this.fields).subscribe((result: any) => {
      if (result.searchAssignment.uuid) {
        this.fields.uuid = result.searchAssignment.uuid;
      }

      this.alertService.success('Got it!', this.searchAssignmentSave.nativeElement);
    });
  }

  share(): void {
    const session = this.sessionStateService.get();
    const user = (session.session.simulatedSession || session.user) as User & Session;

    if (user.user_uuid) {
      user.uuid = user.user_uuid;
    }

    this.fields.createdBy = user.username;

    if (this.isSimulated) {
      const modalRef = this.modalService.open(CommonDialogComponent);

      modalRef.componentInstance.closeButtonText = 'Cancel';
      modalRef.componentInstance.actionButtonText = 'Send';
      modalRef.componentInstance.headerText = 'Share the Search Assignment Form';
      modalRef.componentInstance.bodyText = 'Do you want to email a copy of the Search Assignment form to the following people? ';
      modalRef.componentInstance.list = this.shareList;

      modalRef.result.then(() => {
        this.documentsService.share({
          name: 'SearchAssignment',
          uuid: this.fields.uuid,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          recipients: this.shareListFull
        }).subscribe(() => {
          this.alertService.success('Search Assignment was shared with selected recipients');
        });
      }).catch(() => { });
    } else {
      this.clientsService.upsertSearchAssignment(this.fields).subscribe((result: any) => {
        if (result.searchAssignment.uuid) {
          this.fields.uuid = result.searchAssignment.uuid;

          const modalRef = this.modalService.open(CommonDialogComponent);

          modalRef.componentInstance.closeButtonText = 'Cancel';
          modalRef.componentInstance.actionButtonText = 'Send';
          modalRef.componentInstance.headerText = 'Share the Search Assignment Form';
          modalRef.componentInstance.bodyText = 'Do you want to email a copy of the Search Assignment form to the following people? ';
          modalRef.componentInstance.list = this.shareList;

          modalRef.result.then(() => {
            this.documentsService.share({
              name: 'SearchAssignment',
              uuid: result.searchAssignment.uuid,
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              recipients: this.shareListFull
            }).subscribe(() => {
              this.alertService.success('Search Assignment was shared with selected recipients');
            });
          }).catch(() => { });
        }

        this.alertService.success('Search Assignment saved');
      });
    }
  }

  newPosition(): void {
    if (this.isSimulated) {
      return;
    }

    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static'
    };

    const modalRef = this.modalService.open(PositionComponent, ngbModalOptions);

    modalRef.result.then((result) => {
      if (result) {
        this.position = { position: result };
        this.positions.push(this.position);

        this.fields.position = this.position.position;

        this.changedPosition().pipe(first()).subscribe(() => {
          this.savePartially('position');
        });
      }
    }).catch(() => { });
  }

  deletePosition(): void {
    if (this.isSimulated) {
      return;
    }

    const modalRef = this.modalService.open(CommonDialogComponent);

    modalRef.componentInstance.closeButtonText = 'Cancel';
    modalRef.componentInstance.actionButtonText = 'Delete';
    modalRef.componentInstance.headerText = 'Delete Search Assignment';
    modalRef.componentInstance.bodyText =
      'Are you sure you want to delete this search assignment (' + this.client?.company + ' / ' + this.position.position + ')?';

    modalRef.result.then(() => {
      this.clientsService.removeSearchAssignment(this.fields.uuid).subscribe(() => {
        this.client = this.clients[0] || null;
      });
    }).catch(() => { });
  }

  refreshClients(): void {
    const session = this.sessionStateService.get();
    const user = (session.session.simulatedSession || session.user) as User & Session;

    if (user.user_uuid) {
      user.uuid = user.user_uuid;
    }

    this.clientsService.list(user.uuid || '').subscribe((clients) => {
      if (!clients || !clients.length) {
        clients = [];
      }

      this.clients = clients.sort((a, b) => b.company?.toLowerCase() > a.company?.toLowerCase() ? -1 : 1);

      if (this.latestClient && this.latestClient.uuid) {
        this.clientsService.lookup(this.latestClient.uuid).subscribe(fullClient => {
          this.client = fullClient;
          this.changedClient();
        });

        this.latestClient = null;
      } else {
        const currentClient = this.eventBusService.getClient();

        if (currentClient) {
          this.clientsService.lookup(currentClient.uuid).subscribe(fullClient => {
            this.client = fullClient;
            this.changedClient();
          });
        } else {
          const clientUuid = this.route.snapshot.queryParamMap.get('clientUuid') ||
            (clients.length === 1 ? clients[0].uuid : null);

          if (clientUuid) {
            this.clientsService.lookup(clientUuid).subscribe(fullClient => {
              this.client = fullClient;
              this.changedClient();
            });
          }
        }
      }
    });
  }

  newClient(): void {
    if (this.isSimulated) {
      return;
    }

    const uuid = this.contentService.uuid();
    this.store.dispatch(new ClientsActions.Show({ uuid }));

    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      size: 'lg'
    };

    const modalRef = this.modalService.open(ClientDetailComponent, ngbModalOptions);
    modalRef.componentInstance.uuid = uuid;

    modalRef.result.then((result) => {
      this.current$.pipe(first()).subscribe((current: Client | null) => {
        this.latestClient = current;
      });

      this.store.dispatch(new ClientsActions.Update({ uuid }));

      if (result.another) {
        this.newClient();
      }
    }).catch(() => { });
  }

  showExample(): void {
    const url = this.serverService.path('public/content/documents/samples/SearchIntakeExample.html');

    window.open(url, '_blank', 'popup=yes,width=850,height=700');
  }

  compareClients(a: Client, b: Client): boolean {
    return !!a && !!b && a.uuid === b.uuid;
  }
}
