<app-header></app-header>
<app-toolbar></app-toolbar>

<nav class="navbar navbar-default navbar-fixed-top navbar-global navbar-expand-md w-100 bg-white" role="navigation">
  <div class="container">
    <a class="navbar-brand text-dark" href="javascript:void"><i class="fas fa-users"></i>&nbsp;&nbsp;<b>Activity Report</b></a>
  </div>
</nav>


<div class="container-fluid main-container">
  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
