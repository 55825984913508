<form [formGroup]="form"  class="w-100" *ngIf="form">
  <div class="form-group row">
    <label class="col-3 control-label text-right">Address 1:</label>
    <div class="col-6">
      <input type="text" class="form-control" formControlName="location" placeholder="Main address" />
    </div>
    <div class="col-3 text-right">
      <button type="button" class="btn btn-secondary" [disabled]="isSimulated" (click)="addToPlanClick()">Add to Plan</button>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-3 control-label text-right">Address 2:</label>
    <div class="col-9">
      <input type="text" class="form-control" formControlName="address2" placeholder="Secondary address" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-3 control-label text-right">City:</label>
    <div class="col-9">
      <input type="text" class="form-control" formControlName="city" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-3 control-label text-right">State:</label>
    <div class="col-9">
      <app-state-selector  formControlName="state"></app-state-selector>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-3 control-label text-right">Zip:</label>
    <div class="col-9">
      <input type="text" class="form-control"  formControlName="zip" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-3 control-label text-right">Country:</label>
    <div class="col-9">
      <input type="text" class="form-control" placeholder="Search country" formControlName="country" [ngbTypeahead]="searchCountry$" />
    </div>
  </div>
</form>
