import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timestampToDuration'
})
export class TimestampToDurationPipe implements PipeTransform {
  transform(timestamp: number): string {
    // Assuming timestamp is in milliseconds
    const hours = Math.floor(timestamp / 3600000);
    const minutes = Math.floor((timestamp % 3600000) / 60000);
    const seconds = Math.floor((timestamp % 60000) / 1000);

    let durationString = '';
    if (hours > 0) {
      durationString += `${hours} hour${hours > 1 ? 's' : ''}`;
      if (minutes > 0) {
        durationString += `, `;
      }
    }
    if (minutes > 0) {
      durationString += `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }

    return durationString;
  }

  private pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
}
