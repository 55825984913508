import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';

import { forkJoin } from 'rxjs';

import { SessionActions } from './sessions.actions';
import { Session, SessionStateModel } from './sessions.model';
import { SessionsService } from './sessions.service';

const defaultState: SessionStateModel = {
  sessions: [],
  selected: []
};

@State<SessionStateModel>({
  name: 'sessions',
  defaults: { ...defaultState }
})

@Injectable()
export class SessionState {

  @Selector()
  static sessions(state: SessionStateModel): Session[] {
    return state.sessions;
  }

  @Selector()
  static selected(state: SessionStateModel): string[] {
    return state.selected;
  }

  constructor(
    private sessionsService: SessionsService
  ) { }

  @Action(SessionActions.Load)
  load(context: StateContext<SessionStateModel>): void {
    this.sessionsService.list().subscribe(sessions => {
      context.patchState({
        sessions
      });
    });
  }

  @Action(SessionActions.Delete)
  delete(context: StateContext<SessionStateModel>): void {
    const state = context.getState();
    const sessions = state.sessions.filter(session => state.selected.includes(session.key));
    const deleteParams: {session: string, loggedInAt: string}[] =
      sessions.map(session => ({ session: session.key, loggedInAt: session.loggedInAt }));

    const deleteResults = [];
    for (let i = 0, iLimit = deleteParams.length; i < iLimit; i++) {
      deleteResults.push(this.sessionsService.delete(deleteParams[i].session, deleteParams[i].loggedInAt));
    }

    forkJoin(deleteResults).subscribe(() => {
        this.load(context);
    });
  }

  @Action(SessionActions.Select)
  select(context: StateContext<SessionStateModel>, { payload: { sessionKey, select } }: SessionActions.Select): void {
    const state = context.getState();
    const selected = state.selected.slice();
    const index = selected.indexOf(sessionKey);

    if (select) {
      if (index === -1) {
        context.patchState({
          selected: selected.concat([sessionKey])
        });
      }
    } else {
      if (index > -1) {
        selected.splice(index, 1);

        context.patchState({
          selected
        });
      }
    }
  }
}
