<nav class="navbar navbar-default navbar-subheader bolder-border-bottom" role="navigation">
    <div class="container-fluid">
        <div class="btn-toolbar" role="toolbar">
            <button type="button" class="btn btn-secondary btn-icon" (click)="go('/dashboard')" placement="bottom" [openDelay]="delay" container="body" ngbTooltip="Dashboard"><img class="toolbar-img" src="/assets/images/dashboard/HomeIcon.png" /></button>
            <button type="button" class="btn btn-secondary btn-icon" (click)="go('/dailyplanner')" placement="bottom" [openDelay]="delay" container="body" ngbTooltip="My Daily Plan"><img class="toolbar-img" src="/assets/images/dashboard/daily-plan-icon.png" /></button>
            <button type="button" class="btn btn-secondary btn-wide-icon" [hidden]="isSimulated" (click)="go('/esourcing')" placement="bottom" [openDelay]="delay" container="body" ngbTooltip="E-Sourcing"><img class="toolbar-img" src="/assets/images/dashboard/e-sourcing-icon.png" /></button>
            <button type="button" class="btn btn-secondary btn-wide-icon" (click)="go('/candidates')" placement="bottom" [openDelay]="delay" container="body" ngbTooltip="Candidates"><img class="toolbar-img" src="/assets/images/dashboard/candidate-icon.png" /></button>
            <button type="button" class="btn btn-secondary btn-wide-icon" (click)="go('/clients')" placement="bottom" [openDelay]="delay" container="body" ngbTooltip="Clients"><img class="toolbar-img" src="/assets/images/dashboard/client-icon.png" /></button>
            <a type="button" class="btn btn-secondary btn-wide-icon" href="https://morganmentorstore.uscreen.io/?_ga=2.75337042.118875207.1621978737-1781759086.1621978737" placement="bottom" [openDelay]="delay" container="body" ngbTooltip="PPV Tune Ups" target="_blank"><img class="toolbar-img" src="/assets/images/dashboard/media-icon.png" /></a>
            <button type="button" class="btn btn-secondary btn-wide-icon" (click)="go('/innerview')" placement="bottom" [openDelay]="delay" container="body" ngbTooltip="InnerView"><img class="toolbar-img" src="/assets/images/dashboard/innerview-toolbar.png" /></button>
            <button type="button" class="btn btn-secondary btn-wide-icon btn-searchassignment" (click)="go('/searchassignment')" placement="bottom" [openDelay]="delay" container="body" ngbTooltip="Search Assignment"><img class="toolbar-img" src="/assets/images/dashboard/search-icon.png" /></button>

            <div id="user-controls">
                <span>{{user?.firstName}}</span>&nbsp;<span>{{user?.lastName}}</span><span *ngIf="isSimulated">&nbsp;(simulated)&nbsp;</span>&nbsp;&nbsp;(<a href="javascript:void(0)" (click)="logout()">logout</a>)
                <span class='text-danger' [hidden]="!requiresUpdate"><strong>   -- A Software Update is available (<span>{{softwareVersion}}</span>). <a href="" (click)="installUpdate()">Click to Install ...</a></strong></span>
            </div>
        </div>

        <ng-content></ng-content>
    </div>
</nav>