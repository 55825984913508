import { Component, OnInit } from '@angular/core';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';

import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

import * as _ from 'lodash';

import { CandidatesState } from '../../admin/candidates/candidates.state';
import { Candidate } from 'src/app/admin/candidates/candidate.model';
import { User } from 'src/app/shared/models/user.model';
import { Session } from 'src/app/shared/session-state.service';

import { UsersService } from '../../shared/users.service';
import { CandidatesService } from 'src/app/admin/candidates/candidates.service';
import { AlertService } from '../../shared/alert.service';
import { ServerService } from '../../shared/server.service';
import { SessionStateService } from '../../shared/session-state.service';
import { EventBusService } from '../../shared/event-bus.service';
import { ContentService } from '../../shared/content.service';
import { DocumentsService } from '../documents.service';

import { CandidatesActions } from 'src/app/admin/candidates/candidates.actions';
import { CandidateDetailComponent } from 'src/app/admin/candidates/candidate-detail/candidate-detail.component';

import { CommonDialogComponent } from '../../shared/common-dialog/common-dialog.component';

@Component({
  selector: 'app-referencecheck',
  templateUrl: './referencecheck.component.html',
  styleUrls: ['./referencecheck.component.scss']
})
export class ReferencecheckComponent implements OnInit {

  // @ts-ignore
  @Select(CandidatesState.current) current$: Observable<Candidate | null>;

  candidates: Candidate[] = [];
  candidate: Candidate | null | undefined = null;
  latestCandidate: Candidate | null | undefined = null;

  fields: any = {};
  fieldsPrev: any = {};

  defaultFields = {
    candidate_uuid: '',
    account_uuid: '',
    user_uuid: '',
    uuid: '',

    // Top Section
    firstName: '',      // From Candidate
    lastName: '',       // From Candidate
    positionConsidered: '',
    reference: '',
    referenceTitle: '',
    employer: '',           // From Candidate
    candidateTitle: '',     // From Candidate
    workPhone: '',          // From Candidate
    bestAvailability: '',
    consultant: '',	        // From User
    homePhone: '',          // From Candidate

    //// 27 Questions
    // 1.	In one word, how would you describe [candidate]?
    description: '',
    // 2.	Describe your business relationship.  (Boss, subordinate, co-worker, supplier, etc.) (Confirm title)
    businessRelationship: '',
    // 3.	How would you rate (the candidate’s) performance as compared to others you’ve worked with in the same role?
    performance: '',
    // 4.	What would you add or subtract to (candidates) technical expertise
    // to perfect their performance? (turn into strengths and limitations)
    addSubtract: '',
    // 5.	How many positions above the one (candidate) was doing for you, (candidate) would (candidate) be capable of rising to.
    positions: '',
    // 6.	If you were building a (Marketing/Engineering/IT/...) team that
    // was broken into tight specialty, where would you utilize (candidate)?
    teamUtilize: '',
    // 7.	What caused (candidate) to stand out among other (titles)?
    standout: '',
    // 8.	Describe a personality type that best represents (candidate).
    personalityType: '',
    // 9.	Describe (candidates) imagery.  (Big 4ish, boardroom acceptable, athletic,
    // geekish, big city, rural, wine or beer?) Does (candidate) look like anyone we know?
    imagery: '',
    // 10.	Hand picks a culture for (candidate).
    culture: '',
    // 11.	Tolerance for business stress, deadline stress, human conflict?
    tolerance: '',
    // 12. Resilience
    resilience: '',
    // 13.	What outside influences affected his work?
    outsideInfluences: '',
    // 14.	Integrity... talk to me
    integrity: '',
    // 15.	What type of contributions did (the candidate) make that had a definite impact on the company’s bottom line?
    contributions: '',
    // 16.	Would you say that (candidate) was more comfortable working with tasks, or with peo
    comfortable: '',
    // 17.	How many people reported to (candidate)?
    reportTo: '',
    // 18.	How would you describe his/her management style? (Hands-on, laid-back, delegator, etc.)
    managementStyle: '',
    // 19.	Can he/she communicate technical ideas to non-technical associates?
    communicateIdeas: '',
    // 20.	Would fellow co-workers consider (candidate) to be a leader or follower?
    leaderFollower: '',
    // 21.	Would you consider him/her to be a “lone eagle” or a team player?
    teamPlayer: '',
    // 22.	(If not yet met) Describe the first impression he/she makes____
    firstImpression: '',
    // 23.	How did (candidate) come to leave your firm?
    whyLeaveFirm: '',
    // 24.	If you put (candidate) in the perfect job, given his/her capabilities, what job would that be?
    perfectJob: '',
    // 25.	Describe, if you would, the workplace culture where (candidate) would thrive.
    workplaceCulture: '',
    // 26.	Is there anything I should know that we’ve failed to cover?
    anythingElse: ''
  };

  users: User[] = [];
  shareList: string[] = [];
  shareListFull: any[] = [];

  isSimulated = false;

  constructor(
    private store: Store,
    private actions$: Actions,
    private usersService: UsersService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private candidatesService: CandidatesService,
    private serverService: ServerService,
    private sessionStateService: SessionStateService,
    private documentsService: DocumentsService,
    private eventBusService: EventBusService,
    private contentService: ContentService) { }

  ngOnInit(): void {
    const session = this.sessionStateService.get();
    this.isSimulated = !!session.session.simulatedSession;

    this.fields = {};
    _.merge(this.fields, this.defaultFields);

    this.usersService.loginStatus().subscribe((sessionData) => {
      this.defaultFields.account_uuid = sessionData.status.account_uuid;
      this.defaultFields.user_uuid = sessionData.status.user_uuid;
      this.defaultFields.consultant = sessionData.status.firstName + ' ' + sessionData.status.lastName;

      this.usersService.listByAccount(sessionData.status.account_uuid).subscribe((users) => {
        this.users = users;
      });

      this.refreshCandidates();

      this.actions$.pipe(ofActionSuccessful(CandidatesActions.NeedsReload)).subscribe(() => {
        this.refreshCandidates();
      });
    });
  }

  toggleSelection(user: User): void {
    const idx = this.shareList.indexOf(user.email);

    if (idx > -1) {
      this.shareList.splice(idx, 1);
      this.shareListFull.splice(idx, 1);
    }
    else {
      this.shareList.push(user.email);
      this.shareListFull.push({email: user.email, firstName: user.firstName, lastName: user.lastName});
    }
  }

  trackByFullName(index: number, candidate: Candidate): string {
    return candidate.firstName + ' ' + candidate.lastName;
  }

  save(fieldName?: string): void {
    if (this.isSimulated) {
      return;
    }

    this.savePartially(fieldName);
  }

  private savePartially(fieldName?: string): void {
    if (this.isSimulated) {
      return;
    }

    this.alertService.clear();

    if (!this.fields.uuid) {
      this.candidatesService.upsertReference(this.fields).subscribe((result: any) => {
        if (result.candidateReference.uuid) {
          this.fields.uuid = result.candidateReference.uuid;
        }
      });
    } else {
      if (fieldName) {
        const fields = _.pick(this.fields, ['uuid', 'client_uuid', 'position', fieldName]);

        this.candidatesService.upsertReference(fields).subscribe(() => { });
      }
    }
  }

  download(): void {
    if (this.isSimulated) {
      const link = document.createElement('a');
      link.setAttribute('href', this.serverService.api('/documents/merge/ReferenceCheck/' + this.fields.uuid));
      document.body.appendChild(link); // Required for FF
      link.click();
    } else {
      this.candidatesService.upsertReference(this.fields).subscribe((result: any) => {
        if (result.candidateReference.uuid) {
          this.fields.uuid = result.candidateReference.uuid;

          const link = document.createElement('a');
          link.setAttribute('href', this.serverService.api('/documents/merge/ReferenceCheck/' + this.fields.uuid));
          document.body.appendChild(link); // Required for FF
          link.click();
        }

        this.alertService.success('Candidate Reference saved');
      });
    }
  }

  share(): void {
    if (this.isSimulated) {
      const modalRef = this.modalService.open(CommonDialogComponent);

      modalRef.componentInstance.closeButtonText = 'Cancel';
      modalRef.componentInstance.actionButtonText = 'Send';
      modalRef.componentInstance.headerText = 'Share the Reference Check Form';
      modalRef.componentInstance.bodyText = 'Do you want to email a copy of the Reference Check form to the following people? ';
      modalRef.componentInstance.list = this.shareList;

      modalRef.result.then(() => {
        const session = this.sessionStateService.get();
        const user = (session.session.simulatedSession || session.user) as User & Session;

        if (user.user_uuid) {
          user.uuid = user.user_uuid;
        }

        this.documentsService.share({
          name: 'ReferenceCheck',
          uuid: this.fields.uuid,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          recipients: this.shareListFull
        }).subscribe(() => {
          this.alertService.success('Reference Check was shared with selected recipients');
        });
      }).catch(() => { });
    } else {
      this.candidatesService.upsertReference(this.fields).subscribe((result: any) => {
        if (result.candidateReference.uuid) {
          this.fields.uuid = result.candidateReference.uuid;

          const modalRef = this.modalService.open(CommonDialogComponent);

          modalRef.componentInstance.closeButtonText = 'Cancel';
          modalRef.componentInstance.actionButtonText = 'Send';
          modalRef.componentInstance.headerText = 'Share the Reference Check Form';
          modalRef.componentInstance.bodyText = 'Do you want to email a copy of the Reference Check form to the following people? ';
          modalRef.componentInstance.list = this.shareList;

          modalRef.result.then(() => {
            const session = this.sessionStateService.get();
            const user = (session.session.simulatedSession || session.user) as User & Session;

            if (user.user_uuid) {
              user.uuid = user.user_uuid;
            }

            this.documentsService.share({
              name: 'ReferenceCheck',
              uuid: result.candidateReference.uuid,
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              recipients: this.shareListFull
            }).subscribe(() => {
              this.alertService.success('Reference Check was shared with selected recipients');
            });
          }).catch(() => { });
        }

        this.alertService.success('Reference Check saved');
      });
    }
  }

  accept(): void {
    if (this.isSimulated) {
      return;
    }

    this.candidatesService.upsertReference(this.fields).subscribe((result: any) => {
      if (result.candidateReference.uuid) {
        this.fields.uuid = result.candidateReference.uuid;
      }

      this.alertService.success('Candidate Reference saved');
    });
  }

  changedCandidate(): void {
    if (this.candidate) {
      this.fields = {};
      _.merge(this.fields, this.defaultFields);

      const candidateUuid = this.candidate?.uuid || '';

      forkJoin(
        this.candidatesService.lookupReference(candidateUuid),
        this.candidatesService.lookup(candidateUuid))
      .subscribe(([reference, fullcandidate]) => {
        // Candidate:
        this.fields.candidate_uuid = fullcandidate.uuid;
        this.fields.firstName = fullcandidate.firstName;
        this.fields.lastName = fullcandidate.lastName;
        this.fields.employer = fullcandidate.employer;
        this.fields.candidateTitle = fullcandidate.title;

        if (fullcandidate.phoneNumbers) {
          for (let i = 0, iLimit = fullcandidate.phoneNumbers.length; i < iLimit; i++) {
            const phone = fullcandidate.phoneNumbers[i];

            switch (phone.type) {
              case 'work':
                this.fields.workPhone = phone.number;
                break;

              case 'home':
                this.fields.homePhone = phone.number;
                break;
            }
          }
        }

        // Reference:
        if (reference && !reference.error) {
          _.merge(this.fields, reference);
        }

        this.save();
      });
    }
  }

  refreshCandidates(): void {
    const session = this.sessionStateService.get();
    const user = (session.session.simulatedSession || session.user) as User & Session;

    if (user.user_uuid) {
      user.uuid = user.user_uuid;
    }

    this.candidatesService.list(user.uuid || '').subscribe((candidates) => {
      if (!candidates || !candidates.length) {
        candidates = [];
      }

      this.candidates = candidates.sort((a, b) => (b.firstName?.toLowerCase() + ' ' + b.lastName?.toLowerCase()) >
        (a.firstName?.toLowerCase() + ' ' + a.lastName?.toLowerCase()) ? -1 : 1);

      if (this.latestCandidate && this.latestCandidate.uuid) {
        this.candidatesService.lookup(this.latestCandidate.uuid).subscribe((fullcandidate) => {
          this.candidate = fullcandidate;
          this.changedCandidate();
        });

        this.latestCandidate = null;
      } else {
        const currentCandidate = this.eventBusService.getCandidate();

        if (currentCandidate) {
          this.candidatesService.lookup(currentCandidate.uuid).subscribe((fullcandidate) => {
            this.candidate = fullcandidate;
            this.changedCandidate();
          });
        } else {
          const candidateUuid = (candidates.length === 1 ? candidates[0].uuid : null);

          if (candidateUuid) {
            this.candidatesService.lookup(candidateUuid).subscribe((fullcandidate) => {
              this.candidate = fullcandidate;
              this.changedCandidate();
            });
          }
        }
      }
    });
  }

  newCandidate(): void {
    if (this.isSimulated) {
      return;
    }

    const uuid = this.contentService.uuid();
    this.store.dispatch(new CandidatesActions.Show({ uuid }));

    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      size: 'lg'
    };

    const modalRef = this.modalService.open(CandidateDetailComponent, ngbModalOptions);
    modalRef.componentInstance.uuid = uuid;

    modalRef.result.then((result) => {
      this.current$.pipe(first()).subscribe((current: Candidate) => {
        this.latestCandidate = current;
      });

      if (result.uploadedItem) {
        this.store.dispatch(new CandidatesActions.UploadResume({ uuid, file: result.uploadedItem }));
      } else {
        this.store.dispatch(new CandidatesActions.Update({ uuid }));
      }

      this.store.dispatch(new CandidatesActions.Finished({ uuid }));

      if (result.another) {
        this.newCandidate();
      }
    }).catch(() => { });
  }

  showExample(): void {
    const url = this.serverService.path('public/content/documents/samples/ReferenceCheckExample.html');

    window.open(url, '_blank', 'popup=yes,width=850,height=700');
  }

  compareCandidates(a: Candidate, b: Candidate): boolean {
    return !!a && !!b && a.uuid === b.uuid;
  }
}
