<div class="container">
  <h3>Privacy/User Agreement Policy</h3>
  <p>Note: It is with great disappointment that in today’s business climate, we are advised to publish the following.</p>
  <h4>User Agreement</h4>
  <p>Welcome, and thanks for using Morgan Mentors’ Morgan RecruitersPlaybook&trade; web site and product! When you use our products and services, you’re agreeing to our terms, so please take a few minutes to read over the User Agreement below.</p>
  <p>Note: You are entering into a legally binding agreement. The abbreviation, “MM” is synomous with either Morgan Mentor LLC or Morgan Recruiter Playbook a product of Morgan Mentor LLC.</p>
  <h4>A. License and warranty for your submissions to MM</h4>
  <p>You own the information you provide MM under this Agreement, and may request its deletion at any time, unless you have shared information or content with others and they have not deleted it, or it was copied or stored by other users. Additionally, you grant us a nonexclusive, irrevocable, worldwide, perpetual, unlimited, assignable, sublicenseable, fully paid up and royalty-free right to us to copy, prepare derivative works of, improve, distribute, publish, remove, retain, add, process, analyze, use and commercialize, in any way now known or in the future discovered, any information you provide, directly or indirectly to MM, including, but not limited to, any user generated content, ideas, concepts, techniques and/or data to the services, you submit to us, without any further consent, notice and/or compensation to you or to any third parties. Any information you submit to us is at your own risk of loss. By providing information to us, you represent and warrant that you are entitled to submit the information and that the information is accurate, not confidential, and not in violation of any contractual restrictions or other third party rights. It is your responsibility to keep your MM profile or information accurate and updated.
  You are eligible to enter into this contract and you are at least our “Minimum Age” of 18.</p>
  <p>The information you provide is truthful.</p>
  <h4>B. Service Eligibility</h4>
  <p>To be eligible to use the Service, you must meet the following criteria and represent and warrant that you: (1) are the “Minimum Age” (defined below) or older; (2) are not currently restricted from the MM Services, are not a direct competitor of MM or are not using the Services for reasons that are in competition with MM; (3) will use your real name and only provide accurate information to MM; (4) have full power and authority to enter into this Agreement and doing so will not violate any other agreement to which you are a party; (5) will not violate any rights of MM, including intellectual property rights such as copyright or trademark rights assigned to MM or Morgan Consulting Group Inc.</p>
  <p>You will keep your password a secret.</p>
  <p>You will not share an account with anyone else other than legal and pre-authorized and declared users of MM products.</p>
  <h4>C. Your Membership</h4>
  <p>The profile you create on MMs product web site(s) will become part of MM and is owned by MM. You will pay us for any losses that you cause.</p>
  <h4>D. Indemnification</h4>
  <p>You agree to indemnify us and hold us harmless for all damages, losses and costs (including, but not limited to, reasonable attorneys’ fees and costs) related to all third party claims, charges, and investigations, caused by (1) your failure to comply with this Agreement, including, without limitation, your submission of content that violates third party rights or applicable laws, (2) any content you submit to MM, and (3) any activity in which you engage on or through MM.</p>
  <p>You will honor your payment obligations and you are okay with us storing your payment information. Also there can be fees and taxes that are added to our prices.</p>
  <h4>E. Payment</h4>
  <p>If you purchase any services that we offer for a fee, either on a one-time or subscription basis , you agree to MM storing your payment information and personal and corporate identification you’ve provided.</p>
  <h4>F. Notify us of acts contrary to the Agreement</h4>
  <p>If you believe that you are entitled or obligated to act contrary to this Agreement under any mandatory law, you agree to provide us with detailed and substantiated explanation of your reasons in writing at least 30 days before you act contrary to this Agreement, to allow us to assess whether we may, at our sole discretion, provide an alternative remedy for the situation, though we are under no obligation to do so.</p>
  <p>You are okay with us providing you with important notices on the website, mobile apps, or email correspondence or offers.</p>
  <p>The contact information you provide must be accurate or you may not receive important notices.</p>
  <p>MM at its sole discretion, may close or transfer MM ‘s web site, LinkedIn Groups or Company Pages, or remove content from them if the content violates this Agreement or others’ intellectual property rights. Please note that ideas you post and information you share may be seen and used by other visitors of MM, if your account administrator has granted them permission, and MM cannot guarantee that other users/visitors will not use the ideas and information that you share on MM. Therefore, if you have an idea or information that you would like to keep confidential or don’t want others to use, or that is subject to third party rights that may be infringed by your sharing it, do not post it to any MM page or related LinkedIn Group or elsewhere on MM.</p>
  <p>MM IS NOT RESPONSIBLE FOR A ANOTHER’S MISUSE OR MISAPPROPRIATION OF ANY CONTENT OR INFORMATION YOU POST ON ITS’ product(s) WE ARE NOT LIABLE FOR ANY DAMAGES BROUGHT AGAINST YOU OR YOUR COMPANY BASED ON THE USAGE OF THIS MM PRODUCT.</p>
  <p>You are okay with us collecting, using, storing, and disclosing information about you in keeping with our Privacy Policy.</p>
  <h4>G. Privacy</h4>
  <p>Please note that certain information, statements, data, and content (such as photographs) which you may submit to MM, or groups you choose to join might, or are likely to, reveal your gender, ethnic origin, nationality, age, and/or other personal information about you. You acknowledge that your submission of any information, statements, data, and content to us is voluntary on your part and that MM may process such information, within the terms of this agreement.</p>
  <h4>H. Contributions to MM</h4>
  <p>By submitting ideas, suggestions, documents, scripts or proposals (“Contributions”) to MM through its suggestion or feedback webpages or blogs or student chat areas or customizable scripting, you acknowledge and agree that: (a) your Contributions might contain confidential or proprietary information relating to YOUR candidate or client and; (b) MM is not attached or responsible nor under any obligation of confidentiality, express or implied, with respect to the Contributions; (c) MM shall be entitled to use or disclose (or choose not to use or disclose) such Contributions for any purpose, in any way, in any media worldwide; (d) MCG may have something similar to the Contributions already under consideration or in development; (e) you irrevocably non-exclusively license to MM rights to exploit your Contributions; and (f) you are not entitled to any compensation or reimbursement of any kind from MM under any circumstances.</p>
  <h4>I. Your Rights:</h4>
  <p>If you follow this agreement, we grant you a limited right to use MM product(s) provided you have paid for it’s usage.
  You will only access and use Morgan Mentor’s Morgan Recruiter Playbook in the way this agreement allows you. It may not be copied and exposed to any non paid user of the product.</p>
  <p>On the condition that you comply with all your obligations under this Agreement, we grant you a limited, revocable, nonexclusive, nonassignable, nonsublicenseable license and right to access the Morgan Recruiters’Playbook through a generally available web browser, mobile device or Morgan Mentor authorized application (but not through scraping, spidering, crawling or other technology or software used to access data without the express written consent of Morgan Mentor LLC), view information and use the Services that we provide on our webpages and in accordance with this Agreement. Any other use of Morgan Recruiter Playbook contrary to our mission and purpose (such as to use information gathered from Morgan Recruiter Playbook commercially unless expressly authorized by Morgan Mentor LLC) is strictly prohibited and a violation of this Agreement. We reserve all rights not expressly granted in this Agreement, including, without limitation, title, ownership, intellectual property rights, and all other rights and interest in Morgan Mentor products and all related items, including any and all copies made of the Morgan Recruiter Playbook.</p>
  <h4>J. Our Rights & Obligations</h4>
  <p>We may change or discontinue Morgan Recruiter Playbook, and in such case, we do not promise to keep showing or storing your information and materials.</p>
  <h4>K. Intellectual Property Notices</h4>
  <p>The web site includes the copyrights and Intellectual property rights of Morgan Mentor LLC and we reserve all of its intellectual property rights in it. The Morgan Recruiter Playbook logos, “POP marketing”, "<i>Inner</i>View<sup>TM</sup>" and other Morgan Consulting Group trademarks, service marks, graphics, and logos used in connection with this web site are trademarks or registered trademarks of Morgan Consulting Group, Inc. This Agreement does not grant you any right or license with respect to any such trademarks and logos.</p>
  <h4>L. Termination</h4>
  <p>We can each end this agreement anytime we want.</p>
  <h4>M. Mutual rights of termination</h4>
  <p>You may terminate this Agreement, for any or no reason, at any time, with notice to Morgan Mentor LLC.</p>
  <h4>N. Entire Agreement</h4>
  <p>You agree that this Agreement constitutes the entire, complete and exclusive agreement between you and Morgan Mentor LLC regarding the Services and supersedes all prior agreements and understandings, whether written or oral, or whether established by custom, practice, policy or precedent, with respect to the subject matter of this Agreement.</p>
</div>
