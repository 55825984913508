<app-header></app-header>
<app-toolbar></app-toolbar>

<nav class="navbar navbar-default navbar-fixed-top navbar-global navbar-expand-md w-100 bg-white" role="navigation">
  <div class="container">
    <a class="navbar-brand text-dark" href="javascript:void"><i class="fas fa-users"></i>&nbsp;&nbsp;<b>Clients</b></a>

    <div class="ml-auto" *ngIf="selected$ | async as selected">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Search" #clientsrch (input)="search($event.target)" />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" (click)="search(null); clientsrch.value = '';"><i class="fas fa-times-circle"></i></button>
          <button class="btn btn-outline-secondary" type="submit" (click)="search(clientsrch)"><i class="fas fa-search"></i></button>
        </div>

        <button type="button" class="btn btn-secondary mr-3 ml-3" [disabled]="isSimulated" (click)="add()" placement="bottom" ngbTooltip="Create Client"><i class="fas fa-user-plus"></i></button>
        <button type="button" class="btn btn-danger-inversed mr-3" (click)="delete()" [disabled]="isSimulated || !selected.length" placement="bottom" ngbTooltip="Delete Client(s)"><i class="fas fa-trash"></i></button>
        <button type="button" class="btn btn-primary mr-3" [disabled]="isSimulated || !selected.length" (click)="exportClicked()" placement="bottom" [ngbTooltip]="selected?.length ? 'Export Candidates' : 'Select Candidates first'">Export</button>
        <button type="button" class="btn btn-primary" (click)="importClicked()" placement="bottom" [ngbTooltip]="'Import Clients'">Import</button>
      </div>
    </div>
  </div>
</nav>

<br />

<div class="container-fluid main-container" *ngIf="{ clients: clients$ | async, pagination: pagination$ | async } as data">
  <ngx-table
    #table
    [data]="data.clients || []"
    [configuration]="configuration"
    [columns]="columns"
    [pagination]="data.pagination || { limit: 100, offset: 0, count: (data.clients || []).length }"
    (event)="eventEmitted($event)"></ngx-table>
</div>

<app-footer></app-footer>

<ng-template #columnActionTemplate>
  <button class="text-nowrap" (click)="hideColumn($event)">Hide Column</button>
</ng-template>

<ng-template #checkboxTpl let-row let-column="column">
  <div class="form-group">
    <label class="form-checkbox">
      <input type="checkbox" (change)="onChange(row, $event.target)" />
    </label>
  </div>
</ng-template>

<ng-template #searchAssignmentTpl let-row>
  <span [hidden]="!row.searchassignments || !row.searchassignments.length" class="img-link" (click)="gotoSearchAssignment(row)"><img src="/assets/images/search-assignment.png" /></span>
</ng-template>

<ng-template #firstNameTpl let-row>
  <span>{{row.firstName}}</span>
</ng-template>

<ng-template #lastNameTpl let-row>
  <span>{{row.lastName}}</span>
</ng-template>

<ng-template #ownerTpl let-row>
  <span>{{ row.ownerUserName }}</span>
</ng-template>

<ng-container *ngIf="bulkCreate$ | async as bulkCreate">
  <app-loading-notification
    [text]="'Importing clients...'"
    [bulkCreate]="bulkCreate">
  </app-loading-notification>
</ng-container>
