import { Directive, OnInit, ElementRef } from '@angular/core';

declare var jQuery: any;
declare var $: any;

@Directive({
  selector: '[appFullHeight]'
})
export class FullHeightDirective implements OnInit {

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    const element = this.el.nativeElement;

    setTimeout(() => {
    // @ts-ignore
    element.style.height = (element.parentElement.parentElement.scrollHeight - element.offsetTop) + 'px';

    }, 100);
  }
}
