import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';

import { Session } from './sessions.model';
import { SessionState } from './sessions.state';
import { SessionActions } from './sessions.actions';

import { CommonDialogComponent } from '../../shared/common-dialog/common-dialog.component';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit {

  // @ts-ignore
  @Select(SessionState.sessions) sessions$: Observable<Session[]>;
  // @ts-ignore
  @Select(SessionState.selected) selected$: Observable<string[]>;

  // @ts-ignore
  @ViewChild('loggedInAt', { static: true }) loggedInAt: TemplateRef<any>;

  // @ts-ignore
  @ViewChild('checkboxTpl', { static: true }) checkboxTpl: TemplateRef<any>;

  // @ts-ignore
  @ViewChild('columnActionTemplate', { static: true }) columnActionTemplate: TemplateRef<any>;

  public configuration = {
    ...DefaultConfig,
    rows: 100
  };

  public columns = [];

  constructor(private store: Store, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.columns = [
      // @ts-ignore
      { key: '', width: '5%', cellTemplate: this.checkboxTpl },
      // @ts-ignore
      { key: 'key', title: 'User', width: '20%', headerActionTemplate: this.columnActionTemplate },
      // @ts-ignore
      { key: 'loggedInAt', title: 'Logged In', width: '75%', cellTemplate: this.loggedInAt,
      // @ts-ignore
        headerActionTemplate: this.columnActionTemplate }
    ];

    this.store.dispatch(new SessionActions.Load());
  }

  refresh(): void {
    this.store.dispatch(new SessionActions.Load());
  }

  delete(): void {
    const modalRef = this.modalService.open(CommonDialogComponent);

    modalRef.componentInstance.closeButtonText = 'Cancel';
    modalRef.componentInstance.actionButtonText = 'Delete';
    modalRef.componentInstance.headerText = 'Delete Session';
    modalRef.componentInstance.bodyText = 'Are you sure you want to delete the selected sessions?';

    modalRef.result.then((result) => {
      this.store.dispatch(new SessionActions.Delete());
    }).catch((error) => { });
  }

  onChange(row: any, checkbox: EventTarget | null): void {
    if (checkbox) {
      this.store.dispatch(new SessionActions.Select({sessionKey: row.key, select: (checkbox as HTMLInputElement).checked}));
    }
  }

  hideColumn(event: any): void {
    let target = event.target as HTMLElement;

    while (target.parentElement && target.tagName.toLowerCase() !== 'th') {
      target = target.parentElement;
    }

    let index = 0;

    while (target.previousSibling) {
      target = target.previousSibling as HTMLElement;

      if (target.tagName && target.tagName.toLowerCase() === 'th') {
        index++;
      }
    }

    this.columns.splice(index, 1);
  }
}
