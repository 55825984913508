import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';

import { ServerService } from '../shared/server.service';
import { AlertService } from '../shared/alert.service';

@Injectable({
  providedIn: 'root'
})
export class PlannerService {

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private serverService: ServerService) { }

  get(): Observable<any> {
    return this.http.get(this.serverService.api('/planners/'), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to retrieve a planner ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  insert(planner: any): Observable<any> {
    return this.http.post(this.serverService.api('/planners'), { planner }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to insert a planner ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  update(planner: any): Observable<any> {
    return this.http.put(this.serverService.api('/planners'), { planner }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to update a planner ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  remove(uuid: string): Observable<any> {
    return this.http.delete(this.serverService.api('/planners', uuid), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to delete a planner ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  /**
   *
   * @param options = { recipients: [array] of user email addresses
   *                    email: user's email
   *                    blob: PDF to be shared }
   */
   share(options: any): Observable<any> {
    const formData = new FormData();
    formData.append('recipients', JSON.stringify(options.recipients));
    formData.append('email', options.email);
    formData.append('blob', options.blob);

    return this.http.post(this.serverService.api('/planners/share'), formData, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to share the planner ' + JSON.stringify(error.data));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }
}
