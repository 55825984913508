
export const GetAllColumnsFromCsvData = (items: any[] | undefined = []): string[] => {
  if (!items?.length) {
    return[];
  }
  const csvColumnsSet: string[] = [];
  items.forEach(obj => {
    Object.keys(obj).forEach(key => {
      if (!csvColumnsSet.find(c => c === key)) {
        csvColumnsSet.push(key);
      }
    });
  });

  const csvColumns = Array.from(csvColumnsSet);
  return csvColumns;
};
