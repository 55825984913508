<div class="modal-header">
    <h4 class="modal-title">Addresses</h4>
    <button type="button" class="close" (click)="close()" aria-hidden="true">&times;</button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="form-group row">
            <label class="col-3 control-label">Address 1:</label>
            <div class="col-9">
                <input type="text" class="form-control" formControlName="address1" appAutofocus />
                <span class="form-text text-muted">Enter the main address</span>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-3 control-label">Address 2:</label>
            <div class="col-9">
                <input type="text" class="form-control" formControlName="address2" />
                <span class="form-text text-muted">Enter the secondary address</span>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-3 control-label">&nbsp;</label>
            <div class="col-9 row pr-0">
                <div class="col-3 pr-0">
                    <input type="text" class="form-control" formControlName="city" placeholder="City" />
                </div>
                <div class="col-3 pr-0">
                    <select class="form-control" formControlName="state">
                    	<option value="AL">Alabama</option>
                    	<option value="AK">Alaska</option>
	                    <option value="AZ">Arizona</option>
	                    <option value="AR">Arkansas</option>
	                    <option value="CA">California</option>
	                    <option value="CO">Colorado</option>
	                    <option value="CT">Connecticut</option>
	                    <option value="DE">Delaware</option>
	                    <option value="DC">District Of Columbia</option>
	                    <option value="FL">Florida</option>
	                    <option value="GA">Georgia</option>
	                    <option value="HI">Hawaii</option>
	                    <option value="ID">Idaho</option>
	                    <option value="IL">Illinois</option>
	                    <option value="IN">Indiana</option>
	                    <option value="IA">Iowa</option>
	                    <option value="KS">Kansas</option>
	                    <option value="KY">Kentucky</option>
	                    <option value="LA">Louisiana</option>
	                    <option value="ME">Maine</option>
	                    <option value="MD">Maryland</option>
	                    <option value="MA">Massachusetts</option>
	                    <option value="MI">Michigan</option>
	                    <option value="MN">Minnesota</option>
	                    <option value="MS">Mississippi</option>
	                    <option value="MO">Missouri</option>
	                    <option value="MT">Montana</option>
	                    <option value="NE">Nebraska</option>
	                    <option value="NV">Nevada</option>
	                    <option value="NH">New Hampshire</option>
	                    <option value="NJ">New Jersey</option>
	                    <option value="NM">New Mexico</option>
	                    <option value="NY">New York</option>
	                    <option value="NC">North Carolina</option>
	                    <option value="ND">North Dakota</option>
	                    <option value="OH">Ohio</option>
	                    <option value="OK">Oklahoma</option>
	                    <option value="OR">Oregon</option>
	                    <option value="PA">Pennsylvania</option>
	                    <option value="RI">Rhode Island</option>
	                    <option value="SC">South Carolina</option>
	                    <option value="SD">South Dakota</option>
	                    <option value="TN">Tennessee</option>
	                    <option value="TX">Texas</option>
	                    <option value="UT">Utah</option>
	                    <option value="VT">Vermont</option>
	                    <option value="VA">Virginia</option>
	                    <option value="WA">Washington</option>
	                    <option value="WV">West Virginia</option>
	                    <option value="WI">Wisconsin</option>
	                    <option value="WY">Wyoming</option>
                        <option value="AS">American Samoa</option>
                        <option value="GU">Guam</option>
                        <option value="MP">Northern Mariana Islands</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="UM">United States Minor Outlying Islands</option>
                        <option value="VI">Virgin Islands</option>
                        <option value="AA">Armed Forces Americas</option>
                        <option value="AP">Armed Forces Pacific</option>
                        <option value="AE">Armed Forces Others</option>
                    </select>
                </div>
                <div class="col-3 pr-0">
                    <input type="text" class="form-control" formControlName="zip" placeholder="Zip Code" />
                </div>
                <div class="col-3 pr-0">
                    <input type="text" class="form-control" formControlName="country" placeholder="Country" />
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button class="btn btn-primary" type="button" (click)="save()">Save</button>
    <button class="btn btn-default" type="button" (click)="close()">Cancel</button>
</div>
