import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-password',
  templateUrl: './user-password.component.html',
  styleUrls: ['./user-password.component.scss']
})
export class UserPasswordComponent implements OnInit {

  passwordForm: FormGroup;
  signedInOthers = false;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) {
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmpassword: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
  }

  change(): void {
    this.activeModal.close({ password: this.f.password.value });
  }

  close(): void {
    this.activeModal.dismiss();
  }

  // convenience getter for easy access to form fields
  get f(): {[key: string]: AbstractControl} { return this.passwordForm.controls; }
}
