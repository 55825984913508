import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';

import { AlertService } from './alert.service';
import { ServerService } from './server.service';

import { Notification } from '../shared/models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient,
    private serverService: ServerService,
    private alertService: AlertService) { }

  getByGuide(guideId: string): Observable<any> {
    return this.http.get(this.serverService.api('/notifications/byguide', guideId), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to retrieve notification ' + JSON.stringify(error));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  list(receiver: string | undefined, unreadOnly: boolean = false): Observable<any> {
    return this.http.get(this.serverService.api('/notifications/list/receiver', receiver) +
      (unreadOnly ? '?unread=true' : ''), this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        // this.alertService.error('Failed to list notifications ' + JSON.stringify(error));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  create(notification: Notification): Observable<any> {
    return this.http.post(this.serverService.api('/notifications'), { notification }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to create a notification ' + JSON.stringify(error));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  update(notification: Notification): Observable<any> {
    return this.http.put(this.serverService.api('/notifications'), { notification }, this.serverService.apiConfig())
      .pipe(first(), catchError((error) => {
        this.alertService.error('Failed to update a notification ' + JSON.stringify(error));
        return throwError(error);
      }), map((response: any) => {
        return response;
      }));
  }

  remove(uuid: string): Observable<any> {
    return this.http.delete(this.serverService.api('/notifications', uuid), this.serverService.apiConfig())
    .pipe(first(), catchError((error) => {
      this.alertService.error('Failed to remove a notification ' + JSON.stringify(error));
      return throwError(error);
    }), map((response: any) => {
      return response;
    }));
  }
}
