import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateDifference'
})
export class DateDifferencePipe implements PipeTransform {

  transform(date1: Date,  date2: Date, args?: any): string {
    if (!date1 || !date2) {
      return '';
    }

    const diffMs = Math.abs(new Date(date2).getTime() - new Date(date1).getTime());
    const days = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

    let result = '';
    if (days > 0) {
      result += days > 1 ? `${days} days ` : `${days} day `;
    }
    if (hours > 0) {
      result += hours > 1 ? `${hours} hours ` :  `${hours} hour `;
    }
    if (minutes > 0) {
      result += minutes > 1 ? `${minutes} minutes` : `${minutes} minute`;
    }

    return result.trim();
  }
}

