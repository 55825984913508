import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UsersService } from '../users.service';
import { SessionStateService } from '../session-state.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  user: any;
  isSimulated = false;
  requiresUpdate = false;
  softwareVersion = '';
  delay = 250;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private sessionStateService: SessionStateService,
    private usersService: UsersService) { }

  ngOnInit(): void {
    const session = this.sessionStateService.getSession();

    this.user = {
      firstName: session.firstName,
      lastName: session.lastName
    };

    this.isSimulated = !!this.sessionStateService.get().session.isSimulated;
  }

  go(route: string): void {
    this.router.navigate([route]);
  }

  logout(): void {
    if (this.modalService.hasOpenModals()) {
      return;
    }

    if (this.sessionStateService.get().session.isSimulated) {
      this.usersService.simulateLogout().subscribe(() => {});
    } else {
      this.usersService.logout().subscribe(() => {});
    }
  }

  installUpdate(): void {
  }
}
