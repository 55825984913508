import {CamelCaseToWords} from '../utils/camel-case-to-words.util';

export abstract class BaseMapField {
  name: string;
  title: string;

  constructor(data: {name: string, title?: string}) {
    this.name = data.name;
    this.title = data.title || CamelCaseToWords(data.name);
  }
}
export class MapField extends BaseMapField {
  isMultiple: boolean;
  isRequired?: boolean;
  customSetter?: (obj: any, value: string) => {};

  constructor(data: {name: string, isMultiple?: boolean, title?: string, isRequired?: boolean, customSetter?: (obj: any, value: string) => {}}) {
    super(data);
    this.isMultiple = !!data.isMultiple;
    this.customSetter = data.customSetter;
    this.isRequired = data.isRequired || false;
  }
}

export class SourceFiled extends BaseMapField {
  fieldToAutoMap?: string;
  isInvalid: boolean;

  constructor(data: {name: string, title?: string, isInvalid?: boolean, fieldToAutoMap?: string }) {
    super(data);
    this.isInvalid = !!data.isInvalid;
    this.fieldToAutoMap = data.fieldToAutoMap;
  }
}

export class RequiredFieldToMap {
  name: string;
  title: string;
  isMapped: boolean;
  constructor(data: {name: string, title: string, isMapped?: boolean}) {
    this.name = data.name;
    this.title = data.title;
    this.isMapped = data.isMapped || false;
  }
}
