<div
  *ngIf="requiredFields?.length"
  class="required-block alert"
  [ngClass]="{'alert-danger': !isAllRequiredFieldsMapped, 'alert-primary': isAllRequiredFieldsMapped}"
  [ngbTooltip]="isAllRequiredFieldsMapped ? 'All required fields are mapped. Great job!' : 'Please, map all required fields.'"
>
  <p>Required fields to map: </p>
  <div class="form-check" *ngFor="let requiredField of requiredFields" >
    <input class="form-check-input" type="checkbox" name="exampleRadios" [id]="requiredField.name" [value]="requiredField.name" [checked]="requiredField.isMapped">
    <label class="form-check-label" [for]="requiredField.name">
      {{requiredField.title}}
    </label>
  </div>
</div>

<div class=" table-sticky-header-container map-columns">
  <table class="custom-table">
    <thead>
      <tr>
        <th class="source">Source</th>
        <th class="destination">Destination</th>
        <th [attr.colspan]="numberOfExampleColumns">Sample Data</th>
      </tr>
    </thead>
    <tr *ngFor="let sourceField of sourceFields;  let lastRow = last">
      <td class="source">{{sourceField.title}}</td>
      <td class="destination">
        <select
          class="form-control"
          (change)="onDestinationFieldChange(sourceField, $event.target)"
          [ngClass]="{'is-invalid': sourceField.isInvalid}"
          [ngbTooltip]="sourceField.isInvalid ? 'The field could be mapped only once' : ''"
          [ngModel]="sourceField.fieldToAutoMap">
          <option selected></option>
          <option *ngFor="let destinationField of destinationFields" [value]="destinationField.name">{{destinationField.title}}</option>
        </select>
      </td>
      <td *ngFor="let row of jsonData">
        {{row[sourceField.name]}}
      </td>
      <ng-container *ngIf="lastRow && !isStepContentRendered">
        {{ onStepRendered() }}
      </ng-container>
    </tr>
  </table>
</div>
