<app-header></app-header>
<app-toolbar></app-toolbar>

<div class="container-fluid main-container">
  <div class="row position-relative">
    <div class="col-4 pb-4 d-flex flex-column" id="esourcing-tools">
      <h2 class="text-center font-weight-bold mt-4 mb-4" id="sidebar-title">Enter Your Search Key Words:</h2>

      <div id="esourcing-sidebar">
        <ngb-accordion #esourcingacc="ngbAccordion">
          <ngb-panel id="ngb-esourcing-title" (hidden)="titlesShown=false" (shown)="titlesShown=true">
            <ng-template ngbPanelHeader>
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="m-0">Title(s)</h5>
                <div>
                  <button ngbPanelToggle class="btn btn-outline-primary" (click)="showTitles()">
                    <i [hidden]="titlesShown" class="fas fa-plus"></i>
                    <i [hidden]="!titlesShown" class="fas fa-minus"></i>
                  </button>
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="input-group mb-3" *ngFor="let title of titles; let i = index; trackBy: trackByFn">
                <input type="text" class="form-control" [placeholder]="(!i ? 'Enter ' : 'Additional ') + 'Title'" [(ngModel)]="titles[i]" #titleinputs>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary text-danger" type="button" (click)="removeTitle(i)"><i class="fas fa-times-circle"></i></button>
                </div>
              </div>
              <button class="btn btn-outline-secondary text-success" type="button" (click)="addTitle()"><i class="fas fa-plus"></i></button>
            </ng-template>
          </ngb-panel>

          <ngb-panel id="ngb-esourcing-industry" (hidden)="criteriasShown=false" (shown)="industryShown=true">
            <ng-template ngbPanelHeader>
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="m-0">Industry Preference</h5>
                <div>
                  <button ngbPanelToggle class="btn btn-outline-primary" (click)="showIndustries()">
                    <i [hidden]="industryShown" class="fas fa-plus"></i>
                    <i [hidden]="!industryShown" class="fas fa-minus"></i>
                  </button>
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="input-group mb-3" *ngFor="let industry of industries; let i = index; trackBy: trackByFn">
                <input type="text" class="form-control" [placeholder]="(!i ? 'Enter ' : 'Additional ') + 'Industry'" [(ngModel)]="industries[i]" #industryinputs>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary text-danger" type="button" (click)="removeIndustry(i)"><i class="fas fa-times-circle"></i></button>
                </div>
              </div>
              <button class="btn btn-outline-secondary text-success" type="button" (click)="addIndustry()"><i class="fas fa-plus"></i></button>
            </ng-template>
          </ngb-panel>

          <ngb-panel id="ngb-esourcing-criteria" (hidden)="criteriasShown=false" (shown)="criteriasShown=true">
            <ng-template ngbPanelHeader>
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="m-0">Must Haves</h5>
                <div>
                  <div class="operator">
                    <div class="btn-group btn-group-toggle" role="group" name="stringTypeSwitches" ngbRadioGroup [(ngModel)]="criteriasOperator">
                      <label ngbButtonLabel class="btn-outline-secondary">
                        <input ngbButton type="radio" [value]="'AND'">AND
                      </label>
                      <label ngbButtonLabel class="btn-outline-secondary">
                        <input ngbButton type="radio" [value]="'OR'">OR
                      </label>
                    </div>
                  </div>
                  <button ngbPanelToggle class="btn btn-outline-primary" (click)="showDuties()">
                    <i [hidden]="criteriasShown" class="fas fa-plus"></i>
                    <i [hidden]="!criteriasShown" class="fas fa-minus"></i>
                  </button>
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="input-group mb-3" *ngFor="let criteria of criterias; let i = index; trackBy: trackByFn">
                <input type="text" class="form-control" [placeholder]="(!i ? 'Enter ' : 'Additional ') + 'Criteria'" [(ngModel)]="criterias[i]" #dutyinputs>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary text-danger" type="button" (click)="removeCriteria(i)"><i class="fas fa-times-circle"></i></button>
                </div>
              </div>
              <button class="btn btn-outline-secondary text-success" type="button" (click)="addCriteria()"><i class="fas fa-plus"></i></button>
            </ng-template>
          </ngb-panel>

          <ngb-panel id="ngb-esourcing-location" (hidden)="locationsShown=false" (shown)="locationsShown=true">
            <ng-template ngbPanelHeader>
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="m-0">Client Pref for Candidate Location</h5>
                <div>
                  <button ngbPanelToggle class="btn btn-outline-primary" (click)="showLocations()">
                    <i [hidden]="locationsShown" class="fas fa-plus"></i>
                    <i [hidden]="!locationsShown" class="fas fa-minus"></i>
                  </button>
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="input-group mb-3" *ngFor="let location of locations; let i = index; trackBy: trackByFn">
                <input type="text" class="form-control" [placeholder]="(!i ? 'Enter ' : 'Additional ') + 'Location'" [(ngModel)]="locations[i]" #locationinputs>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary text-danger" type="button" (click)="removeLocation(i)"><i class="fas fa-times-circle"></i></button>
                </div>
              </div>
              <button class="btn btn-outline-secondary text-success" type="button" (click)="addLocation()"><i class="fas fa-plus"></i></button>
            </ng-template>
          </ngb-panel>

          <ngb-panel id="ngb-esourcing-company" (hidden)="companiesShown=false" (shown)="companiesShown=true">
            <ng-template ngbPanelHeader>
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="m-0">Client Competitors</h5>
                <div>
                  <button ngbPanelToggle class="btn btn-outline-primary" (click)="showCompanies()">
                    <i [hidden]="companiesShown" class="fas fa-plus"></i>
                    <i [hidden]="!companiesShown" class="fas fa-minus"></i>
                  </button>
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="input-group mb-3" *ngFor="let company of companies; let i = index; trackBy: trackByFn">
                <input type="text" class="form-control" [placeholder]="(!i ? 'Enter ' : 'Additional ') + 'Company'" [(ngModel)]="companies[i]" #companyinputs>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary text-danger" type="button" (click)="removeCompany(i)"><i class="fas fa-times-circle"></i></button>
                </div>
              </div>
              <button class="btn btn-outline-secondary text-success" type="button" (click)="addCompany()"><i class="fas fa-plus"></i></button>
            </ng-template>
          </ngb-panel>

        </ngb-accordion>
      </div>

      <div id="search-hint">
        <p class="text-center mt-3"><b>AFTER ENTERING YOUR KEYWORDS:</b></p>
        <ol type="1">
          <li>Now, go to the <b><i>Coolean&trade;</i>&nbsp;<img id="coolean-logo-small" src="/assets/images/dashboard/e-sourcing-icon.png" />&nbsp;Fishing Ponds</b> at the top of your screen and select and appropriate <b>&quot;Pond&quot;</b> to fish from.</li>
          <br />
          <li>Any time you readjust your keywords to tighten or widen your search... You must <i>re-click the Search button.</i></li>
        </ol>
        <p class="text-center"><i>Happy Hunting!</i></p>
      </div>

      <div class="btn-group btn-group-lg mt-3 mb-3" role="group" id="search-action">
        <button type="button" class="btn btn-default" [disabled]="!searchString" (click)="processQuery()">Search</button>

        <div class="btn-group dropup">
          <button #esourcingSaveBtn type="button" class="btn btn-primary" [disabled]="!searchString" (click)="saveSearchClicked()">Save Search</button>
          <div class="btn-group" ngbDropdown role="group" aria-label="Saved Searches">
            <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle [disabled]="!searchString && !savedSearches.length"></button>
            <div class="dropdown-menu" ngbDropdownMenu>
              <div class="dropdown-container saved-searches-container">
                <ng-container *ngIf="savedSearches?.length; else noData">
                  <ng-container *ngFor="let savedSearch of savedSearches; let i = index">
                    <div>
                      <button ngbDropdownItem type="button" (click)="recallSearch(savedSearch)">{{savedSearch.title}}</button>
                      <button class="btn btn-outline-secondary text-danger float-right delete-btn" type="button" (click)="removeSearchClicked(i)"><i class="fas fa-times-circle"></i></button>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #noData>
                  <p class="pl-1 pr-1 m-0 text-center">There is no saved searches yet</p>
                </ng-template>
              </div>
            </div>
          </div>
        </div>

        <button type="button" class="btn btn-danger" [disabled]="!searchString" (click)="clear()">Clear</button>
      </div>
      <br />
      
      <div id="data-transfer-component">
        <app-esourcing-process-selected></app-esourcing-process-selected>
      </div>
    </div>

    <div class="col-8" #tipactual id="tip-actual">
      <nav class="navbar navbar-default navbar-fixed-top navbar-global navbar-expand-md w-100 bg-white p-0" role="navigation">
        <div class="ml-auto mr-auto p-0 text-center">
          <div><a class="navbar-brand d-block text-center" id="coolean-title" href="javascript:void"><b>Your <i>Coolean&trade;</i>&nbsp;<img id="coolean-logo" src="/assets/images/dashboard/e-sourcing-icon.png" />&nbsp;Fishing Ponds</b></a></div>

          <div class="btn-group btn-group-toggle" role="group" name="stringTypeSwitches" id="coolean-strings" ngbRadioGroup [(ngModel)]="searchStringName">
            <label ngbButtonLabel class="btn-outline-secondary" *ngFor="let searchStr of searchStrings">
              <input ngbButton type="radio" [value]="searchStr.string" (click)="searchStringChanged(searchStr.string, searchStr.name)"> {{searchStr.displayName}}
            </label>
          </div>
        </div>
      </nav>

      <img src="/assets/images/information.png" id="information" #information (click)="showTipsPage(); correctTips();" />

      <div [hidden]="!!searchString">
        <div [hidden]="!showTips">
          <div id="title-tip" class="tip">
            <p><span class="text-danger font-weight-bold">Title Tip:</span> Start with the exact title match, but then add alternative titles for the same job.<br />
            (Ex: Programmer, Engineer, Developer, Coder, etc.)</p>

            <p><span class="text-danger font-weight-bold">Title Tip:</span> Keep in mind, a &quot;Manager&quot; in a small company might be an &quot;Assistant Manager&quot; or a &quot;Sr. Staff&quot; in a larger company. Think!</p>
          </div>
          <div id="industry-tip" class="tip">
            <p><span class="text-danger font-weight-bold">Industry Tip:</span> Most clients’ first preference is a candidate out of their industry.<br />
            (Ex: Plastics, healthcare, SaaS, aerospace, building supply, etc.)</p>
          </div>
          <div id="duties-tip" class="tip">
            <p><span class="text-danger font-weight-bold">Must Haves Tip:</span> List skills and/or attributes specific to this job.<br />
            (Ex: Specific: Software, ERP's, equipment, reports, systems, atypical duties, etc.)</p>
          </div>
          <div id="locations-tip" class="tip">
            <p><span class="text-danger font-weight-bold">Location Tip:</span> Remote, hybrid, or on-site, most clients’ first choice is the location the candidate reports to. Start there, then go wide. Use multiple cities if commutable. Use LinkedIn labels if possible.<br />
            (Ex: "Greater Chicago Area").</p>
          </div>
          <div id="competitor-tip" class="tip">
            <p><span class="text-danger font-weight-bold">Competitor Tip:</span> Most clients prefer candidates from competitors, unless they state otherwise.<br />
            In a search engine, type: &quot;<i>your client's name</i> competition&quot;. Then enter as keywords.</p>
          </div>
        </div>
      </div>

      <iframe [id]="sourcingIFrameId" class="mt-4" [src]="queryString" frameborder="0" width="100%" height="100%" [hidden]="!searchString"></iframe>
    </div>
  </div>
</div>

<app-footer></app-footer>
