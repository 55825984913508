<div class="modal-header">
  <h4 class="modal-title">Data Export Options</h4>
  <button type="button" class="close" aria-hidden="true" (click)="close()">&times;</button>
</div>

<div class="modal-body">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <h6 class="m-0"><b>Select the fields you want to include.</b></h6>
    <button
      class="btn btn-outline-secondary"
      (click)="resetFields()">Reset Fields</button>
  </div>
  <p class="mb-3">This will let you download a file with contact data. It could take a few minutes, depending on how many contacts are being exported.</p>

  <ng-container *ngIf="categories?.length">
    <app-nested-checklist [categories]="categories" (selectionChanged)="onCategoriesSelectionChanged($event)" class="d-block ml-5"></app-nested-checklist>
  </ng-container>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-default"
    (click)="close()">Cancel</button>
  <button
    type="submit"
    class="btn btn-primary"
    [disabled]="!isSelection"
    (click)="save()">Export</button>
</div>
