import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';

import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import * as _ from 'lodash';
import * as moment from 'moment';
import { jsPDF } from 'jspdf';

import { Candidate } from '../admin/candidates/candidate.model';
import { Client } from '../admin/clients/clients.model';
import { User } from '../shared/models/user.model';
import { PhoneType } from '../shared/models/phone.model';
import { Session } from '../shared/session-state.service';

import { CandidatesService } from 'src/app/admin/candidates/candidates.service';
import { CandidatesActions } from 'src/app/admin/candidates/candidates.actions';
import { CandidateDetailComponent } from 'src/app/admin/candidates/candidate-detail/candidate-detail.component';

import { ClientsService } from '../admin/clients/clients.service';
import { ClientsActions } from '../admin/clients/clients.actions';
import { ClientDetailComponent } from '../admin/clients/client-detail/client-detail.component';

import { CommonDialogComponent } from '../shared/common-dialog/common-dialog.component';

import { UsersService } from '../shared/users.service';
import { SessionStateService } from '../shared/session-state.service';
import { PlannerService } from './planner.service';
import { AlertService } from '../shared/alert.service';
import { EventBusService } from '../shared/event-bus.service';
import { ContentService } from '../shared/content.service';

@Component({
  selector: 'app-dailyplanner',
  templateUrl: './dailyplanner.component.html',
  styleUrls: ['./dailyplanner.component.scss']
})
export class DailyplannerComponent implements OnInit, OnDestroy {

  user: User | null = null;
  candidates: Candidate[] = [];
  clients: Client[] = [];

  planner: any = null;

  rawDate = moment();

  date = {
    weekday: this.rawDate.format('dddd'),
    date: this.rawDate.format('MMMM Do YYYY')
  };

  innerviewopen: boolean[] = [];
  sendoutdpopen: boolean[] = [];

  timeslotUpdateBus$ = new BehaviorSubject<number>(new Date().getTime());
  timeslotUpdateSub$: Subscription | null = null;
  updateTimeout: any;

  users: User[] = [];
  shareList: string[] = [];
  shareListFull: any[] = [];

  isSimulated = false;

  constructor(
    private store: Store,
    private router: Router,
    private modalService: NgbModal,
    private alertService: AlertService,
    private usersService: UsersService,
    private clientsService: ClientsService,
    private candidatesService: CandidatesService,
    private sessionStateService: SessionStateService,
    private eventBusService: EventBusService,
    private plannerService: PlannerService,
    private contentService: ContentService) { }

  ngOnInit(): void {
    const session = this.sessionStateService.get();
    this.isSimulated = !!session.session.simulatedSession;

    this.usersService.currentUser().subscribe((user) => {
      this.user = user;

      this.plannerService.get().subscribe((planner) => {
        // Migrate time values:

        if (planner.innerViews) {
          for (let i = 0, iLimit = planner.innerViews.length; i < iLimit; i++) {
            const innerView = planner.innerViews[i];

            if (innerView.time) {
              const timeParts = innerView.time.split(' ');

              if (timeParts[1] === 'PM') {
                const time = timeParts[0].split(':');
                const hour = +time[0];

                if (hour > 12) {
                  innerView.time = (hour - 12) + ':' + time[1] + ' PM';
                }
              }
            }
          }
        }

        if (planner.sendouts) {
          for (let i = 0, iLimit = planner.sendouts.length; i < iLimit; i++) {
            const sendout = planner.sendouts[i];

            if (sendout.time) {
              const timeParts = sendout.time.split(' ');

              if (timeParts[1] === 'PM') {
                const time = timeParts[0].split(':');
                const hour = +time[0];

                if (hour > 12) {
                  sendout.time = (hour - 12) + ':' + time[1] + ' PM';
                }
              }
            }
          }
        }

        this.planner = planner || {};

        this.setUpPlanner();
      }, () => {
        this.planner = {};

        this.setUpPlanner();
      });

      this.refreshCandidates();

      this.refreshClients();

      this.usersService.listByAccount(user.account_uuid).subscribe((users) => {
        this.users = users;
      });
    });
  }

  setUpPlanner(): void {
    if (!this.planner.generatedLeads) {
      this.planner.generatedLeads = [];
    }

    if (!this.planner.generatedLeads.length) {
      this.planner.generatedLeads.push({status: 'none', company: '', phone: '', position: '', hiringManager: '', source: '', notes: ''});
    }

    if (!this.planner.popMarketing) {
      this.planner.popMarketing = [];
    }

    if (!this.planner.popMarketing.length) {
      this.planner.popMarketing.push(
        {status: 'none', candidate: '', company: '', phone: '', email: '', hiringManager: '', title: '', result: '', notes: ''});
    }

    if (!this.planner.newMarketing) {
      this.planner.newMarketing = [];
    }

    if (!this.planner.newMarketing.length) {
      this.planner.newMarketing.push(
        {status: 'none', company: '', phone: '', email: '', hiringManager: '', title: '', result: '', notes: ''});
    }

    if (!this.planner.existingCheckin) {
      this.planner.existingCheckin = [];
    }

    if (!this.planner.existingCheckin.length) {
      this.planner.existingCheckin.push(
        {status: 'none', candidate: '', phone: '', hiringManager: '', phoneMgr: '', memo: '', result: '', notes: ''});
    }

    if (!this.planner.innerViews) {
      this.planner.innerViews = [];
    }

    if (!this.planner.innerViews.length) {
      this.planner.innerViews.push(
        {date: '', time: '', candidate: '', title: '', company: '', phone: '', email: '', source: '', form: '', notes: ''});
    }

    if (!this.planner.telesourcing) {
      this.planner.telesourcing = [];
    }

    if (!this.planner.telesourcing.length) {
      this.planner.telesourcing.push({status: 'none', company: '', phone: '', result: ''});
    }

    if (!this.planner.recruitingCalls) {
      this.planner.recruitingCalls = [];
    }

    if (!this.planner.recruitingCalls.length) {
      this.planner.recruitingCalls.push(
        {status: 'none', project: '', candidate: '', title: '', company: '',
         phone: '', hiringManager: '', memo: '', action: '', notes: ''});
    }

    if (!this.planner.sendouts) {
      this.planner.sendouts = [];
    }

    if (!this.planner.sendouts.length) {
      this.planner.sendouts.push({priority: '', status: 'none', date: '', time: '', candidate: '',
        hiringManager: '', company: '', phone: '', notes: ''});
    }

    if (!this.planner.preps) {
      this.planner.preps = [];
    }

    if (!this.planner.preps.length) {
      this.planner.preps.push({priority: '', status: 'none', name: '', phone: '', memo: '', notes: ''});
    }

    if (!this.planner.followUps) {
      this.planner.followUps = [];
    }

    if (!this.planner.followUps.length) {
      this.planner.followUps.push({priority: '', name: '', type: '', phone: '', issue: '', result: '', notes: ''});
    }

    if (!this.planner.activitySummary) {
      this.planner.activitySummary = {
        goals: {},
        actual: {},
        production: 0,
        productionGoal: 0
      };
    }

    this.timeslotUpdateSub$ = this.timeslotUpdateBus$.subscribe(() => {
      this.update('timeSlots');
    });
  }

  ngOnDestroy(): void {
    if (this.timeslotUpdateSub$) {
      this.timeslotUpdateSub$.unsubscribe();
      this.timeslotUpdateSub$ = null;
    }
  }

  refreshCandidates(): void {
    setTimeout(() => {
      this.candidatesService.list(this.user?.uuid || '').subscribe((candidates) => {
        if (!candidates) {
          candidates = [];
        }

        this.candidates = candidates;
      });
    }, 250);
  }

  refreshClients(): void {
    setTimeout(() => {
      this.clientsService.list(this.user?.uuid || '').subscribe((clients) => {
        if (!clients) {
          clients = [];
        }

        this.clients = clients;
      });
    }, 250);
  }

  addRecord(blockName: string): void {
    if (this.isSimulated) {
      return;
    }

    if (this.planner[blockName]) {
      switch (blockName) {
        case 'generatedLeads':
          this.planner[blockName].push({status: 'none', company: '', phone: '', position: '', hiringManager: '', source: '', notes: ''});
          break;

        case 'popMarketing':
          this.planner[blockName].push(
            {status: 'none', candidate: '', company: '', phone: '', email: '', hiringManager: '', title: '', result: '', notes: ''});
          break;

        case 'newMarketing':
          this.planner[blockName].push({status: 'none', company: '', phone: '', email: '', hiringManager: '', title: '', result: '', notes: ''});
          break;

        case 'existingCheckin':
          this.planner[blockName].push({status: 'none', candidate: '', phone: '', hiringManager: '',
            phoneMgr: '', memo: '', result: '', notes: ''});
          break;

        case 'innerViews':
          this.planner.innerViews.push(
            {date: '', time: '', candidate: '', title: '', company: '', phone: '', email: '', source: '', form: '', notes: ''});
          break;

        case 'telesourcing':
          this.planner[blockName].push({status: 'none', company: '', phone: '', result: ''});
          break;

        case 'recruitingCalls':
          this.planner[blockName].push(
            {status: 'none', project: '', candidate: '', title: '', company: '', phone: '', hiringManager: '', memo: '', action: '', notes: ''});
          break;

        case 'sendouts':
          this.planner[blockName].push({
            priority: '', status: 'none', date: '', time: '', candidate: '', hiringManager: '', company: '', phone: '', notes: ''});
          break;

        case 'preps':
          this.planner[blockName].push({priority: '', status: 'none', type: '', name: '', phone: '', memo: '', notes: ''});
          break;

        case 'followUps':
          this.planner[blockName].push({priority: '', name: '', type: '', phone: '', issue: '', result: '', notes: ''});
          break;
      }
    }
  }

  removeRecord(blockName: string, index: number): void {
    if (this.isSimulated) {
      return;
    }

    const modalRef = this.modalService.open(CommonDialogComponent);

    modalRef.componentInstance.closeButtonText = 'Cancel';
    modalRef.componentInstance.actionButtonText = 'Delete';
    modalRef.componentInstance.headerText = 'Delete Planner Entry';
    modalRef.componentInstance.bodyText = 'Are you sure you want to delete this planner entry?';

    modalRef.result.then((result) => {
      if (this.planner[blockName]) {
        this.planner[blockName].splice(index, 1);

        if (this.planner[blockName].length === 0) {
          this.addRecord(blockName);
        }

        this.update(blockName);
      }
    });
  }

  addToClientDb(record: any): void {
    if (this.isSimulated) {
      return;
    }

    if (!record.company) {
      return;
    }

    const session = this.sessionStateService.get();
    const user = (session.session.simulatedSession || session.user) as User & Session;

    if (user.user_uuid) {
      user.uuid = user.user_uuid;
    }

    const [firstName, ...lastNameArray] = (record.candidate || record.hiringManager || '').split(' ');

    const client: Client = {
      firstName,
      lastName: lastNameArray.join(' '),
      title: record.title || '',
      company: record.company || '',
      active: true,
      private: false,
      memo: '',
      emails: [],
      phoneNumbers: [],
      addresses: [],
      owner: user.uuid,
      account: user.account_uuid
    };

    if (record.phone) {
      client.phoneNumbers.push({ type: PhoneType.work, number: record.phone });
    }

    this.clientsService.insert(client).subscribe(() => {
      this.alertService.success('Added client to the client database.');
    });
  }

  addToCandidateDb(record: any): void {
    if (this.isSimulated) {
      return;
    }

    if (!record.candidate) {
      return;
    }

    const session = this.sessionStateService.get();
    const user = (session.session.simulatedSession || session.user) as User & Session;

    if (user.user_uuid) {
      user.uuid = user.user_uuid;
    }

    const [firstName, ...lastNameArray] = (record.candidate || '').split(' ');

    const candidate: Candidate = {
      firstName,
      lastName: lastNameArray.join(' '),
      title: record.title || '',
      employer: record.company,
      active: true,
      private: false,
      memo: '',
      emails: [],
      phoneNumbers: [],
      addresses: [],
      owner: user.uuid,
      account: user.account_uuid
    };

    if (record.phone) {
      candidate.phoneNumbers.push({ type: PhoneType.work, number: record.phone });
    }

    this.candidatesService.insert(candidate).subscribe(() => {
      this.alertService.success('Added candidate to the candidate database.');
    });
  }

  isToday(td: string): boolean {
    const d = new Date();

    let day = String(d.getDate());
    let month = String(d.getMonth() + 1);

    if (day.length === 1) {
      day = '0' + day;
    }

    if (month.length === 1) {
      month = '0' + month;
    }

    return (td === (month + '/' + day + '/' + d.getFullYear()));
  }

  updatePortal(blockName: string, notify: boolean = false): void {
    if (this.isSimulated) {
      return;
    }

    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
    }

    this.updateTimeout = setTimeout(() => {
      this.updateTimeout = undefined;

      this.plannerService.update(_.pick(this.planner, ['uuid', blockName])).subscribe(() => {
        if (notify) {
          this.alertService.success('Daily Planner has been updated.');
        }
      });
    }, 500);
  }

  update(blockName: string, notify: boolean = false): void {
    this.updatePortal(blockName, notify);
  }

  upgradePopStatus(obj: any, blockName: string): void {
    if (this.isSimulated) {
      return;
    }

    let notify = true;

    switch (obj.result) {
      case '':
        obj.status = 'none';
        break;

      /* @ts-ignore */
      case 'CB':
        notify = false;
        this.addToClientDb(obj);

      /* @ts-ignore */
      case 'LVM':
        obj.status = 'dialed';
        break;

      /* @ts-ignore */
      case 'EM':
        notify = false;
        this.addToClientDb(obj);

      /* @ts-ignore */
      default:
        obj.status = 'contacted';
        break;
    }

    this.update(blockName, notify);
  }

  upgradeNewClientDevStatus(obj: any, blockName: string): void {
    if (this.isSimulated) {
      return;
    }

    let notify = true;

    switch (obj.result) {
      case '':
        obj.status = 'none';
        break;

      /* @ts-ignore */
      case 'CB':
        notify = false;
        this.addToClientDb(obj);

      /* @ts-ignore */
      case 'LVM':
        obj.status = 'dialed';
        break;

      /* @ts-ignore */
      case 'EM':
        notify = false;
        this.addToClientDb(obj);

      /* @ts-ignore */
      default:
        obj.status = 'contacted';
        break;
    }

    this.update(blockName, notify);
  }

  upgradeRecruitingCallStatus(obj: any, blockName: string): void {
    if (this.isSimulated) {
      return;
    }

    let notify = true;

    switch (obj.result) {
      case '':
        obj.status = 'none';
        break;

      case 'Left VM':
        obj.status = 'dialed';
        break;

      /* @ts-ignore */
      case 'InnerView Set':
        notify = false;
        this.addToCandidateDb(obj);

      /* @ts-ignore */
      default:
        obj.status = 'contacted';
        break;
    }

    this.update(blockName, notify);
  }

  upgradeTelesourcingStatus(obj: any, blockName: string): void {
    if (this.isSimulated) {
      return;
    }

    switch (obj.result) {
      case '':
        obj.status = 'none';
        break;

      case 'incomplete':
        obj.status = 'dialed';
        break;

      case 'completed':
        obj.status = 'contacted';
        break;
    }

    this.update(blockName, true);
  }

  upgradePrepStatus(obj: any, blockName: string): void {
    if (this.isSimulated) {
      return;
    }

    switch (obj.memo) {
      case '':
        obj.status = 'none';
        break;

      case 'Incomplete':
        obj.status = 'dialed';
        break;

      default:
        obj.status = 'contacted';
        break;
    }

    this.update(blockName, true);
  }

  updateWithAction(obj: any, blockName: string): void {
    if (this.isSimulated) {
      return;
    }

    switch (obj.action) {
      case 'LVM':
      case 'EM':
      case 'CB':
      case 'Reschedule':
      case 'Client Confirmed':
      case 'Candidate Confirmed':
        obj.status = 'dialed';
        break;

      case 'COV':
      case 'Success':
      case 'Not Ready':
      case 'No Interest':
      case 'Both Confirmed':
        obj.status = 'contacted';
        break;

      default:
        obj.status = 'none';
        break;
    }

    this.update(blockName);
  }

  updateWithResult(obj: any, blockName: string): void {
    if (this.isSimulated) {
      return;
    }

    switch (obj.result) {
      case 'LVM':
      case 'EM':
      case 'Incomplete':
        obj.status = 'dialed';
        break;

      case 'COV':
      case 'Complete':
        obj.status = 'contacted';
        break;

      default:
        obj.status = 'none';
        break;
    }

    this.update(blockName);
  }

  searchCandidates = (token$: Observable<string>) =>
    token$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(token => token.length === 0 ? []
        : _.filter(this.candidates,
            candidate =>
              (candidate.firstName.toLowerCase().indexOf(token.toLowerCase()) > -1) ||
              (candidate.lastName.toLowerCase().indexOf(token.toLowerCase()) > -1) ))
    )

  candidateFullname = (candidate: Candidate) => {
    return candidate.uuid ? (candidate.firstName + ' ' + candidate.lastName) : (candidate.toString() || '');
  }

  searchClients = (token$: Observable<string>) =>
    token$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(token => token.length === 0 ? []
        : _.filter(this.clients,
          client => (client.company.toLowerCase().indexOf(token.toLowerCase()) > -1) ))
    )

  clientCompany = (client: Client) => {
    return (client.company || '') + (client.company ? ' (' : '') + this.clientManager(client) + (client.company ? ')' : '');
  }

  clientManager = (client: Client) => {
    return client.firstName + ' ' + client.lastName;
  }

  applyCandidate(event: any, obj: any, blockName: string): void {
    if (this.isSimulated) {
      return;
    }

    event.preventDefault();

    obj.candidate = event.item.firstName + ' ' + event.item.lastName;

    if (event.item.uuid) {
      obj.name = event.item.firstName + ' ' + event.item.lastName;
      obj[blockName === 'sendouts' ? 'candidatePhone' : 'phone'] = event.item.phoneNumbers.length ? event.item.phoneNumbers[0].number : '';
      obj.email = event.item.emails.length ? event.item.emails[0].address : '';
      obj.title = event.item.title;

      if (blockName !== 'sendouts') {
        obj.company = event.item.employer;
      }

      this.update(blockName);

      this.candidatesService.lookup(event.item.uuid).subscribe((fullCandidate: Candidate) => {
        this.eventBusService.emit({ event: 'candidateChanged', value: fullCandidate });
      });
    } else {
      this.update(blockName);
    }
  }

  applyClient(event: any, field: string, obj: any, blockName: string): void {
    if (this.isSimulated) {
      return;
    }

    event.preventDefault();

    obj[field] = event.item[field];

    const client = _.find(this.clients, {uuid: event.item.uuid});

    if (client) {
      this.clientsService.lookup(client.uuid || '').subscribe((fullClient: Client) => {
        obj[field] = event.item[field];

        obj.phone = fullClient.phoneNumbers.length ? fullClient.phoneNumbers[0].number : '';
        obj.email = fullClient.emails.length ? fullClient.emails[0].address : '';
        obj.title = client.title;
        obj.hiringManager = client.firstName + ' ' + client.lastName;

        this.update(blockName);

        this.eventBusService.emit({ event: 'clientChanged', value: fullClient });
      });
    } else {
      this.update(blockName);
    }
  }

  applyClientMgr(event: any, obj: any, blockName: string): void {
    if (this.isSimulated) {
      return;
    }

    event.preventDefault();

    obj.hiringManager = event.item.firstName + ' ' + event.item.lastName;

    const client = _.find(this.clients, {company: event.item.company});

    if (client) {
      this.clientsService.lookup(client.uuid || '').subscribe((fullClient: Client) => {
        obj.phoneMgr = fullClient.phoneNumbers.length ? fullClient.phoneNumbers[0].number : '';

        this.update(blockName);

        this.eventBusService.emit({ event: 'clientChanged', value: fullClient });
      });
    } else {
      this.update(blockName);
    }
  }

  newClient(clientTemplate?: Partial<Client>): void {
    if (this.isSimulated) {
      return;
    }

    const uuid = this.contentService.uuid();
    this.store.dispatch(new CandidatesActions.Show({ uuid }));

    this.store.dispatch(new ClientsActions.SetCurrentPartial({ uuid, client: clientTemplate || {} }));

    const ngbModalOptions: NgbModalOptions = {
      windowClass: 'free-floating-modal',
      backdrop: false,
      size: 'lg'
    };

    const modalRef = this.modalService.open(ClientDetailComponent, ngbModalOptions);
    modalRef.componentInstance.uuid = uuid;

    modalRef.result.then((result) => {
      this.store.dispatch(new ClientsActions.Update({ uuid }));

      this.refreshClients();

      if (result.another) {
        this.newClient();
      }
    }).catch((error) => { });
  }

  viewClient(client: Client): void {
    const uuid = client.uuid || this.contentService.uuid();
    this.store.dispatch(new CandidatesActions.Show({ uuid }));

    const ngbModalOptions: NgbModalOptions = {
      windowClass: 'free-floating-modal',
      backdrop: false,
      size: 'lg'
    };

    const modalRef = this.modalService.open(ClientDetailComponent, ngbModalOptions);
    modalRef.componentInstance.uuid = uuid;

    modalRef.result.then((result) => {
      this.store.dispatch(new ClientsActions.Update({ uuid }));
      this.refreshClients();
    }).catch((error) => {
      if (error && error.delete) {
        this.store.dispatch(new ClientsActions.Delete({ uuid }));
        this.refreshClients();
      } else {
        this.store.dispatch(new ClientsActions.Load());
        this.refreshClients();
      }
    });
  }

  newCandidate(candidateTemplate?: Partial<Candidate>): void {
    if (this.isSimulated) {
      return;
    }

    const uuid = this.contentService.uuid();
    this.store.dispatch(new CandidatesActions.Show({ uuid }));

    this.store.dispatch(new CandidatesActions.SetCurrentPartial({ uuid, candidate: candidateTemplate || {} }));

    const ngbModalOptions: NgbModalOptions = {
      windowClass: 'free-floating-modal',
      backdrop: false,
      size: 'lg'
    };

    const modalRef = this.modalService.open(CandidateDetailComponent, ngbModalOptions);
    modalRef.componentInstance.uuid = uuid;

    modalRef.result.then((result) => {
      if (result.uploadedItem) {
        this.store.dispatch(new CandidatesActions.UploadResume({ uuid, file: result.uploadedItem, initialNotes: result.initialNotes }));
      } else {
        this.store.dispatch(new CandidatesActions.Update({ uuid, initialNotes: result.initialNotes }));

        this.store.dispatch(new CandidatesActions.Finished({ uuid }));
      }

      this.refreshCandidates();

      if (result.another) {
        this.newCandidate();
      }
    }).catch((error) => { });
  }

  viewCandidate(candidate: Candidate): void {
    const uuid = candidate.uuid || this.contentService.uuid();
    this.store.dispatch(new CandidatesActions.Show({ uuid }));

    const ngbModalOptions: NgbModalOptions = {
      windowClass: 'free-floating-modal',
      backdrop: false,
      size: 'lg'
    };

    const modalRef = this.modalService.open(CandidateDetailComponent, ngbModalOptions);
    modalRef.componentInstance.uuid = uuid;

    modalRef.result.then((result) => {
      if (result.uploadedItem) {
        this.store.dispatch(new CandidatesActions.UploadResume({ uuid, file: result.uploadedItem }));
      } else {
        this.store.dispatch(new CandidatesActions.Update({ uuid }));

        this.store.dispatch(new CandidatesActions.Finished({ uuid }));
      }

      this.refreshCandidates();
    }).catch((error) => {
      if (error && error.delete) {
        this.store.dispatch(new CandidatesActions.Delete({ uuid }));
        this.refreshCandidates();
      } else {
        this.store.dispatch(new CandidatesActions.Load());
        this.refreshCandidates();
      }

      this.store.dispatch(new CandidatesActions.Finished({ uuid }));
    });
  }

  selectNewMarketingClient(obj: any): void {
    const client: Client | undefined = _.find(this.clients, { company: obj.company });

    if (client) {
      this.eventBusService.emit({ event: 'clientChanged', value: client });
    }
  }

  selectRecruitingCallCandidate(obj: any): void {
    const candidate: Candidate | undefined = _.find(this.candidates,
      (candidateIter => (candidateIter.firstName + ' ' + candidateIter.lastName) === obj.candidate));

    if (candidate) {
      this.eventBusService.emit({ event: 'candidateChanged', value: candidate });
    }
  }

  selectTelesourcingCandidate(obj: any): void {
    if (obj && !!obj.company && !!obj.phone) {
      this.eventBusService.emit({ event: 'dataChanged', value: { company: obj.company, phone: obj.phone } });
    }
  }

  lookupNewLeadCandidate(obj: any): void {
    const candidate: Candidate | undefined = _.find(this.candidates,
      (candidateIter => candidateIter.firstName + ' ' + candidateIter.lastName === obj.name));

    if (candidate) {
      this.viewCandidate(candidate);
    }
    else {
      this.newCandidate(obj);
    }
  }

  lookupGeneratedLeadClient(obj: any): void {
    const client: Client | undefined = _.find(this.clients, { company: obj.company });

    if (client) {
      this.viewClient(client);
    }
    else {
      const obj2 = _.clone(obj);
      delete obj2.position;

      this.newClient(obj2);
    }
  }

  lookupPopMarketingCandidate(obj: any): void {
    const candidate: Candidate | undefined = _.find(this.candidates,
      (candidateIter => candidateIter.firstName + ' ' + candidateIter.lastName === obj.candidate));

    if (candidate) {
      this.viewCandidate(candidate);
    }
    else {
      this.newCandidate(obj);
    }
  }

  lookupPopMarketingClient(obj: any): void {
    const client: Client | undefined = _.find(this.clients, { company: obj.company });

    if (client) {
      this.viewClient(client);
    }
    else {
      this.newClient(obj);
    }
  }

  gotoInnerView(innerView: any): void {
    const candidate = this.candidates.find(candidat => candidat.firstName + ' ' + candidat.lastName === innerView.candidate);

    this.eventBusService.emit({ event: 'candidateChanged', value: candidate });
    this.router.navigate(['/innerview']);
  }

  print(): void {
    window.print();
  }

  clear(): void {
    if (this.isSimulated) {
      return;
    }

    const modalRef = this.modalService.open(CommonDialogComponent);

    modalRef.componentInstance.closeButtonText = 'Cancel';
    modalRef.componentInstance.actionButtonText = 'Clear Planner';
    modalRef.componentInstance.headerText = 'Clear Planner';
    modalRef.componentInstance.bodyText = 'Are you sure you want to completely clear the planner? This action is irreversible.';

    modalRef.result.then(() => {
      this.planner.generatedLeads = [{status: 'none', company: '', phone: '', position: '', hiringManager: '', source: '', notes: ''}];
      this.planner.popMarketing = [
        {status: 'none', candidate: '', company: '', phone: '', email: '', hiringManager: '', title: '', result: '', notes: ''}];
      this.planner.newMarketing = [
        {status: 'none', company: '', phone: '', email: '', hiringManager: '', title: '', result: '', notes: ''}];
      this.planner.existingCheckin = [
        {status: 'none', candidate: '', phone: '', hiringManager: '', phoneMgr: '', memo: '', result: '', notes: ''}];
      this.planner.innerViews = [
        {date: '', time: '', candidate: '', title: '', company: '', phone: '', email: '', source: '', form: '', notes: ''}];
      this.planner.telesourcing = [{status: 'none', company: '', phone: '', result: ''}];

      this.planner.recruitingCalls = [
        {status: 'none', project: '', candidate: '', title: '', company: '',
         phone: '', hiringManager: '', memo: '', action: '', notes: ''}];

      this.planner.sendouts = [{priority: '', status: 'none', date: '', time: '', candidate: '',
        hiringManager: '', company: '', phone: '', notes: ''}];
      this.planner.preps = [{priority: '', status: 'none', type: '', name: '', phone: '', memo: '', notes: ''}];
      this.planner.followUps = [{priority: '', name: '', type: '', phone: '', issue: '', result: '', notes: ''}];

      this.plannerService.update(this.planner).subscribe(() => {
        this.ngOnInit();
      });
    }).catch(() => { });
  }

  callTypeToClass(type: string): string {
    return (type || '').toLowerCase().replace(' ', '-').replace('.', '');
  }

  gotoGuide(type: string): void {
    switch (type) {
      case 'Cand. Prep':
        this.router.navigate(['/guides/candidate-prep-innerview']);
        break;

      case 'Cand. Prep Final':
        this.router.navigate(['/guides/candidate-prep-final']);
        break;

      case 'Cand. Debrief':
        this.router.navigate(['/guides/candidate-debrief']);
        break;

      case 'Cand. Debrief Final':
        this.router.navigate(['/guides/candidate-debrief-trial-closing']);
        break;

      case 'Close':
        this.router.navigate(['/guides/closing-candidate']);
        break;

      case 'Resignation':
        this.router.navigate(['/guides/resignation']);
        break;

      case 'Client Prep':
        this.router.navigate(['/guides/client-prep-first']);
        break;

      case 'Client Prep Final':
        this.router.navigate(['/guides/client-prep-final']);
        break;

      case 'Client Debrief':
        this.router.navigate(['/guides/client-debrief']);
        break;

      case 'Ref':
        this.router.navigate(['/referencecheck']);
        break;

      case 'Client Debrief Final':
        this.router.navigate(['/guides/client-debrief-trial-closing']);
        break;

      case 'Client Close':
        this.router.navigate(['/guides/closing-client']);
        break;

      default:
        return;
    }
  }

  toggleSelection(user: User): void {
    const idx = this.shareList.indexOf(user.email);

    if (idx > -1) {
      this.shareList.splice(idx, 1);
      this.shareListFull.splice(idx, 1);
    }
    else {
      this.shareList.push(user.email);
      this.shareListFull.push({email: user.email, firstName: user.firstName, lastName: user.lastName});
    }
  }

  share(): void {
    const modalRef = this.modalService.open(CommonDialogComponent);

    modalRef.componentInstance.closeButtonText = 'Cancel';
    modalRef.componentInstance.actionButtonText = 'Send';
    modalRef.componentInstance.headerText = 'Share the Daily Planner';
    modalRef.componentInstance.bodyText = 'Do you want to email a copy of your Daily Planner to the following people? ';
    modalRef.componentInstance.list = this.shareList;

    modalRef.result.then(() => {
      const jsPdfObj = new jsPDF({ unit: 'px', hotfixes: ['px_scaling'] });
      const plannerContainer = document.getElementById('planner-container');

      const session = this.sessionStateService.get();
      const user = (session.session.simulatedSession || session.user) as User & Session;

      if (plannerContainer) {
        jsPdfObj.html(plannerContainer, {
          callback: (doc) => {
            this.plannerService.share({
              recipients: this.shareListFull,
              email: user.email,
              blob: doc.output('blob')
            }).subscribe(() => {
              this.alertService.success('Daily Planner was shared with selected recipients');
            });
          },
          x: 0,
          y: 0,
          width: 794,
          windowWidth: 1500,
        });
      }
    });
  }
}
