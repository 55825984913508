import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MapField, RequiredFieldToMap, SourceFiled} from './map-columns.consts';

@Component({
  selector: 'app-map-columns',
  templateUrl: './map-columns.component.html',
  styleUrls: ['./map-columns.component.scss']
})
export class MapColumnsComponent implements OnChanges {
  @Input() jsonData: any[] | undefined;
  @Input() columnNames: string[] = [];
  @Input() destinationFields: MapField[] = [];
  @Input() platformFieldsMap: Record<string, string> = {}; // platform, RPb

  @Output() mappedFieldsChanged = new EventEmitter<Record<string, MapField>>();
  @Output() validationChanged = new EventEmitter<boolean>();
  @Output() loadingChanged = new EventEmitter<boolean>();

  sourceFields: SourceFiled[] = [];
  mappedFields: Record<string, MapField> = {}; // source, destination
  requiredFields: RequiredFieldToMap[] = [];
  isAllRequiredFieldsMapped = false;
  isStepContentRendered = false;

  readonly  numberOfExampleColumns = 100; // used to provide styling for the last column header

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.columnNames?.length) {
      this.setSourceFields();
    }

    if (this.destinationFields?.length) {
       this.setRequiredFields(this.destinationFields);
    }
  }

  setSourceFields(): void {
    this.sourceFields = this.columnNames.map(name => {
      const fieldToAutoMap = this.platformFieldsMap[name] || undefined;
      if (fieldToAutoMap) {
        const destinationField = this.destinationFields.find(d => d.name.toLowerCase() === fieldToAutoMap.toLowerCase());
        if (destinationField) {
          this.mappedFields[name] = destinationField;
        }
      }
      return new SourceFiled({name, fieldToAutoMap});
    });
    this.onMappedFieldsUpdate();
  }

  setRequiredFields(destinationFields: MapField[]): void {
    this.requiredFields = destinationFields.filter(f => f.isRequired).map(f => new RequiredFieldToMap(f));
    this.onMappedFieldsUpdate();
  }

  onDestinationFieldChange(sourceField: SourceFiled, select: any): void {
    if (!select) {
      return;
    }
    const destinationFieldName: string = select.value;
    const destinationField = this.destinationFields.find(d => d.name === destinationFieldName);

    if (destinationField) {
      this.mappedFields[sourceField.name] = destinationField;
    } else {
      delete this.mappedFields[sourceField.name];
    }
    this.onMappedFieldsUpdate();
  }

  onMappedFieldsUpdate(): void {
    this.validateMappedFields();
    this.validateRequiredFields();
    this.emitFormValidation();
    this.mappedFieldsChanged.emit(this.mappedFields);
  }
  validateMappedFields(): void {
    this.sourceFields.forEach(sourceField => {
      sourceField.isInvalid = false;
      const mappedSourceFieldValue = this.mappedFields[sourceField.name];
      if (mappedSourceFieldValue) {
        const allDestinationValues =  Object.values(this.mappedFields).filter(v => v.name === mappedSourceFieldValue.name);
        if (allDestinationValues?.length > 1 && !mappedSourceFieldValue.isMultiple) {
          sourceField.isInvalid = true;
        }
      }
    });
  }

  validateRequiredFields(): void {
    this.requiredFields.forEach(requiredField => {
      const relatedMappedField = Object.values(this.mappedFields).find(f => f.name === requiredField.name);
      requiredField.isMapped = !!relatedMappedField;
    });
    this.isAllRequiredFieldsMapped = this.requiredFields.every(f => f.isMapped);
  }

  emitFormValidation(): void {
    const isSourceFieldsValid = !this.sourceFields.find(f => f.isInvalid);
    const isValid = isSourceFieldsValid && this.isAllRequiredFieldsMapped;
    this.validationChanged.emit(isValid);
  }

  onStepRendered(): void {
    this.isStepContentRendered = true;
    this.loadingChanged.emit(false);
    this.cd.detectChanges();
  }

}
