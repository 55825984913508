<app-header></app-header>
<app-toolbar></app-toolbar>

<nav class="navbar navbar-default navbar-fixed-top navbar-global navbar-expand-md w-100 bg-white" role="navigation">
  <div class="container">
    <a class="navbar-brand text-dark" href="javascript:void"><i class="fas fa-users"></i>&nbsp;&nbsp;<b>Users</b></a>

    <div class="ml-auto" *ngIf="selected$ | async as selected">
      <div class="input-group">
        <select class="form-control col-6 mr-3" (change)="switchToAccount($event.target)" [hidden]="!isSuperUser">
          <option value="">-- all accounts --</option>
          <option *ngFor="let account of accounts" [selected]="accountId === account.uuid" [value]="account.uuid">{{account.displayName}}</option>
        </select>

        <input type="text" class="form-control" placeholder="Search" #usersrch (input)="search($event.target)" />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" (click)="search(null); usersrch.value = '';"><i class="fas fa-times-circle"></i></button>
          <button class="btn btn-outline-secondary" type="submit" (click)="search(usersrch)"><i class="fas fa-search"></i></button>
        </div>

        <button type="button" class="btn btn-secondary mr-3 ml-3" (click)="add()" [disabled]="!accountId" placement="bottom" ngbTooltip="Create User"><i class="fas fa-user"></i></button>
        <button type="button" class="btn btn-danger-inversed" (click)="delete()" [disabled]="!selected.length" placement="bottom" ngbTooltip="Delete User(s)"><i class="fas fa-trash"></i></button>
      </div>
    </div>
  </div>
</nav>

<br />

<div class="container-fluid main-container" *ngIf="{ users: users$ | async, pagination: pagination$ | async } as data">
  <ngx-table
    [data]="data.users || []"
    [configuration]="configuration"
    [columns]="columns"
    [pagination]="data.pagination || { limit: 100, offset: 0, count: (data.users || []).length }"
    (event)="eventEmitted($event)"></ngx-table>
</div>

<app-footer></app-footer>

<ng-template #columnActionTemplate>
  <button class="text-nowrap" (click)="hideColumn($event)">Hide Column</button>
</ng-template>

<ng-template #nameTpl let-row>
  <span>{{row.username}}</span>
</ng-template>

<ng-template #fullNameTpl let-row>
  <span>{{row.firstName}} {{row.lastName}}</span>
</ng-template>

<ng-template #loginTpl let-row>
  <span>{{row.lastLogin | date: 'medium'}}</span>
</ng-template>

<ng-template #activeTpl let-row>
  <span>{{row.active ? 'Yes' : 'No'}}</span>
</ng-template>

<ng-template #checkboxTpl let-row let-column="column">
  <div class="form-group">
    <label class="form-checkbox">
      <input type="checkbox" (change)="onChange(row, $event.target)" />
    </label>
  </div>
</ng-template>

<ng-template #notesTpl let-row>
  <span class="link" (click)="note(row.uuid)">Notes/Observations</span>
</ng-template>

<ng-template #simulateTpl let-row>
  <span [hidden]="row.uuid === userId || row.account_uuid !== myAccountId" class="link" (click)="simulate(row.uuid)">View Activity</span>
</ng-template>
