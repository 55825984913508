export enum PhoneType {
    'home' = 'home',
    'work' = 'work',
    'mobile' = 'mobile',
    'fax' = 'fax',
    'other' = 'other'
}

export interface Phone {
    type: PhoneType;
    number: string;
    id?: string;
}
